import {FieldBody} from '@ngx-mil-drupal/models/field-body.model';
import {MultipleRelationship, SingleRelationship} from '@ngx-mil-drupal/models/relationship.model';

export enum InnovationWorkflow {
  Draft = 'editorial_innovation_draft',
  Review = 'editorial_innovation_review',
  Vote = 'editorial_innovation_vote',
  Voted = 'editorial_innovation_voted',
  Undecided = 'editorial_innovation_undecided',
  Published = 'editorial_innovation_published',
  Archived = 'editorial_innovation_archived',
}

export class Innovation {

  id: string;
  type: string;
  attributes: InnovationAttributes;
  relationships: InnovationRelationShips;

}

export class InnovationAttributes {

  title: string;
  field_innovation_description: FieldBody;
  innovativeReason?: string;
  intellectualProperty?: string;
  field_innovation_workflow: InnovationWorkflow;
  changed: string;

}

export class InnovationRelationShips {
  field_free_tagging?: MultipleRelationship;
  field_media_images?: MultipleRelationship;
  uid?: SingleRelationship;
}
