import {Pipe, PipeTransform} from '@angular/core';
import {Resource, ResourceIdentifier} from 'ngrx-json-api';

@Pipe({
  name: 'innovationInArtboard'
})
export class InnovationInArtboardPipe implements PipeTransform {

  transform(artboard: Resource, dataToAdd: Resource): boolean {
    if (artboard && dataToAdd) {
      const innovations: ResourceIdentifier[] = artboard.relationships.field_innovation.data;
      return innovations.filter(resourceIdentifier => resourceIdentifier.id === dataToAdd.id).length > 0;
    }
    return false;
  }

}
