import {Direction, Query} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from './ngrx-json-api-definitions';
import {InnovationWorkflow} from '../models/innovation.model';
import {CartWorkflow} from '../models/cart.model';

export class NgrxJsonApiQueries {

  /**Example
   * static readonly queryPageNode: Query = {
    queryId: 'pageNode',
    type: NgrxJsonApiDefinitions.page.type,
    params: {
      include: [
        'field_paragraphs',
        'field_paragraphs.field_container',
        'field_paragraphs.field_container.field_prgp_image',
        'field_paragraphs.field_container.field_prgp_video'
      ]
    }
  };

   static readonly nodeQueries: { [nodeType: string]: Query } = {
    [NgrxJsonApiDefinitions.page.type]: NgrxJsonApiQueries.queryPageNode
  };
   */

  static readonly contains: string = 'CONTAINS';
  static readonly startsWith: string = 'STARTS_WITH';
  static readonly equals: string = '=';
  static readonly in: string = 'IN';

  static readonly bootZone: string = 'boot';

  static readonly mostPopularInnovationQueryId: string = 'mostPopularInnovations';

  static readonly queryInspirationNodeCollection: Query = {
    queryId: 'inspirations',
    type: NgrxJsonApiDefinitions.inspiration.type,
    params: {
      filtering: []
    }
  };

  static readonly queryVoteCollection: Query = {
    queryId: 'votes',
    type: NgrxJsonApiDefinitions.vote.type,
    params: {
      filtering: [
        {
          path: 'userId',
          value: 'user_id.id',
          operator: 'path'
        },
        {
          value: 'will be set later',
          path: 'userId',
          operator: 'value'
        },
        {
          path: 'userId',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        }
      ]
    }
  };

  static readonly queryPolling: Query = {
    queryId: 'polling',
    type: NgrxJsonApiDefinitions.innovation.type,
    params: {
      include: [
        'uid',
        'field_free_tagging',
        'field_node_thumbnail',
        'field_media_images.field_media_image',
        'field_media_videos.field_media_video_file',
        'field_media_videos.field_media_transcoded.field_media_file',
        'field_media_videos.field_media_thumbnails.field_media_thumbnail',
        'field_media_videos.thumbnail',
        'field_innovation_category'
      ],
      filtering: [
        {
          path: 'workflow',
          value: 'field_innovation_workflow',
          operator: 'path'
        },
        {
          value: InnovationWorkflow.Vote,
          path: 'workflow',
          operator: 'value'
        },
        {
          path: 'workflow',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        }
      ]
    }
  };

  static readonly queryWorkflowScheduledTransition: Query = {
    queryId: 'polling',
    type: NgrxJsonApiDefinitions.workflowScheduledTransition.type,
    params: {
      include: [
        'to_sid'
      ]
    }
  };

  static readonly queryCart: Query = {
    queryId: 'shareBox',
    type: NgrxJsonApiDefinitions.cart.type,
    params: {
      include: [
        'field_recipient_profiles',
        'field_cart_part',
        'field_cart_part.field_media_images.field_media_image',
        'field_cart_part.field_node_thumbnail'
      ],
      filtering: [
        {
          path: 'workflow',
          value: 'field_cart_workflow',
          operator: 'path'
        },
        {
          value: CartWorkflow.Draft,
          path: 'workflow',
          operator: 'value'
        },
        {
          path: 'workflow',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        }
      ]
    }
  };

  static readonly queryInspirationNodeCollectionInclInnovations: Query = {
    queryId: 'inspirationsCollection',
    type: NgrxJsonApiDefinitions.inspiration.type,
    params: {
      include: [
        'field_innovation.field_node_thumbnail'
      ],
      filtering: []
    }
  };

  static readonly queryInspirationNodeInclInnovations: Query = {
    queryId: 'inspirationFull',
    type: NgrxJsonApiDefinitions.inspiration.type,
    id: 'will be set later',
    params: {
      include: [
        'field_innovation.field_node_thumbnail',
        'field_innovation.field_media_images.field_media_image',
        'field_innovation.field_media_videos.field_media_video_file',
        'field_innovation.field_media_videos.field_media_transcoded.field_media_file',
        'field_innovation.field_media_videos.field_media_thumbnails.field_media_thumbnail',
        'field_innovation.field_media_videos.thumbnail',
        'field_innovation.field_free_tagging',
        'field_innovation.field_innovation_category',
        'field_innovation.field_innovation_status',
        'field_innovation.uid',
        'field_innovation.field_brands',
        'field_innovation.field_awards',
        'field_innovation.field_new_category'
      ]
    }
  };

  static readonly queryInnovationCollectionNode: Query = {
    queryId: 'innovationCollection',
    type: NgrxJsonApiDefinitions.innovation.type,
    params: {
      include: [
        'field_node_thumbnail',
        'field_innovation_category'
      ]
    }
  };

  static readonly queryInnovationNode: Query = {
    queryId: 'innovation',
    type: NgrxJsonApiDefinitions.innovation.type,
    params: {
      include: [
        'field_node_thumbnail',
        'field_media_images.field_media_image',
        'field_media_videos.field_media_video_file',
        'field_media_videos.field_media_transcoded.field_media_file',
        'field_media_videos.field_media_thumbnails.field_media_thumbnail',
        'field_media_videos.thumbnail',
        'field_free_tagging',
        'field_innovation_category',
        'field_innovation_status',
        'uid'
      ]
    }
  };

  static readonly queryNewsCollectionNode: Query = {
    queryId: 'newsCollection',
    type: NgrxJsonApiDefinitions.news.type,
    params: {
      include: [
        'field_node_thumbnail',
        'field_new_category'
      ]
    }
  };

  static readonly queryNewsNode: Query = {
    queryId: 'news',
    type: NgrxJsonApiDefinitions.news.type,
    params: {
      include: [
        'field_node_thumbnail',
        'field_media_images.field_media_image',
        'field_media_videos.field_media_video_file',
        'field_media_videos.field_media_transcoded.field_media_file',
        'field_media_videos.field_media_thumbnails.field_media_thumbnail',
        'field_media_videos.thumbnail',
        'field_free_tagging',
        'field_brands',
        'field_awards',
        'field_new_category',
        'uid'
      ]
    }
  };

  static readonly queryPromotedNews: Query = {
    queryId: 'promotedNews',
    type: NgrxJsonApiDefinitions.news.type,
    params: {
      include: [
        'field_node_thumbnail.field_media_image'
      ],
      sorting: [
        {
          api: 'created',
          direction: Direction.DESC
        }
      ],
      filtering: [
        {
          path: 'promoted',
          value: 'promote',
          operator: 'path'
        },
        {
          path: 'promoted',
          value: '1',
          operator: 'value'
        },
        {
          path: 'promoted',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        },
        {
          path: 'status',
          value: 'status',
          operator: 'path'
        },
        {
          path: 'status',
          value: '1',
          operator: 'value'
        },
        {
          path: 'status',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        }
      ]
    }
  };

  static readonly nodeQueries: { [nodeType: string]: Query } = {
    'node--innovation': NgrxJsonApiQueries.queryInnovationNode,
    'node--news': NgrxJsonApiQueries.queryNewsNode
  };

  static readonly innovationSearchQuery: Query = {
    queryId: 'autoComplete',
    type: NgrxJsonApiDefinitions.innovation.type,
    params: {
      page: {
        limit: 10,
        offset: 0
      },
      include: ['field_media_images.field_media_image'],
      filtering: [
        {
          path: 'freeText',
          value: 'title',
          operator: 'path'
        },
        {
          value: null,
          path: 'freeText',
          operator: 'value'
        },
        {
          path: 'freeText',
          value: NgrxJsonApiQueries.startsWith,
          operator: 'operator'
        }
      ]
    }
  };

  static readonly userSearchQuery: Query = {
    queryId: 'autoComplete',
    type: NgrxJsonApiDefinitions.mhProfile.type,
    params: {
      page: {
        limit: 10,
        offset: 0
      },
      sorting: [
        {
          api: 'field_ad_profile_last_name',
          direction: Direction.ASC
        }
      ],
      filtering: [
        {
          path: 'title',
          operator: 'operator',
          value: NgrxJsonApiQueries.contains
        },
        {
          path: 'title',
          operator: 'value'
        },
        {
          path: 'status',
          value: 1
        }
      ]
    }
  };

  static readonly taxonomyTermSearchQuery: Query = {
    queryId: 'autoComplete',
    type: 'will be set later',
    params: {
      page: {
        limit: 30,
        offset: 0
      },
      filtering: [
        {
          path: 'freeText',
          value: 'name',
          operator: 'path'
        },
        {
          value: null,
          path: 'freeText',
          operator: 'value'
        },
        {
          path: 'freeText',
          value: NgrxJsonApiQueries.startsWith,
          operator: 'operator'
        }
      ]
    }
  };

  static readonly taxonomyTermCollectionQuery: Query = {
    queryId: 'will be set later',
    type: 'will be set later'
  };

  static getMostPopularInnovationQuery(language: string): Query {
    return {
      queryId: NgrxJsonApiQueries.mostPopularInnovationQueryId,
      type: NgrxJsonApiDefinitions.searchIndex.type,
      params: {
        page: {
          limit: 20
        },
        include: ['field_node_thumbnail'],
        filtering: [
          {
            path: 'content_type',
            value: NgrxJsonApiDefinitions.ctInnovation
          },
          {
            path: 'langcode',
            value: language
          }
        ],
        sorting: [
          {
            api: 'field_inspiration_reverse_entity',
            direction: Direction.DESC
          }
        ]
      }
    };
  }


}

