import {Component, OnInit} from '@angular/core';
import {LanguageSwitcherService} from '../../services/language-switcher.service';
import {getRouteData} from '../../store/reducers';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {PathService} from '@ngx-mil-drupal/path.service';
import {AppConfigService} from '@config/app-config.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  nid$: Observable<string> = this.store.pipe(select(getRouteData)).pipe(
    filter(data => !!data && !!data.nid),
    map(data => data.nid)
  );

  alternativePath$: Observable<string> = combineLatest([this.nid$, this.languageSwitcherService.alternativeLanguage$])
    .pipe(
      filter(([nid, alternativeLanguage]) => !!nid && !!alternativeLanguage),
      switchMap(([nid, alternativeLanguage]) => {
        return this.pathService.resolve(alternativeLanguage + '/node/' + nid);
      }),
      filter(pathInfos => !!pathInfos?.metatags),
      map(pathInfos => {
        const canonicalUrl: string = pathInfos.metatags?.canonical_url?.attributes?.href.replace(
          this.appConfig.getConfig().backendUrl, ''
        );
        return canonicalUrl;
      })
    );

  constructor(
    public languageSwitcherService: LanguageSwitcherService,
    private store: Store<any>,
    private pathService: PathService,
    private appConfig: AppConfigService
  ) {
  }

  ngOnInit() {
  }

}
