import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, take, tap} from 'rxjs/operators';
import {
  AddContentToBoard,
  AddToShareBox,
  CreateBoard,
  RemoveContentFromBoard,
  RemoveFromShareBox,
  UserActionTypes
} from '../actions/user.actions';
import {ManyQueryResult, NgrxJsonApiService, NgrxJsonApiZoneService, Resource, ResourceIdentifier, uuid} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {CartWorkflow} from '../../models/cart.model';
import {UniquePipe} from 'ngx-pipes';

@Injectable()
export class UserEffects {


  
  addContentToBoard = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.AddContentToBoard),
    tap((action: AddContentToBoard) => {
        const board = JSON.parse(JSON.stringify(action.board));
        const content = action.content;
        board.relationships.field_innovation.data.unshift({id: content.id, type: content.type});
        const lightBoard: Resource = {
          id: board.id,
          type: board.type,
        };
        lightBoard.relationships = {
          field_innovation: {data: board.relationships.field_innovation.data}
        };
        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards)
          .patchResource({resource: lightBoard, toRemote: true});
      }
    )
  ), {dispatch: false});

  
  createBoard = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.CreateBoard),
    tap((action: CreateBoard) => {
        const content = action.content;
        const newArtboard: Resource = {
          id: uuid(),
          type: NgrxJsonApiDefinitions.inspiration.type,
          attributes: {
            title: action.name
          },
          relationships: {
            field_innovation: {
              data: [{
                id: content.id,
                type: content.type
              }]
            }
          }
        };
        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards)
          .postResource({resource: newArtboard, toRemote: true});
        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards).selectStoreResource(newArtboard).pipe(
          filter(resource => resource && resource.state === 'IN_SYNC'),
          take(1)
        ).subscribe(() => {
          this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards)
            .refreshQuery(NgrxJsonApiQueries.queryInspirationNodeCollection.queryId);
        });
      }
    )
  ), {dispatch: false});

  
  removeContentFromBoard = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.RemoveContentFromBoard),
    tap((action: RemoveContentFromBoard) => {
        const board = action.board;
        const content = action.content;
        const clonedBoard: Resource = {
          id: board.id,
          type: board.type,
          relationships: {
            field_innovation: {
              data: board.relationships.field_innovation.data
            }
          }
        };
        clonedBoard.relationships.field_innovation.data = clonedBoard.relationships.field_innovation.data
          .filter(innovationReference => innovationReference.id !== content.id);
        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards)
          .patchResource({resource: clonedBoard, toRemote: true});
      }
    )
  ), {dispatch: false});

  
  addToShareBox = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.AddToShareBox),
    tap((action: AddToShareBox) => {

        const shareBoxService: NgrxJsonApiZoneService = this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox);
        shareBoxService.selectManyResults(NgrxJsonApiQueries.queryCart.queryId).pipe(
          map((manyQueryResult: ManyQueryResult) => manyQueryResult.data),
          take(1)
        ).subscribe((carts) => {

          let cart: Resource;
          const contentsToAdd: ResourceIdentifier[] = action.contents.map(content => {
            return {id: content.id, type: content.type};
          });

          if (carts.length === 0 && action.contents?.length > 0) {
            cart = {
              type: NgrxJsonApiDefinitions.cart.type,
              id: uuid(),
              attributes: {
                field_cart_workflow: CartWorkflow.Draft
              },
              relationships: {
                field_cart_part: {
                  data: contentsToAdd
                }
              }
            };
            shareBoxService.postResource({resource: cart, toRemote: false});
          } else {
            if (action.contents?.length > 0) {
              const fullCart: Resource = JSON.parse(JSON.stringify(carts[0]));
              cart = {
                id: fullCart.id,
                type: fullCart.type,
                relationships: {
                  ...fullCart.relationships,
                  field_cart_part: {
                    data: this.uniquePipe.transform([
                      ...fullCart.relationships.field_cart_part.data,
                      ...contentsToAdd
                    ], 'id')
                  }
                }
              };
              shareBoxService.patchResource({resource: cart, toRemote: false});
            }
          }
          shareBoxService.apply();

          setTimeout(() => {
            this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox).selectStoreResource({id: cart.id, type: cart.type}).pipe(
              filter(resource => resource && resource.state === 'IN_SYNC'),
              take(1)
            ).subscribe(() => {
              this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox)
                .refreshQuery(NgrxJsonApiQueries.queryCart.queryId);
            });
          });

        });
      }
    )
  ), {dispatch: false});

  
  removeFromShareBox = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.RemoveFromShareBox),
    tap((action: RemoveFromShareBox) => {
        const content = action.content;
        const shareBoxService: NgrxJsonApiZoneService = this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox);
        shareBoxService.selectManyResults(NgrxJsonApiQueries.queryCart.queryId).pipe(
          map((manyQueryResult: ManyQueryResult) => manyQueryResult.data),
          take(1)
        ).subscribe((carts) => {
          let cart: Resource;
          if (content) {
            const fullCart: Resource = JSON.parse(JSON.stringify(carts[0]));
            cart = {
              id: fullCart.id,
              type: fullCart.type,
              relationships: {
                field_cart_part: {
                  data: [
                    ...fullCart.relationships.field_cart_part.data.filter(cartPart => cartPart.id !== content.id)
                  ]
                }
              }
            };
            shareBoxService.patchResource({resource: cart, toRemote: true});
          }

          this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox).selectStoreResource({id: cart.id, type: cart.type}).pipe(
            filter(resource => resource && resource.state === 'IN_SYNC'),
            take(1)
          ).subscribe(() => {
            // this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox).refreshQuery(NgrxJsonApiQueries.queryCart.queryId);
          });

        });
      }
    )
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private ngrxJsonApiService: NgrxJsonApiService,
    private uniquePipe: UniquePipe
  ) {
  }
}
