import {Pipe, PipeTransform} from '@angular/core';
import {AppWorkflowStates} from '../models/app-workflow-states';
import {NewsWorkflow} from '../models/news.model';
import {InnovationWorkflow} from '../models/innovation.model';

@Pipe({
  name: 'getAppWorkflowState'
})
export class GetAppWorkflowStatePipe implements PipeTransform {

  transform(drupalWorkflowName: string): { label: string, state: string } {
    const draftInfos: any = {label: 'WORKFLOW_STATES.DRAFT', state: AppWorkflowStates.draft};
    const pendingInfos: any = {label: 'WORKFLOW_STATES.PENDING', state: AppWorkflowStates.pending};
    const acceptedInfos: any = {label: 'WORKFLOW_STATES.ACCEPTED', state: AppWorkflowStates.accepted};
    const rejectedInfos: any = {label: 'WORKFLOW_STATES.REJECTED', state: AppWorkflowStates.rejected};
    const union: { [workflowId: string]: { label: string, state: string } } = {
      [NewsWorkflow.Draft]: draftInfos,
      [InnovationWorkflow.Draft]: draftInfos,
      [NewsWorkflow.Review]: pendingInfos,
      [InnovationWorkflow.Review]: pendingInfos,
      [NewsWorkflow.Published]: acceptedInfos,
      [InnovationWorkflow.Published]: acceptedInfos,
      [InnovationWorkflow.Vote]: pendingInfos,
      [InnovationWorkflow.Voted]: pendingInfos,
      [NewsWorkflow.Archived]: rejectedInfos,
      [InnovationWorkflow.Archived]: rejectedInfos,
    };
    // console.log('drupalWorkflowName', drupalWorkflowName);
    let workflowKey: { label: string, state: string } = union[drupalWorkflowName];
    if (!workflowKey) {
      workflowKey = {label: 'unknown yet', state: 'unknown yet'};
    }
    return workflowKey;
  }

}
