<div class="row infos-window-block">

  <div class="col-12 d-flex flex-column flex-sm-row">

    <div class="d-flex flex-column flex-fill">
      <span [innerHTML]="userService.userName$|async"></span>
      <span [innerHTML]="userService.userMail$|async"></span>
    </div>

    <div class="d-flex flex-none mt-2 mt-sm-0">
      <button mat-flat-button (click)="onLogOutClick()" mat-dialog-close>
        <mat-icon svgIcon="icon-logout"></mat-icon>
        <span class="ml-2" [innerHTML]="'APP.LOG_OUT'|translate"></span>
      </button>
    </div>

  </div>

</div>

<div class="row infos-window-block transparent">

  <div class="col-12">

    <h3 [innerHTML]="'MY_ACCOUNT.MY_ROLES.TITLE'|translate" class="mb-1"></h3>

    <ul class="mt-1 list-style-type-none p-0 m-0">
      <ng-template ngFor [ngForOf]="(roles$|async)|filterBy: ['scope']:'front'" let-role>

        <li class="mb-2">
          <ng-template
            [ngTemplateOutlet]="userRoleTemplate"
            [ngTemplateOutletContext]="{role: role}">
          </ng-template>
        </li>

      </ng-template>
    </ul>

    <ng-container *ngIf="((roles$|async)|filterBy: ['scope']:'back') as backRoles">

      <ng-container *ngIf="backRoles.length > 0">

        <div class="d-flex flex-fill align-items-center mt-5 mb-1">
          <h3 [innerHTML]="'MY_ACCOUNT.DASHBOARD_ROLES.TITLE'|translate" class="flex-fill"></h3>
          <a color="primary" target="_blank" rel="noopener" mat-flat-button [href]="appConfig.getConfig().backendUrl">Go to Dashboard</a>
        </div>

        <ul class="mt-1 list-style-type-none p-0 m-0">
          <ng-template ngFor [ngForOf]="backRoles" let-role>

            <li class="mb-2">
              <ng-template
                [ngTemplateOutlet]="userRoleTemplate"
                [ngTemplateOutletContext]="{role: role}">
              </ng-template>
            </li>

          </ng-template>
        </ul>

      </ng-container>

    </ng-container>

  </div>

</div>

<ng-template #userRoleTemplate let-role="role">
  <p [innerHTML]="role.label" class="mb-0 mat-body-2"></p>
  <p class="mb-2" [innerHTML]="('MY_ACCOUNT.ROLES.' + role.id)|translate"></p>

  <ng-container *ngIf="role.id === 'administrator'">

    <button mat-flat-button color="accent" (click)="onAutoPlayClick()">AutoPlay</button>

  </ng-container>

</ng-template>
