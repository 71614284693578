import {AfterViewInit, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../templates.service';
import {Templates} from '../templates';
import {MyContributionsService} from '../../services/my-contributions.service';

@Component({
  selector: 'app-toggle-button-bar-templates',
  templateUrl: './toggle-button-bar-templates.component.html',
  styleUrls: ['./toggle-button-bar-templates.component.scss']
})
export class ToggleButtonBarTemplatesComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('toggleBarStateFiltersTemplate', /* TODO: check static flag */ {static: true})
  toggleBarStateFiltersTemplate: TemplateRef<any>;

  constructor(
    public templatesService: TemplatesService,
    private myContributionsService: MyContributionsService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.toggleBarStateFilters, this.toggleBarStateFiltersTemplate);
  }

  onChangeStateFilter(changeEmitter: EventEmitter<any>, value: any) {
    this.myContributionsService.currentFacet = {state: value};
  }

}
