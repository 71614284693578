<ng-container *ngIf="apolloService">

  <ul class="list-style-type-none p-0 m-0">
    <ng-template ngFor [ngForOf]="itemsByContentType$|async" let-listItem>
      <li>
        <ng-template
          [ngTemplateOutlet]="miniListItemTemplate"
          [ngTemplateOutletContext]="{data:listItem}">
        </ng-template>
      </li>
    </ng-template>
  </ul>

</ng-container>

<ng-template
  #miniListItemTemplate
  let-data="data">

  <ng-template [ngTemplateOutlet]="(templatesService.getTemplate$('listLink')|async)"
               [ngTemplateOutletContext]="{data: data, template: (templatesService.getTemplate$('listSmallRenderer')|async)}">
  </ng-template>

</ng-template>
