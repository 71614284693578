<div class="row no-gutters flex-fill d-flex overflow-hidden">

  <div class="col-12 overflow-hidden max-height-100">
    <app-image-renderer
      class="d-flex w-100 h-100"
      [imageMedia]="data"
      [derivative]="'original_640'">
    </app-image-renderer>
  </div>

</div>
