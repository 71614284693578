// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {NgrxJsonApiService, StoreResource} from '@madeinlune/ngrx-json-api';
import {getAuthClaims} from '../store/reducers';
import {map, switchMap} from 'rxjs/operators';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';

export const USER = new InjectionToken<Observable<StoreResource>>(
  'A stream with the User Resource'
);

export const USER_FIRST_NAME = new InjectionToken<Observable<string>>(
  'A stream with the User fist name'
);

export const USER_PROVIDERS: Provider[] = [
  {
    provide: USER,
    deps: [Store, NgrxJsonApiService],
    useFactory: userFactory
  },
  {
    provide: USER_FIRST_NAME,
    deps: [USER],
    useFactory: userFirstNameFactory
  }
];

export function userFactory(
  store: Store<any>,
  ngrxJsonApiService: NgrxJsonApiService
): Observable<StoreResource> {
  return store.pipe(
    select(getAuthClaims),
    switchMap(claims => {
      return ngrxJsonApiService.getZone('user')
        .selectStoreResource({id: claims.sub, type: NgrxJsonApiDefinitions.user.type});
    })
  );
}

export function userFirstNameFactory(
  user$: Observable<StoreResource>
): Observable<string> {
  return user$.pipe(
    map(user => user?.attributes?.field_user_firstname?.toLowerCase())
  );
}

