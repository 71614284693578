import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {MenusService} from '../../services/menus.service';
import {TemplatesService} from '../../templates.service';
import {Templates} from '../templates';
import {ResponsiveService} from '../../services/responsive.service';
import {Store} from '@ngrx/store';
import {OpenWindow} from '../../store/actions/app-core.actions';
import {TranslateService} from '@ngx-translate/core';
import {RouterService} from '../../services/router.service';
import {take} from 'rxjs/operators';
import {MatDialogConfig} from '@angular/material/dialog';
import {PwaService} from '../../pwa.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  userName: string;

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    public menusService: MenusService,
    public templatesService: TemplatesService,
    public responsiveService: ResponsiveService,
    private cdr: ChangeDetectorRef,
    public routerService: RouterService,
    public pwaService: PwaService
  ) {
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {

  }

  openUserAccount() {
    this.openWindow(Templates.userAccount, 'MY_ACCOUNT.TITLE');
  }

  openAppSettings() {
    this.openWindow(Templates.settings, 'SETTINGS.TITLE');
  }

  openWindow(templateId: string, titleKey: string) {

    this.responsiveService.isXs$
      .pipe(take(1))
      .subscribe(isXs => {

        const title: string = this.translateService.instant(titleKey);

        const matDialogConfig: MatDialogConfig = {
          minWidth: '600px',
          maxWidth: '600px',
          minHeight: '500px',
          maxHeight: '80vh',
          panelClass: 'edit-theme',
          disableClose: true
        };

        if (isXs) {
          matDialogConfig.minWidth = '100vw';
          matDialogConfig.maxWidth = '100vw';
          matDialogConfig.minHeight = '100vh';
          matDialogConfig.maxHeight = '100vh';
        }

        this.store.dispatch(new OpenWindow(title,
          {
            contentTemplate: templateId,
            classes: 'edit-window overflow-hidden'
          },
          matDialogConfig));

      });

  }

  get userIconsMenuTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.userFunctionMenu);
  }

}
