import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../../services/responsive.service';
import {TemplatesService} from '../../../templates.service';
import {ListThumbSizes} from '../../../models/list-thumb-sizes.model';
import {OpenWindow, SetListThumbSize, UpdateBreadcrumb} from '../../../store/actions/app-core.actions';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import {getListThumbSize, getRouteData, getRouteParams} from '../../../store/reducers';
import {Observable} from 'rxjs';
import {Templates} from '../../templates';
import {BreadcrumbsService} from '../../../services/breadcrumbs.service';
import {distinctUntilChanged, filter, map, mergeMap, share, switchMap, take} from 'rxjs/operators';
import {Breadcrumb} from '../../../models/breadcrumb';
import {NgrxJsonApiService, Resource, uuid} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from '../../../ngrx-json-api/ngrx-json-api-definitions';
import {MatInput} from '@angular/material/input';
import {NgrxJsonApiQueries} from '../../../ngrx-json-api/ngrx-json-queries';

@Component({
  selector: 'app-main-header-templates',
  templateUrl: './main-header-templates.component.html',
  styleUrls: ['./main-header-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainHeaderTemplatesComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  listThumbSize$: Observable<ListThumbSizes> = this.store.pipe(
    select(getListThumbSize)
  );

  routeParams$: Observable<any> = this.store.pipe(
    select(getRouteParams),
  );

  routeData$: Observable<any> = this.store.pipe(
    select(getRouteData),
  );

  headerIcon$: Observable<string> = this.routeData$.pipe(
    filter(routeData => !!routeData),
    map(routeData => routeData.headerIcon)
  );

  isInspirations$: Observable<boolean> = this.routeParams$.pipe(
    filter(params => !!params),
    map(params => (params.inspirationId !== null && params.inspirationId !== undefined))
  );

  hasArtboardsZone$: Observable<boolean> = this.store.pipe(
    filter(store => {
      return store['NgrxJsonApi'] && store['NgrxJsonApi']['zones'] && store['NgrxJsonApi']['zones'][NgrxJsonApiDefinitions.zoneArtBoards];
    }),
    map(store => {
      return true;
    }),
    take(1)
  );

  @ViewChild('mainHeaderTemplate', /* TODO: check static flag */ {static: true})
  mainHeaderTemplate: TemplateRef<any>;

  @ViewChild('mainHeaderSearchTemplate', /* TODO: check static flag */ {static: true})
  mainHeaderSearchTemplate: TemplateRef<any>;

  @ViewChild('mainHeaderInspirationsTemplate', /* TODO: check static flag */ {static: true})
  mainHeaderInspirationsTemplate: TemplateRef<any>;

  @ViewChild('mainHeaderContributionsTemplate', /* TODO: check static flag */ {static: true})
  mainHeaderContributionsTemplate: TemplateRef<any>;

  @ViewChild('createInspirationActionsTemplate', /* TODO: check static flag */ {static: true})
  createInspirationActionsTemplate: TemplateRef<any>;

  @ViewChild('createInspirationContentTemplate', /* TODO: check static flag */ {static: true})
  createInspirationContentTemplate: TemplateRef<any>;

  @ViewChild('breadcrumbsTemplate', /* TODO: check static flag */ {static: true})
  breadcrumbsTemplate: TemplateRef<any>;

  @ViewChild('inspirationNameInput', /* TODO: check static flag */ { read: MatInput })
  inspirationNameInput: MatInput;

  showSizeControls$: Observable<boolean>;

  inspirationBreadcrumb$: Observable<Breadcrumb>;

  uuid: string;

  constructor(
    public responsiveService: ResponsiveService,
    public templatesService: TemplatesService,
    private store: Store<fromRoot.State>,
    private breadcrumbService: BreadcrumbsService,
    private ngrxJsonApiService: NgrxJsonApiService,
  ) {
  }

  ngOnInit() {

    this.uuid = uuid();

    this.showSizeControls$ = this.breadcrumbService.breadcrumbs$.pipe(
      filter(breadcrumbs => !!breadcrumbs && breadcrumbs.length > 0),
      map(breadcrumbs => breadcrumbs[breadcrumbs.length - 1]),
      map(lastBreadcrumb => {
        const showSizeControls: boolean = lastBreadcrumb.data && lastBreadcrumb.data.showSizeControls === true;
        return showSizeControls;
      })
    );

    this.inspirationBreadcrumb$ = this.breadcrumbService.breadcrumbs$.pipe(
      filter(breadcrumbs => !!breadcrumbs && breadcrumbs.length > 0),
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      }),
      map(breadcrumbs => breadcrumbs.filter(breadcrumb => !!breadcrumb.params.inspirationId).shift()),
      filter(inspirationBreadcrumb => !!inspirationBreadcrumb),
      switchMap(inspirationBreadcrumb => {
        return this.hasArtboardsZone$.pipe(
          switchMap(hasArtboardsZone => {
            return this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards)
              .selectStoreResource({id: inspirationBreadcrumb.params.inspirationId, type: NgrxJsonApiDefinitions.inspiration.type})
              .pipe(
                filter(inspiration => !!inspiration),
                map(inspiration => {
                  return {...inspirationBreadcrumb, title: inspiration.attributes.title};
                }),
                distinctUntilChanged((oldValue, newValue) => {
                  return JSON.stringify(oldValue) === JSON.stringify(newValue);
                })
              );
          })
        );
      }),
      share()
    );

    this.inspirationBreadcrumb$.pipe(
      filter(inspirationBreadcrumb => !!inspirationBreadcrumb && !!inspirationBreadcrumb.title)
    ).subscribe(inspirationBreadcrumb => {
      this.store.dispatch(new UpdateBreadcrumb(inspirationBreadcrumb));
    });

  }

  ngAfterViewInit(): void {

    this.templatesService.addTemplate(Templates.mainHeader, this.mainHeaderTemplate);
    this.templatesService.addTemplate(Templates.mainHeaderSearch, this.mainHeaderSearchTemplate);
    this.templatesService.addTemplate(Templates.mainHeaderInspirations, this.mainHeaderInspirationsTemplate);
    this.templatesService.addTemplate(Templates.mainHeaderContributions, this.mainHeaderContributionsTemplate);
    this.templatesService.addTemplate(Templates.breadcrumbs, this.breadcrumbsTemplate);

    this.templatesService.addTemplate(Templates.createInspirationActions, this.createInspirationActionsTemplate);
    this.templatesService.addTemplate(Templates.createInspirationContent, this.createInspirationContentTemplate);
  }

  changeListThumbSize(size: ListThumbSizes) {
    this.store.dispatch(new SetListThumbSize(size));
  }

  openNewInspirationWindow() {
    this.store.dispatch(new OpenWindow('WINDOWS.CREATE_NEW_BOARD', {
      contentTemplate: Templates.createInspirationContent,
      actionsTemplate: Templates.createInspirationActions
    }));
  }

  createInspiration(inspirationTitle: string) {

    if (inspirationTitle && inspirationTitle.length > 0) {

      const newInspiration: Resource = {
        id: uuid(),
        type: NgrxJsonApiDefinitions.inspiration.type,
        attributes: {
          title: inspirationTitle
        }
      };

      this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .postResource({resource: newInspiration, toRemote: true});

      this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectStoreResource(newInspiration)
        .pipe(
          filter(newInspirationResource => newInspirationResource.state === 'IN_SYNC'),
          take(1)
        ).subscribe(() => {
        this.ngrxJsonApiService
          .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
          .refreshQuery(NgrxJsonApiQueries.queryInspirationNodeCollectionInclInnovations.queryId);
      });
    }

  }

  get searchIndexResultCountTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.searchIndexResultCount);
  }

  get inspirationActionsTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.inspirationsActions);
  }

  get contributionsActionsTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.myContributionsActions);
  }

  get bigIcon(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.bigIcon);
  }

}
