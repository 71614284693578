<ng-container *ngIf="(drupalFormIoService.schema$|async) as schema;else loadingTemplate">

  <ng-container *ngIf="(model$|async) as model;else loadingTemplate">

    <sf-form
      class="overflow-hidden d-flex flex-fill flex-column"
      (isValid)="isFormValid = $event"
      (error)="onFormError($event)"
      (onErrorChange)="onFormError($event)"
      (onErrorsChange)="onFormError($event)"
      (onChange)="onFormChange($event)"
      [actions]="formActions"
      [model]="model"
      [schema]="schema">
    </sf-form>

  </ng-container>

</ng-container>

<ng-template #loadingTemplate>
  <div class="position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center">
    <mat-progress-spinner [diameter]="40" [color]="'accent'" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
</ng-template>
