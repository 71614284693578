import {Action} from '@ngrx/store';
import {Resource, ResourceIdentifier} from 'ngrx-json-api';

export enum UserActionTypes {
  AddContentToBoard = '[User] Add Content To Board',
  RemoveContentFromBoard = '[User] Remove Content From Board',
  CreateBoard = '[User] Create Board',
  AddToShareBox = '[User] Add To ShareBox',
  RemoveFromShareBox = '[User] Remove From ShareBox',
}


export class AddContentToBoard implements Action {
  readonly type = UserActionTypes.AddContentToBoard;

  constructor(public board: Resource, public content: Resource) {

  }
}

export class RemoveContentFromBoard implements Action {
  readonly type = UserActionTypes.RemoveContentFromBoard;

  constructor(public board: Resource, public content: Resource) {

  }
}

export class CreateBoard implements Action {
  readonly type = UserActionTypes.CreateBoard;

  constructor(public name: string, public content: any) {

  }
}

export class RemoveFromShareBox implements Action {
  readonly type = UserActionTypes.RemoveFromShareBox;

  constructor(public content: Resource) {

  }
}

export class AddToShareBox implements Action {
  readonly type = UserActionTypes.AddToShareBox;

  constructor(public contents: ResourceIdentifier[]) {

  }
}


export type UserActionsUnion =
  AddContentToBoard |
  CreateBoard |
  RemoveContentFromBoard |
  AddToShareBox |
  RemoveFromShareBox;
