<ng-container *ngIf="swiper">

  <ul class="list-style-type-none p-0 m-0 d-flex flex-fill">

    <ng-template ngFor [ngForOf]="data" let-media let-i="index">

      <li
        class="d-flex mr-2 cursor-pointer"
        [class.active]="(swiper.slideIndex$|async) === i"
        (click)="swiper.goToIndex(i)">

        <ng-container *ngIf="media; else defaultImage">

          <ng-container *ngIf="media.type === 'media--image'">
            <app-image-renderer [imageMedia]="media" [derivative]="'original_128'"></app-image-renderer>
          </ng-container>

          <ng-container *ngIf="media.type === 'media--original_video'">

            <ng-container *ngIf="media?.relationships?.field_media_thumbnails?.data?.length > 0; else defaultVideoImage">

              <ng-container
                *ngIf="(media.relationships.field_media_thumbnails.data|jaSelectStoreResources:zone?zone:'drupal'+'-'+(languageSwitcherService.currentLanguage$|async))|async as thumbnails; else defaultVideoImage">

                <ng-template ngFor [ngForOf]="thumbnails" let-thumbnail let-i="index">

                  <ng-container *ngIf="i == 1">

                    <app-image-renderer [relationshipName]="'field_media_thumbnail'" [imageMedia]="thumbnail"
                                        [derivative]="'original_128'"></app-image-renderer>

                  </ng-container>

                </ng-template>

              </ng-container>

            </ng-container>

          </ng-container>

          <ng-container *ngIf="media.type === 'media--video'">

            <ng-container *ngIf="media?.meta?.video?.thumbnailUrl as thumbnailUrl">
              <img [src]="thumbnailUrl"/>
            </ng-container>

          </ng-container>

          <ng-container
            *ngIf="media?.type === 'media--remote_video'">

            <ng-container *ngIf="media?.relationships?.thumbnail?.data; else defaultYouTubeImage">

              <ng-container
                *ngIf="(media?.relationships?.thumbnail?.data|jaSelectStoreResource:zone?zone:'drupal'+'-'+(languageSwitcherService.currentLanguage$|async))|async as thumbnail; else defaultYouTubeImage">

                <img [src]="thumbnail?.attributes?.uri?.url|mediaUrl"/>

              </ng-container>

            </ng-container>

          </ng-container>

        </ng-container>

      </li>

    </ng-template>

  </ul>

</ng-container>

<ng-template #defaultImage>

  <img [src]="'assets/media/default_picture_win.jpg'"/>

</ng-template>

<ng-template #defaultYouTubeImage>

  <img class="default-thumb" [src]="'assets/media/youtube_default_thumb.jpg'"/>

</ng-template>

<ng-template #defaultVideoImage>

  <img class="default-thumb" [src]="'assets/media/movie_default_thumb.jpg'"/>

</ng-template>
