import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {distinctUntilChanged, filter, map, take, tap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {select, Store} from '@ngrx/store';
import * as fromRoot from './store/reducers';
import {
  getAuthenticated,
  getAuthenticatedLoaded,
  getBreakpoint,
  getCurrentLanguage,
  getCurrentUrl,
  getDefaultLanguage,
  getListThumbSize,
  getRouteAppClassName,
  getRouteData,
  getRouteIsHome,
  getRouteMainContentGroupClassName,
  getRouteMainGroupClassName,
  getRouteTopGroupClassName
} from './store/reducers';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {BreakpointObserver, Breakpoints, BreakpointState, MediaMatcher} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {
  SetAvailableLanguages,
  SetBreakpoint,
  SetCurrentLanguage,
  SetDefaultLanguage,
  SetPageTitle,
  UpdateBreadcrumbs
} from './store/actions/app-core.actions';
import {DomSanitizer} from '@angular/platform-browser';
import {NgrxJsonApiService, Resource, ResourceIdentifier} from 'ngrx-json-api';
import {LoaderService} from './services/loader.service';
import {OAuthModuleConfig, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';
import {AppConfigService} from './config/app-config.service';
import {Apollo} from 'apollo-angular';
import {PathInfos} from '@ngx-mil-drupal/models/path-infos.model';
import {TemplatesService} from './templates.service';
import {ImagesSliderComponent} from './components/images-slider/images-slider.component';
import {getPathInfos} from '@ngx-mil-drupal/store/reducers/path.reducers';
import {MatSidenav} from '@angular/material/sidenav';
import {UserService} from './services/user.service';
import {Templates} from './components/templates';
import {ListThumbSizes} from './models/list-thumb-sizes.model';
import {ResponsiveService} from './services/responsive.service';
import {PwaService} from './pwa.service';
import {SideMenuService} from './services/side-menu.service';
import {Breadcrumb} from './models/breadcrumb';
import {Angulartics2} from 'angulartics2';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';
import {VERSION_INFO} from '../environments/version';
import {ScrollDispatcher} from '@angular/cdk/overlay';
import {DbService} from './services/db.service';
import {IS_STATIC_ROUTE, PAGE_TITLE, STATIC_ROUTE_TITLE} from './providers/route.providers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {

  authenticatedLoaded$: Observable<boolean> = this.store.pipe(select(getAuthenticatedLoaded));

  authenticated$: Observable<boolean> = this.store.pipe(select(getAuthenticated));

  appClassName$: Observable<string> = this.store.pipe(select(getRouteAppClassName));
  _appClassName: string;

  mainGroupClassName$: Observable<string> = this.store.pipe(select(getRouteMainGroupClassName));

  mainContentGroupClassName$: Observable<string> = this.store.pipe(select(getRouteMainContentGroupClassName));

  topGroupClassName$: Observable<string> = this.store.pipe(select(getRouteTopGroupClassName));

  routeIsHome$: Observable<boolean> = this.store.pipe(select(getRouteIsHome));

  breakpoint$ = this.store.pipe(select(getBreakpoint));

  topContainerOutletActive = false;

  openSideNav$: Observable<boolean> = this.store.pipe(
    select(getRouteData),
    map(data => {
      return data && data.openSideBar;
    })
  );

  showMiniList$: Observable<boolean> = this.store.pipe(
    select(getRouteData),
    map(data => {
      return data && (data.context === 'innovation' || data.context === 'news');
    }),
    distinctUntilChanged((oldValue, newValue) => {
      return oldValue === newValue;
    })
  );

  currentLanguage$: Observable<string> = this.store.pipe(select(getCurrentLanguage));

  listThumbSize$: Observable<ListThumbSizes> = this.store.pipe(
    select(getListThumbSize)
  );

  pathInfos$: Observable<PathInfos> = this.store.pipe(
    select(getPathInfos),
    filter(pathInfos => !!pathInfos));

  currentUrl$: Observable<string> = this.store.pipe(select(getCurrentUrl));

  @HostBinding('class.dark-theme')
  darkTheme = false;

  @ViewChild('imageSlider', /* TODO: check static flag */ {static: true})
  imageSlider: TemplateRef<ImagesSliderComponent>;

  @ViewChild('newsDescriptionTemplate', /* TODO: check static flag */ {static: true})
  newsDescriptionTemplate: TemplateRef<any>;

  @ViewChild('simplePageTemplate', /* TODO: check static flag */ {static: true})
  simplePageTemplate: TemplateRef<any>;

  @ViewChild('bodyTemplate', /* TODO: check static flag */ {static: true})
  bodyTemplate: TemplateRef<any>;

  @ViewChild('defaultNodeTemplate', /* TODO: check static flag */ {static: true})
  defaultNodeTemplate: TemplateRef<any>;

  @ViewChild('listLinkTemplate', /* TODO: check static flag */ {static: true})
  listLinkTemplate: TemplateRef<any>;

  @ViewChild('linkButtonTemplate', /* TODO: check static flag */ {static: true})
  linkButtonTemplate: TemplateRef<any>;

  @ViewChild('searchResultCountTemplate', /* TODO: check static flag */ {static: true})
  searchResultCountTemplate: TemplateRef<any>;

  @ViewChild('myContributionsTemplate', /* TODO: check static flag */ {static: true})
  myContributionsTemplate: TemplateRef<any>;

  @ViewChild('myContributionsHeaderTemplate', /* TODO: check static flag */ {static: true})
  myContributionsHeaderTemplate: TemplateRef<any>;

  @ViewChild('myPollTemplate', /* TODO: check static flag */ {static: true})
  myPollTemplate: TemplateRef<any>;

  @ViewChild('myShareTemplate', /* TODO: check static flag */ {static: true})
  myShareTemplate: TemplateRef<any>;

  @ViewChild('searchFullTextTemplate', /* TODO: check static flag */ {static: true})
  searchFullTextTemplate: TemplateRef<any>;

  @ViewChild('searchFullTextDetailTemplate', /* TODO: check static flag */ {static: true})
  searchFullTextDetailTemplate: TemplateRef<any>;

  @ViewChild('searchHeaderTemplate', /* TODO: check static flag */ {static: true})
  searchHeaderTemplate: TemplateRef<any>;

  @ViewChild('cartFormTemplate', /* TODO: check static flag */ {static: true})
  cartFormTemplate: TemplateRef<any>;

  @ViewChild('searchFiltersGroupRefTemplate', /* TODO: check static flag */ {static: true})
  searchFiltersGroupRefTemplate: TemplateRef<any>;

  @ViewChild('innovationDescriptionTemplate', /* TODO: check static flag */ {static: true})
  innovationDescriptionTemplate: TemplateRef<any>;

  @ViewChild('innovationWhyTemplate', /* TODO: check static flag */ {static: true})
  innovationWhyTemplate: TemplateRef<any>;

  @ViewChild('templatesDialog', /* TODO: check static flag */ {static: true})
  templatesDialog: TemplateRef<any>;

  @ViewChild('freeTagsTemplate', /* TODO: check static flag */ {static: true})
  freeTagsTemplate: TemplateRef<any>;

  @ViewChild('brandTagsTemplate', /* TODO: check static flag */ {static: true})
  brandTagsTemplate: TemplateRef<any>;

  @ViewChild('innovationInfosTemplate', /* TODO: check static flag */ {static: true})
  innovationInfosTemplate: TemplateRef<any>;

  @ViewChild('appFooterTemplate', /* TODO: check static flag */ {static: true})
  appFooterTemplate: TemplateRef<any>;

  @ViewChild('awardTagsTemplate', /* TODO: check static flag */ {static: true})
  awardTagsTemplate: TemplateRef<any>;

  @ViewChild('newsCategoryLaunchDateTemplate', /* TODO: check static flag */ {static: true})
  newsCategoryLaunchDateTemplate: TemplateRef<any>;


  _sideMainMenu: MatSidenav;
  @ViewChild('sideMainMenu')
  set sideMainMenu(value: MatSidenav) {
    this._sideMainMenu = value;
  }

  _sideFiltersMenu: MatSidenav;
  @ViewChild('sideFiltersMenu')
  set sideFiltersMenu(value: MatSidenav) {
    this._sideFiltersMenu = value;
    this.sideMenuService.sideFiltersMenu = this._sideFiltersMenu;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.shiftKey) {
      if ($event.key === 'E') {
        this.pwaService.openAppSettings();
      } else if ($event.key === 'T') {
        this.openAppTemplates();
      }
    }
  }

  constructor(
    @Inject(PAGE_TITLE) private pageTitle$: Observable<string>,
    @Inject(IS_STATIC_ROUTE) private isStaticRoute$: Observable<boolean>,
    @Inject(STATIC_ROUTE_TITLE) private staticRouteTitle$: Observable<string>,
    private appConfig: AppConfigService,
    public translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    protected localStorage: LocalStorage,
    private store: Store<fromRoot.State>,
    private media: MediaMatcher,
    private breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private ngrxJsonApiService: NgrxJsonApiService,
    public oauthService: OAuthService,
    private oauthStorage: OAuthStorage,
    private oauthModuleConfig: OAuthModuleConfig,
    private loaderService: LoaderService,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private templateService: TemplatesService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public userService: UserService,
    public responsiveService: ResponsiveService,
    public pwaService: PwaService,
    public sideMenuService: SideMenuService,
    private scrollDispatcher: ScrollDispatcher,
    private oAuthService: OAuthService,
    private dbService: DbService,
    angulartics2: Angulartics2,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language

    // https://backend.icilalune.com/en/jsonapi/menu/menu/

    this.oauthService.events.subscribe(oauthEvent => {
      // console.log('oauthEvent', oauthEvent);
    });

    // TODO start data loading when user is authenticated

    this.appClassName$
      .subscribe(appClassName => {
        if (this._appClassName) {
          this.renderer.removeClass(this.elementRef.nativeElement, this._appClassName);
        }
        if (this._appClassName !== appClassName) {
          this._appClassName = appClassName;
          if (!!this._appClassName) {
            this.renderer.addClass(this.elementRef.nativeElement, this._appClassName);
          }
        }
      });

    this.store.pipe(
      select(getDefaultLanguage),
      filter(defaultLang => !!defaultLang),
      map(defaultLang => {
        translate.setDefaultLang(defaultLang);
      }),
      take(1)
    ).subscribe();

    this.store.pipe(
      select(getCurrentLanguage),
      filter(currentLanguage => !!currentLanguage),
      withLatestFrom(this.store.pipe(select(getAuthenticated))),
      tap(([currentLanguage, authenticated]) => {
        // translate.use(currentLanguage);

        if (authenticated) {

          this.localStorage.setItem('language', currentLanguage).pipe(take(1)).subscribe();

          if (this._sideMainMenu) {
            this._sideMainMenu.close();
          }
        }

      }),
      distinctUntilChanged()
    ).subscribe();


    const availableLanguages: string[] = ['en', 'fr'];
    this.store.dispatch(new SetDefaultLanguage('en'));
    this.store.dispatch(new SetAvailableLanguages(availableLanguages));
    this.translate.addLangs(availableLanguages);

    const browserLanguage: string = translate.getBrowserLang();
    const defaultLanguage: string = translate.langs.indexOf(browserLanguage) > -1 ? browserLanguage : translate.defaultLang;

    this.localStorage.getItem('language').pipe(
      withLatestFrom(this.store.select(getCurrentLanguage))
    ).subscribe(([currentLanguage, cookieLanguage]: [string, string]) => {
      if (!currentLanguage) {
        let futureLanguage: string;
        if (cookieLanguage) {
          futureLanguage = translate.langs.indexOf(cookieLanguage) > -1 ? cookieLanguage : translate.defaultLang;
        } else {
          futureLanguage = defaultLanguage;
        }
        this.store.dispatch(new SetCurrentLanguage(futureLanguage));
      } else {
        if (translate.langs.indexOf(currentLanguage) > -1) {
          this.store.dispatch(new SetCurrentLanguage(currentLanguage));
        } else {
          this.store.dispatch(new SetCurrentLanguage(defaultLanguage));
        }
      }
    }, ([currentLanguage, error]: [string, any]) => {
      this.store.dispatch(new SetCurrentLanguage(defaultLanguage));
    });

    const breakpoints: string[] = [
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ];

    const breakpointsMap: any = {
      [Breakpoints.XSmall]: 'xs',
      [Breakpoints.Small]: 'sm',
      [Breakpoints.Medium]: 'md',
      [Breakpoints.Large]: 'lg',
      [Breakpoints.XLarge]: 'xl',
    };

    const breakpointsMatcher$: Observable<BreakpointState> = this.breakpointObserver.observe(breakpoints);
    breakpointsMatcher$.pipe(
      map((breakpointState: BreakpointState) => {
        const matchesBreakpoints: string[] = breakpoints.filter(breakpoint => {
          return breakpointState.breakpoints[breakpoint] === true;
        });
        return matchesBreakpoints[matchesBreakpoints.length - 1];
      }),
      filter(breakpoint => !!breakpoint)
    ).subscribe(breakpoint => {
      const breakpointKey: string = breakpointsMap[breakpoint];
      this.store.dispatch(new SetBreakpoint(breakpointKey));
    });

    this.matIconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icomoon/sprite.svg'));

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
        this.store.dispatch(new UpdateBreadcrumbs(breadcrumbs));
      });

    this.pageTitle$.pipe(
      filter(pageTitle => !!pageTitle),
      take(1)
    ).subscribe((pageTitle) => {
      angulartics2GoogleAnalytics.startTracking();
    });

    angulartics2.setUserProperties.next({
      'dimension1': VERSION_INFO.version,
    });

  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '' && child.outlet === 'primary') {
        url += `/${routeURL}`;
        if (breadcrumbs.filter(breadcrumb => breadcrumb.url === url).length === 0) {
          let title: string;
          if (child.snapshot.data && child.snapshot.data.title) {
            title = child.snapshot.data.title;
          }
          breadcrumbs.push({url: url, params: child.snapshot.params, data: child.snapshot.data, title: title});
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        if (this._sideMainMenu) {
          this._sideMainMenu.close();
        }
        if (this._sideFiltersMenu) {
          this._sideFiltersMenu.close();
        }
      });

    if (window) {
      let vh: number = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      window.addEventListener('resize', () => {
        // We execute the same script as before
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }

  }

  onLinkClicked(contentTitle: string, contentType: string) {
    this.store.dispatch(new SetPageTitle(contentTitle, contentType));
  }

  selectLanguage(language: string) {
    this.translate.use(language);
    this.localStorage.setItem('language', language).subscribe(() => {
      // Done
    }, (error) => {
      // Error
    });

  }

  openAppTemplates() {
    this.dialog.open(this.templatesDialog, {
      width: '100vw',
      height: '95vh'
    });
  }

  ngAfterViewInit(): void {
    this.templateService.addTemplate(Templates.imagesSlider, this.imageSlider);
    this.templateService.addTemplate(Templates.newsDescription, this.newsDescriptionTemplate);
    this.templateService.addTemplate('simplePage', this.simplePageTemplate);
    this.templateService.addTemplate('bodyTemplate', this.bodyTemplate);
    this.templateService.addTemplate('defaultNode', this.defaultNodeTemplate);
    this.templateService.addTemplate(Templates.listLink, this.listLinkTemplate);
    this.templateService.addTemplate('linkButton', this.linkButtonTemplate);
    this.templateService.addTemplate('searchResultCount', this.searchResultCountTemplate);
    this.templateService.addTemplate('myContributions', this.myContributionsTemplate);
    this.templateService.addTemplate(Templates.myContributionsHeader, this.myContributionsHeaderTemplate);
    this.templateService.addTemplate('myPoll', this.myPollTemplate);
    this.templateService.addTemplate(Templates.myShare, this.myShareTemplate);
    this.templateService.addTemplate(Templates.searchFullText, this.searchFullTextTemplate);
    this.templateService.addTemplate('searchHeader', this.searchHeaderTemplate);
    this.templateService.addTemplate('cartForm', this.cartFormTemplate);
    this.templateService.addTemplate('searchFiltersGroupRef', this.searchFiltersGroupRefTemplate);
    this.templateService.addTemplate('innovationDescription', this.innovationDescriptionTemplate);
    this.templateService.addTemplate(Templates.searchFullTextDetail, this.searchFullTextDetailTemplate);
    this.templateService.addTemplate(Templates.innovationWhy, this.innovationWhyTemplate);
    this.templateService.addTemplate(Templates.freeTags, this.freeTagsTemplate);
    this.templateService.addTemplate(Templates.innovationInfos, this.innovationInfosTemplate);
    this.templateService.addTemplate(Templates.appFooter, this.appFooterTemplate);
    this.templateService.addTemplate(Templates.brandTags, this.brandTagsTemplate);
    this.templateService.addTemplate(Templates.awardTags, this.awardTagsTemplate);
    this.templateService.addTemplate(Templates.newsCategoryLaunchDate, this.newsCategoryLaunchDateTemplate);

    this.pwaService.init();

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.oAuthService.refreshToken().then((success) => {
            // console.log('success', success);
          },
          (error) => {
            // console.error('error', error);
          });
      }
    });

  }

  get searchFiltersTemplate(): TemplateRef<any> {
    return this.templateService.getTemplate(Templates.searchFiltersGroup);
  }

  getSliderMedia$(node: Resource, zone: string): Observable<ResourceIdentifier[]> {

    let mediaRefs: ResourceIdentifier[] = [];
    if (node.relationships) {
      if (node.relationships.field_media_videos && node.relationships.field_media_videos.data) {
        mediaRefs = [...mediaRefs, ...node.relationships.field_media_videos.data];
      }
      if (node.relationships.field_media_images && node.relationships.field_media_images.data) {
        mediaRefs = [...mediaRefs, ...node.relationships.field_media_images.data];
      }
    }

    return this.ngrxJsonApiService.getZone(zone)
      .selectStoreResources(mediaRefs).pipe(
        map(media => media),
        take(1)
      );
  }

}
