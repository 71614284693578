import {Component, OnInit} from '@angular/core';
import {PollService} from '../../services/poll.service';
import {TemplatesService} from '../../templates.service';
import {LanguageSwitcherService} from '../../services/language-switcher.service';

@Component({
  selector: 'app-poll-list',
  templateUrl: './poll-list.component.html',
  styleUrls: ['./poll-list.component.scss']
})
export class PollListComponent implements OnInit {

  today = new Date();

  loadingVotes$ = this.pollService.loadingVotes$;

  constructor(
    public pollService: PollService,
    public templatesService: TemplatesService,
    public languageSwitcherService: LanguageSwitcherService
  ) {
  }

  ngOnInit() {

  }

}
