import {Pipe, PipeTransform} from '@angular/core';
import {DrupalResource} from '../models/drupal-resource';

@Pipe({
  name: 'pathAlias'
})
export class PathAliasPipe implements PipeTransform {

  transform(resource: DrupalResource): string {
    const defaultUrl: string = ['/' + resource.attributes.langcode, 'node', resource.attributes.drupal_internal__nid].join('/');
    const alias: string = resource.attributes.path ? resource.attributes.path.alias : null;
    return  alias ? alias : defaultUrl;
  }

}
