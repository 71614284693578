<ng-container *ngIf="swiper">

  <ul class="list-style-type-none p-0 m-0 d-flex flex-fill justify-content-center">

    <ng-template ngFor [ngForOf]="data" let-image let-i="index" [ngForTrackBy]="trackByFn">

      <li
        class="d-flex mr-2 cursor-pointer"
        [class.active]="(swiper.slideIndex$|async) === i"
        (click)="goToIndex(i)">

        <mat-icon svgIcon="icon-slideshow-bullet"></mat-icon>

      </li>

    </ng-template>

  </ul>

</ng-container>

