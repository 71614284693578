<ng-container *ngIf="node">

  <div class="d-flex align-items-baseline">

    <h1 class="text-uppercase mat-display-1 flex-fill" [innerHTML]="node.attributes.title"></h1>

    <ng-container *ngIf="node.type=='node--innovation' && node.attributes && node.attributes.field_innovation_id">
      <ng-template
        [ngTemplateOutlet]="templatesService.getTemplate('innovationId')"
        [ngTemplateOutletContext]="{id: node.attributes.field_innovation_id, date: node.attributes.created}">
      </ng-template>
    </ng-container>

  </div>

  <div class="d-flex mt-2">

    <div class="flex-fill d-flex">

      <ng-container *ngIf="node?.relationships?.uid?.data as userRef">

        <ng-container *ngIf="zone">

          <ng-container *ngIf="((userRef|jaSelectStoreResource:zone)|async) as user">

            <ng-container
              *ngIf="node.attributes.field_innovation_workflow || node.attributes.field_news_workflow as workflow">

              <app-user-date
                class="flex-fill d-flex align-items-center"
                [user]="user"
                [date]="((workflow|getAppWorkflowState).state === 'accepted')?node.attributes.published_at:''">
              </app-user-date>

            </ng-container>

          </ng-container>

        </ng-container>

      </ng-container>

    </div>

    <ng-container *ngIf="node.attributes.field_innovation_workflow || node.attributes.field_news_workflow as workflow">

      <ng-container *ngIf="(workflow|getAppWorkflowState).state === 'accepted'">

        <button
          [matTooltip]="(isInInspiration$|async)?('ADD_TO_BOARD.REMOVE'|translate):('ADD_TO_BOARD.ADD'|translate)"
          (click)="addRemoveInnovationFromBoard()"
          mat-fab
          color="accent"
          [ngClass]="{'function-active': isInInspiration$|async}"
          class="circle-button mr-3 add-to-inspiration">
          <mat-icon svgIcon="icon-inspirations"></mat-icon>
        </button>

        <button
          [matTooltip]="(isInCart$|async)?('MY_SHARE.REMOVE'|translate):('MY_SHARE.ADD'|translate)"
          (click)="addToShareBox()"
          mat-fab
          color="accent"
          [ngClass]="{'function-active': isInCart$|async}"
          class="circle-button mr-3 add-to-cart">
          <mat-icon svgIcon="icon-share"></mat-icon>
        </button>

        <ng-container *ngIf="node.type == 'node--innovation'">
          <a
            [matTooltip]="'PDF_VERSION.TITLE'|translate"
            mat-fab
            color="accent"
            target="_blank"
            [href]="node|pdfUrl"
            class="circle-button export-pdf">
            <mat-icon svgIcon="icon-export"></mat-icon>
          </a>
        </ng-container>

      </ng-container>

    </ng-container>

  </div>


</ng-container>

<ng-template #addToBoardTemplate>

  <div class="d-flex flex-column px-3 pb-3 flex-fill overflow-y-auto">
    <app-add-to-board
      [dataToAdd]="node"
      [inspirations]="inspirations$|async"
      (addToBoard)="addContentToBoard($event)"
      (removeFromBoard)="removeContentFromBoard($event)"
      (createBoard)="createBoard($event)">
    </app-add-to-board>
  </div>

</ng-template>

<ng-template #removeFromBoardConfirmationContentTemplate let-data="data">

  <div
    class="content-group my-3 px-3 d-flex flex-column align-items-center py-3 px-3 text-center subheading-2 text-uppercase">

    <p [innerHTML]="data.message"></p>

  </div>

</ng-template>

<ng-template #removeFromBoardActionsTemplate let-data="data">

  <div class="d-flex justify-content-end mx-4 my-2">

    <button
      (click)="cancelRemoveFromBoard()"
      class="secondary-action-button"
      mat-flat-button
      [mat-dialog-close]>
      {{'ACTIONS.CANCEL'|translate}}
    </button>

    <button
      (click)="confirmRemoveFromBoard(data.inspiration)"
      mat-flat-button
      color="accent"
      class="mr-3 main-action-button"
      [mat-dialog-close]="true">
      {{'ACTIONS.CONFIRM'|translate}}
    </button>

  </div>

</ng-template>
