import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../../templates.service';
import {Templates} from '../../templates';

@Component({
  selector: 'app-vote-templates',
  templateUrl: './vote-templates.component.html',
  styleUrls: ['./vote-templates.component.scss']
})
export class VoteTemplatesComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('voteHeaderTemplate', /* TODO: check static flag */ {static: true})
  voteHeaderTemplate: TemplateRef<any>;

  constructor(
    public templatesService: TemplatesService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.voteHeader, this.voteHeaderTemplate);
  }

}
