import {Pipe, PipeTransform} from '@angular/core';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';

@Pipe({
  name: 'listPath'
})
export class ListPathPipe implements PipeTransform {

  transform(contentType: string): string {
    const listPaths: {[contentType: string]: string} = {
      [NgrxJsonApiDefinitions.news.type]: '/news',
      [NgrxJsonApiDefinitions.innovation.type]: '/innovations',
      'news': '/news',
      'innovation': '/innovations'
    };
    return listPaths[contentType];
  }

}
