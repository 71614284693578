import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../templates.service';
import {Templates} from '../templates';
import {Observable} from 'rxjs';
import {ListThumbSizes} from '../../models/list-thumb-sizes.model';
import {select, Store} from '@ngrx/store';
import {getListThumbSize} from '../../store/reducers';
import {map} from 'rxjs/operators';
import {FormTemplatesService} from '@madeinlune/ngx-material-schema-form';

@Component({
  selector: 'app-renderer-templates',
  templateUrl: './renderer-templates.component.html',
  styleUrls: ['./renderer-templates.component.scss']
})
export class RendererTemplatesComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('listRendererTemplate', /* TODO: check static flag */ {static: true})
  listRendererTemplate: TemplateRef<any>;

  @ViewChild('listSmallRendererTemplate', /* TODO: check static flag */ {static: true})
  listSmallRendererTemplate: TemplateRef<any>;

  @ViewChild('innovationIdTemplate', /* TODO: check static flag */ {static: true})
  innovationIdTemplate: TemplateRef<any>;

  @ViewChild('sharedBoxRecipientSearchItemTemplate', /* TODO: check static flag */ {static: true})
  sharedBoxRecipientSearchItemTemplate: TemplateRef<any>;

  @ViewChild('userChipTemplate', /* TODO: check static flag */ {static: true})
  userChipTemplate: TemplateRef<any>;

  listThumbSize$: Observable<number> = this.store.pipe(
    select(getListThumbSize),
    map(size => {
      if (size === ListThumbSizes.Normal) {
        return 256;
      } else if (size === ListThumbSizes.Small) {
        return 165;
      }
    })
  );

  constructor(
    public templatesServices: TemplatesService,
    public formTemplatesService: FormTemplatesService,
    private store: Store<any>
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.templatesServices.addTemplate(Templates.listRenderer, this.listRendererTemplate);
    this.templatesServices.addTemplate(Templates.listSmallRenderer, this.listSmallRendererTemplate);
    this.templatesServices.addTemplate(Templates.innovationId, this.innovationIdTemplate);
    this.formTemplatesService.addTemplate(Templates.sharedRecipientSearchItem, this.sharedBoxRecipientSearchItemTemplate);
    this.formTemplatesService.addTemplate(Templates.userChip, this.userChipTemplate);
  }

}
