<ng-container *ngIf="render">

  <h3>Content Header</h3>

  <ng-template
    [ngTemplateOutlet]="contentHeaderTemplate"
    [ngTemplateOutletContext]="{
    node:{
    type:'node--innovation',
    attributes: {
    title: 'Innovation Content'
    }
    }
    }"
  ></ng-template>

</ng-container>


<ng-template #contentHeaderTemplate let-node="node" let-zone="zone">

  <app-news-innovation-header
    [node]="node"
    [zone]="zone">
  </app-news-innovation-header>

</ng-template>
