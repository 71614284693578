import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ObjectLayoutWidget} from 'ngx-schema-form';
import {NewsSchemaPipe} from '../../../pipes/schema/news-schema.pipe';
import {FormTemplatesService} from '@material-form-widgets/form-templates.service';
import {InnovationSchemaPipe} from '../../../pipes/schema/innovation-schema.pipe';

@Component({
  selector: 'app-form-cart',
  templateUrl: './cart.material.component.html',
})
export class CartMaterialComponent extends ObjectLayoutWidget implements OnInit, AfterViewInit {


  constructor(
    public formTemplatesService: FormTemplatesService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

}
