<ng-container *ngIf="render">

  <div class="d-flex align-items-start flex-column justify-content-start">

    <h1>Innovation Renderers</h1>

    <div class="d-flex align-items-start justify-content-start">

      <div class="mr-3">

        <h3>Normal</h3>

        <ng-template
          [ngTemplateOutlet]="listRendererTemplate"
          [ngTemplateOutletContext]="{
          data:{
          title: 'Innovation Test',
          description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          thumbnail_url: ['/assets/temp/inno-sample-1.jpg'],
          thumbnail_width: [320],
          thumbnail_height: [500],
          innovation_category_name: 'POSM',
          innovation_innovation_id: '150',
          changed: 1566848289,
          content_type: 'innovation'
          }
          }">
        </ng-template>

        <h3>Normal
          <small>selected</small>
        </h3>

        <ng-template
          [ngTemplateOutlet]="listRendererTemplate"
          [ngTemplateOutletContext]="{
          selected: true,
          data:{
          title: 'Innovation Test',
          description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          thumbnail_url: ['/assets/temp/inno-sample-1.jpg'],
          thumbnail_width: [320],
          thumbnail_height: [500],
          innovation_category_name: 'POSM',
          innovation_innovation_id: '150',
          changed: 1566848289,
          content_type: 'innovation'
          }
          }">
        </ng-template>

      </div>

      <div class="mr-3">

        <h3>Small</h3>

        <ng-template
          [ngTemplateOutlet]="listSmallRendererTemplate"
          [ngTemplateOutletContext]="{
          data:{
          title: 'Innovation Test',
          description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          thumbnail_url: ['/assets/temp/inno-sample-1.jpg'],
          thumbnail_width: [320],
          thumbnail_height: [500],
          innovation_category_name: 'POSM',
          changed: 1566848289,
          innovation_innovation_id: '150',
          content_type: 'innovation'
          }
          }">
        </ng-template>

      </div>

    </div>


    <h1>News Renderers</h1>

    <div class="d-flex align-items-start justify-content-start">

      <div class="mr-3">

        <h3>Normal</h3>

        <ng-template
          [ngTemplateOutlet]="listRendererTemplate"
          [ngTemplateOutletContext]="{
          data:{
          title: 'News Test',
          description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          thumbnail_url: ['/assets/temp/inno-sample-1-crop.jpg'],
          thumbnail_width: [320],
          thumbnail_height: [180],
          innovation_category_name: 'POSM',
          innovation_innovation_id: '150',
          changed: 1566848289,
          sticky: false,
          free_tagging_name: 'Ocean Plastic, Plastic Bank, Recycling',
          content_type: 'news'
          }
          }">
        </ng-template>

      </div>

      <div class="mr-3">

        <h3>Sticky</h3>

        <ng-template
          [ngTemplateOutlet]="listRendererTemplate"
          [ngTemplateOutletContext]="{
          data:{
          title: 'News Test',
          description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          thumbnail_url: ['/assets/temp/inno-sample-1-crop.jpg'],
          thumbnail_width: [320],
          thumbnail_height: [180],
          innovation_category_name: 'POSM',
          innovation_innovation_id: '150',
          changed: 1566848289,
          sticky: true,
          free_tagging_name: 'Ocean Plastic, Plastic Bank, Recycling',
          content_type: 'news'
          }
          }">
        </ng-template>

      </div>

      <div class="mr-3">

        <h3>Small</h3>

        <ng-template
          [ngTemplateOutlet]="listSmallRendererTemplate"
          [ngTemplateOutletContext]="{
          data:{
          title: 'News Test',
          description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          thumbnail_url: ['/assets/temp/inno-sample-1-crop.jpg'],
          thumbnail_width: [320],
          thumbnail_height: [180],
          innovation_category_name: 'POSM',
          innovation_innovation_id: '150',
          changed: 1566848289,
          sticky: false,
          free_tagging_name: 'Ocean Plastic, Plastic Bank, Recycling',
          content_type: 'news'
          }
          }">
        </ng-template>

      </div>

    </div>

  </div>

</ng-container>

<ng-template #listSmallRendererTemplate let-data="data" let-selected="selected" let-pollInfos="pollInfos">

  <ng-container *ngIf="data">

    <div
      class="list-item-renderer small-renderer d-flex flex-column"
      [class.selected]="selected"
      [class.sticky]="data.sticky === true"
      [ngClass]="data.content_type?data.content_type:''">

      <ng-container *ngIf="data.field_node_thumbnail_image_style_uri_crop_16_9_256 as image; else defaultImage">
        <img [ngStyle]="{'width': '100%'}" [lazyLoad]="image"/>
      </ng-container>

      <div class="infos-group">

        <ng-container *ngIf="pollInfos">
          <div class="poll-infos text-center py-1 mb-3">
            <span>{{pollInfos.title}}</span>
          </div>
        </ng-container>

        <ng-template
          [ngTemplateOutlet]="rendererTitleTemplate"
          [ngTemplateOutletContext]="{title: data.title}">
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="{
        news: newsSmallRendererTemplate,
        innovation: innovationSmallRendererTemplate
        }[data.content_type]"
          [ngTemplateOutletContext]="{data: data}">
        </ng-template>

      </div>

    </div>

  </ng-container>

</ng-template>

<ng-template #newsSmallRendererTemplate let-data="data">

  <div class="d-flex justify-content-between">
    <span class="caption category" [innerHTML]="data.news_category_name"></span>
  </div>

  <ng-template
    [ngTemplateOutlet]="dateTemplate"
    [ngTemplateOutletContext]="{date: data.published_at}">
  </ng-template>

</ng-template>

<ng-template #innovationSmallRendererTemplate let-data="data">

  <div class="d-flex justify-content-between">
    <span class="caption category" [innerHTML]="data.innovation_category_name"></span>
  </div>

  <ng-template
    [ngTemplateOutlet]="innovationIdTemplate"
    [ngTemplateOutletContext]="{id:data.innovation_innovation_id, date:data.changed}">
  </ng-template>

</ng-template>

<ng-template #rendererTitleTemplate let-title="title">
  <h3 [innerHTML]="title" class="renderer-title"></h3>
</ng-template>

<ng-template #listRendererTemplate
             let-data="data"
             let-selected="selected"
             let-itemClass="itemClass"
             let-topLayerTemplate="topLayerTemplate"
             let-size="size">

  <ng-container *ngIf="data">

    <div
      class="list-item-renderer d-flex flex-column"
      [class.selected]="selected"
      [class.sticky]="data.sticky === true"
      [class.small-size]="size=='small'"
      [ngClass]="[data?.content_type?data.content_type:'', itemClass?itemClass:'']">

      <ng-template
        [ngTemplateOutlet]="thumbnailTemplate"
        [ngTemplateOutletContext]="{data: data}">
      </ng-template>

      <div class="infos-group">

        <ng-template
          [ngTemplateOutlet]="rendererTitleTemplate"
          [ngTemplateOutletContext]="{title: data.title}">
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="{news: newsRendererTemplate, innovation: innovationRendererTemplate}[data.content_type]"
          [ngTemplateOutletContext]="{data: data}">
        </ng-template>

        <ng-container *ngIf="topLayerTemplate">

          <div class="top-layer-group">
            <ng-template
              [ngTemplateOutlet]="topLayerTemplate"
              [ngTemplateOutletContext]="{data: data}">
            </ng-template>
          </div>

        </ng-container>

      </div>

    </div>

  </ng-container>

</ng-template>

<ng-template
  #thumbnailTemplate
  let-data="data">

  <ng-container *ngIf="data">


    <ng-container *ngIf="(data|thumbUrl) as image; else defaultImage">

      <ng-container *ngIf="data.content_type=='innovation'">
        <img [lazyLoad]="image.url"
             [ngStyle]="{'height':(image|masonryImageHeight:(listThumbSize$|async))+'px', 'width': '100%'}"/>
      </ng-container>

      <ng-container *ngIf="data.content_type=='news'">

        <img [lazyLoad]="image.url"
             [ngStyle]="{'width': '100%', 'height':(image|masonryImageHeight:(listThumbSize$|async):0.5625)+'px'}"/>

      </ng-container>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template
  #innovationRendererTemplate
  let-data="data">

  <div class="d-flex justify-content-between">
    <span class="caption category" [innerHTML]="data.innovation_category_name"></span>
  </div>
  <ng-template
    [ngTemplateOutlet]="descriptionTemplate"
    [ngTemplateOutletContext]="{description: data.description|shortenDescription}">
  </ng-template>

</ng-template>

<ng-template
  #newsRendererTemplate
  let-data="data">

  <div class="d-flex justify-content-between">
    <span class="caption category" [innerHTML]="data.news_category_name"></span>
  </div>

  <ng-template
    [ngTemplateOutlet]="dateTemplate"
    [ngTemplateOutletContext]="{date: data.published_at}">
  </ng-template>

</ng-template>


<ng-template
  #descriptionTemplate
  let-description="description">

  <ng-container *ngIf="description">

    <ng-container *ngIf="description as cleanedDescription">

      <div
        class="caption flex-fill overflow-hidden mb-1 mt-1"
        [innerHTML]="cleanedDescription |shortenDescription">
      </div>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template
  #dateTemplate
  let-date="date">

  <ng-container *ngIf="date">
    <small [innerHTML]="(date*1000)|date"></small>
  </ng-container>

</ng-template>

<ng-template
  #innovationIdTemplate
  let-id="id" let-date="date">

  <ng-container *ngIf="date|isNumber">
    <span class="flex-none caption" [innerHTML]="id + '-' + ((date*1000)|date:'y')"></span>
  </ng-container>

  <ng-container *ngIf="date|isString">
    <span class="flex-none caption" [innerHTML]="id + '-' + ((date)|date:'y')"></span>
  </ng-container>

</ng-template>

<ng-template #sharedBoxRecipientSearchItemTemplate let-data="data">
  <div class="d-flex">
    <span class="mr-3">{{data.label}}</span>
  </div>

</ng-template>

<ng-template #userChipTemplate let-data="data">
  <span>{{data?.attributes?.title}}</span>
</ng-template>

<ng-template #defaultImage>

  <img [ngStyle]="{'width': '100%'}" [src]="'assets/media/default_picture_win.jpg'"/>

</ng-template>
