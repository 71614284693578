import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';

import {SlideRenderer} from '../../models/slide-renderer';

import Swiper, {SwiperOptions} from 'swiper';
import {ReplaySubject} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';
import {SwiperInterface} from '../../models/swiper-interface';

@Component({
  selector: 'app-swiper',
  templateUrl: 'swiper.component.html',
  styleUrls: ['swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSwiperComponent implements OnInit, OnDestroy, AfterViewInit, SwiperInterface {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  data$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  slideIndex$: ReplaySubject<number> = new ReplaySubject<number>(1);

  realSlideIndex$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @Input()
  slideTemplate: TemplateRef<SlideRenderer>;

  @Input()
  set data(value: any[]) {
    this.data$.next(value);
  }

  @Input()
  options: SwiperOptions;

  public type = 'component';

  mySwiper: Swiper;

  checkSwiperContainerSizeTimer: any;

  @ViewChild('swiperContainer') swiperContainer: ElementRef;

  onSlideChangeFunction: () => void;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.mySwiper.off('slideChange', this.onSlideChangeFunction);
  }

  ngOnInit(): void {

  }

  onResize(event: any) {
    if (this.mySwiper) {
      this.mySwiper.update();
    }

  }

  ngAfterViewInit(): void {

    this.mySwiper = new Swiper(this.swiperContainer.nativeElement, {
      // Optional parameters
      direction: 'horizontal',
      ...(this.options ? this.options : {})
    });

    this.slideIndex$.next(0);

    this.slideIndex$.next(this.mySwiper.activeIndex);
    this.realSlideIndex$.next(this.mySwiper.realIndex);

    this.onSlideChangeFunction = this.onSlideChange.bind(this);

    this.mySwiper.on('slideChange', this.onSlideChangeFunction);

    this.data$.pipe(
      takeUntil(this.destroyed$),
      delay(0)
    ).subscribe(data => {
      this.mySwiper.update();
      this.cdr.detectChanges();
    });

  }

  public goToIndex(index: number) {
    if (this.mySwiper) {
      this.mySwiper.slideTo(index);
    }
  }

  public goNext() {

  }

  public goPrevious() {

  }

  onSlideChange(e: any) {
    this.slideIndex$.next(this.mySwiper.activeIndex);
    this.realSlideIndex$.next(this.mySwiper.realIndex);
  }

}
