import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TemplatesService} from '../../templates.service';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {combineLatest, Observable} from 'rxjs';
import {NgrxJsonApiService, OneQueryResult, ResourceIdentifier, StoreResource} from 'ngrx-json-api';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {CURRENT_LANGUAGE} from '../../providers/app-core.providers';

@Component({
  selector: 'app-inspiration-content-list',
  templateUrl: './inspiration-content-list.component.html',
  styleUrls: ['./inspiration-content-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InspirationContentListComponent implements OnInit {

  zone: string = NgrxJsonApiDefinitions.zoneArtBoards;

  data$: Observable<StoreResource[]>;

  inspiration$: Observable<StoreResource> = this.activatedRoute.params.pipe(
    distinctUntilKeyChanged('inspirationId'),
    map(params => {
      return params.inspirationId;
    }),
    switchMap(inspirationId => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectStoreResource({id: inspirationId, type: NgrxJsonApiDefinitions.inspiration.type});
    }),
    filter(inspiration => {
      return !!inspiration;
    }),
    distinctUntilKeyChanged('id'),
    shareReplay(1)
  );

  inspirationInnovationQueryId$: Observable<string> = this.inspiration$.pipe(
    map(inspiration => 'inspiration-full-' + inspiration.id),
    distinctUntilChanged()
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    public templatesService: TemplatesService,
    private ngrxJsonApiService: NgrxJsonApiService,
    @Inject(CURRENT_LANGUAGE) public readonly currentLanguage$: Observable<string>
  ) {
  }

  ngOnInit() {

    this.data$ = combineLatest([this.inspirationInnovationQueryId$, this.currentLanguage$]).pipe(
      withLatestFrom(this.inspiration$),
      tap(([[queryId, currentLanguage], inspiration]) => {
        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards).putQuery({
          query: {
            ...NgrxJsonApiQueries.queryInspirationNodeInclInnovations,
            queryId: queryId,
            id: inspiration.id
          }
        });
      }),
      switchMap(([[queryId, currentLanguage], inspiration]) => {
        return this.ngrxJsonApiService.getZone(this.zone).selectOneResults(queryId)
          .pipe(
            filter((oneQueryResult: OneQueryResult) => !!oneQueryResult && oneQueryResult.loading === false),
            map((oneQueryResult: OneQueryResult) => oneQueryResult.data.relationships.field_innovation.data),
            switchMap((resourceIdentifiers: ResourceIdentifier[]) => {
              return this.ngrxJsonApiService.getZone(this.zone).selectStoreResources(resourceIdentifiers);
            })
          );
      })
    );

  }

}
