import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ManyQueryResult, NgrxJsonApiService, StoreResource} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../ngrx-json-api/ngrx-json-queries';
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService
  ) {
  }

  carts$: Observable<StoreResource[]> = this.ngrxJsonApiService
    .getZone(NgrxJsonApiDefinitions.zoneShareBox)
    .selectManyResults(NgrxJsonApiQueries.queryCart.queryId)
    .pipe(
      filter((manyQueryResult: ManyQueryResult) => !!manyQueryResult && manyQueryResult.loading === false && !!manyQueryResult.data),
      map((manyQueryResult: ManyQueryResult) => manyQueryResult.data)
    );

  currentCart$: Observable<StoreResource> = this.carts$.pipe(
    filter(carts => !!carts && carts.length > 0),
    map(carts => carts[0])
  );

}
