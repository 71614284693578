<div class="d-flex justify-content-between align-items-center">

  <mat-list role="navigation" class="main-menu text-uppercase flex-fill">

    <mat-list-item class="user-settings-group d-flex justify-content-between">

      <a
        [matTooltip]="'MY_ACCOUNT.TITLE'|translate"
        (click)="openUserAccount()"
        class="cursor-pointer d-flex align-items-center">
        <mat-icon class="mr-2" svgIcon="icon-account"></mat-icon>
        <span class="text-uppercase text-right m-0" [innerHTML]="userName"></span>
      </a>

      <a
        [matTooltip]="'SETTINGS.TITLE'|translate"
        [matBadge]="1"
        matBadgeColor="accent"
        [matBadgeHidden]="(pwaService.updateAvailable$|async) !== true"
        (click)="openAppSettings()"
        class="cursor-pointer d-flex badged">
        <mat-icon svgIcon="icon-settings"></mat-icon>
      </a>

    </mat-list-item>
  </mat-list>

  <!--
  <p class="m-0" [innerHTML]="'APP.LOG_OUT'|translate"></p>
  -->

</div>

<mat-divider></mat-divider>

<div class="row no-gutters flex-fill">

  <div class="col-7 col-sm-12 d-flex flex-column">

    <mat-list role="navigation" class="main-menu text-uppercase">

      <ng-container *ngIf="menusService.getMainMenu()|async as mainMenu">

        <ng-template
          [ngTemplateOutlet]="menuTemplate"
          [ngTemplateOutletContext]="{menuItems: mainMenu, itemClass:'mat-h2 main-menu-item'}">
        </ng-template>

      </ng-container>

    </mat-list>

    <mat-list role="navigation" class="text-uppercase">

      <ng-container *ngIf="menusService.getSecondaryMenu()|async as secondaryMenu">

        <ng-template
          [ngTemplateOutlet]="menuTemplate"
          [ngTemplateOutletContext]="{menuItems: secondaryMenu}">
        </ng-template>

      </ng-container>


    </mat-list>

    <div class="d-flex flex-fill flex-column justify-content-end">

      <mat-list role="navigation">

        <ng-container *ngIf="menusService.getLegalMenu()|async as legalMenu">

          <ng-template
            [ngTemplateOutlet]="menuTemplate"
            [ngTemplateOutletContext]="{menuItems: legalMenu}">
          </ng-template>

        </ng-container>

        <mat-list-item class="mr-3 user-settings-group language-switcher cursor-pointer">

          <app-language-switcher class="d-flex flex-fill h-100"></app-language-switcher>

        </mat-list-item>

      </mat-list>

    </div>

  </div>

  <ng-container *ngIf="responsiveService.isXs$|async">

    <div class="col-5 d-flex mat-elevation-z4">
      <ng-template
        [ngTemplateOutlet]="('userFunctionMenu'|templates)|async"
        [ngTemplateOutletContext]="{showLabel: true, layoutClasses:['flex-column']}">
      </ng-template>
    </div>

  </ng-container>

</div>

<ng-template #menuTemplate let-menuItems="menuItems" let-itemClass="itemClass">

  <ng-template ngFor [ngForOf]="menuItems" let-menuItem>

    <ng-template
      [ngTemplateOutlet]="menuItemTemplate"
      [ngTemplateOutletContext]="{menuItem: menuItem, itemClass: itemClass, count:(menuItem?.count$|async)}">
    </ng-template>

  </ng-template>

</ng-template>

<ng-template #menuItemTemplate let-menuItem="menuItem" let-itemClass="itemClass" let-count="count">

  <a mat-list-item
     [drupalRouterLink]="menuItem.relative"
     [class.active]="(menuItem.relative|menuItemActive:(routerService.currentUrl$|async))">
    <span [matBadge]="count"
          matBadgeColor="accent"
          [matBadgeHidden]="!count"
          [ngClass]="itemClass">{{menuItem.title}}</span>
  </a>

  <mat-divider></mat-divider>

</ng-template>

