import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as Sentry from '@sentry/browser';

export abstract class AppConfigLoader {
  abstract load();

  abstract loadAll(): Observable<any>;
}

@Injectable()
export class BrowserAppConfigLoader implements AppConfigLoader {

  constructor(private http: HttpClient) {
  }

  load() {
    return this.http.get<any>('/assets/config/appConfig.json').pipe(map(config => {
      const defaultConfigKey = config.defaultConfig;
      let customConfigKey = null;
      try {
        const urlParams = new URLSearchParams(window.location.search);
        customConfigKey = urlParams.get('appConfigKey');
      } catch (e) {
      }
      if (!customConfigKey && localStorage) {
        customConfigKey = localStorage.getItem('app_config_key');
      }
      if (customConfigKey) {
        console.warn('Using a custom config key (' + customConfigKey + ') instead of default (' + defaultConfigKey + ')');
      }
      const configKey = customConfigKey ? customConfigKey : defaultConfigKey;
      Sentry.setTag('config_key', configKey);
      if (!config.configs[configKey]) {
        console.error('this app config key doesn’t exist in your config', configKey);
      }
      return config.configs[configKey];
    })).toPromise();
  }

  loadAll(): Observable<any> {
    return this.http.get<any>('/assets/config/appConfig.json').pipe(
      map(config => {
        return config.configs;
      })
    );
  }
}

@Injectable()
export class ServerAppConfigLoader implements AppConfigLoader {

  constructor(@Inject('ServerAppConfig') private config: any) {
  }

  load() {
    return new Promise((resolve, fault) => {
      resolve(this.config);
    });
  }

  loadAll() {
    return null;
  }

}
