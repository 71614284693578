<ng-container *ngIf="data">
  <img [src]="data.attributes.uri.url|mediaUrl"/>
</ng-container>

<ng-container *ngIf="imageMedia && derivative">

  <ng-container
    *ngIf="imageMedia?.relationships[imageMedia?.meta?.drupal?.media?.configuration?.source_field]?.data?.meta?.imageDerivatives?.links as mediaDerivatives">

    <ng-container *ngIf="mediaDerivatives[derivative]?.href as url">

      <img [src]="url"/>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="!imageMedia?.relationships[imageMedia?.meta?.drupal?.media?.configuration?.source_field]?.data?.meta?.imageDerivatives">

    <!--
    <p>Can't display this image {{imageMedia.id}}</p>
  -->

  </ng-container>

</ng-container>
