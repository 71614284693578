<ng-container *ngIf="render">

  <h1>PWA Banner</h1>

  <ng-template
    [ngTemplateOutlet]="pwaBannerTemplate">
  </ng-template>

</ng-container>

<ng-template #pwaBannerTemplate>

  <ng-container *ngIf="pwaService.installNotNow == false">

    <div class="pwa-banner-group d-flex flex-fill align-items-sm-center py-3 px-2 py-sm-3 px-sm-5 flex-column flex-sm-row">

      <ng-container *ngIf="!(responsiveService.isXs$|async)">
        <img class="pwa-logo" src="assets/logos/pwa_logo_white.svg"/>
      </ng-container>

      <div class="infos-group d-flex flex-sm-fill">

        <img class="app-icon ml-0 ml-sm-5 mr-3" src="assets/icons/icon-144x144.png"/>

        <div class="d-flex flex-column">

          <h2 [innerHTML]="'PWA.TITLE'|translate"></h2>

          <div class="d-flex flex-fill justify-content-center">
            <span [innerHTML]="'PWA.SUBTITLE'|translate"></span>
          </div>

        </div>

      </div>

      <div class="actions-group d-flex mt-3 mt-0 align-items-center">

        <ng-container *ngIf="(responsiveService.isXs$|async)">
          <img class="pwa-logo mr-3" src="assets/logos/pwa_logo_white.svg"/>
        </ng-container>

        <button mat-flat-button class="mr-3" (click)="pwaService.installNotNow = true">
          <span [innerHTML]="'PWA.NOT_NOW'|translate"></span>
        </button>

        <button mat-flat-button (click)="pwaService.install()">
          <span [innerHTML]="'PWA.INSTALL'|translate"></span>
        </button>

      </div>

    </div>

  </ng-container>

</ng-template>
