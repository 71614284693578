import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NgxMasonryComponent} from '../../ngx-masonry/ngx-masonry.component';
import {select, Store} from '@ngrx/store';
import {getListThumbSize} from '../../store/reducers';
import {ListThumbSizes} from '../../models/list-thumb-sizes.model';
import {Observable, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-apollo-list',
  templateUrl: './apollo-list.component.html',
  styleUrls: ['./apollo-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApolloListComponent implements OnInit, OnDestroy, AfterViewInit {


  @Input()
  data: any[];

  @Input()
  loading: boolean;

  @Input()
  end: boolean;

  @Input()
  rendererTemplate: TemplateRef<any>;

  @Input()
  noDataMessage: string;

  @ViewChild('masonryLayout') masonryLayout: NgxMasonryComponent;

  listThumbSize$: Observable<ListThumbSizes> = this.store.pipe(
    select(getListThumbSize)
  );

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private store: Store<any>,
    private cdr: ChangeDetectorRef
  ) {
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  ngOnInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  ngAfterViewInit(): void {
    this.listThumbSize$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(() => this.masonryLayout.layout());
  }

}
