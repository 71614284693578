import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {EMPTY, from, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, retry, switchMap, tap} from 'rxjs/operators';
import {JsonApiHttpErrorResponse} from './models/json-api-error';
import {NotificationsService} from './services/notifications.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {AlertService} from './services/alert.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private injector: Injector,
    private oauthService: OAuthService,
    private alertService: AlertService
  ) {
  }

  addAuthHeader(request) {
    const authHeader = this.oauthService.authorizationHeader();
    if (authHeader) {
      return request.clone({
        setHeaders: {
          'Authorization': authHeader
        }
      });
    }
    return request;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return from(this.oauthService.refreshToken()).pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(true);
        }),
        catchError(() => {
          this.refreshTokenInProgress = false;
          this.alertService.openNeedAuthentication();
          return EMPTY;
        }));
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const notifier = this.injector.get(NotificationsService);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        if ((error.status === 403 || error.status === 401) && error.url.indexOf('oauth') === -1) {
          if (error.status === 401) {
            return this.refreshToken().pipe(
              switchMap(() => {
                request = this.addAuthHeader(request);
                return next.handle(request);
              }),
              catchError(e => {
                return throwError(() => error);
              }));
          }
        } else if (error.status === 401 && error.url.indexOf('oauth') > -1) {
          console.error('error', error);
        }

        const jsonApiError: JsonApiHttpErrorResponse = error as JsonApiHttpErrorResponse;
        if (request.url.indexOf('graphql') > -1) {
          const httpResponse: HttpResponse<any> = new HttpResponse<any>({
            body: {
              data: {
                searchAPISearch: {
                  documents: [],
                  result_count: 0,
                  facets: [],
                  __typename: null
                }
              }
            }
          });
          return of(httpResponse);
        } else if (jsonApiError) {
          let messages: string[];
          if (jsonApiError?.error?.errors) {
            messages = jsonApiError.error.errors.map(jsonApiErrorDetail => jsonApiErrorDetail.detail);
          } else {
            messages = [jsonApiError.message];
          }
          // notifier.showServerError(jsonApiError.status, messages);
          return throwError(error);
        }
      })
    );
  }

}
