import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {InnovationIndexItem} from '../../apollo/innovation-index-item';
import {NgrxJsonApiService, Resource, StoreResource} from 'ngrx-json-api';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {MediaImage} from '@ngx-mil-drupal/models/media-image';
import {ShortenPipe, StripTagsPipe} from 'ngx-pipes';

@Pipe({
  name: 'resourceToInnovationIndexItem'
})
@Injectable()
export class ResourceToInnovationIndexItemPipe implements PipeTransform {

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    private stripTagsPipe: StripTagsPipe,
    private shortenPipe: ShortenPipe
  ) {
  }


  transform(resource: Resource, zone: string, language: string = null): Observable<InnovationIndexItem> {

    const innovationIndexItem: InnovationIndexItem = {};
    innovationIndexItem.uuid = resource.id;
    innovationIndexItem.content_type = resource.type.split('--')[1];
    innovationIndexItem.title = resource.attributes.title;
    if (resource?.attributes?.field_innovation_description?.value) {
      innovationIndexItem.description = resource.attributes.field_innovation_description.value;
    }

    if (resource.attributes.published_at) {
      innovationIndexItem.published_at = (new Date(resource.attributes.published_at).getTime() / 1000).toString();
    }

    innovationIndexItem.innovation_innovation_id = resource.attributes.field_innovation_id;
    innovationIndexItem.changed = resource.attributes.created;
    innovationIndexItem.field_innovation_workflow = resource.attributes.field_innovation_workflow;
    innovationIndexItem.field_news_workflow = resource.attributes.field_news_workflow;

    if (language) {
      innovationIndexItem.path = '/' + language + resource.attributes?.path?.alias;
    }

    const innovationIndexItem$: Observable<InnovationIndexItem> = of(innovationIndexItem);

    return innovationIndexItem$.pipe(
      switchMap(innovationItem => {
        if (resource?.relationships?.field_node_thumbnail?.data && resource?.relationships?.field_node_thumbnail?.data?.id !== 'missing') {
          return this.ngrxJsonApiService
            .getZone(zone)
            .selectStoreResource(resource.relationships.field_node_thumbnail.data)
            .pipe(
              map((imageResource: StoreResource) => {
                const mediaImage: MediaImage = (imageResource as any as MediaImage);
                if (mediaImage?.relationships) {
                  let fieldMediaName: string = mediaImage?.meta?.drupal?.media?.configuration?.source_field;
                  fieldMediaName = fieldMediaName ? fieldMediaName : 'field_media_image';
                  const mediaImageMeta = mediaImage?.relationships[fieldMediaName].data?.meta;
                  if (mediaImageMeta?.imageDerivatives) {
                    const mediaDerivatives: any = mediaImageMeta.imageDerivatives.links;
                    innovationItem.field_node_thumbnail_width = mediaImageMeta.width;
                    innovationItem.field_node_thumbnail_height = mediaImageMeta.height;
                    innovationItem.field_node_thumbnail_image_style_uri_original_256 = mediaDerivatives['original_256'].href;
                    innovationItem.field_node_thumbnail_image_style_uri_original_512 = mediaDerivatives['original_512'].href;
                    innovationItem.field_node_thumbnail_image_style_uri_crop_16_9_256 = mediaDerivatives['crop_16_9_256'].href;
                    innovationItem.field_node_thumbnail_image_style_uri_crop_16_9_512 = mediaDerivatives['crop_16_9_512'].href;
                  }
                }
                return innovationItem;
              }),
              take(1)
            );
        } else {
          return of(innovationItem);
        }
      }),
      switchMap(innovationItemWithThumbnail => {
        if (resource?.relationships?.field_innovation_category?.data) {
          return this.ngrxJsonApiService
            .getZone(zone)
            .selectStoreResource(resource.relationships.field_innovation_category.data)
            .pipe(
              map((categoryResource: StoreResource) => {
                if (categoryResource) {
                  innovationItemWithThumbnail.innovation_category_name = categoryResource.attributes.name;
                }
                return innovationItemWithThumbnail;
              })
            );
        } else if (resource?.relationships?.field_new_category?.data) {
          return this.ngrxJsonApiService
            .getZone(zone)
            .selectStoreResource(resource.relationships.field_new_category.data)
            .pipe(
              map((categoryResource: StoreResource) => {
                if (categoryResource?.attributes?.name) {
                  innovationItemWithThumbnail.news_category_name = categoryResource.attributes.name;
                }
                return innovationItemWithThumbnail;
              })
            );
        } else {
          return of(innovationItemWithThumbnail);
        }
      }),
      map(innovationItemWithThumbnail => {
        return innovationItemWithThumbnail;
      }),
      shareReplay(1),
      catchError(error => throwError(error))
    );
  }
}
