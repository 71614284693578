// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {getInnovationKeywords, getNewsKeywords} from '../store/reducers';
import {distinctUntilChanged, filter, shareReplay} from 'rxjs/operators';
import {Keyword} from '../store/effects/app-core.effects';


export const NEWS_KEYWORDS = new InjectionToken<Observable<Keyword[]>>(
  'A stream with the News Free Tags'
);

export const INNOVATION_KEYWORDS = new InjectionToken<Observable<Keyword[]>>(
  'A stream with the Innovation Free Tags'
);

export const SEARCH_PROVIDERS: Provider[] = [
  {
    provide: NEWS_KEYWORDS,
    deps: [Store],
    useFactory: newsKeywordsFactory
  },
  {
    provide: INNOVATION_KEYWORDS,
    deps: [Store],
    useFactory: innovationKeywordsFactory
  }
];

export function newsKeywordsFactory(
  store: Store
): Observable<Keyword[]> {
  return store.pipe(
    select(getNewsKeywords),
    filter(keywords => !! keywords),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function innovationKeywordsFactory(
  store: Store
): Observable<Keyword[]> {
  return store.pipe(
    select(getInnovationKeywords),
    filter(keywords => !! keywords),
    distinctUntilChanged(),
    shareReplay(1)
  );
}
