import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchPlaceHolderCount'
})
@Injectable()
export class SearchPlaceHolderCountPipe implements PipeTransform {

  constructor(
  ) {

  }

  transform(resultCount: number, contentTypes: string[], placeholderBase: string): string {
    if (!!resultCount && !isNaN(resultCount)) {
      const quantityTermKey: string = resultCount > 1 ? 'SEVERAL' : 'SINGLE';
      return placeholderBase + '.COUNT.' + quantityTermKey;
    } else {
      return placeholderBase + '.DEFAULT';
    }
  }

}
