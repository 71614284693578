import gql from 'graphql-tag';

export class ApolloQueries {

  static readonly searchResultSize: number = 10;
  static readonly listPageSize: number = 30;
  static readonly maxListPageSize: number = 50;

  static readonly innovationList = gql`
  query SearchIndexList($start: Int!,$end: Int!, $langCode: String!, $contentType: String!) {
  searchAPISearch(
  index_id: "innovation",
  range: {offset:$start, limit:$end},
  condition_group:
  {groups: [{conjunction: AND, conditions: [
  {operator: "=", name: "langcode", value: $langCode},
  {operator: "=", name: "status", value: "true"},
  {operator: "=", name: "content_type", value: $contentType}]}
  ]},
  sort: {field:"published_at", value: "desc"}
  ) {
    result_count
    documents {
      ... on InnovationDoc {
        content_type
        title
        field_node_thumbnail_image_style_uri_crop_16_9_256
        field_node_thumbnail_image_style_uri_original_256
        field_node_thumbnail_width
        field_node_thumbnail_height
        innovation_category_name
        news_category_name
        description
        innovation_innovation_id
        published_at
        free_tagging_name
        uuid
        path
        langcode
        nid
      }
    }
  }
}`;

  static readonly myContributions = gql`
  query MyContributionsList($langCode: String!, $userUid: String!, $range: RangeInput){
  searchAPISearch(index_id: "innovation",
    range:$range,
    sort: {field:"changed", value: "desc"},
    condition_group: {
    groups: [
      {
        conjunction: AND,
        conditions:[
          {operator: "=", name: "uid", value:$userUid},
          {operator: "=", name: "langcode", value:$langCode}
        ]
      }
      {conjunction: OR,
        conditions: [
           {operator: "=", name: "content_type", value: "innovation"},
           {operator: "=", name: "content_type", value: "news"}
      ]},
      {conjunction: OR, conditions: [
        {operator: "=", name: "field_news_workflow", value: "editorial_news_draft"},
        {operator: "=", name: "field_news_workflow", value: "editorial_news_review"},
        {operator: "=", name: "field_news_workflow", value: "editorial_news_published"},
        {operator: "=", name: "field_innovation_workflow", value: "editorial_innovation_draft"},
        {operator: "=", name: "field_innovation_workflow", value: "editorial_innovation_review"},
        {operator: "=", name: "field_innovation_workflow", value: "editorial_innovation__published"}
      ]}
    ]}
   facets: [
    {
      field: "field_news_workflow",
      limit: 0,
      min_count: 0,
      operator: "=",
      missing: false
    },
    {
      field: "field_innovation_workflow",
      limit: 0,
      min_count: 0,
      operator: "=",
      missing: false
    }
  ]) {
    result_count
    documents {
      ... on InnovationDoc {
        content_type
        title
        description
        innovation_category_name
        field_node_thumbnail_image_style_uri_crop_16_9_256
        field_node_thumbnail_image_style_uri_original_256
        field_node_thumbnail_width
        field_node_thumbnail_height
        innovation_innovation_id
        free_tagging_name
        changed
        news_category_name
        field_news_workflow
        field_innovation_workflow
        innovation_innovation_id
        sticky
        uuid
        path
        langcode
        uid
      }
    }
    facets{
    name
      values{
        count
        filter
      }
  }
  }
}
`;

  // Used to search with specific filters, refreshes the main search view

  static readonly searchInnovation = gql`query SearchInnovation($start: Int!,$end: Int!, $freeText: [String]="", $filterConditions:ConditionGroupInput,  $facets: [FacetInput]) {
  searchAPISearch(
  index_id: "innovation",
  condition_group:$filterConditions,
  range: {offset:$start, limit:$end},
  sort: {field:"published_at", value: "desc"},
  fulltext: {keys: $freeText, conjunction: "AND"},
  facets: $facets) {
    result_count
    documents {
      ... on InnovationDoc {
        content_type
        title
        description
        field_node_thumbnail_image_style_uri_crop_16_9_256
        field_node_thumbnail_image_style_uri_original_256
        field_node_thumbnail_image_style_uri_crop_16_9_512
        field_node_thumbnail_image_style_uri_original_512
        field_node_thumbnail_width
        field_node_thumbnail_height
        published_at
        free_tagging_name
        uuid
        path
        langcode
        innovation_innovation_id
        innovation_category_name
        news_category_name
        field_brands
        news_brands_name
        news_awards_name
        sticky
        field_supplier
        nid
        awards_count
      }
    },
    facets{
    name
      values{
        count
        filter
      }
  }
  }
}`;

  // Uses to search with freeText only, refreshes autocomplete only

  static readonly searchIndex = gql`
  query searchIndexAutoComplete($langCode: String!, $freeText: [String]!, $contentType: String!) {
  searchAPISearch(index_id: "innovation", condition_group:
  {
    groups: [
      {
        conjunction: AND,
        conditions: [
          {operator: "=", name: "langcode", value: $langCode},
          {operator: "=", name: "status", value: "true"},
          {operator: "=", name: "content_type", value: $contentType}
    \t\t]
      }
  \t]
  },
  fulltext: {keys: $freeText, fields: ["free_tagging_name", "title"]}) {
    result_count
    documents {
      ... on InnovationDoc {
        content_type
        title
        description
        field_node_thumbnail_image_style_uri_crop_16_9_256
        field_node_thumbnail_image_style_uri_original_256
        field_node_thumbnail_width
        field_node_thumbnail_height
        changed
        free_tagging_name
        uuid
        path
        langcode
      }
    }
  }
}`;

}
