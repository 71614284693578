import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../../templates.service';
import {Templates} from '../../templates';
import {ResponsiveService} from '../../../services/responsive.service';
import {Observable} from 'rxjs';
import {getRouteData} from '../../../store/reducers';
import {select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {NgrxJsonApiDefinitions} from '../../../ngrx-json-api/ngrx-json-api-definitions';

@Component({
  selector: 'app-button-templates',
  templateUrl: './button-templates.component.html',
  styleUrls: ['./button-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonTemplatesComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('backDetailButtonTemplate', /* TODO: check static flag */ {static: true})
  backDetailButtonTemplate: TemplateRef<any>;

  routeData$: Observable<any> = this.store.pipe(
    select(getRouteData)
  );

  contentType$: Observable<any> = this.routeData$.pipe(
    filter(data => !!data),
    map(data => data.contentType),
    filter(contentType => !!contentType)
  );

  backDetailButtonLabel$: Observable<string> = this.contentType$.pipe(
    map(contentType => {
      if (contentType === NgrxJsonApiDefinitions.ctInnovation) {
        return 'INNOVATIONS';
      } else if (contentType === NgrxJsonApiDefinitions.ctNews) {
        return 'NEWS';
      } else if (contentType === NgrxJsonApiDefinitions.ctFakeVote) {
        return 'MY VOTE';
      }
    })
  );

  backDetailButtonLink$: Observable<string> = this.contentType$.pipe(
    map(contentType => {
      if (contentType === NgrxJsonApiDefinitions.ctInnovation) {
        return '/innovations';
      } else if (contentType === NgrxJsonApiDefinitions.ctNews) {
        return '/news';
      } else if (contentType === NgrxJsonApiDefinitions.ctFakeVote) {
        return '/poll';
      }
    })
  );

  constructor(
    public templatesService: TemplatesService,
    public responsiveService: ResponsiveService,
    private store: Store<any>
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    this.templatesService.addTemplate(Templates.backDetailButton, this.backDetailButtonTemplate);

  }

}
