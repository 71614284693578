<!--
'id',
'type',
'field_cart_workflow',
'ds_switch',
'field_cart_recipient',
'field_cart_part',
'field_cart_message',
'field_cart_subject'
-->

<ng-container *ngIf="formProperty">

  <div class="row">

    <div class="col-12 col-sm-6">

      <ng-template
        [ngTemplateOutlet]="blockTitleTemplate"
        [ngTemplateOutletContext]="{title: 'MY_SHARE.SUB_TITLE'|translate}">
      </ng-template>

      <sf-form-element class="d-flex flex-fill overflow-hidden"
                       [formProperty]="formProperty.getProperty('field_cart_part')"></sf-form-element>

    </div>

    <div class="col-12 col-sm-6 mt-4 mt-sm-0">

      <ng-template
        [ngTemplateOutlet]="blockTitleTemplate"
        [ngTemplateOutletContext]="{title: 'MY_SHARE.RECIPIENTS_LIST'|translate, classes: 'mb-0'}">
      </ng-template>

      <sf-form-element class="d-flex flex-fill"
                       [formProperty]="formProperty.getProperty('field_recipient_profiles')"></sf-form-element>

      <ng-template
        [ngTemplateOutlet]="blockTitleTemplate"
        [ngTemplateOutletContext]="{title: 'MY_SHARE.MESSAGE'|translate}">
      </ng-template>

      <sf-form-element class="d-flex flex-fill"
                       [formProperty]="formProperty.getProperty('field_cart_subject')"></sf-form-element>

      <sf-form-element class="d-flex flex-fill"
                       [formProperty]="formProperty.getProperty('field_cart_message')"></sf-form-element>

    </div>

  </div>

</ng-container>

<ng-template #blockTitleTemplate let-title="title" let-classes="classes">

  <h2 class="text-uppercase mb-3 mt-2" [ngClass]="classes" [innerHTML]="title">
  </h2>

</ng-template>
