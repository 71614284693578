import {Injectable, TemplateRef} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  public templates: { [templateId: string]: TemplateRef<any> } = {};

  templates$: ReplaySubject<{ [templateId: string]: TemplateRef<any> }> = new ReplaySubject<{ [templateId: string]: TemplateRef<any> }>();

  constructor() {
  }

  addTemplate(templateId: string, template: TemplateRef<any>) {
    // if (!this.templates[templateId]) {
      this.templates[templateId] = template;
      this.templates$.next(this.templates);
    // }
  }

  removeTemplate(templateId: string) {
    if (this.templates[templateId]) {
      delete this.templates[templateId];
      this.templates$.next(this.templates);
    }
  }

  getTemplate(templateId: string): TemplateRef<any> {
    if (this.templates[templateId]) {
      return this.templates[templateId];
    } else {
      // console.warn('no template for this id', templateId);
    }
    return null;
  }

  getTemplate$(templateId: string): Observable<TemplateRef<any>> {
    return this.templates$.pipe(
      filter(templates => !!templates[templateId]),
      map(templates => {
        return templates[templateId];
      }));
  }

}
