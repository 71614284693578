import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';

@Pipe({
  name: 'facetValueTidMatcher'
})
export class FacetValueTidMatcher implements PipeTransform {

  transform(term: Resource, facetValues: any[]): number {
    const tid: number = term.attributes.drupal_internal__tid;
    const facetInfo = facetValues.filter(value => {
      return parseInt(value.filter, 10) === tid;
    }).shift();
    return facetInfo ? parseInt(facetInfo.count, 10) : 0;
  }

}
