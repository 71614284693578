import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-simple-page',
  templateUrl: './simple-page.component.html',
  styleUrls: ['./simple-page.component.scss']
})
export class SimplePageComponent implements OnInit {

  @Input()
  page: any;

  constructor() { }

  ngOnInit() {
  }

}
