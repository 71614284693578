import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {

  resultCount$: ReplaySubject<number> = new ReplaySubject<number>();

  set resultCount(value: number) {
    this.resultCount$.next(value);
  }

  constructor() {
  }

}
