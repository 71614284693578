<ng-container *ngIf="data$|async as data">

  <div class="d-flex flex-fill innovation-reference align-items-center">

    <ng-container *ngIf="thumbnail$|async as thumbnail">

      <app-image-renderer
        [derivative]="'original_256'"
        [imageMedia]="thumbnail">
      </app-image-renderer>

    </ng-container>

    <div class="d-flex flex-fill flex-column overflow-hidden mr-3">

      <a
        [drupalRouterLink]="'/' + data.attributes.langcode + '/' + data.attributes.path.alias"
        [drupalNid]="data.attributes.drupal_internal__nid"
        [drupalTitle]="data.attributes.title"
        [drupalLanguage]="data.attributes.langcode"
        [drupalIdentifier]="{id:data.id, type: data.type}"
        class="flex-fill ml-3 text-uppercase">
        <h3 [innerHTML]="data.attributes.title"></h3>
      </a>
      <small class="flex-fill ml-3" [innerHTML]="data.type|contentTypeToLabel"></small>

    </div>

    <ng-container *ngIf="removable && removeEmitter">
      <button class="remove-btn" mat-mini-fab aria-label="Remove Reference" (click)="removeEmitter.emit(data)">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>

  </div>


</ng-container>
