<ng-container *ngIf="render">

  <h3>Workflow Filters ToggleButtonBar</h3>

  <ng-template
    [ngTemplateOutlet]="toggleBarStateFiltersTemplate"
    [ngTemplateOutletContext]="{
    count: 10,
    facets: [
    {
    label: 'Draft',
    count: 3,
    state: 'draft'
    },
    {
    label: 'Review',
    count: 4,
    state: 'pending'
    },
    {
    label: 'Accepted',
    count: 3,
    state: 'accepted'
    }
    ]
    }">
  </ng-template>

</ng-container>

<ng-template #toggleBarStateFiltersTemplate let-count="count" let-facets="facets" let-changeEmitter="changeEmitter">

  <mat-button-toggle-group [value]="'all'" (change)="onChangeStateFilter(changeEmitter, $event.value)" class="essential small-size transparent workflow-filters">

    <mat-button-toggle [value]="'all'">
      <ng-template
        class="workflow-filter subheading-1"
        [ngTemplateOutlet]="filterToggleButton"
        [ngTemplateOutletContext]="{
          label: 'WORKFLOW_STATES.ALL',
          count: count
          }">
      </ng-template>
    </mat-button-toggle>

    <ng-template ngFor [ngForOf]="facets" let-facet>

      <ng-container *ngIf="facet.state !== 'unknown yet'">

        <mat-button-toggle [value]="facet.state" class="workflow-filter subheading-1">
          <ng-template
            [ngTemplateOutlet]="filterToggleButton"
            [ngTemplateOutletContext]="{
            label: facet.label|translate,
            count: facet.count,
            state: facet.state
        }">
          </ng-template>
        </mat-button-toggle>

      </ng-container>

    </ng-template>

  </mat-button-toggle-group>

</ng-template>

<ng-template #filterToggleButton let-label="label" let-count="count" let-state="state">

  <ng-container *ngIf="state">
    <span class="state-chip mr-2" [ngClass]="state"></span>
  </ng-container>

  <span class="mr-3" [innerHTML]="label|translate"></span>
  <small [innerHTML]="count"></small>

</ng-template>
