import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {MaterialWidgetRegistry} from '../../material-widgets-registry';
import {FieldSet, FormAutocompleteParams} from '@madeinlune/ngx-drupal-schemata';
import {Templates} from '../../components/templates';

@Pipe({
  name: 'cartSchema'
})
@Injectable()
export class CartSchemaPipe implements PipeTransform {

  static readonly cartForm: string = 'cartForm';

  constructor() {
  }

  transform(schema: any): any {
    const properties: any = schema.properties;

    if (properties.published_at) {
      delete properties.published_at;
    }

    if (properties.metatag) {
      delete properties.metatag;
      if (schema.required.indexOf('metatag') > -1) {
        const index = schema.required.indexOf('metatag');
        schema.required.splice(index, 1);
      }
    }

    properties.id.widget = MaterialWidgetRegistry.hidden;
    properties.type.widget = MaterialWidgetRegistry.hidden;
    properties.field_cart_workflow.widget = MaterialWidgetRegistry.hidden;

    delete properties.field_cart_recipient;

    if (properties.field_recipient_profiles) {
      const cartRecipientField = properties.field_recipient_profiles;
      cartRecipientField.data = {prefixIcon: 'icon-address-book'};
      // cartRecipientField.searchQuery = JSON.parse(JSON.stringify(NgrxJsonApiQueries.userSearchQuery));
      // cartRecipientField.searchQuery.type = NgrxJsonApiDefinitions.mhProfile.type;
      cartRecipientField.widget = MaterialWidgetRegistry.chipsAutoComplete;
      cartRecipientField.templateInfos = {
        autoCompleteItem: Templates.sharedRecipientSearchItem,
        chipContent: Templates.userChip
      };

      const formAutoCompleteParams: FormAutocompleteParams = {
        bundle: NgrxJsonApiDefinitions.ctCart,
        entity_type: 'node',
        field_name: 'field_recipient_profiles'
      };
      cartRecipientField.meta.formAutoCompleteParams = formAutoCompleteParams;
    }

    delete properties.innovation_voting_result;

    if (properties.field_cart_part) {
      delete properties.field_cart_part.title;
      properties.field_cart_part.properties.data.minItems = 1;
      properties.field_cart_part.meta = {
        ...properties.field_cart_part.meta, creationOnly: true, templateInfos: {
          item: 'innovationReference',
          list: 'innovationReferenceList'
        }
      };
    }

    const fieldSets: FieldSet[] =
      [
        {
          id: 'main',
          title: 'Infos',
          className: 'root-fieldset',
          name: '',
          description: '',
          fields: [
            'id',
            'type',
            'field_cart_workflow',
            'field_recipient_profiles',
            'field_cart_part',
            'field_cart_message',
            'field_cart_subject'
          ]
        }
      ];

    schema.fieldsets = fieldSets;
    schema.required = [...schema.required, 'field_recipient_profiles', 'field_cart_part'].filter(field => field !== 'innovation_voting_result');
    schema.widget = MaterialWidgetRegistry.cart;

    return schema;
  }
}
