import {Injectable} from '@angular/core';
import {FacetModel} from '../models/facet.model';
import {Router} from '@angular/router';

@Injectable()
export class SearchFilterService {

  facet: FacetModel;

  constructor(
    private router: Router
  ) {
  }

  updateFilter(values: number[]) {
    this.router.navigate([], {
      queryParams: {
        [this.facet.name]: values
      },
      queryParamsHandling: 'merge'
    });
  }

  updateMonoFilter(value: number) {
    this.router.navigate([], {
      queryParams: {
        [this.facet.name]: value
      },
      queryParamsHandling: 'merge'
    });
  }

}
