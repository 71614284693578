import {Injectable} from '@angular/core';
import {DrupalConfig} from './drupal-config';
import {Route} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DrupalService {

  constructor(
    public config: DrupalConfig
  ) {
  }

  activatedRoutes: { [contextId: string]: Route } = {};

  addActivatedRoute(contextId: string, route: Route) {
    if (!this.activatedRoutes[contextId]) {
      this.activatedRoutes[contextId] = route;
    }
  }

  getActivatedRoute(contextId: string): Route {
    return this.activatedRoutes[contextId];
  }

  routeExists(contextId: string): boolean {
    return !!this.activatedRoutes[contextId];
  }

}
