import {Component, HostBinding, OnInit} from '@angular/core';
import {VERSION_INFO} from '../../../environments/version';
import {Store} from '@ngrx/store';
import {ChangeSyncDataRequest, ClearLocalStorageRequest} from '../../store/actions/app-core.actions';
import {AppCoreService} from '../../services/app-core.service';
import {PwaService} from '../../pwa.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {

  @HostBinding('class') class = 'overflow-y-auto overflow-x-hidden';

  version = VERSION_INFO.version;

  constructor(
    private store: Store<any>,
    public appCoreService: AppCoreService,
    public pwaService: PwaService
  ) {
  }

  ngOnInit() {
  }

  clearLocalStorage() {
    this.store.dispatch(new ClearLocalStorageRequest());
  }

  onSyncDataChange(checked: boolean) {
    this.store.dispatch(new ChangeSyncDataRequest(checked));
  }

}
