import {Injectable} from '@angular/core';
import {StorageMap} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {NgrxJsonApiZone} from 'ngrx-json-api';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    public storage: StorageMap
  ) {
  }

  hydrateAllZones$(): Observable<{ zoneId: string, data: NgrxJsonApiZone }> {
    return this.storage.keys().pipe(
      /* Keep only keys starting with 'app_' */
      filter((key) => key.startsWith('NgrxJsonApiZone-')),
      switchMap((key) => this.storage.get(key).pipe(map(data => {
        const zoneData: NgrxJsonApiZone = data as NgrxJsonApiZone;
        zoneData.isApplying = 0;
        zoneData.isCreating = 0;
        zoneData.isDeleting = 0;
        zoneData.isReading = 0;
        zoneData.isUpdating = 0;
        return {zoneId: key.split('NgrxJsonApiZone-')[1], data: zoneData};
      })))
    );
  }

  hydrateZones$(zoneIds: string[]): Observable<{ zoneId: string, data: NgrxJsonApiZone }> {
    return this.storage.keys().pipe(
      /* Keep only keys starting with 'app_' */
      filter((key) => zoneIds.indexOf(key) > -1),
      switchMap((key) => this.storage.get(key).pipe(map(data => {
        const zoneData: NgrxJsonApiZone = data as NgrxJsonApiZone;
        zoneData.isApplying = 0;
        zoneData.isCreating = 0;
        zoneData.isDeleting = 0;
        zoneData.isReading = 0;
        zoneData.isUpdating = 0;
        return {zoneId: key.split('NgrxJsonApiZone-')[1], data: zoneData};
      })))
    );
  }

  hydrateZone$(zoneId: string): Observable<{ zoneId: string, data: NgrxJsonApiZone }> {
    return this.storage.get('NgrxJsonApiZone-' + zoneId).pipe(map(data => {
      const zoneData: NgrxJsonApiZone = data as NgrxJsonApiZone;
      zoneData.isApplying = 0;
      zoneData.isCreating = 0;
      zoneData.isDeleting = 0;
      zoneData.isReading = 0;
      zoneData.isUpdating = 0;
      return {zoneId: zoneId, data: zoneData};
    }));
  }

  hydrateSchemas$(): Observable<any> {
    return this.storage.get('drupal-schema-loader').pipe(map(data => {
      return data;
    }));
  }

  hydrateSearch$(): Observable<any> {
    return this.storage.get('search').pipe(map(data => {
      return data;
    }));
  }

  hydrateDrupal$(): Observable<any> {
    return this.storage.get('drupal').pipe(map(data => {
      return data;
    }));
  }

  hydrateDrupalPathInfos$(): Observable<any> {
    return this.storage.get('drupalPath').pipe(map(data => {
      return data;
    }));
  }

  getRedirectUrl$(): Observable<string> {
    return this.storage.get('redirectUrl').pipe(map(data => {
      return data as string;
    }));
  }

  getReservationZones$(): Observable<any> {

    return this.storage.keys().pipe(
      /* Keep only keys starting with 'app_' */
      filter((key) => key.startsWith('reservation*')),
      switchMap((key) => this.storage.get(key).pipe(map(data => {
        return {zone: key, data: data};
      }))),
    );

  }

  getKey(key: string): Observable<any> {

    return this.storage.get(key).pipe(map(data => {
      return data;
    }));

  }

  setKey(key: string, value: any): Observable<any> {

    return this.storage.set(key, value);

  }

  delete(key: string): Observable<any> {

    return this.storage.delete(key);

  }

  clearAll(all: boolean) {
    this.storage.keys().subscribe(key => {
      if (all === true) {
        this.storage.clear().subscribe(
          () => {
          }
        );
      } else {
        if (key !== 'syncData' && key !== 'language') {
          this.storage.delete(key).subscribe(() => {
          });
        }
      }
    });
  }

}
