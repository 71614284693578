<ng-container *ngIf="render">

  <h1>Buttons</h1>

  <ng-template [ngTemplateOutlet]="backDetailButtonTemplate"
               [ngTemplateOutletContext]="{title: 'Test', forRender:true}">

  </ng-template>

</ng-container>

<ng-template #backDetailButtonTemplate let-title="title" let-forRender="forRender">

  <ng-container *ngIf="(responsiveService.isXs$|async) || forRender">

    <a
      [routerLink]="backDetailButtonLink$|async"
      class="back-detail-button d-flex flex-fill px-2 py-2 align-items-center position-relative">

      <mat-icon svgIcon="icon-arrow-left"></mat-icon>

      <div class="text-uppercase position-absolute w-100 h-100 top-0 left-0 d-flex flex-column align-items-center justify-content-center">

        <span
          [innerHTML]="title ? title : (backDetailButtonLabel$|async)|translate">
      </span>

      </div>

    </a>

  </ng-container>

</ng-template>
