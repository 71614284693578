// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {getAutoPlay, getCurrentLanguage, getUnSeenInnovationsCount, getUnSeenNewsCount} from '../store/reducers';
import {distinctUntilChanged, shareReplay} from 'rxjs/operators';


export const CURRENT_LANGUAGE = new InjectionToken<Observable<string>>(
  'A stream with the application current language'
);

export const AUTO_PLAY = new InjectionToken<Observable<boolean>>(
  'A stream with the autoPlay Preference'
);

export const UNSEEN_NEWS = new InjectionToken<Observable<number>>(
  'A stream with the unseen News count'
);

export const UNSEEN_INNOVATIONS = new InjectionToken<Observable<number>>(
  'A stream with the unseen Innovations count'
);

export const APP_CORE_PROVIDERS: Provider[] = [
  {
    provide: CURRENT_LANGUAGE,
    deps: [Store],
    useFactory: currentLanguageFactory
  },
  {
    provide: AUTO_PLAY,
    deps: [Store],
    useFactory: autoPlayFactory
  },
  {
    provide: UNSEEN_NEWS,
    deps: [Store],
    useFactory: unSeenNewsFactory
  },
  {
    provide: UNSEEN_INNOVATIONS,
    deps: [Store],
    useFactory: unSeenInnovationsFactory
  }
];

export function autoPlayFactory(
  store: Store
): Observable<boolean> {
  return store.pipe(
    select(getAutoPlay),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function currentLanguageFactory(
  store: Store
): Observable<string> {
  return store.pipe(
    select(getCurrentLanguage),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function unSeenNewsFactory(
  store: Store
): Observable<number> {
  return store.pipe(
    select(getUnSeenNewsCount),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function unSeenInnovationsFactory(
  store: Store
): Observable<number> {
  return store.pipe(
    select(getUnSeenInnovationsCount),
    distinctUntilChanged(),
    shareReplay(1)
  );
}
