import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {InnovationIndexItem} from '../apollo/innovation-index-item';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';

@Pipe({
  name: 'thumbUrl'
})
@Injectable()
export class ThumbUrlPipe implements PipeTransform {

  constructor() {

  }

  transform(value: InnovationIndexItem, profileType: string = null): any {

    let imageInfos: any;

    let thumbUrl: string;
    if (profileType) {

      if (profileType === 'crop') {
        thumbUrl = value.field_node_thumbnail_image_style_uri_crop_16_9_256;
      }

    } else if (value.content_type === NgrxJsonApiDefinitions.ctNews) {

      if (value.sticky) {
        thumbUrl = value.field_node_thumbnail_image_style_uri_crop_16_9_512;
      } else {
        thumbUrl = value.field_node_thumbnail_image_style_uri_crop_16_9_256;
      }

    } else if (value.content_type === NgrxJsonApiDefinitions.ctInnovation) {

      if (value.sticky) {
        thumbUrl = value.field_node_thumbnail_image_style_uri_original_512;
      } else {
        thumbUrl = value.field_node_thumbnail_image_style_uri_original_256;
      }
    }
    if (thumbUrl) {
      imageInfos = {
        url: thumbUrl,
        size: {
          width: value.field_node_thumbnail_width,
          height: value.field_node_thumbnail_height
        }
      };
    }
    return imageInfos;
  }
}
