import {DefaultWidgetRegistry} from 'ngx-schema-form';
import {NodeMaterialComponent} from './components/forms/node/node.material.component';
import {CartMaterialComponent} from './components/forms/cart/cart.material.component';

import {
  ButtonMaterialComponent,
  CheckboxMaterialComponent,
  ChipsAutoCompleteComponent,
  DateMaterialComponent,
  EntityReferenceMaterialComponent,
  FileMaterialComponent,
  ObjectMaterialComponent,
  RadioMaterialComponent,
  SelectMaterialComponent,
  StringMaterialComponent,
  TaxonomyTermAutoCompleteComponent,
  TaxonomyTermSelectComponent,
  TextareaMaterialComponent
} from '@madeinlune/ngx-material-schema-form';
import {Injectable} from '@angular/core';
import {QuillTextComponent} from './components/quill-text/quill-text.component';


@Injectable()
export class MaterialWidgetRegistry extends DefaultWidgetRegistry {

  static readonly boolean = 'boolean';
  static readonly checkboxes = 'checkboxes';
  static readonly button = 'button';
  static readonly select = 'select';
  static readonly object = 'object';
  static readonly entityReference = 'entityReference';
  static readonly singleEntityReference = 'single-entity-reference';
  static readonly file = 'file';
  static readonly taxonomyTermReference = 'taxonomy-term-reference';
  static readonly taxonomyTermAutoCompleteReference = 'taxonomy-term-auto-complete-reference';
  static readonly paragraph = 'paragraph';
  static readonly date = 'date';
  static readonly string = 'string';
  static readonly integer = 'integer';
  static readonly richTextarea = 'richTextArea';
  static readonly textarea = 'textarea';
  static readonly hidden = 'hidden';
  static readonly radio = 'radio';
  static readonly chipsAutoComplete = 'chipsAutoComplete';
  static readonly node = 'node';
  static readonly templateSwitcher = 'templateSwitcher';
  static readonly cart = 'cart';

  static readonly country = 'country';

  static readonly userReference = 'userReference';

  constructor() {
    super();

    this.register(MaterialWidgetRegistry.object, ObjectMaterialComponent);
    this.register(MaterialWidgetRegistry.string, StringMaterialComponent);
    this.register(MaterialWidgetRegistry.integer, StringMaterialComponent);
    this.register(MaterialWidgetRegistry.boolean, CheckboxMaterialComponent);
    this.register(MaterialWidgetRegistry.checkboxes, CheckboxMaterialComponent);
    // this.register('view-' + MaterialWidgetRegistry.string, StringViewMaterialWidget);

    this.register(MaterialWidgetRegistry.entityReference, EntityReferenceMaterialComponent);

    this.register(MaterialWidgetRegistry.button, ButtonMaterialComponent);

    this.register(MaterialWidgetRegistry.select, SelectMaterialComponent);

    this.register(MaterialWidgetRegistry.taxonomyTermReference, TaxonomyTermSelectComponent);

    this.register(MaterialWidgetRegistry.taxonomyTermAutoCompleteReference, TaxonomyTermAutoCompleteComponent);

    this.register(MaterialWidgetRegistry.date, DateMaterialComponent);

    this.register(MaterialWidgetRegistry.radio, RadioMaterialComponent);

    this.register(MaterialWidgetRegistry.file, FileMaterialComponent);

    this.register(MaterialWidgetRegistry.chipsAutoComplete, ChipsAutoCompleteComponent);

    this.register(MaterialWidgetRegistry.textarea, TextareaMaterialComponent);

    this.register(MaterialWidgetRegistry.richTextarea, QuillTextComponent);

    this.register(MaterialWidgetRegistry.node, NodeMaterialComponent);

    this.register(MaterialWidgetRegistry.cart, CartMaterialComponent);

    this.setDefaultWidget(StringMaterialComponent);
  }

}
