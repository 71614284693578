import {Inject, Injectable} from '@angular/core';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../ngrx-json-api/ngrx-json-queries';
import {catchError, distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {FilteringParam, ManyQueryResult, NgrxJsonApiService, Query, Resource, StoreResource} from 'ngrx-json-api';
import {combineLatest, Observable, ReplaySubject, throwError} from 'rxjs';
import {UserService} from './user.service';
import {LanguageSwitcherService} from './language-switcher.service';
import {ApolloQueries} from '../apollo/apollo-queries';
import {InnovationWorkflow} from '../models/innovation.model';
import {NewsWorkflow} from '../models/news.model';
import {ContributionWorkflowFacet} from '../models/contribution-workflow-facet';
import {AppConfigService} from '@config/app-config.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {CURRENT_LANGUAGE} from '../providers/app-core.providers';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MyContributionsService {


  currentFacet$: ReplaySubject<ContributionWorkflowFacet> = new ReplaySubject<ContributionWorkflowFacet>(1);

  set currentFacet(value: ContributionWorkflowFacet) {
    this._currentFacet = value;
    this.currentFacet$.next(value);
  }

  private _currentFacet: ContributionWorkflowFacet;

  contributionsZone$: Observable<string> = this.currentLanguage$.pipe(
    map(language => NgrxJsonApiDefinitions.zoneContributionsList)
  );

  newsList$ = this.contributionsZone$.pipe(
    switchMap(zone => {
      return this.ngrxJsonApiService.getZone(zone)
        .selectManyResults(NgrxJsonApiQueries.queryNewsCollectionNode.queryId)
        .pipe(
          filter(manyQueryResult => !!manyQueryResult && !!manyQueryResult.data),
          map((manyQueryResult: ManyQueryResult) => {
            return manyQueryResult.data;
          })
        );
    })
  );


  innovationsList$ = this.contributionsZone$.pipe(
    switchMap(zone => {
      return this.ngrxJsonApiService.getZone(zone)
        .selectManyResults(NgrxJsonApiQueries.queryInnovationCollectionNode.queryId)
        .pipe(
          filter(manyQueryResult => !!manyQueryResult && !!manyQueryResult.data),
          map((manyQueryResult: ManyQueryResult) => {
            return manyQueryResult.data.filter(item => item.state !== 'DELETED');
          })
        );
    })
  );

  // this.newsList$.subscribe(newsList => console.log('newsList', newsList));

  contributionsList$ = combineLatest([this.newsList$, this.innovationsList$])
    .pipe(
      map(([news, innovations]) => {
        return [...news, ...innovations];
      })
    );

  contributionsCount$: Observable<number> = this.contributionsList$.pipe(
    map(contributionsList => contributionsList.length)
  );

  newsFilters$: ReplaySubject<FilteringParam[]> = new ReplaySubject<FilteringParam[]>();

  innovationFilters$: ReplaySubject<FilteringParam[]> = new ReplaySubject<FilteringParam[]>();

  workflowCreation: { [contentType: string]: { key: string, value: string } } = {
    [NgrxJsonApiDefinitions.innovation.type]: {key: 'field_innovation_workflow', value: InnovationWorkflow.Draft},
    [NgrxJsonApiDefinitions.news.type]: {key: 'field_news_workflow', value: NewsWorkflow.Draft},
  };

  workflowReview: { [contentType: string]: { key: string, value: string } } = {
    [NgrxJsonApiDefinitions.innovation.type]: {key: 'field_innovation_workflow', value: InnovationWorkflow.Review},
    [NgrxJsonApiDefinitions.news.type]: {key: 'field_news_workflow', value: NewsWorkflow.Review},
  };

  workflowPublished: { [contentType: string]: { key: string, value: string } } = {
    [NgrxJsonApiDefinitions.innovation.type]: {key: 'field_innovation_workflow', value: InnovationWorkflow.Published},
    [NgrxJsonApiDefinitions.news.type]: {key: 'field_news_workflow', value: NewsWorkflow.Published},
  };

  workflowRejected: { [contentType: string]: { key: string, value: string } } = {
    [NgrxJsonApiDefinitions.innovation.type]: {key: 'field_innovation_workflow', value: InnovationWorkflow.Archived},
    [NgrxJsonApiDefinitions.news.type]: {key: 'field_news_workflow', value: NewsWorkflow.Archived},
  };

  publishedContents$: Observable<StoreResource[]> = this.contributionsList$.pipe(
    map(contents => contents.filter(content => {
      const contentType: string = content.type;
      const attributeKey: string = this.workflowPublished[contentType].key;
      const workflowState: string = this.workflowPublished[contentType].value;
      return content.attributes[attributeKey] === workflowState;
    }))
  );

  reviewContents$: Observable<StoreResource[]> = this.contributionsList$.pipe(
    map(contents => contents.filter(content => {
      const contentType: string = content.type;
      const attributeKey: string = this.workflowReview[contentType].key;
      const workflowState: string = this.workflowReview[contentType].value;
      return content.attributes[attributeKey] === workflowState;
    }))
  );

  draftContents$: Observable<StoreResource[]> = this.contributionsList$.pipe(
    map(contents => contents.filter(content => {
      const contentType: string = content.type;
      const attributeKey: string = this.workflowCreation[contentType].key;
      const workflowState: string = this.workflowCreation[contentType].value;
      return content.attributes[attributeKey] === workflowState;
    }))
  );

  rejectedContents$: Observable<StoreResource[]> = this.contributionsList$.pipe(
    map(contents => contents.filter(content => {
      const contentType: string = content.type;
      const attributeKey: string = this.workflowRejected[contentType].key;
      const workflowState: string = this.workflowRejected[contentType].value;
      return content.attributes[attributeKey] === workflowState;
    }))
  );

  facets$: Observable<ContributionWorkflowFacet[]> = combineLatest([
    this.draftContents$,
    this.reviewContents$,
    this.publishedContents$,
    this.rejectedContents$
  ]).pipe(
    map(([
           draftContents,
           reviewContents,
           publishedContents,
           rejectedContents
         ]) => {
      const facets: ContributionWorkflowFacet[] = [
        {
          label: 'MY_CONTRIBUTIONS.STATUS.DRAFT',
          count: draftContents.length,
          state: 'draft'
        },
        {
          label: 'MY_CONTRIBUTIONS.STATUS.REVIEW',
          count: reviewContents.length,
          state: 'pending'
        },
        {
          label: 'MY_CONTRIBUTIONS.STATUS.ACCEPTED',
          count: publishedContents.length,
          state: 'accepted'
        },
        {
          label: 'MY_CONTRIBUTIONS.STATUS.REJECTED',
          count: rejectedContents.length,
          state: 'rejected'
        }
      ];
      return facets;
    })
  );

  filteredContributionsList$ = combineLatest([
    this.currentFacet$,
    this.contributionsList$,
    this.draftContents$,
    this.reviewContents$,
    this.publishedContents$,
    this.rejectedContents$
  ])
    .pipe(
      map((
        [
          currentFacet,
          allList,
          draftList,
          reviewList,
          publishedList,
          rejectedList
        ]
      ) => {
        let selectedList: StoreResource[];
        if (currentFacet.state === 'draft') {
          selectedList = draftList;
        } else if (currentFacet.state === 'pending') {
          selectedList = reviewList;
        } else if (currentFacet.state === 'accepted') {
          selectedList = publishedList;
        } else if (currentFacet.state === 'rejected') {
          selectedList = rejectedList;
        } else {
          selectedList = allList;
        }
        return selectedList;
      }),
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      }),
      map(selectedList => {
        return selectedList;
      }),
      shareReplay(1)
    );

  public limit = ApolloQueries.maxListPageSize;
  public offset = 0;

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    private userService: UserService,
    private languageSwitcherService: LanguageSwitcherService,
    private appConfigService: AppConfigService,
    private http: HttpClient,
    @Inject(CURRENT_LANGUAGE) public currentLanguage$: Observable<string>
  ) {

    /*his.newsFilters$.next([]);
    this.innovationFilters$.next([]);

    combineLatest(
      [
        this.userService.userUid$,
        this.languageSwitcherService.currentLanguage$,
        this.newsFilters$,
        this.innovationFilters$
      ]
    ).subscribe(
      ([userId, language, newsFilters, innovationFilters]) => {
        console.log('userId', userId);
        console.log('language', language);
        console.log('newsFilters', newsFilters);
        console.log('innovationFilters', innovationFilters);
      }
    );*/

  }

  loadCounters() {

  }

  destroy() {

  }

  loadFullContributions() {
    combineLatest([this.userService.userUid$, this.currentLanguage$]).pipe(
      untilDestroyed(this, 'destroy')
    ).subscribe(([userDrupalUid, language]) => {

      const contributionsNewsQuery: Query = this.getListQuery(NgrxJsonApiQueries.queryNewsCollectionNode, userDrupalUid);
      const contributionsInnovationQuery: Query = this.getListQuery(NgrxJsonApiQueries.queryInnovationCollectionNode, userDrupalUid);

      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneContributionsList).putQuery({
        query: contributionsNewsQuery,
        fromServer: true
      });

      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneContributionsList).putQuery({
        query: contributionsInnovationQuery,
        fromServer: true
      });
    });
  }

  reLoadFullContributions() {
    this.contributionsZone$.pipe(take(1)).subscribe((zone) => {

      this.ngrxJsonApiService.getZone(zone)
        .hasQuery(NgrxJsonApiQueries.queryNewsCollectionNode.queryId)
        .pipe(
          take(1)
        ).subscribe((hasQueryNewsNode: boolean) => {
        if (hasQueryNewsNode) {
          this.ngrxJsonApiService.getZone(zone)
            .refreshQuery(NgrxJsonApiQueries.queryNewsCollectionNode.queryId);
        }
      });

      this.ngrxJsonApiService.getZone(zone)
        .hasQuery(NgrxJsonApiQueries.queryInnovationCollectionNode.queryId)
        .pipe(
          take(1)
        ).subscribe((hasQueryInnovationNode: boolean) => {
        if (hasQueryInnovationNode) {
          this.ngrxJsonApiService.getZone(zone)
            .refreshQuery(NgrxJsonApiQueries.queryInnovationCollectionNode.queryId);
        }
      });

    });
  }

  filter(newsFilter: FilteringParam[], innovationFilter: FilteringParam[]) {

    this.newsFilters$.next(newsFilter);
    this.innovationFilters$.next(innovationFilter);

    this.userService.userUid$.pipe(
      take(1),
      withLatestFrom(this.languageSwitcherService.currentLanguage$)
    ).subscribe(([userDrupalUid, language]) => {
      const contributionsNewsQuery: Query = this.getListQuery(NgrxJsonApiQueries.queryNewsCollectionNode, userDrupalUid);
      contributionsNewsQuery.params.filtering = [...contributionsNewsQuery.params.filtering, ...newsFilter];

      const contributionsInnovationQuery: Query = this.getListQuery(NgrxJsonApiQueries.queryInnovationCollectionNode, userDrupalUid);
      contributionsInnovationQuery.params.filtering = [...contributionsInnovationQuery.params.filtering, ...innovationFilter];

      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneContributionsList + '-' + language).putQuery({
        query: contributionsNewsQuery,
        fromServer: true
      });

      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneContributionsList + '-' + language).putQuery({
        query: contributionsInnovationQuery,
        fromServer: true
      });
    });

  }

  getListQuery(baseQuery: Query, userId: string): Query {
    return {
      ...baseQuery,
      params: {
        ...baseQuery.params,
        page: {
          limit: this.limit,
          offset: this.offset
        },
        filtering: [
          {
            path: 'author',
            value: 'uid.id',
            operator: 'path'
          },
          {
            path: 'author',
            value: userId,
            operator: 'value'
          },
          {
            path: 'author',
            value: NgrxJsonApiQueries.equals,
            operator: 'operator'
          }
        ]
      }
    };
  }

  loadNextPage() {

  }

  sendToReview(resource: Resource): Observable<any> {

    const stateChangeParams: { [contentType: string]: { fieldName: string, toState: string, method: string } } = {
      [NgrxJsonApiDefinitions.innovation.type]: {
        fieldName: 'field_innovation_workflow',
        toState: InnovationWorkflow.Review,
        method: 'innovation_workflow.execute_transition'
      },
      [NgrxJsonApiDefinitions.news.type]: {
        fieldName: 'field_news_workflow',
        toState: NewsWorkflow.Review,
        method: 'innovation_workflow.execute_transition'
      },
    };

    const currentParams: { fieldName: string, toState: string, method: string } = stateChangeParams[resource.type];

    const url: string = this.appConfigService.getConfig().jsonrpc;
    const body: any = {
      jsonrpc: '2.0',
      id: 'execute-transition',
      method: currentParams.method,
      params: {
        entity_type: 'node',
        field_name: currentParams.fieldName,
        entity_uuid: resource.id,
        to_state: currentParams.toState
      }
    };

    return this.http.post<any>(url, body)
      .pipe(
        catchError(this.handleError)
      );

  }

  triggerTranslation(uuid: string, fromLanguage: string, toLanguage: string): Observable<any> {

    const url: string = this.appConfigService.getConfig().jsonrpc;
    const body: any = {
      jsonrpc: '2.0',
      id: 'update-translation',
      method: 'innovation_workflow.request_translation',
      params: {
        entity_type: 'node',
        'entity_uuid': uuid,
        'from_language': fromLanguage,
        'to_language': toLanguage
      }
    };

    return this.http.post<any>(url, body)
      .pipe(
        catchError(this.handleError)
      );

  }

  handleError(error: HttpErrorResponse) {
    console.error('error', error);
    return throwError(error);
  }

}
