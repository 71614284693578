<ng-container *ngIf="(languageSwitcherService.languages$|async) as language">

  <ng-container *ngIf="!(languageSwitcherService.isStaticRoute$|async)">

    <a
      class="d-flex flex-fill align-items-center"
      [drupalRouterLink]="alternativePath$|async"
      [drupalLanguage]="languageSwitcherService.alternativeLanguage$|async"
      [drupalIdentifier]="languageSwitcherService.routeResourceIdentifier$|async">

      <ng-template
        [ngTemplateOutlet]="languageTemplate"
        [ngTemplateOutletContext]="{language:language}">
      </ng-template>

    </a>

  </ng-container>

  <ng-container *ngIf="(languageSwitcherService.isStaticRoute$|async)">
    <a
      class="d-flex flex-fill align-items-center"
      (click)="languageSwitcherService.switchLanguage()">
      <ng-template
        [ngTemplateOutlet]="languageTemplate"
        [ngTemplateOutletContext]="{language:language}">
      </ng-template>
    </a>
  </ng-container>

</ng-container>

<ng-template #languageTemplate let-language="language">
  <span *ngIf="language=='fr'" [innerHTML]="'LANGUAGE_MENU.en'|translate"></span>
  <span *ngIf="language=='en'" [innerHTML]="'LANGUAGE_MENU.fr'|translate"></span>
</ng-template>
