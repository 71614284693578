import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApolloQueries} from '../../apollo/apollo-queries';
import {WatchQueryOptions} from 'apollo-client';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getCurrentLanguage, getItemsByContentType, getRouteData} from '../../store/reducers';
import {distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {ApolloListComponent} from '../apollo-list/apollo-list.component';
import {ApolloService} from '../../apollo/apollo.service';
import {TemplatesService} from '../../templates.service';
import {CdkScrollable, ScrollDispatcher} from '@angular/cdk/overlay';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {InnovationIndexItem} from '../../apollo/innovation-index-item';
import {AddToIndex} from '../../store/actions/search.actions';
import {StripTagsPipe} from 'ngx-pipes';

@UntilDestroy()
@Component({
  selector: 'app-mini-list',
  templateUrl: './mini-list.component.html',
  styleUrls: ['./mini-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StripTagsPipe]
})
export class MiniListComponent implements OnInit, OnDestroy, AfterViewInit {

  innovationQueryOptions: WatchQueryOptions = {
    query: ApolloQueries.innovationList,
    fetchPolicy: 'network-only'
  };

  currentLanguage$: Observable<string> = this.store.pipe(select(getCurrentLanguage));

  @ViewChild('apolloList')
  apolloList: ApolloListComponent;

  apolloService: ApolloService;

  private scrollable: CdkScrollable;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  routeContentTypes$: Observable<string[]> = this.store.pipe(select(getRouteData)).pipe(
    map(data => data.contentTypes),
    filter(contentTypes => !!contentTypes),
    shareReplay(1)
  );

  itemsByContentType$: Observable<InnovationIndexItem[]>;

  constructor(
    private store: Store<any>,
    @Inject('apolloServiceFactory') private apolloServiceFactory,
    public templatesService: TemplatesService,
    private scrollDispatcher: ScrollDispatcher,
    private elementRef: ElementRef,
    private zone: NgZone,
    private stripTagsPipe: StripTagsPipe
  ) {
  }

  ngOnInit() {

    combineLatest([this.currentLanguage$, this.routeContentTypes$.pipe(distinctUntilChanged())]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      ([language, contentTypes]) => {
        const variables: any = {contentType: contentTypes[0], langCode: language};
        if (!this.apolloService) {
          this.innovationQueryOptions.variables = variables;
          this.apolloService = this.apolloServiceFactory(this.innovationQueryOptions, 0, ApolloQueries.listPageSize);
          this.apolloServiceInitialized();
        } else {
          this.apolloService.variables = {...this.apolloService.variables, ...variables};
        }
      }
    );

    this.itemsByContentType$ = combineLatest([this.routeContentTypes$, this.currentLanguage$]).pipe(
      untilDestroyed(this),
      switchMap(([contentTypes, language]) => {
        return this.store.pipe(
          select(getItemsByContentType(language, contentTypes[0])),
        );
      }),
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      }),
      shareReplay(1)
    );

  }

  apolloServiceInitialized() {

    this.scrollDispatcher.scrolled(100).pipe(
      takeUntil(this.destroyed$),
      withLatestFrom(this.apolloService?.lockLoading$),
    ).subscribe(([scrolled, lockLoading]) => {
      if (scrolled && !lockLoading) {
        if (this.scrollDispatcher.getAncestorScrollContainers(this.elementRef).indexOf(scrolled) > -1) {
          if (scrolled.measureScrollOffset('bottom') < scrolled.getElementRef().nativeElement.offsetHeight) {
            this.apolloService.nextPage();
          }
        }
      }
    });

    this.apolloService.data$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.routeContentTypes$, this.currentLanguage$)
      )
      .subscribe(([data, contentTypes, language]) => {
        const newData = data.map(item => {
          const clonedItem: InnovationIndexItem = {...item};
          return clonedItem;
        });
        this.store.dispatch(new AddToIndex(language, contentTypes[0], newData));
      });

  }

  ngAfterViewInit(): void {
    this.scrollable = new CdkScrollable(this.elementRef, this.scrollDispatcher, this.zone);
    this.scrollable.ngOnInit();
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    if (this.scrollable) {
      this.scrollable.ngOnDestroy();
    }
  }

}
