<ng-container *ngIf="render">

  <h3>Snack Bar Server Error</h3>

  <ng-template
    [ngTemplateOutlet]="serverErrorSnackBarTemplate"
    [ngTemplateOutletContext]="{message:'Test error', status:500}"
  ></ng-template>

</ng-container>


<ng-template #serverErrorSnackBarTemplate let-data let-snackBarRef="snackBarRef">

  <div class="d-flex align-items-center">

    <div class="mr-5">

      <ng-container *ngIf="data">

        <div class="d-flex align-items-baseline">
          <h3 class="flex-fill">Sorry an error occurred</h3>
          <small>{{data?.status}}</small>
        </div>
        <p class="mb-2">A notification has been sent to our development team to identify the problem</p>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Details
            </mat-panel-title>
          </mat-expansion-panel-header>

          <small [innerHTML]="data?.message"></small>

        </mat-expansion-panel>

      </ng-container>

    </div>

    <button mat-flat-button (click)="snackBarRef.dismiss()">OK</button>

  </div>

</ng-template>
