import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import * as RouterActions from '../actions/router.actions';
import {ROUTER_NAVIGATED, ROUTER_REQUEST, RouterNavigatedAction, RouterRequestAction} from '@ngrx/router-store';
import {lastInnovationsTimestampKey, lastNewsTimestampKey} from './app-core.effects';
import {SetUnSeenContents} from '../actions/app-core.actions';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';

@Injectable()
export class RouterEffects {
  
  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.GO),
    map((action: RouterActions.Go) => action.payload),
    tap((payload) =>
      this.router.navigate(payload.path, {queryParams: payload.query, ...payload.extras})
    )
  ), {dispatch: false});

  
  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.BACK),
    tap(action => {
      this.location.back();
    })
  ), {dispatch: false});

  
  navigateForward$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.FORWARD),
    tap(action => {
      this.location.forward();
    })
  ), {dispatch: false});

  
  request$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_REQUEST),
    filter((action: RouterRequestAction) => {
      return action.payload.routerState.url === '/news' || action.payload.routerState.url === '/innovations';
    }),
    switchMap((action: RouterRequestAction) => {
      if (action.payload.routerState.url === '/news') {
        return [new SetUnSeenContents(NgrxJsonApiDefinitions.ctNews, 0)];
      } else if (action.payload.routerState.url === '/innovations') {
        return [new SetUnSeenContents(NgrxJsonApiDefinitions.ctInnovation, 0)];
      }
    })
  ));

  
  navigated$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    filter((action: RouterNavigatedAction) => {
      return action.payload.routerState.url === '/news' || action.payload.routerState.url === '/innovations';
    }),
    tap((action: RouterNavigatedAction) => {
      if (action.payload.routerState.url === '/news') {
        localStorage.setItem(lastNewsTimestampKey, Date.now().toString());
      } else if (action.payload.routerState.url === '/innovations') {
        localStorage.setItem(lastInnovationsTimestampKey, Date.now().toString());
      }
    })
  ), {dispatch: false});

  /*@Effect({dispatch: false})
  navigateCancel$ = this.actions$.pipe(
    ofType(ROUTER_CANCEL),
    tap(action => {
      console.log('action', action);
    })
  );*/

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location
  ) {
  }
}
