import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ApolloQueries} from '../../apollo/apollo-queries';
import {select, Store} from '@ngrx/store';
import {distinctUntilKeyChanged, filter, map, mergeMap, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiService, Query, Resource, ResourceIdentifier, StoreResource, uuid} from 'ngrx-json-api';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {WatchQueryOptions} from 'apollo-client';
import {ApolloService} from '../../apollo/apollo.service';
import {Observable, ReplaySubject} from 'rxjs';
import {getCurrentLanguage} from '../../store/reducers';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {TemplatesService} from '../../templates.service';
import {UserService} from '../../services/user.service';
import {Templates} from '../../components/templates';
import {OpenTemplateAlert, OpenWindow} from '../../store/actions/app-core.actions';
import {NodeFormPassport} from '../../models/node-form.passport';
import {NodeFormComponent} from '../../components/node-form/node-form.component';
import {ContributionModes} from './contribution-modes';
import {ContentTypeToLabelPipe} from '../../pipes/content-type-to-label.pipe';
import {ResourceToInnovationIndexItemPipe} from '../../pipes/schema/resource-to-innovation-index-item';
import {OrderByPipe} from 'ngx-pipes';
import {MyContributionsService} from '../../services/my-contributions.service';
import {ScrollDispatcher} from '@angular/cdk/overlay';
import {TranslateService} from '@ngx-translate/core';
import {ResponsiveService} from '../../services/responsive.service';
import {MyContributionsFormService} from './my-contributions-form.service';
import {MyContributionsDeletionService} from './my-contributions-deletion.service';

@Component({
  selector: 'app-my-contributions',
  templateUrl: './my-contributions.component.html',
  styleUrls: ['./my-contributions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrderByPipe, MyContributionsFormService]
})
export class MyContributionsComponent implements OnInit, AfterViewInit, OnDestroy {

  contributionsQueryOptions: WatchQueryOptions = {
    query: ApolloQueries.myContributions,
    fetchPolicy: 'network-only'
  };

  apolloService: ApolloService;

  currentLanguage$: Observable<string> = this.store.pipe(select(getCurrentLanguage));

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('myContributionsActionsTemplate', /* TODO: check static flag */ {static: true})
  myContributionsActionsTemplate: TemplateRef<any>;

  @ViewChild('contributionFiltersTemplate', /* TODO: check static flag */ {static: true})
  contributionFiltersTemplate: TemplateRef<any>;

  @ViewChild('formEditContentTemplate', /* TODO: check static flag */ {static: true})
  formEditContentTemplate: TemplateRef<any>;

  @ViewChild('formEditActionsTemplate', /* TODO: check static flag */ {static: true})
  formEditActionsTemplate: TemplateRef<any>;

  @ViewChild('contentForm', /* TODO: check static flag */ {read: NodeFormComponent})
  contentFormComponent: NodeFormComponent;

  @ViewChild('deleteConfirmationContentTemplate', /* TODO: check static flag */ {static: true})
  deleteConfirmationContentTemplate: TemplateRef<any>;

  @ViewChild('deleteConfirmationActionsTemplate', /* TODO: check static flag */ {static: true})
  deleteConfirmationActionsTemplate: TemplateRef<any>;

  @ViewChild('deleteErrorContentTemplate', /* TODO: check static flag */ {static: true})
  deleteErrorContentTemplate: TemplateRef<any>;

  @ViewChild('deleteErrorActionsTemplate', /* TODO: check static flag */ {static: true})
  deleteErrorActionsTemplate: TemplateRef<any>;

  @ViewChild('saveErrorActionsTemplate', /* TODO: check static flag */ {static: true})
  saveErrorActionsTemplate: TemplateRef<any>;

  @ViewChild('saveErrorTemplate', /* TODO: check static flag */ {static: true})
  saveErrorTemplate: TemplateRef<any>;

  currentPassport$: ReplaySubject<NodeFormPassport> = new ReplaySubject<NodeFormPassport>(0);

  editionContext$: ReplaySubject<{ mode: string, resourceIdentifier: ResourceIdentifier }> = new ReplaySubject<{ mode: string, resourceIdentifier: ResourceIdentifier }>(0);

  currentEditingContent$: Observable<StoreResource>;

  canSubmit$: Observable<boolean>;

  canModify$: Observable<boolean>;

  isApplying$: Observable<boolean>;

  isSubmitting$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  isDeleting$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  isSaving$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('submitConfirmTemplate', /* TODO: check static flag */ {static: true})
  submitConfirmTemplate: TemplateRef<any>;

  @ViewChild('submitConfirmActionsTemplate', /* TODO: check static flag */ {static: true})
  submitConfirmActionsTemplate: TemplateRef<any>;

  formValid: boolean;

  constructor(
    private store: Store<any>,
    private ngrxJsonApiService: NgrxJsonApiService,
    private dialog: MatDialog,
    public templatesService: TemplatesService,
    public userService: UserService,
    private contentTypeToLabelPipe: ContentTypeToLabelPipe,
    private resourceToInnovationIndexItemPipe: ResourceToInnovationIndexItemPipe,
    private orderByPipe: OrderByPipe,
    public myContributionsService: MyContributionsService,
    private cdr: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private responsiveService: ResponsiveService,
    public myContributionsFormService: MyContributionsFormService,
    public myContributionsDeletionService: MyContributionsDeletionService,
    @Inject('apolloServiceFactory') private apolloServiceFactory
  ) {
  }

  ngOnInit() {

    this.myContributionsService.currentFacet = {state: 'all'};

    this.isSubmitting$.next(false);

    this.currentEditingContent$ = this.currentPassport$.pipe(
      filter(nodeFormPassport => !!nodeFormPassport),
      distinctUntilKeyChanged('zone'),
      switchMap((nodeFormPassport: NodeFormPassport) => {
        return this.ngrxJsonApiService.getZone(nodeFormPassport.zone).selectStoreResource(nodeFormPassport.resourceIdentifier);
      })
    );

    this.canSubmit$ = this.currentEditingContent$.pipe(
      map(editingContent => {
        let canSubmit = false;
        if (!!editingContent && !!editingContent.relationships && !!editingContent.attributes) {
          let hasDescription = false;
          if (editingContent.type === NgrxJsonApiDefinitions.news.type) {
            hasDescription = editingContent.attributes.field_news_description
              && editingContent.attributes.field_news_description.value
              && editingContent.attributes.field_news_description.value.length > 0;
          } else if (editingContent.type === NgrxJsonApiDefinitions.innovation.type) {
            hasDescription = editingContent.attributes.field_innovation_description
              && editingContent.attributes.field_innovation_description.value
              && editingContent.attributes.field_innovation_description.value.length > 0;
          }
          const hasImages: boolean = editingContent.relationships.field_media_images.data.length > 0;
          const hasTranslations: boolean = Object.entries(editingContent.meta.drupal.translations).length > 1;

          canSubmit = hasDescription && hasImages && hasTranslations && this.formValid;

        }
        return canSubmit;
      })
    );

    this.canModify$ = this.currentEditingContent$.pipe(
      filter(editingContent => !!editingContent),
      map(editingContent => {

          const workflowInfos: { key: string, value: string } = this.myContributionsService.workflowCreation[editingContent.type];

          return editingContent.attributes[workflowInfos.key] === workflowInfos.value;

        }
      )
    );

    this.editionContext$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(editionContext => {
        const resourceIdentifier: ResourceIdentifier = editionContext.resourceIdentifier;
        const resourceType: string = resourceIdentifier.type;
        const resourceId: string = resourceIdentifier.id;
        const zone: string = 'edit-' + resourceType + '-' + resourceId;
        let editingResource: Resource;
        if (editionContext.mode === ContributionModes.create) {

          editingResource = {
            ...resourceIdentifier,
            attributes: {
              [this.myContributionsService.workflowCreation[resourceType].key]: this.myContributionsService.workflowCreation[resourceType].value
            }
          };

          this.ngrxJsonApiService.getZone(zone).newResource({resource: editingResource});

        } else if (editionContext.mode === ContributionModes.modify) {

          let query: Query;
          if (resourceIdentifier.type === NgrxJsonApiDefinitions.news.type) {
            query = NgrxJsonApiQueries.queryNewsNode;
          } else if (resourceIdentifier.type === NgrxJsonApiDefinitions.innovation.type) {
            query = NgrxJsonApiQueries.queryInnovationNode;
          }
          query = JSON.parse(JSON.stringify(query));
          query.id = resourceIdentifier.id;

          this.ngrxJsonApiService.getZone(zone).putQuery({query: query, fromServer: true});

        }

        this.isApplying$ = this.ngrxJsonApiService.getZone(zone).isApplying();
        this.cdr.detectChanges();

        this.currentPassport$.next({zone: zone, resourceIdentifier: resourceIdentifier});

        // const editorialType: string = this.contentTypeToLabelPipe.transform(resourceIdentifier.type);
        this.openEditor(editionContext.mode, resourceIdentifier.type);

      });

    this.myContributionsService.loadFullContributions();


    this.cdr.detectChanges();

    // this.contributionsList$.subscribe(contributionsList => console.log('contributionsList', contributionsList));

    this.currentLanguage$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(language => {
          /*if (this.apolloService.variables) {
            this.apolloService.variables = {...this.apolloService.variables, langCode: language};
          }*/
        }
      );

    /*this.filterBarChange
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(workflowState => {
        console.log('workflowState', workflowState);
        this.myContributionsService.currentFacet = {state: workflowState};
      });*/

  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  setFormValid(valid: boolean) {
    this.formValid = valid;
  }

  onSubmitForReview() {
    this.store.dispatch(new OpenTemplateAlert({
      contentTemplate: Templates.submitConfirm,
      actionsTemplate: Templates.submitConfirmActions
    }, {
      width: '420px',
      height: '280px',
      data: {}
    }));
  }

  onConfirmSubmitForReview() {
    this.isSubmitting$.next(true);
    this.canSubmit$.pipe(
      withLatestFrom(this.currentEditingContent$),
      take(1)
    ).subscribe(
      ([canSubmit, currentEditingContent]) => {
        if (canSubmit) {
          this.save()
            .pipe(
              switchMap(resource => {
                return this.myContributionsService.sendToReview(resource);
              }),
              take(1)
            )
            .subscribe(editedResource => {
              this.dialog.closeAll();
              this.myContributionsService.reLoadFullContributions();
              this.isSubmitting$.next(false);
            });
        }
      }
    );
  }

  onSave() {
    this.isSaving$.next(true);
    this.save().subscribe(
      (editingResource) => {
        this.isSaving$.next(false);
        const zone: string = 'edit-' + editingResource.type + '-' + editingResource.id;
        if (editingResource.errors.length > 0) {
          this.store.dispatch(new OpenTemplateAlert({
            contentTemplate: Templates.saveError,
            actionsTemplate: Templates.saveErrorActions
          }, {
            width: '420px',
            height: '280px',
            data: {}
          }));
          this.ngrxJsonApiService.getZone(zone).removeResourceErrors(editingResource, editingResource.errors);
        } else {
          this.myContributionsService.reLoadFullContributions();
        }
      }
    );
  }

  save(): Observable<StoreResource> {
    this.myContributionsFormService.saveEmitter.emit();
    return this.currentEditingContent$.pipe(
      map(editingResource => {
        return {id: editingResource.id, type: editingResource.type};
      }),
      switchMap(editingResourceIdentifier => {
        const zone: string = 'edit-' + editingResourceIdentifier.type + '-' + editingResourceIdentifier.id;
        return this.ngrxJsonApiService.getZone(zone).selectStoreResource(editingResourceIdentifier)
          .pipe(
            filter(editingResource => {
              return editingResource.state === 'IN_SYNC' || editingResource.errors.length > 0;
            }),
            take(1)
          );
      }),
      take(1)
    );
  }

  ngAfterViewInit(): void {

    this.templatesService.addTemplate(Templates.myContributionsActions, this.myContributionsActionsTemplate);
    this.templatesService.addTemplate(Templates.contributionFilters, this.contributionFiltersTemplate);
    this.templatesService.addTemplate(Templates.formEditContent, this.formEditContentTemplate);
    this.templatesService.addTemplate(Templates.formEditActions, this.formEditActionsTemplate);

    this.templatesService.addTemplate(Templates.submitConfirm, this.submitConfirmTemplate);
    this.templatesService.addTemplate(Templates.submitConfirmActions, this.submitConfirmActionsTemplate);

    this.templatesService.addTemplate(Templates.deleteConfirm, this.deleteConfirmationContentTemplate);
    this.templatesService.addTemplate(Templates.deleteConfirmationActions, this.deleteConfirmationActionsTemplate);

    this.templatesService.addTemplate(Templates.deleteError, this.deleteErrorContentTemplate);
    this.templatesService.addTemplate(Templates.deleteErrorActions, this.deleteErrorActionsTemplate);

    this.templatesService.addTemplate(Templates.saveError, this.saveErrorTemplate);
    this.templatesService.addTemplate(Templates.saveErrorActions, this.saveErrorActionsTemplate);

    /*this.scrollDispatcher.scrolled(100).pipe(
      takeUntil(this.destroyed$),
      withLatestFrom(this.myContributionsService.isReading$),
    ).subscribe(([scrolled, loading]) => {
      console.log('loading', loading);
      if (scrolled) {
        if (this.scrollDispatcher.getAncestorScrollContainers(this.elementRef).indexOf(scrolled) > -1) {
          if (scrolled.measureScrollOffset('bottom') < scrolled.getElementRef().nativeElement.offsetHeight) {
            console.log('loadNextPage()');
          }
        }
      }
    });*/

  }

  openCreateWindow(contentType: string) {

    const resourceIdentifier: ResourceIdentifier = {id: uuid(), type: 'node--' + contentType};
    this.editionContext$.next({mode: ContributionModes.create, resourceIdentifier: resourceIdentifier});

  }

  openEditWindow(content: any) {
    const resourceIdentifier: ResourceIdentifier = {id: content.uuid, type: 'node--' + content.content_type};
    this.editionContext$.next({mode: ContributionModes.modify, resourceIdentifier: resourceIdentifier});
  }

  onDelete() {

    this.store.dispatch(new OpenTemplateAlert({
      contentTemplate: Templates.deleteConfirm,
      actionsTemplate: Templates.deleteConfirmationActions
    }, {
      width: '420px',
      data: {
        isAlert: true
      }
    }));

  }

  confirmDeletion() {

    this.myContributionsDeletionService.deletingResource = null;

    this.currentEditingContent$.pipe(
      take(1)
    ).subscribe(editingResource => {

      this.myContributionsDeletionService.deletingResource = editingResource;

      this.isDeleting$.next(true);

      const editingResourceIdentifier = {id: editingResource.id, type: editingResource.type};

      const zone: string = 'edit-' + editingResourceIdentifier.type + '-' + editingResourceIdentifier.id;
      this.ngrxJsonApiService.getZone(zone).deleteResource({resourceId: editingResourceIdentifier, toRemote: true});

      this.ngrxJsonApiService.getZone(zone).selectStoreResource(editingResourceIdentifier)
        .pipe(
          filter(deletedResource => {
            return deletedResource === undefined || deletedResource.errors?.length > 0;
          }),
          take(1)
        )
        .subscribe(deletedResource => {
          this.myContributionsDeletionService.deletingResource = null;
          this.isDeleting$.next(false);
          if (!deletedResource) {
            this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneContributionsList).deleteResource({
              resourceId: editingResourceIdentifier,
              toRemote: false
            });
            this.myContributionsService.reLoadFullContributions();
            this.dialog.closeAll();
          } else if (deletedResource.errors?.length > 0) {
            this.ngrxJsonApiService.getZone(zone).removeResourceErrors(editingResourceIdentifier, deletedResource.errors);
            this.store.dispatch(new OpenTemplateAlert({
              contentTemplate: Templates.deleteError,
              actionsTemplate: Templates.deleteErrorActions
            }, {
              width: '420px',
              height: '280px',
              data: {
                isAlert: true
              }
            }));
          }
        });

    });

  }

  openEditor(mode: string, contentType: string) {

    this.responsiveService.isXs$
      .pipe(take(1))
      .subscribe(isXs => {

        const terms: { [mode: string]: { [contentType: string]: string } } = {
          [ContributionModes.create]: {
            [NgrxJsonApiDefinitions.news.type]: 'MY_CONTRIBUTIONS.CREATE_NEWS',
            [NgrxJsonApiDefinitions.innovation.type]: 'MY_CONTRIBUTIONS.CREATE_INNOVATION'
          },
          [ContributionModes.modify]: {
            [NgrxJsonApiDefinitions.news.type]: 'MY_CONTRIBUTIONS.CREATE_NEWS',
            [NgrxJsonApiDefinitions.innovation.type]: 'MY_CONTRIBUTIONS.CREATE_INNOVATION'
          }
        };
        const title: string = terms[mode][contentType];

        const matDialogConfig: MatDialogConfig = {
          panelClass: ['edit-theme'],
          disableClose: true
        };

        if (isXs) {
          matDialogConfig.minWidth = '100vw';
          matDialogConfig.width = '100vw';
          (matDialogConfig.panelClass as string[]).push('fill-height');
        } else {
          matDialogConfig.minWidth = '985px';
          matDialogConfig.maxWidth = '985px';
          matDialogConfig.minHeight = '80vh';
          matDialogConfig.maxHeight = '80vh';
        }

        this.store.dispatch(new OpenWindow(
          title,
          {
            contentTemplate: Templates.formEditContent,
            actionsTemplate: Templates.formEditActions,
            classes: 'edit-window overflow-hidden'
          },
          matDialogConfig
        ));

      });

  }

  getFiltersToggleBarTemplate() {
    return this.templatesService.getTemplate(Templates.toggleBarStateFilters);
  }

  get appFooterTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.appFooter);
  }

}
