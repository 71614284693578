<ng-container *ngIf="render">

  <h3>Vote Header</h3>

  <ng-template [ngTemplateOutlet]="voteHeaderTemplate"></ng-template>

</ng-container>


<ng-template #voteHeaderTemplate let-node="node" let-zone="zone">

  <app-vote-header [node]="node" [zone]="zone"></app-vote-header>

</ng-template>
