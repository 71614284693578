<ng-container *ngIf="videoFiles$|async as videoFiles;else noVideoTemplate">

  <ng-container *ngIf="videoFiles.length > 0;else noVideoTemplate">

    <video #vPlayer controls class="w-100 h-100">
      <ng-template ngFor [ngForOf]="videoFiles" let-videoFile>
        <source [src]="videoFile.attributes.uri.url|mediaUrl" [type]="videoFile.attributes.filemime">
      </ng-template>
      Your browser does not support the video tag.
    </video>

  </ng-container>

</ng-container>

<ng-template #noVideoTemplate>

  <div class="d-flex flex-column flex-fill align-items-center justify-content-center">

    <p class="mat-body-2" [innerHTML]="(videoPlayerPassport$|async)?.media?.attributes?.name"></p>

    <p class="text-center px-5" [innerHTML]="'MY_CONTRIBUTIONS.NO_VIDEO_MESSAGE'|translate"></p>

  </div>

</ng-template>
