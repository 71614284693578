<ng-container *ngIf="data$|async; else loadingTemplate">

  <ng-content>
  </ng-content>

</ng-container>

<ng-template #loadingTemplate>
  <div class="position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center">
    <mat-progress-spinner [diameter]="40" [color]="'accent'" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
</ng-template>

<div #swiperContainer class="swiper-container"  waResizeBox="content-box" (waResizeObserver)="onResize($event)">

  <div class="swiper-wrapper">

    <ng-template ngFor [ngForOf]="data$|async" let-slideData let-i="index">

      <div class="swiper-slide overflow-hidden d-flex">

        <div class="d-flex flex-column flex-fill overflow-hidden">

          <ng-template
            [ngTemplateOutlet]="slideTemplate"
            [ngTemplateOutletContext]="{data: slideData, isCurrent: (slideIndex$|async) === i}">
          </ng-template>

        </div>

      </div>

    </ng-template>

  </div>
</div>
