import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {Store} from '@ngrx/store';
import * as fromRoot from './store/reducers';
import {getAuthState} from './store/reducers';
import {PwaService} from './pwa.service';
import {filter, map, take} from 'rxjs/operators';
import {DbService} from './services/db.service';
import {SetRedirectUrl} from './store/actions/app-core.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  protected authenticated$: Observable<boolean> = this.store.select(getAuthState).pipe(
    filter(state => state.loaded),
    map(state => state.authenticated),
    take(1)
  );

  protected redirectUrl$: Observable<string> = this.dbService.getRedirectUrl$().pipe(take(1));

  constructor(
    private oauthService: OAuthService,
    private store: Store<fromRoot.State>,
    private pwaService: PwaService,
    private dbService: DbService,
    private router: Router
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return combineLatest([this.redirectUrl$, this.authenticated$]).pipe(
      take(1)
    ).pipe(
      map(([redirectUrl, authenticated]) => {
        if (!authenticated) {
          return false;
        } else {
          if (redirectUrl) {
            this.store.dispatch(new SetRedirectUrl(null));
            const urlTree: UrlTree = this.router.parseUrl(redirectUrl);
            return urlTree;
          } else {
            return true;
          }
        }
      }),
      take(1)
    );
  }

}
