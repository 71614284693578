import {Pipe, PipeTransform} from '@angular/core';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';

@Pipe({
  name: 'contentTypeToLabel'
})
export class ContentTypeToLabelPipe implements PipeTransform {

  transform(contentType: string): string {
    const typesToLabel: { [type: string]: string } = {
      [NgrxJsonApiDefinitions.innovation.type]: 'Innovation',
      [NgrxJsonApiDefinitions.news.type]: 'News',
    };
    return typesToLabel[contentType];
  }

}
