import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getAvailableLanguages, getCurrentLanguage, getRouteData} from '../store/reducers';
import {distinctUntilChanged, filter, map, shareReplay, take, withLatestFrom} from 'rxjs/operators';
import {SetCurrentLanguage} from '../store/actions/app-core.actions';

@Injectable({
  providedIn: 'root'
})
export class LanguageSwitcherService {

  languages$: Observable<string> = this.store.pipe(select(getCurrentLanguage), distinctUntilChanged());
  availableLanguages$: Observable<string[]> = this.store.pipe(select(getAvailableLanguages));
  routeData$: Observable<any> = this.store.pipe(select(getRouteData));

  currentLanguage$: Observable<string> = this.store.pipe(
    select(getCurrentLanguage),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isStaticRoute$: Observable<boolean> = this.routeData$.pipe(
    filter(data => !!data),
    map(data => {
      return data.staticRoute;
    }));

  alternativeLanguagePath$: Observable<string>;
  alternativeLanguage$: Observable<string> = this.currentLanguage$.pipe(
    withLatestFrom(this.availableLanguages$),
    filter(([language, availableLanguages]) => {
      return !!language && !!availableLanguages;
    }),
    map(([language, availableLanguages]) => availableLanguages.filter(availableLanguage => availableLanguage !== language).shift())
  );

  routeResourceIdentifier$: Observable<any> = this.routeData$.pipe(
    filter(routeData => !!routeData),
    map(routeData => routeData.resourceIdentifier)
  );

  constructor(
    private store: Store<any>
  ) {
  }

  switchLanguage() {
    this.languages$.pipe(
      withLatestFrom(this.availableLanguages$),
      take(1)
    ).subscribe(([currentLanguage, availableLanguages]: [string, string[]]) => {
      const alternativeLanguage: string = availableLanguages.filter(lang => lang !== currentLanguage).shift();
      this.store.dispatch(new SetCurrentLanguage(alternativeLanguage));
    });
  }

}
