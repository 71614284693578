import {RoutesMap} from '@ngx-mil-drupal/models/routes-map.model';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {DoubleColumnsComponent} from '../layout/double-columns/double-columns.component';
import {RoutedTemplateComponent} from '../components/routed-template/routed-template.component';
import {UserLayoutComponent} from '../templates/layouts/user-layout/user-layout.component';
import {MiniListComponent} from '../components/mini-list/mini-list.component';
import {Templates} from '../components/templates';

export class AppRoutes {

  /**
   * Example :
   static dynamicPageRoute: Route = {
    path: 'page',
    component: PageComponent,
    resolve: {drupalResolver: DrupalResolver, drupalContentResolver: DrupalContentResolver},
    data: {
      context: 'page'
    }
  };
   static routeMap: any = {
    page: AppRoutes.dynamicPageRoute,
  };
   */

  static detailNewsViewTemplates = {
    top: [Templates.contentHeader],
    left: [Templates.imagesSlider],
    right: [
      Templates.newsDescription,
      Templates.newsCategoryLaunchDate,
      Templates.brandTags,
      Templates.awardTags,
      Templates.freeTags,
    ],
    footer: [Templates.appFooter]
  };

  static detailInnovationViewTemplates = {
    top: [Templates.contentHeader],
    left: [
      Templates.innovationDescription,
      Templates.innovationWhy,
      Templates.innovationInfos,
      Templates.freeTags
    ],
    right: ['imagesSlider'],
    footer: [Templates.appFooter]
  };

  static routeMap: RoutesMap = {
    innovation: {
      path: 'will be set later',
      children: [
        {
          path: '',
          component: DoubleColumnsComponent,
          data: {
            className: 'innovation-template',
          }
        },
        {
          path: '',
          component: MiniListComponent,
          outlet: 'sideLeftList'
        },
        {
          path: '',
          component: UserLayoutComponent,
          outlet: 'topContainer',
          data: {
            templates: [
              'searchFullTextDetail',
              Templates.backDetailButton
            ]
          }
        }
      ],
      data: {
        openSideBar: true,
        contentTypes: [NgrxJsonApiDefinitions.ctInnovation],
        contentType: NgrxJsonApiDefinitions.ctInnovation,
        topGroupClass: 'pb-sm-3',
        mainGroupClass: 'pr-sm-4',
        mainContentGroupClass: 'detail',
        placeholderBase: 'SEARCH.PLACEHOLDERS.innovation',
        templates: AppRoutes.detailInnovationViewTemplates
      }
    },
    news: {
      path: 'will be set later',
      children: [
        {
          path: '',
          component: DoubleColumnsComponent,
          data: {
            className: 'news-template'
          }
        },
        {
          path: '',
          component: MiniListComponent,
          outlet: 'sideRightList'
        },
        {
          path: '',
          component: UserLayoutComponent,
          outlet: 'topContainer',
          data: {
            templates: [
              'searchFullTextDetail',
              Templates.backDetailButton
            ]
          }
        }
      ],
      data: {
        openSideBar: true,
        contentTypes: [NgrxJsonApiDefinitions.ctNews],
        contentType: NgrxJsonApiDefinitions.ctNews,
        appClass: 'context-news',
        topGroupClass: 'pb-sm-3',
        mainGroupClass: 'pl-sm-4',
        mainContentGroupClass: 'detail',
        placeholderBase: 'SEARCH.PLACEHOLDERS.news',
        templates: AppRoutes.detailNewsViewTemplates
      }
    },
    page: {
      path: 'will be set later',
      children: [
        {
          path: '',
          component: RoutedTemplateComponent
        }
      ],
      data: {
        reuse: true,
        contentType: NgrxJsonApiDefinitions.ctPage,
        template: Templates.simplePage,
        mainContentGroupClass: 'detail'
      }
    }
  };

}

