import {Pipe, PipeTransform} from '@angular/core';
import {Resource, ResourceIdentifier} from 'ngrx-json-api';

@Pipe({
  name: 'alreadyVoted'
})
export class AlreadyVotedPipe implements PipeTransform {

  transform(votes: ResourceIdentifier[], content: Resource): boolean {
    if (votes) {
      return votes.filter(vote => vote.id === content.id).length > 0;
    }
    return false;
  }

}
