import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {SlideRenderer} from '../../../models/slide-renderer';
import {ResponsiveService} from '../../../services/responsive.service';

@Component({
  selector: 'app-news-slide-renderer',
  templateUrl: './news-slide-renderer.component.html',
  styleUrls: ['./news-slide-renderer.component.scss']
})
export class NewsSlideRendererComponent implements OnInit, SlideRenderer {

  @HostBinding('class') class = 'd-flex overflow-hidden flex-fill';

  @Input()
  data: any;

  @Input()
  zone: string;

  constructor(
    public responsiveService: ResponsiveService
  ) {
  }

  ngOnInit() {
  }

}
