import {Action} from '@ngrx/store';
import {OAuthEvent} from 'angular-oauth2-oidc';

export enum AuthActionType {
  AUTH_SET_UP = '[Auth] Set Up',
  AUTH_INIT = '[Auth] Init',
  INIT_IMPLICIT_FLOW = '[Auth] INIT_IMPLICIT_FLOW',
  OAUTH_EVENT = '[Auth] OAUTH_EVENT',
  AUTHENTICATED_CHANGE = '[Auth] AUTHENTICATED_CHANGE',
  LOG_OUT = '[Auth] Log Out',
}

export class SetUpAuthAction implements Action {
  readonly type = AuthActionType.AUTH_SET_UP;
}

export class InitAuthAction implements Action {
  readonly type = AuthActionType.AUTH_INIT;
}

export class InitImplicitFlowAction implements Action {
  readonly type = AuthActionType.INIT_IMPLICIT_FLOW;

  constructor(public readonly redirectUrl: string = null) {
  }
}

export class OAuthEventAction implements Action {
  readonly type = AuthActionType.OAUTH_EVENT;

  constructor(public readonly event: OAuthEvent) {
  }
}

export class LogOutAction implements Action {
  readonly type = AuthActionType.LOG_OUT;

  constructor() {
  }
}

export class AuthenticatedChangeAction implements Action {
  readonly type = AuthActionType.AUTHENTICATED_CHANGE;

  constructor(
    public readonly authenticated: boolean,
    public readonly claims: any = null,
    public readonly indeterminate: boolean = false) {

  }
}
