import {Inject, Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../ngrx-json-api/ngrx-json-queries';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, mergeMap, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {ManyQueryResult, NgrxJsonApiService, NgrxJsonApiStoreResources, Resource, StoreResource} from 'ngrx-json-api';
import {WatchQueryOptions} from 'apollo-client';
import {ApolloQueries} from '../apollo/apollo-queries';
import {PollService} from './poll.service';
import {GetAppWorkflowStatePipe} from '../pipes/get-app-workflow-state.pipe';
import {UserPaths} from '../models/user-path.model';
import {AppIcons} from '../models/app-icons';
import {Roles} from '../models/roles';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  contributionsQueryOptions: WatchQueryOptions = {
    query: ApolloQueries.myContributions,
    fetchPolicy: 'network-only',
    variables: {
      langCode: 'en',
      range: {start: 0, end: 50}
    }
  };

  userInfos$: Observable<Resource> = this.ngrxJsonApiService
    .getZone(NgrxJsonApiDefinitions.zoneUser)
    .selectStoreResourcesOfType(NgrxJsonApiDefinitions.user.type)
    .pipe(
      filter(ngrxJsonApiStoreResources => !!ngrxJsonApiStoreResources),
      map((ngrxJsonApiStoreResources: NgrxJsonApiStoreResources) => {
        return Object.keys(ngrxJsonApiStoreResources).map(key => ngrxJsonApiStoreResources[key]).shift();
      }),
      distinctUntilKeyChanged('id')
    );

  userName$: Observable<string> = this.userInfos$.pipe(
    map(userInfos => {
        return (userInfos.attributes.display_name && userInfos.attributes.display_name !== '') ?
          userInfos.attributes.display_name :
          (userInfos.attributes.name && userInfos.attributes.name !== '' ? userInfos.attributes.name : userInfos.attributes.mail);
      }
    )
  );

  userMail$: Observable<string> = this.userInfos$.pipe(
    map(userInfos => userInfos.attributes.mail)
  );

  userDrupalUid$: Observable<string> = this.userInfos$.pipe(
    map(userInfos => userInfos.attributes.drupal_internal__uid),
    distinctUntilChanged()
  );

  roles$: Observable<Resource[]> = this.userInfos$.pipe(
    switchMap(userInfos => {
      if (userInfos && userInfos.relationships && userInfos.relationships.roles) {
        return this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneUser)
          .selectStoreResources(userInfos.relationships.roles.data)
          .pipe(
            map(roleResources => {
              return roleResources;
            }),
            distinctUntilChanged((oldValue, newValue) => {
              return JSON.stringify(oldValue) === JSON.stringify(newValue);
            }),
            shareReplay(1)
          );
      } else {
        return of([]);
      }
    }),
    shareReplay(1)
  );

  isContributor$: Observable<boolean> = this.roles$.pipe(
    map(roles => {
      return roles.filter(role => role.attributes.drupal_internal__id === Roles.contributor).length > 0;
    }),
    shareReplay(1)
  );

  isJury$: Observable<boolean> = this.roles$.pipe(
    map(roles => {
      return roles.filter(role => role.attributes.drupal_internal__id === Roles.jury).length > 0;
    }),
    shareReplay(1)
  );

  userUid$: Observable<string> = this.userInfos$.pipe(
    map(userInfos => userInfos.id),
    distinctUntilChanged(),
    tap(uid => {
      NgrxJsonApiQueries.queryCart.params.filtering = [
        ...NgrxJsonApiQueries.queryCart.params.filtering,
        {
          path: 'user',
          value: 'uid.id',
          operator: 'path'
        },
        {
          path: 'user',
          value: uid,
          operator: 'value'
        },
        {
          path: 'user',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        }
      ];

      NgrxJsonApiQueries.queryInspirationNodeCollection.params.filtering = [
        {
          path: 'user',
          value: 'uid.id',
          operator: 'path'
        },
        {
          path: 'user',
          value: uid,
          operator: 'value'
        },
        {
          path: 'user',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        }
      ];

      NgrxJsonApiQueries.queryInspirationNodeCollectionInclInnovations.params.filtering = [
        {
          path: 'user',
          value: 'uid.id',
          operator: 'path'
        },
        {
          path: 'user',
          value: uid,
          operator: 'value'
        },
        {
          path: 'user',
          value: NgrxJsonApiQueries.equals,
          operator: 'operator'
        }
      ];

    }),
    shareReplay(1)
  );

  inspirationsCount$: Observable<number> = this.userUid$.pipe(
    filter(uid => !!uid),
    switchMap(uid => {

      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards).putQuery({
        query: NgrxJsonApiQueries.queryInspirationNodeCollection,
        fromServer: true
      });

      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectManyResults(NgrxJsonApiQueries.queryInspirationNodeCollection.queryId)
        .pipe(
          filter((manyQueryResult: ManyQueryResult) => manyQueryResult.loading === false && !!manyQueryResult.data),
          map((manyQueryResult: ManyQueryResult) => manyQueryResult.data),
          map((data: StoreResource[]) => {
            return data.length;
          })
        );
    }),
    shareReplay(1)
  );

  cartCount$ = this.ngrxJsonApiService
    .getZone(NgrxJsonApiDefinitions.zoneShareBox)
    .selectManyResults(NgrxJsonApiQueries.queryCart.queryId).pipe(
      filter((manyQueryResult: ManyQueryResult) => !!manyQueryResult && !!manyQueryResult.data),
      map((manyQueryResult: ManyQueryResult) => {
        if (manyQueryResult.data.length > 0) {
          return manyQueryResult.data[0];
        } else {
          return null;
        }
      }),
      map(cart => {
        if (cart) {
          return cart.relationships?.field_cart_part?.data?.length;
        } else {
          return 0;
        }
      }),
      shareReplay(1)
    );

  contributionsCount$: ReplaySubject<number> = new ReplaySubject<number>();

  contributionsFacets$: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    @Inject('apolloServiceFactory') private apolloServiceFactory,
    private pollService: PollService,
    private getAppWorkflowStatePipe: GetAppWorkflowStatePipe
  ) {


    this.userUid$.pipe(
      tap(uid => {
        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox).putQuery({
          query: NgrxJsonApiQueries.queryCart,
          fromServer: true
        });
      }),
      take(1)
    ).subscribe();

  }

  getIcon(path: string): string {
    path = path.substring(1);
    let iconId: string;
    switch (path) {
      case UserPaths.Contributions:
        iconId = AppIcons.contributions;
        break;
      case UserPaths.Inspirations:
        iconId = AppIcons.inspirations;
        break;
      case UserPaths.Share:
        iconId = AppIcons.share;
        break;
      case UserPaths.Poll:
        iconId = AppIcons.poll;
        break;
    }
    return iconId;
  }

  loadCurrentUser(uuid: string) {

    this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneUser).putQuery({
      query: {
        queryId: 'user',
        type: NgrxJsonApiDefinitions.user.type,
        id: uuid,
        params: {
          include: ['roles']
        }
      },
      fromServer: true
    });

    this.userDrupalUid$.pipe(
      take(1)
    ).subscribe(drupalUserId => {

      this.isJury$.pipe(
        take(1)
      ).subscribe(isJury => {
        if (isJury) {
          this.pollService.init();
        }
      });

    });

  }
}
