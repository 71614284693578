import {AppCoreActions} from '../actions';
import {ListThumbSizes} from '../../models/list-thumb-sizes.model';
import {Breadcrumb} from '../../models/breadcrumb';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {Keyword} from '../effects/app-core.effects';

export class CookieBotConsent {

  marketing: boolean;
  necessary: boolean;
  preferences: boolean;
  stamp: string;
  statistics: boolean;

}

export interface State {
  defaultLanguage?: string;
  currentLanguage?: string;
  userPreferredLanguage?: string;
  availableLanguages?: string[];
  breakpoint?: string;
  cookieBotConsent?: CookieBotConsent;
  listThumbSize: ListThumbSizes;
  breadcrumbs: Breadcrumb[];
  redirectUrl: string;
  syncData: boolean;
  innovationCount: number;
  newsCount: number;
  innovationKeywords: Keyword[];
  newsKeywords: Keyword[];
  autoPlay: boolean;
  pageTitle: string;
  unSeenContents: { innovation: number, news: number };
}

const initialState: State = {
  defaultLanguage: null,
  currentLanguage: null,
  availableLanguages: null,
  userPreferredLanguage: null,
  breakpoint: null,
  listThumbSize: ListThumbSizes.Normal,
  breadcrumbs: [],
  redirectUrl: null,
  syncData: null,
  innovationCount: null,
  newsCount: null,
  innovationKeywords: null,
  newsKeywords: null,
  autoPlay: null,
  pageTitle: null,
  unSeenContents: null
};

export function reducer(
  state: State = initialState,
  action: AppCoreActions.AppCoreActionsUnion
): State {
  switch (action.type) {

    case AppCoreActions.AppCoreActionTypes.SetRedirectUrl:
      return {
        ...state,
        redirectUrl: action.url
      };

    case AppCoreActions.AppCoreActionTypes.SetAutoPlay:
      return {
        ...state,
        autoPlay: true
      };

    case AppCoreActions.AppCoreActionTypes.SetUnSeenContents:
      // console.log('action', action);
      return {
        ...state,
        unSeenContents: {
          ...state.unSeenContents,
          [action.contentType]: action.count
        }
      };

    case AppCoreActions.AppCoreActionTypes.SetCurrentLanguage:
      return {
        ...state,
        currentLanguage: action.language
      };

    case AppCoreActions.AppCoreActionTypes.SetDefaultLanguage:
      return {
        ...state,
        defaultLanguage: action.language
      };

    case AppCoreActions.AppCoreActionTypes.SetAvailableLanguages:
      return {
        ...state,
        availableLanguages: action.languages
      };

    case AppCoreActions.AppCoreActionTypes.SetBreakpoint:
      return {
        ...state,
        breakpoint: action.breakpoint
      };

    case AppCoreActions.AppCoreActionTypes.SetCookieBotConsent:
      return {
        ...state,
        cookieBotConsent: action.cookieBotConsent
      };

    case AppCoreActions.AppCoreActionTypes.SetListThumbSize:
      return {
        ...state,
        listThumbSize: action.size
      };

    case AppCoreActions.AppCoreActionTypes.UpdateBreadcrumbs:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs
      };

    case AppCoreActions.AppCoreActionTypes.SetPageTitle:
      return {
        ...state,
        pageTitle: action.title
      };

    case AppCoreActions.AppCoreActionTypes.UpdateBreadcrumb:
      let breadcrumbs: Breadcrumb[] = state.breadcrumbs;
      let breadcrumbIndex: number;
      const breadcrumbToUpdate: Breadcrumb = breadcrumbs.filter((breadcrumb, index) => {
        if (breadcrumb.url === action.breadcrumb.url) {
          breadcrumbIndex = index;
          return true;
        }
      }).shift();

      if (breadcrumbToUpdate && !isNaN(breadcrumbIndex)) {
        breadcrumbs = Object.assign([...breadcrumbs], {[breadcrumbIndex]: action.breadcrumb});
      }
      return {
        ...state,
        breadcrumbs: breadcrumbs
      };

    case AppCoreActions.AppCoreActionTypes.ChangeSyncData:
      return {
        ...state,
        syncData: action.syncData
      };

    case AppCoreActions.AppCoreActionTypes.UpdateContentCount:
      if (action.contentType === NgrxJsonApiDefinitions.ctNews) {
        return {
          ...state,
          newsCount: action.count
        };
      } else if (action.contentType === NgrxJsonApiDefinitions.ctInnovation) {
        return {
          ...state,
          innovationCount: action.count
        };
      }
      break;

    case AppCoreActions.AppCoreActionTypes.UpdateKeywords:
      if (action.contentType === NgrxJsonApiDefinitions.ctNews) {
        return {
          ...state,
          newsKeywords: action.keywords
        };
      } else if (action.contentType === NgrxJsonApiDefinitions.ctInnovation) {
        return {
          ...state,
          innovationKeywords: action.keywords
        };
      }
      break;

    default:
      return state;
  }
}

export const getBreakpoint = (state: State) => state.breakpoint;

export const getMarketingConsent = (state: State) => state.cookieBotConsent ? state.cookieBotConsent.marketing : null;

export const getPreferencesConsent = (state: State) => state.cookieBotConsent ? state.cookieBotConsent.preferences : null;

export const getStatisticsConsent = (state: State) => state.cookieBotConsent ? state.cookieBotConsent.statistics : null;

export const getDefaultLanguage = (state: State) => state.defaultLanguage;

export const getCurrentLanguage = (state: State) => state.currentLanguage;

export const getAvailableLanguages = (state: State) => state.availableLanguages;

export const getListThumbSize = (state: State) => state.listThumbSize;

export const getBreadcrumbs = (state: State) => state.breadcrumbs;

export const getRedirectUrl = (state: State) => state.redirectUrl;

export const getSyncData = (state: State) => state.syncData;

export const getInnovationKeywords = (state: State) => state.innovationKeywords;

export const getNewsKeywords = (state: State) => state.newsKeywords;

export const getAutoPlay = (state: State) => state.autoPlay;

export const getPageTitle = (state: State) => state.pageTitle;

export const getUnSeenNewsCount = (state: State) => state?.unSeenContents?.news;

export const getUnSeenInnovationsCount = (state: State) => state?.unSeenContents?.innovation;
