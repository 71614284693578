import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {NotFoundComponent} from './components/not-found/not-found.component';
import {AppPreloadStrategy} from './app-preload-strategy';
import {WelcomeComponent} from './welcome/welcome.component';
import {WelcomeModule} from './welcome/welcome.module';
import {SearchComponent} from './search/search.component';
import {SearchModule} from './search/search.module';
import {AuthGuard} from './auth.guard';
import {MyContributionsModule} from './my-contributions/my-contributions.module';
import {DrupalPathGuard} from '@ngx-mil-drupal/drupal-path-guard';
import {DrupalContentResolver} from '@ngx-mil-drupal/drupal-content-resolver.service';
import {DrupalListResolver} from '@ngx-mil-drupal/drupal-list-resolver.service';
import {DrupalResolver} from '@ngx-mil-drupal/drupal-resolver.service';
import {PathInfosResolver} from '@ngx-mil-drupal/path-infos-resolver.service';
import {SharedModule} from './shared.module';
import {NgrxJsonApiDefinitions} from './ngrx-json-api/ngrx-json-api-definitions';
import {MyInspirationsModule} from './my-inspirations/my-inspirations.module';
import {InspirationComponent} from './my-inspirations/inspiration/inspiration.component';
import {InspirationsListComponent} from './my-inspirations/inspirations-list/inspirations-list.component';
import {PollModule} from './poll/poll.module';
import {MyShareModule} from './my-share/my-share.module';
import {LayoutModule} from './layout/layout.module';
import {UserLayoutComponent} from './templates/layouts/user-layout/user-layout.component';
import {PollListComponent} from './poll/poll-list/poll-list.component';
import {Templates} from './components/templates';
import {DoubleColumnsComponent} from './layout/double-columns/double-columns.component';
import {InspirationContentListComponent} from './my-inspirations/inspiration-content-list/inspiration-content-list.component';
import {AppIcons} from './models/app-icons';
import {PollGuard} from './poll/poll.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full'
      },
      {
        path: 'welcome',
        pathMatch: 'full',
        children: [
          {
            path: '',
            component: WelcomeComponent
          },
          {
            path: '',
            component: UserLayoutComponent,
            outlet: 'topContainer',
            data: {
              templates: [
                Templates.searchFullText
              ]
            }
          }
        ],
        data: {
          home: true,
          title: 'APP.TITLE',
          staticRoute: true,
          appClass: 'context-home',
          mainGroupClass: 'px-0',
          mainContentGroupClass: 'home',
          placeholderBase: 'SEARCH.PLACEHOLDERS.newsAndInnovation',
          searchAll: true,
          contentTypes: [NgrxJsonApiDefinitions.ctNews, NgrxJsonApiDefinitions.ctInnovation]
        }
      },
      {
        path: 'news',
        data: {
          staticRoute: true,
          contentTypes: [NgrxJsonApiDefinitions.ctNews],
          appClass: 'context-news',
          mainGroupClass: 'px-2 px-sm-4',
          mainContentGroupClass: 'list search-main-group',
          topGroupClass: 'pb-3',
          title: 'SEARCH.NEWS',
          placeholderBase: 'SEARCH.PLACEHOLDERS.news',
          showSizeControls: true
        },
        children: [
          {
            path: '',
            component: SearchComponent
          },
          {
            path: '',
            component: UserLayoutComponent,
            outlet: 'topContainer',
            data: {
              templates: [
                Templates.searchFullText,
                Templates.searchFiltersGroup,
                Templates.mainHeaderSearch
              ]
            }
          }
        ]
      },
      {
        path: 'innovations',
        data: {
          staticRoute: true,
          contentTypes: [NgrxJsonApiDefinitions.ctInnovation],
          mainGroupClass: 'px-2 px-sm-4',
          mainContentGroupClass: 'list search-main-group',
          topGroupClass: 'pb-3',
          title: 'SEARCH.INNOVATIONS',
          placeholderBase: 'SEARCH.PLACEHOLDERS.innovation',
          showSizeControls: true
        },
        children: [
          {
            path: '',
            component: SearchComponent
          },
          {
            path: '',
            component: UserLayoutComponent,
            outlet: 'topContainer',
            data: {
              templates: [
                Templates.searchFullText,
                Templates.searchFiltersGroup,
                Templates.mainHeaderSearch
              ]
            }
          }
        ]
      },
      {
        path: 'my-contributions',
        data: {
          staticRoute: true,
          mainGroupClass: 'px-2 px-sm-4',
          mainContentGroupClass: 'list',
          title: 'MY_CONTRIBUTIONS.TITLE',
          showSizeControls: true,
          headerIcon: AppIcons.contributions
        },
        children: [
          {
            path: '',
            component: UserLayoutComponent,
            data: {
              templates: [
                'myContributions'
              ]
            }
          },
          {
            path: '',
            component: UserLayoutComponent,
            outlet: 'topContainer',
            data: {
              templates: [
                Templates.mainHeaderContributions,
                Templates.contributionFilters
              ]
            }
          }
        ]
      },
      {
        path: 'poll',
        canActivate: [PollGuard],
        data: {
          staticRoute: true,
          mainGroupClass: 'px-2 px-sm-4',
          templates: [],
          mainContentGroupClass: 'list poll',
          title: 'POLL.TITLE',
          headerIcon: AppIcons.poll
        },
        children: [
          {
            path: '',
            component: PollListComponent
          },
          {
            path: ':type/:id',
            component: UserLayoutComponent,
            data: {
              staticRoute: true,
              zone: NgrxJsonApiDefinitions.zonePolling,
              contentType: NgrxJsonApiDefinitions.ctInnovation,
              title: 'POLL.TITLE'
            },
            children: [
              {
                path: '',
                component: DoubleColumnsComponent,
                data: {
                  className: 'innovation-template poll',
                  staticRoute: true,
                  zone: DrupalPathGuard.DRUPAL_ZONE + '-%language',
                  contentType: NgrxJsonApiDefinitions.ctFakeVote,
                  mainContentGroupClass: 'detail poll',
                  title: 'POLL.TITLE',
                  templates: {
                    top: [
                      Templates.backDetailButton,
                      Templates.voteHeader,
                      Templates.contentHeader
                    ],
                    left: [
                      Templates.innovationDescription,
                      Templates.innovationWhy,
                      Templates.innovationInfos,
                      Templates.freeTags
                    ],
                    right: [
                      'imagesSlider'
                    ],
                    footer: [Templates.appFooter]
                  }
                }
              }
            ]
          },
          {
            path: '',
            component: PollListComponent,
            outlet: 'sideLeftList'
          },
          {
            path: '',
            component: UserLayoutComponent,
            outlet: 'topContainer',
            data: {
              templates: [
                Templates.mainHeader
              ]
            }
          }
        ]
      },
      {
        path: 'my-inspirations',
        data: {
          staticRoute: true,
          mainGroupClass: 'px-2 px-sm-4',
          mainContentGroupClass: 'list my-inspirations',
          title: 'MY_INSPIRATION.TITLE',
          headerIcon: AppIcons.inspirations
        },
        children: [
          {
            path: '',
            component: InspirationsListComponent,
            data: {
              staticRoute: true
            }
          },
          {
            path: '',
            component: UserLayoutComponent,
            outlet: 'topContainer',
            data: {
              templates: [
                Templates.mainHeaderInspirations
              ]
            }
          },
          {
            path: ':inspirationId',
            data: {
              mainContentGroupClass: 'my-inspirations detail',
              staticRoute: true,
              title: 'MY_INSPIRATION.TITLE',
              showSizeControls: true
            },
            children: [
              {
                path: '',
                component: InspirationComponent
              },
              {
                path: 'node--innovation/:id',
                data: {
                  staticRoute: true,
                  zone: NgrxJsonApiDefinitions.zoneArtBoards,
                  showSizeControls: false,
                  title: 'MY_INSPIRATION.TITLE'
                },
                children: [
                  {
                    path: '',
                    component: DoubleColumnsComponent,
                    data: {
                      className: 'innovation-template',
                      mainGroupClass: 'px-0',
                      staticRoute: true,
                      zone: NgrxJsonApiDefinitions.zoneArtBoards,
                      contentType: NgrxJsonApiDefinitions.ctInnovation,
                      bundle: NgrxJsonApiDefinitions.innovation.type,
                      mainContentGroupClass: 'detail',
                      templates: {
                        top: [
                          Templates.contentHeader
                        ],
                        left: [
                          Templates.innovationDescription,
                          Templates.innovationWhy,
                          Templates.innovationInfos,
                          Templates.freeTags
                        ],
                        right: [
                          'imagesSlider'
                        ],
                        footer: [Templates.appFooter]
                      }
                    }
                  },
                  {
                    path: '',
                    component: InspirationContentListComponent,
                    outlet: 'sideLeftList'
                  }
                ]
              },
              {
                path: 'node--news/:id',
                data: {
                  staticRoute: true,
                  zone: NgrxJsonApiDefinitions.zoneArtBoards,
                  showSizeControls: false,
                  title: 'MY_INSPIRATION.TITLE'
                },
                children: [
                  {
                    path: '',
                    component: DoubleColumnsComponent,
                    data: {
                      className: 'news-template',
                      mainGroupClass: 'px-0',
                      staticRoute: true,
                      zone: NgrxJsonApiDefinitions.zoneArtBoards,
                      contentType: NgrxJsonApiDefinitions.ctNews,
                      bundle: NgrxJsonApiDefinitions.news.type,
                      mainContentGroupClass: 'detail',
                      templates: {
                        top: [Templates.contentHeader],
                        left: [Templates.imagesSlider],
                        right: [
                          Templates.newsDescription,
                          Templates.newsCategoryLaunchDate,
                          Templates.brandTags,
                          Templates.awardTags,
                          Templates.freeTags,
                        ],
                        footer: [Templates.appFooter]
                      }
                    }
                  },
                  {
                    path: '',
                    component: InspirationContentListComponent,
                    outlet: 'sideLeftList'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'my-share',
        data: {
          staticRoute: true,
          mainGroupClass: 'px-2 px-sm-4',
          mainContentGroupClass: 'detail',
          title: 'MY_SHARE.TITLE',
          headerIcon: AppIcons.share
        },
        children: [
          {
            path: '',
            component: UserLayoutComponent,
            data: {
              templates: [Templates.myShare]
            }
          },
          {
            path: '',
            component: UserLayoutComponent,
            outlet: 'topContainer',
            data: {
              templates: [
                Templates.mainHeader
              ]
            }
          }
        ]
      },
      {
        path: '**',
        component: NotFoundComponent,
        canActivate: [DrupalPathGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadStrategy, relativeLinkResolution: 'legacy' }),
    LayoutModule,
    MyContributionsModule,
    MyInspirationsModule,
    MyShareModule,
    PollModule,
    SearchModule,
    SharedModule,
    WelcomeModule
  ],
  exports: [RouterModule],
  providers: [
    AppPreloadStrategy,
    DrupalContentResolver,
    DrupalListResolver,
    DrupalPathGuard,
    DrupalResolver,
    PathInfosResolver
  ]
})
export class AppRoutingModule {
}
