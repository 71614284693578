<!--
If we have a node to load, we wait for the content, then create the templates
Else
We create the templates #staticTemplate
-->

<ng-template #testTemplate>

  <ng-container *ngIf="routeData$|async as routeData">

    <a routerLink=".">
      {{routeData.title|translate}}
    </a>

  </ng-container>

</ng-template>

<ng-container *ngIf="(resourceIdentifier$|async); else staticTemplate">

  <ng-container *ngIf="(node$|async) as node; else loadingTemplate">

    <ng-template
      [ngTemplateOutlet]="templatesCreator"
      [ngTemplateOutletContext]="{node: node}">
    </ng-template>

  </ng-container>

</ng-container>

<router-outlet></router-outlet>

<!-- TEMPLATES -->

<ng-template #templatesCreator let-node="node">

  <ng-template ngFor [ngForOf]="templatesIds$|async" let-templateId>

    <ng-template
      [ngTemplateOutlet]="(templateId|templates)|async"
      [ngTemplateOutletContext]="{
      node: node,
      routeData: routeData$|async,
      routeParams: routeParams$|async,
      routeUrl: routeUrl$|async
      }">
    </ng-template>

  </ng-template>

</ng-template>

<ng-template #staticTemplate>

  <ng-template
    [ngTemplateOutlet]="templatesCreator">
  </ng-template>

</ng-template>


<ng-template #loadingTemplate>

  <!--
  <h1>Loading</h1>
  -->

</ng-template>
