import {Injectable} from '@angular/core';
import {InnovationSchemaPipe} from './pipes/schema/innovation-schema.pipe';
import {NgrxJsonApiDefinitions} from './ngrx-json-api/ngrx-json-api-definitions';
import {NewsSchemaPipe} from './pipes/schema/news-schema.pipe';
import {CartSchemaPipe} from './pipes/schema/cart-schema.pipe';
import {FieldTypes, NgxDrupalSchemataService, WidgetConfig} from '@madeinlune/ngx-drupal-schemata';
import {AppConfigService} from '@config/app-config.service';
import {take} from 'rxjs/operators';
import {MaterialWidgetRegistry} from './material-widgets-registry';

@Injectable({
  providedIn: 'root'
})
export class MhInnoSchemaLoaderService extends NgxDrupalSchemataService {

  constructor(
    public appConfig: AppConfigService
  ) {
    super();

    this.appConfig.appConfig$.pipe(take(1)).subscribe(appConfigResult => {
      const config: WidgetConfig = {
        contentTypesPipesMap: {
          [NgrxJsonApiDefinitions.innovation.type]: new InnovationSchemaPipe(),
          [NgrxJsonApiDefinitions.news.type]: new NewsSchemaPipe(),
          [NgrxJsonApiDefinitions.cart.type]: new CartSchemaPipe()
        },
        apiUrl: appConfig.getConfig().schemataUrl,
        widgets: {
          [FieldTypes.string]: MaterialWidgetRegistry.string,
          [FieldTypes.textArea]: MaterialWidgetRegistry.textarea,
          [FieldTypes.richTextArea]: MaterialWidgetRegistry.richTextarea,
          [FieldTypes.integer]: MaterialWidgetRegistry.integer,
          [FieldTypes.hidden]: MaterialWidgetRegistry.hidden,
          [FieldTypes.select]: MaterialWidgetRegistry.select,
          [FieldTypes.entityReference]: MaterialWidgetRegistry.entityReference,
          [FieldTypes.file]: MaterialWidgetRegistry.file,
          [FieldTypes.date]: MaterialWidgetRegistry.date,
          [FieldTypes.paragraph]: MaterialWidgetRegistry.paragraph,
          [FieldTypes.boolean]: MaterialWidgetRegistry.boolean,
          [FieldTypes.taxonomyTermReference]: MaterialWidgetRegistry.taxonomyTermReference,
        }
      };

      this.widgetConfig = config;
    });


  }
}
