import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getCurrentUrl} from '../store/reducers';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  currentUrl$: Observable<string> = this.store.pipe(
    select(getCurrentUrl),
    shareReplay(1)
  );

  constructor(
    private store: Store<any>
  ) {
  }
}
