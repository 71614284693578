import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TemplatesService} from '../../../templates.service';
import {Templates} from '../../templates';
import {Store} from '@ngrx/store';
import {ChangeSyncData, ClearLocalStorage, OpenAlert, OpenWindow} from '../../../store/actions/app-core.actions';
import {PwaService} from '../../../pwa.service';

@Component({
  selector: 'app-window-templates',
  templateUrl: './window-templates.component.html',
  styleUrls: ['./window-templates.component.scss']
})
export class WindowTemplatesComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('alertWindowTemplate', /* TODO: check static flag */ {static: true})
  alertWindowTemplate: TemplateRef<any>;

  @ViewChild('windowTemplate', /* TODO: check static flag */ {static: true})
  windowTemplate: TemplateRef<any>;

  @ViewChild('alertWindowContentTemplate', /* TODO: check static flag */ {static: true})
  alertWindowContentTemplate: TemplateRef<any>;

  @ViewChild('alertWindowActionsTemplate', /* TODO: check static flag */ {static: true})
  alertWindowActionsTemplate: TemplateRef<any>;

  @ViewChild('pwaUpdateTemplate', /* TODO: check static flag */ {static: true})
  pwaUpdateTemplate: TemplateRef<any>;

  @ViewChild('pwaUpdateActionsTemplate', /* TODO: check static flag */ {static: true})
  pwaUpdateActionsTemplate: TemplateRef<any>;

  @ViewChild('userAccountTemplate', /* TODO: check static flag */ {static: true})
  userAccountTemplate: TemplateRef<any>;

  @ViewChild('settingsTemplate', /* TODO: check static flag */ {static: true})
  settingsTemplate: TemplateRef<any>;

  @ViewChild('clearLocalStorageTemplate', /* TODO: check static flag */ {static: true})
  clearLocalStorageTemplate: TemplateRef<any>;

  @ViewChild('clearLocalStorageActionsTemplate', /* TODO: check static flag */ {static: true})
  clearLocalStorageActionsTemplate: TemplateRef<any>;

  @ViewChild('syncDataTemplate', /* TODO: check static flag */ {static: true})
  syncDataTemplate: TemplateRef<any>;

  @ViewChild('syncDataActionsTemplate', /* TODO: check static flag */ {static: true})
  syncDataActionsTemplate: TemplateRef<any>;

  constructor(
    private matDialog: MatDialog,
    private templatesService: TemplatesService,
    private store: Store<any>,
    private pwaService: PwaService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    this.templatesService.addTemplate(Templates.alertWindow, this.alertWindowTemplate);
    this.templatesService.addTemplate(Templates.window, this.windowTemplate);

    this.templatesService.addTemplate(Templates.testWindowContent, this.alertWindowContentTemplate);
    this.templatesService.addTemplate(Templates.testWindowActions, this.alertWindowActionsTemplate);

    this.templatesService.addTemplate(Templates.pwaUpdate, this.pwaUpdateTemplate);
    this.templatesService.addTemplate(Templates.pwaUpdateActions, this.pwaUpdateActionsTemplate);

    this.templatesService.addTemplate(Templates.userAccount, this.userAccountTemplate);
    this.templatesService.addTemplate(Templates.settings, this.settingsTemplate);

    this.templatesService.addTemplate(Templates.clearLocalStorage, this.clearLocalStorageTemplate);
    this.templatesService.addTemplate(Templates.clearLocalStorageActions, this.clearLocalStorageActionsTemplate);

    this.templatesService.addTemplate(Templates.syncData, this.syncDataTemplate);
    this.templatesService.addTemplate(Templates.syncDataActions, this.syncDataActionsTemplate);

  }

  openAlertWindow() {
    this.store.dispatch(new OpenAlert('test'));
  }

  openWindow() {
    this.store.dispatch(new OpenWindow(
      'Window test', {
        contentTemplate: Templates.testWindowContent,
        actionsTemplate: Templates.testWindowActions
      }));
  }

  confirmClearLocalStorage() {
    this.store.dispatch(new ClearLocalStorage());
  }

  confirmSyncData(syncData: boolean) {
    this.store.dispatch(new ChangeSyncData(syncData));
  }

}
