import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgrxJsonApiService, Resource, ResourceIdentifier, StoreResource} from 'ngrx-json-api';
import {Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {TrurthifyPipe} from 'ngx-pipes';

@Component({
  selector: 'app-inspiration-renderer',
  templateUrl: './inspiration-renderer.component.html',
  styleUrls: ['./inspiration-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrurthifyPipe]
})
export class InspirationRendererComponent implements OnInit {

  inspiration$: ReplaySubject<Resource> = new ReplaySubject<Resource>();

  firstInnovations$: Observable<StoreResource[]>;

  inspirationImages$: Observable<StoreResource[]>;

  innovationReferences$: Observable<ResourceIdentifier[]>;

  innovationCount$: Observable<number>;

  @Input()
  set inspiration(value: Resource) {
    this.inspiration$.next(value);
  }

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    private trurthifyPipe: TrurthifyPipe
  ) {
  }

  ngOnInit() {

    this.innovationReferences$ = this.inspiration$.pipe(
      filter(inspiration => !!inspiration && inspiration.relationships && inspiration.relationships.field_innovation.data.length > 0),
      map(inspiration => {
          return inspiration.relationships.field_innovation.data;
        }
      )
    );

    this.innovationCount$ = this.innovationReferences$.pipe(
      map(innovationReferences => innovationReferences.length)
    );

    this.firstInnovations$ = this.innovationReferences$.pipe(
      filter(innovationReferences => !!innovationReferences),
      switchMap(innovationReferences => {
          return this.ngrxJsonApiService.denormaliseResource(
            this.ngrxJsonApiService
              .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
              .selectStoreResources(innovationReferences.slice(0, 4)),
            NgrxJsonApiDefinitions.zoneArtBoards
          ) as Observable<StoreResource[]>;
        }
      )
    );

    this.inspirationImages$ = this.firstInnovations$.pipe(
      filter(innovations => !!innovations),
      map(innovations => {
        return this.trurthifyPipe.transform(innovations.map(innovation => innovation?.relationships?.field_node_thumbnail?.reference));
      }),
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      })
    );

  }

}
