import {InnovationIndexItem} from '../../apollo/innovation-index-item';
import {SearchActionsUnion, SearchActionTypes} from '../actions/search.actions';

export interface SearchState {
  [language: string]: SearchI18nState;
}

const initialState: SearchState = {};

export interface SearchI18nState {
  [contentType: string]: SearchContentTypeState;
}

const initialSearchI18nState: SearchI18nState = {};

export interface SearchContentTypeState {
  items?: InnovationIndexItem[];
  itemsMap?: { [itemId: string]: InnovationIndexItem };
}

const initialContentTypeState: SearchContentTypeState = {
  items: [],
  itemsMap: {}
};

export function searchReducer(
  state: SearchState = initialState,
  action: SearchActionsUnion
): SearchState {
  if (action.type === SearchActionTypes.AddToIndex) {
    return {
      ...state,
      [action.language]: searchI18nReducer(state[action.language], action)
    };
  } else if (action.type === SearchActionTypes.ClearAll) {
    return initialState;
  } else {
    return {
      ...state
    };
  }
}

export function searchI18nReducer(state: SearchI18nState = initialSearchI18nState,
                                  action: SearchActionsUnion): SearchI18nState {
  switch (action.type) {
    case SearchActionTypes.AddToIndex :
      return {
        ...state,
        [action.contentType]: searchContentTypeReducer(state[action.contentType], action)
      };
    default:
      return state;
  }
}

export function searchContentTypeReducer(state: SearchContentTypeState = initialContentTypeState,
                                         action: SearchActionsUnion): SearchContentTypeState {
  switch (action.type) {
    case SearchActionTypes.AddToIndex :
      const actionItems: InnovationIndexItem[] = action.items;
      const itemsToAdd: InnovationIndexItem[] = [];
      const newState = {
        ...state
      };
      actionItems.forEach(actionItem => {
        if (newState && newState.itemsMap) {
          const existingItem: InnovationIndexItem = newState.itemsMap[actionItem.uuid];
          if (existingItem) {
            newState.itemsMap = {
              ...newState.itemsMap,
              [actionItem.uuid]: {...existingItem, ...actionItem}
            };
            const arrayExistingItemIndex: number = newState.items.findIndex(arrayItem => arrayItem.uuid === actionItem.uuid);

            if (arrayExistingItemIndex > -1) {
              newState.items = newState.items.map((existingItemInArray, index) => {
                if (index !== arrayExistingItemIndex) {
                  // This isn't the item we care about - keep it as-is
                  return existingItemInArray;
                }
                // Otherwise, this is the one we want - return an updated value
                return {
                  ...existingItemInArray,
                  ...actionItem
                };
              });
            }
          } else {
            itemsToAdd.push(actionItem);
            newState.itemsMap = {
              ...newState.itemsMap,
              [actionItem.uuid]: actionItem
            };
          }
        }
      });
      const items: InnovationIndexItem[] = [...newState.items, ...itemsToAdd];
      return {
        ...newState,
        items: items
      };

    default:
      return state;
  }
}

export function removeDuplicates(array, key) {
  return array.reduce((accumulator, element) => {
    if (!accumulator.find(el => el[key] === element[key])) {
      accumulator.push(element);
    }
    return accumulator;
  }, []);
}

export function arrayToObject(array) {
  return array.reduce((obj, item) => {
    obj[item.uuid] = item;
    return obj;
  }, {});
}

