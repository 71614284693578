import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DoubleColumnsComponent} from './double-columns/double-columns.component';
import {SharedModule} from '../shared.module';

@NgModule({
    declarations: [DoubleColumnsComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        DoubleColumnsComponent
    ]
})
export class LayoutModule { }
