import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {PathInfos} from '../../models/path-infos.model';

import * as PathActions from '../actions/path.actions';

export {PathActions};


/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface DrupalPathState extends EntityState<any> {
  pathInfos?: PathInfos;
  loading?: boolean;
  loaded?: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (pathInfos: PathInfos) => pathInfos.metatags.canonical_url.attributes.href,
  sortComparer: false,
});

const initialState: DrupalPathState = adapter.getInitialState({
  pathInfos: null,
  loading: false,
  loaded: false
});

export function pathReducer(state: DrupalPathState = initialState,
                            action: PathActions.PathActionsUnion): DrupalPathState {
  switch (action.type) {

    case PathActions.PathActionTypes.SetPathInfos:
      return adapter.addOne(action.pathInfos, {
        ...state,
        pathInfos: action.pathInfos,
        loaded: true,
        loading: false
      });

    case PathActions.PathActionTypes.HydratePathInfosCollection:
      return adapter.addMany(action.pathInfosCollection, {
        ...state
      });

    default:
      return state;
  }
}

export const getDrupalPathState = createFeatureSelector<DrupalPathState>('path');

export const getPathInfos = createSelector(
  getDrupalPathState,
  (state: DrupalPathState) => state && state.pathInfos
);

