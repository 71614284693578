import {Injectable, TemplateRef} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TemplatesService} from '../templates.service';
import {Templates} from '../components/templates';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private _snackBar: MatSnackBar,
    private templatesService: TemplatesService
  ) {
  }

  showServerError(status: number, messages: string[]) {
    const snackbarTemplate: TemplateRef<any> = this.templatesService.getTemplate(Templates.serverErrorSnackBar);
    this._snackBar.openFromTemplate(snackbarTemplate, {
      panelClass: ['error'],
      data: {
        message: messages.join(''),
        action: 'OK',
        status: status
      }
    });
  }

}
