import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getCurrentLanguage} from './store/reducers';
import {Resource} from 'ngrx-json-api';
import {MyContributionsDeletionService} from './my-contributions/my-contributions/my-contributions-deletion.service';

@Injectable()
export class JsonApiInterceptor implements HttpInterceptor {

  languages$: Observable<string> = this.store.pipe(select(getCurrentLanguage));

  language: string;

  constructor(
    private store: Store<any>,
    public myContributionsDeletionService: MyContributionsDeletionService
  ) {
    this.languages$.subscribe(language => {
      this.language = language;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // we don't want get path calls to be translated
    // we don't want inspiration nodes to be translated

    let newReq;
    if (
      (
        req.url.indexOf('/jsonapi/node') > -1 ||
        req.url.indexOf('/jsonapi/index') > -1 ||
        req.url.indexOf('/jsonapi/taxonomy_term') > -1 ||
        req.url.indexOf('/jsonapi/operations') > -1 ||
        req.url.indexOf('/jsonrpc') > -1
      )
      && req.url.indexOf('=path') === -1
    ) {

      let resourceLanguage: string;
      let parsedRequestBody: any;
      let languageCleanedRequestBody: string;

      const requestURL = new URL(
        req.url
      );

      if (req?.body) {

        parsedRequestBody = typeof req.body === 'string' ? JSON.parse(req.body) : req.body;
        const saveRequestResource: Resource = parsedRequestBody?.data;
        resourceLanguage = saveRequestResource?.attributes?.forceLanguage;
        delete saveRequestResource?.attributes?.forceLanguage;
        languageCleanedRequestBody = JSON.stringify(parsedRequestBody);

      } else if (req.method === 'DELETE') {
        if (this.myContributionsDeletionService?.deletingResource) {
          resourceLanguage = this.myContributionsDeletionService?.deletingResource?.meta?.drupal?.defaultTranslation;
        }
      } else {

        if (requestURL.searchParams.get('filter[forceLanguage]')) {
          resourceLanguage = requestURL.searchParams.get('filter[forceLanguage]');
          requestURL.searchParams.delete('filter[forceLanguage]');
        }

      }

      const requestLanguage: string = resourceLanguage ? resourceLanguage : this.language;

      if (requestLanguage) {
        let url: string;
        if (req.url.indexOf('/jsonrpc') > -1) {
          url = requestURL.href.replace('/jsonrpc', '/' + requestLanguage + '/jsonrpc');
        } else {
          url = requestURL.href.replace('/jsonapi', '/' + requestLanguage + '/jsonapi');
        }
        if (languageCleanedRequestBody) {
          newReq = req.clone({
            body: languageCleanedRequestBody,
            url: url
          });
        } else {
          newReq = req.clone({
            url: url
          });
        }
      } else {
        newReq = req;
      }
      return next.handle(newReq);
    }
    if (req.url.indexOf('schemata/node') > -1 && req.url.indexOf('=path') === -1) {
      if (this.language) {
        newReq = req.clone({
          url: req.url.replace('/schemata', '/' + this.language + '/schemata')
        });
      } else {
        newReq = req;
      }
      return next.handle(newReq);
    } else {
      return next.handle(req);
    }
  }
}
