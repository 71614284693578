<ng-container *ngIf="(data|listItemFirstImage) as image">

  <img [src]="image.url|mediaUrl" [ngStyle]="{'height':(image|masonryImageHeight:260)+'px', 'width': '260px'}"/>

</ng-container>

<ng-container *ngIf="data">

  <div class="content-group p-2 flex-column">

    <h3 class="text-uppercase">{{data.title}}</h3>
    <small>{{(data.changed * 1000)|date}}</small>

    <div
      clampy="4"
      (isClamped)="onClampedChanged($event)"
      class="flex-fill overflow-hidden mb-1"
      [innerHTML]="data.innovation_description">
    </div>

    <ng-container *ngIf="data.free_tagging_name as freeTags">

      <ul class="list-style-type-none p-0 m-0">

        <ng-template ngFor [ngForOf]="freeTags" let-tag>

          <li>

            <ng-template
              [ngTemplateOutlet]="tagTemplate"
              [ngTemplateOutletContext]="{label: tag}">
            </ng-template>

          </li>

        </ng-template>

      </ul>

    </ng-container>

    <ng-content select=".edit-group"></ng-content>

  </div>

</ng-container>

<ng-template #tagTemplate let-label="label">

  <span [innerHTML]="label"></span>

</ng-template>
