<mat-tab-group class="main-tab-group" [backgroundColor]="'accent'" mat-align-tabs="start"
               (selectedTabChange)="onFormSelectedTabChange($event)">

  <ng-template ngFor let-fieldset [ngForOf]="formProperty.schema.fieldsets" let-i="index">

    <mat-tab>

      <ng-template matTabLabel>
        <span class="text-uppercase main-tab-label">{{fieldset.title|translate}}</span>
      </ng-template>

      <ng-template
        [ngTemplateOutlet]="tabTemplate"
        [ngTemplateOutletContext]="{fieldset: fieldset}">
      </ng-template>

    </mat-tab>

  </ng-template>

</mat-tab-group>

<ng-template #tabTemplate let-fieldset="fieldset">

  <ng-container *ngIf="fieldset.template">
    <ng-template
      [ngTemplateOutlet]="templatesService.getTemplate(fieldset.template)"
      [ngTemplateOutletContext]="{formProperty: formProperty}">
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!fieldset.template">
    <ng-template
      [ngTemplateOutlet]="defaultTemplate"
      [ngTemplateOutletContext]="{fieldset: fieldset}">
    </ng-template>
  </ng-container>

</ng-template>

<ng-template #defaultTemplate let-fieldset="fieldset">

  <div *ngIf="fieldset.description">{{fieldset.description}}</div>

  <ng-template ngFor [ngForOf]="fieldset.fields" let-fieldId>

    <div [ngClass]="fieldId" class="form-field-group">

      <sf-form-element [formProperty]="formProperty.getProperty(fieldId)"></sf-form-element>

    </div>

  </ng-template>

</ng-template>

<ng-template #newsFormTemplate let-formProperty="formProperty">

  <!--
  'title',
  'id',
  'type',
  'field_free_tagging',
  'field_news_workflow',
  'field_news_description',
  'field_contact_marketing',
  'field_contact_contributor',
  'field_contact_npd',
  'field_launch_date',
  'field_supplier',
  'field_awards',
  'field_brands',
  'field_new_category',
  'field_innovation'
  -->

  <mat-tab-group #languagesTabGroup mat-align-tabs="start" class="languages-tab-group">

    <ng-template ngFor [ngForOf]="languageTabs$|async" let-languageTab let-i="index"
                 [ngForTrackBy]="languageTabTrackBy">

      <mat-tab [label]="languageTab?.name">

        <div [attr.lang]="languageTab?.id">

          <ng-container *ngIf="i === 0">
            <ng-template
              [ngTemplateOutlet]="sourceNewsTemplate"
              [ngTemplateOutletContext]="{selected: languagesTabGroup.selectedIndex === i}">
            </ng-template>
          </ng-container>
          <ng-container *ngIf="i > 0">
            <ng-template
              [ngTemplateOutlet]="translationNewsTemplate"
              [ngTemplateOutletContext]="{selected: languagesTabGroup.selectedIndex === i}">
            </ng-template>
          </ng-container>

        </div>

      </mat-tab>

    </ng-template>

  </mat-tab-group>

</ng-template>

<ng-template #newsFormMediaTemplate let-formProperty="formProperty">

  <!--
  'field_media_videos',
  'field_media_images'
  -->

  <ng-template [ngTemplateOutlet]="mediaNoticeTemplate">
  </ng-template>

  <ng-container *ngIf="formProperty">

    <div class="row">

      <div class="col-12 col-sm-6">

        <ng-template
          [ngTemplateOutlet]="formElementTemplateRepeat"
          [ngTemplateOutletContext]="{
                propertyNames: ['field_media_images'],
                className: 'd-flex flex-fill'
              }">
        </ng-template>

      </div>

      <div class="col-12 col-sm-6">

        <ng-template
          [ngTemplateOutlet]="formElementTemplateRepeat"
          [ngTemplateOutletContext]="{
                propertyNames: ['field_media_videos'],
                className: 'd-flex flex-fill'
              }">
        </ng-template>

      </div>

    </div>

  </ng-container>

</ng-template>

<ng-template #previewTemplate let-formProperty="formProperty">

  <div class="d-flex p-3 language-notice-group align-items-center mb-2">

    <mat-icon svgIcon="icon-warning" class="mr-2 flex-shrink-0"></mat-icon>
    <span [innerHTML]="'MY_CONTRIBUTIONS.PREVIEW_NOTICE'|translate">
      </span>

  </div>

  <app-double-columns
    [staticData]="{
    className: previewClassnames[formProperty.getProperty('type').value],
    resourceIdentifier:{
      type:formProperty.getProperty('type').value,
      id:formProperty.getProperty('id').value
    },
    zone:'edit-'+formProperty.getProperty('type').value+'-'+formProperty.getProperty('id').value,
    templates: previewTemplates[formProperty.getProperty('type').value]
    }">
  </app-double-columns>

</ng-template>


<ng-template #innovationFormTemplate let-formProperty="formProperty">

  <mat-tab-group #languagesTabGroup mat-align-tabs="start" class="languages-tab-group">

    <ng-template ngFor [ngForOf]="languageTabs$|async" let-languageTab let-i="index"
                 [ngForTrackBy]="languageTabTrackBy">

      <mat-tab [label]="languageTab?.name">

        <div [attr.lang]="languageTab?.id">

          <ng-container *ngIf="i === 0">
            <ng-template
              [ngTemplateOutlet]="sourceInnovationTemplate"
              [ngTemplateOutletContext]="{selected: languagesTabGroup.selectedIndex === i}">>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="i > 0">
            <ng-template
              [ngTemplateOutlet]="translationInnovationTemplate"
              [ngTemplateOutletContext]="{selected: languagesTabGroup.selectedIndex === i}">>
            </ng-template>
          </ng-container>

        </div>

      </mat-tab>

    </ng-template>

  </mat-tab-group>

</ng-template>

<ng-template #formElementTemplateRepeat let-propertyNames="propertyNames" let-className="className">

  <ng-template ngFor [ngForOf]="propertyNames" let-propertyName>

    <ng-template
      [ngTemplateOutlet]="formElementTemplate"
      [ngTemplateOutletContext]="{propertyName: propertyName, className: className}">
    </ng-template>

  </ng-template>

</ng-template>

<ng-template #formElementTemplate let-propertyName="propertyName" let-className="className">

  <ng-container *ngIf="formProperty.getProperty(propertyName) as formProp">

    <sf-form-element [ngClass]="className + ' ' + propertyName"
                     [formProperty]="formProp"></sf-form-element>

  </ng-container>

</ng-template>

<ng-template #languageNoticeTemplate>

  <div class="d-flex flex-column flex-sm-row p-3 language-notice-group align-items-center mb-2">

    <mat-icon svgIcon="icon-warning" class="mr-2 flex-shrink-0"></mat-icon>
    <span [innerHTML]="'MY_CONTRIBUTIONS.LANGUAGE_NOTICE'|translate">
    </span>

    <button mat-flat-button color="accent"
            (click)="generateTranslation()"
            class="flex-shrink-0"
            [disabled]="!nodeFormService.canSave">
      <span class="d-flex align-items-center">
        <ng-container *ngIf="translationProcessing">
          <mat-spinner [diameter]="16" class="mr-2"></mat-spinner>
        </ng-container>
        <span [innerHTML]="'MY_CONTRIBUTIONS.GENERATE_TRANSLATION'|translate"></span>
      </span>
    </button>

  </div>

</ng-template>

<ng-template #mediaNoticeTemplate>

  <div class="d-flex p-3 language-notice-group align-items-center mb-2">

    <mat-icon svgIcon="icon-warning" class="mr-2 flex-shrink-0"></mat-icon>
    <span [innerHTML]="'MY_CONTRIBUTIONS.MEDIA_NOTICE'|translate">
      </span>

  </div>

</ng-template>

<ng-template #sourceInnovationTemplate>

  <!--
  'title',
  'field_free_tagging',
  'field_innovation_workflow',
  'field_innovation_description',
  'field_contact_email',
  'field_intellectual_property',
  'field_supplier',
  'field_innovation_category',
  'field_innovation_status',
  'field_innovation_why'
  -->

  <ng-template [ngTemplateOutlet]="languageNoticeTemplate">
  </ng-template>

  <ng-container *ngIf="formProperty">

    <div class="row">

      <div class="col-12 mb-2">

        <ng-template
          [ngTemplateOutlet]="formElementTemplateRepeat"
          [ngTemplateOutletContext]="{
                propertyNames: ['title', 'field_innovation_description', 'field_innovation_why'],
                className: 'd-flex flex-fill'
              }">
        </ng-template>

      </div>

      <div class="col-12 col-sm-6">

        <ng-template
          [ngTemplateOutlet]="formElementTemplateRepeat"
          [ngTemplateOutletContext]="{
                propertyNames: ['field_innovation_category', 'field_innovation_status', 'field_intellectual_property'],
                className: 'd-flex flex-fill flex-grow-0'
              }">
        </ng-template>

      </div>

      <div class="col-12 col-sm-6">

        <ng-template
          [ngTemplateOutlet]="formElementTemplateRepeat"
          [ngTemplateOutletContext]="{
                propertyNames: ['field_supplier', 'field_free_tagging'],
                className: 'd-flex flex-fill'
              }">
        </ng-template>

      </div>

    </div>

  </ng-container>

</ng-template>

<ng-template #sourceNewsTemplate let-selected="selected">

  <ng-container *ngIf="selected">

    <ng-template [ngTemplateOutlet]="languageNoticeTemplate">
    </ng-template>

    <ng-container *ngIf="formProperty">

      <div class="row mr-0">

        <div class="col-12 col-sm-6">

          <ng-template
            [ngTemplateOutlet]="formElementTemplateRepeat"
            [ngTemplateOutletContext]="{
                propertyNames: [
                'title',
                'field_news_description'
                ],
                className: 'd-flex flex-fill overflow-hidden'
              }">
          </ng-template>

        </div>

        <div class="col-12 col-sm-6">

          <div class="row">

            <div class="col-6">

              <ng-template
                [ngTemplateOutlet]="formElementTemplateRepeat"
                [ngTemplateOutletContext]="{
                propertyNames: [
                'field_new_category'
                ],
                className: 'd-flex flex-fill flex-grow-0'
              }">
              </ng-template>

            </div>

            <div class="col-6">

              <ng-template
                [ngTemplateOutlet]="formElementTemplateRepeat"
                [ngTemplateOutletContext]="{
                propertyNames: [
                'field_launch_date'
                ],
                className: 'd-flex flex-fill flex-grow-0'
              }">
              </ng-template>

            </div>

            <div class="col-12">

              <ng-template
                [ngTemplateOutlet]="formElementTemplateRepeat"
                [ngTemplateOutletContext]="{
                propertyNames: [
                'field_brands',
                'field_awards',
                'field_free_tagging',
                'field_inspired_by_win'
                ],
                className: 'd-flex flex-fill flex-grow-0'
              }">
              </ng-template>

            </div>

          </div>

        </div>

      </div>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template #translationNewsTemplate let-selected="selected">

  <ng-container *ngIf="selected">
    <ng-container *ngIf="formProperty">

      <div class="row">

        <div class="col-6">

          <sf-form [(ngModel)]="translationFormModel" templateSchema>
            <sf-field name="title" widget="string" [required]="true">Title</sf-field>
            <sf-field name="body" widget="richTextArea" [required]="true">Description</sf-field>
          </sf-form>

          <button mat-flat-button color="accent" (click)="saveTranslation('news')">
            <span>Save Translation</span>
          </button>

        </div>

      </div>

    </ng-container>
  </ng-container>

</ng-template>

<ng-template #translationInnovationTemplate let-selected="selected">

  <ng-container *ngIf="selected">

    <ng-container *ngIf="formProperty">

      <div class="row">

        <div class="col-6">

          <sf-form [(ngModel)]="translationFormModel" templateSchema>
            <sf-field name="title" widget="string" [required]="true">Title</sf-field>
            <sf-field name="body" widget="richTextArea" [required]="true">Description</sf-field>
            <sf-field name="innovative_why" widget="richTextArea" [required]="true">Why is it innovative ?</sf-field>
          </sf-form>

          <button mat-flat-button color="accent" (click)="saveTranslation('innovation')">
            <span>Save Translation</span>
          </button>

        </div>

      </div>

    </ng-container>

  </ng-container>

</ng-template>
