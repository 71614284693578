<ng-container *ngIf="titleTemplate || title">

  <mat-toolbar class="title-bar-group d-flex justify-content-between align-items-center flex-none px-3">

    <ng-template *ngIf="titleTemplate; else simpleTitleTemplate" [ngTemplateOutlet]="titleTemplate">
    </ng-template>

    <ng-container *ngIf="!injectedData?.contentData?.hideCloseButton">

      <button [matDialogClose] class="d-flex p-0 close-button">
        <mat-icon class="d-flex" aria-label="Fermer la fenêtre">close</mat-icon>
      </button>

    </ng-container>

  </mat-toolbar>

</ng-container>

<mat-dialog-content class="d-flex flex-fill px-0">
  <div class="d-flex flex-column overflow-hidden flex-fill position-relative" [class.px-2]="data && data.isAlert">

    <ng-container *ngIf="contentTemplate">
      <ng-template
        [ngTemplateOutlet]="contentTemplate"
        [ngTemplateOutletContext]="{data: data?.contentData || injectedData?.contentData}">
      </ng-template>
    </ng-container>

  </div>
</mat-dialog-content>

<ng-container *ngIf="actionsTemplate">

  <mat-dialog-actions class="d-flex flex-shrink-0 justify-content-center">
    <ng-template
      [ngTemplateOutlet]="actionsTemplate"
      [ngTemplateOutletContext]="{data: data?.contentData || injectedData?.contentData}">
    </ng-template>
  </mat-dialog-actions>

</ng-container>


<ng-template #simpleTitleTemplate>
  <h2 class="text-uppercase">{{title|translate}}</h2>
</ng-template>
