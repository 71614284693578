import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from '@config/app-config.service';

@Pipe({
  name: 'pdfUrl'
})
@Injectable()
export class PdfUrlPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
    public appConfig: AppConfigService
  ) {

  }


  transform(value: Resource): string {

    const nid: string = value.attributes.drupal_internal__nid;
    const language: string = this.translateService.currentLang;
    return this.appConfig.getConfig().backendUrl + '/' + language + '/print/pdf/node/' + nid;

  }
}
