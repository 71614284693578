import {Injectable} from '@angular/core';
import {AppConfigService} from '@config/app-config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DrupalMenuItem} from '@ngx-mil-drupal/models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private appConfig: AppConfigService,
    private httpClient: HttpClient
  ) {
  }

  resolve(menuId: string, language: string): Observable<DrupalMenuItem[]> {
    const apiURL = [
      (this.appConfig.getConfig().backendUrl ? this.appConfig.getConfig().backendUrl : ''),
      language,
      'api/menu_items',
      menuId
    ].join('/');
    return this.httpClient.get<DrupalMenuItem[]>(apiURL);
  }

}
