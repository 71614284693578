/**
 * Created by benoitplatre on 25/06/2018.
 */
import {RouterStateSerializer} from '@ngrx/router-store';
import {ActivatedRouteSnapshot, Params, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  flatParams: Params;
  data: any;
}

@Injectable()
export class MilRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route: ActivatedRouteSnapshot = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const routeParams: any = {flatParams: {}};
    for (let i = 0; i < routerState.root.children.length; i++) {
      const childRoute: ActivatedRouteSnapshot = routerState.root.children[i];
      let childParams: any;
      if (childRoute.params) {
        childParams = childRoute.params;
        if (childRoute.firstChild && childRoute.firstChild.params) {
          childParams = childRoute.firstChild.params;
        }

        for (const key in childRoute.params) {
          routeParams.flatParams[key] = childRoute.params[key];
        }

        if (childRoute.firstChild && childRoute.firstChild.params) {
          for (const key in childRoute.firstChild.params) {
            routeParams.flatParams[key] = childRoute.firstChild.params[key];
          }
        }

        // routeParams.params[]
      }
    }

    const {url, root: {queryParams}} = routerState;
    const {params} = route;
    const {flatParams} = routeParams;
    const {data} = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {url, params, queryParams, flatParams, data};

  }

}
