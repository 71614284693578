<ng-container *ngIf="inspirations$|async as inspirations; else loadingTemplate">

  <ng-container *ngIf="inspirations.length > 0; else noContentTemplate">

    <ul class="list-style-type-none d-flex flex-wrap p-0 m-0 align-items-start min-width-100 align-self-start">

      <ng-template ngFor [ngForOf]="inspirations" let-inspiration>

        <ng-container *ngIf="inspiration">
          <li class="inspiration-renderer-group square-ratio">
            <a [routerLink]="inspiration.id" class="content d-flex p-2">
              <app-inspiration-renderer [inspiration]="inspiration"></app-inspiration-renderer>
            </a>
          </li>
        </ng-container>

      </ng-template>

    </ul>

  </ng-container>

</ng-container>

<ng-template #loadingTemplate>
  <mat-spinner [diameter]="30" color="primary"></mat-spinner>
</ng-template>

<ng-template #noContentTemplate>

  <div class="position-absolute w-100 h-100 top-0 left-0 d-flex align-items-center justify-content-center">

    <p class="text-center align-self-center" [innerHTML]="'MY_INSPIRATION.NO_INSPIRATIONS'|translate"></p>

  </div>

</ng-template>
