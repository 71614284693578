import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import * as fromRoot from '../../../store/reducers';
import {getRouteContentTypes} from '../../../store/reducers';
import {select, Store} from '@ngrx/store';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  skip,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {TemplatesService} from '../../../templates.service';
import {NgrxJsonApiService, Resource} from 'ngrx-json-api';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SearchService} from '../../../search/search.service';
import {SearchResultService} from '../../../search/search-result.service';
import {Apollo} from 'apollo-angular';
import {SEARCH_SERVICE} from '../../../app.module';
import {INNOVATION_KEYWORDS, NEWS_KEYWORDS, SEARCH_PROVIDERS} from '../../../providers/search.providers';
import {Keyword} from '../../../store/effects/app-core.effects';
import {FilterByPipe, LatinisePipe} from 'ngx-pipes';

@UntilDestroy()
@Component({
  selector: 'app-search-free-text',
  templateUrl: './search-free-text.component.html',
  styleUrls: ['./search-free-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SEARCH_PROVIDERS,
    LatinisePipe,
    FilterByPipe
  ]
})
export class SearchFreeTextComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'd-flex';

  routeContentTypes$: Observable<string[]> = this.store.pipe(select(getRouteContentTypes));

  freeText$: Observable<string>;

  freeTextFilled$: Observable<boolean>;

  selectedKeyword$: ReplaySubject<string> = new ReplaySubject<string>();

  searchInputFocus: boolean;

  @Input()
  set contentTypes(contentTypes: string[]) {
  }

  @Input()
  placeholderBase: string;

  @Input()
  set selectedKeyword(keyword: string) {
    this.selectedKeyword$.next(keyword);
    this.resetSearch();
  }

  @Input()
  possibleResultTotal = 0;

  @Output()
  searchQueryChange: EventEmitter<string> = new EventEmitter<string>();

  queryParams$: Observable<ParamMap>;

  routeParamsKeywords$: Observable<string[]>;

  showAutoComplete$: Observable<boolean>;

  searchControl: UntypedFormControl = new UntypedFormControl('');

  searchServices$: Observable<SearchService[]>;

  resultCount$: Observable<number>;

  loading$: Observable<boolean>;

  keywords$: ReplaySubject<{ news: Keyword[], innovation: Keyword[] }> = new ReplaySubject<{ news: Keyword[], innovation: Keyword[] }>(1);

  results$: Observable<any[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<fromRoot.State>,
    private templateService: TemplatesService,
    private apollo: Apollo,
    private ngrxJsonApiService: NgrxJsonApiService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public searchResultService: SearchResultService,
    private latinisePipe: LatinisePipe,
    private filterByPipe: FilterByPipe,
    @Inject(SEARCH_SERVICE) private searchServiceFactory,
    @Inject(INNOVATION_KEYWORDS) public innovationKeywords$: Observable<Keyword[]>,
    @Inject(NEWS_KEYWORDS) public newsKeywords$: Observable<Keyword[]>
  ) {
  }

  ngOnInit() {

    this.queryParams$ = this.activatedRoute.queryParamMap.pipe(
      map(paramsMap => {
        return paramsMap;
      }),
      shareReplay(1)
    );

    this.routeParamsKeywords$ = this.queryParams$.pipe(
      map(params => [params.get('free_tagging_name')].filter(tagName => !!tagName)),
      shareReplay(1)
    );

    this.searchServices$ = this.routeContentTypes$.pipe(
      filter(contentTypes => !!contentTypes),
      map(contentTypes => {
        return contentTypes.map(contentType => {
          const searchService: SearchService = this.searchServiceFactory(this.store, this.activatedRoute, this.apollo);
          searchService.contentTypes = [contentType];
          return searchService;
        });
      }),
      shareReplay(1)
    );

    this.routeContentTypes$.pipe(
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      }),
      skip(1)
    ).subscribe(() => {
      this.destroySearchServices();
    });

    // this.searchServices$.subscribe(searchServices => console.log('searchServices', searchServices));

    this.initializeObservable();

    // comme les search services ont tous les mêmes variables et freeText, le plus simple est d'écouter le premier qu'on trouve;
    const firstSearchService$: Observable<SearchService> = this.searchServices$
      .pipe(
        filter(searchServices => searchServices?.length > 0),
        take(1),
        map(searchServices => searchServices[0])
      );

    firstSearchService$.pipe(
      untilDestroyed(this),
      switchMap(firstSearchService => firstSearchService.variables$),
      withLatestFrom(this.searchServices$)
    ).subscribe(([variables, searchServices]) => {
      if (variables.freeText) {
        searchServices.forEach(searchService => searchService.clearResult());
        this.searchInputFocus = false;
        this.searchControl.setValue(variables.freeText.join(' '), {});
        this.cdr.detectChanges();
      }
    });

    this.routeParamsKeywords$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(
          firstSearchService$
            .pipe(
              switchMap(firstSearchService => firstSearchService.variables$)
            )
        )
      )
      .subscribe(([routeParamsKeywords, variables]) => {
        if (routeParamsKeywords.length > 0) {
          this.searchInputFocus = false;
          if (!variables.freeText) {
            this.resetSearch(true);
          }
        }
      });

    this.cdr.detectChanges();


  }

  ngOnDestroy() {
    this.destroySearchServices();
  }

  destroySearchServices() {
    if (this.searchServices$) {
      this.searchServices$
        .pipe(take(1))
        .subscribe(searchServices => {
          searchServices.forEach(searchService => searchService.destroy());
        });
    }
  }

  resetSearch(fromNavigation: boolean = false) {
    this.searchServices$
      .pipe(take(1))
      .subscribe(searchServices => {
        searchServices.forEach(searchService => searchService.reset());
      });
    this.searchControl.setValue('');
    if (!fromNavigation) {
      this.router.navigate([], {queryParams: {freeText: null}, queryParamsHandling: 'merge'});
    }
  }

  setFreeText(value: string) {
    this.router.navigate([], {queryParams: {freeText: value}, queryParamsHandling: 'merge'});
    this.searchInputFocus = false;
  }

  initializeObservable() {

    this.freeText$ = this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      skip(1),
      debounceTime(400),
      shareReplay(1)
    );

    this.freeText$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.searchServices$, this.innovationKeywords$, this.newsKeywords$)
      )
      .subscribe(([freeText, searchServices, innovationKeywords, newsKeywords]) => {
        const latinisedFreeText: string = this.latinisePipe.transform(freeText);
        const innovationKeywordsFound: Keyword[] = this.filterByPipe.transform(innovationKeywords, ['latinised'], latinisedFreeText, false);
        const newsKeywordsFound: Keyword[] = this.filterByPipe.transform(newsKeywords, ['latinised'], latinisedFreeText, false);
        this.keywords$.next({news: newsKeywordsFound, innovation: innovationKeywordsFound});
        searchServices.forEach(searchService => searchService.freeText = freeText);
      });

    this.freeTextFilled$ = this.freeText$.pipe(
      map(freeText => freeText && freeText.length > 0)
    );

    this.results$ = this.searchServices$.pipe(
      switchMap(searchServices => combineLatest(searchServices.map(searchService => searchService.results$))),
      map(results => [].concat.apply([], results).filter(item => !!item)),
      shareReplay(1)
    );

    this.showAutoComplete$ = combineLatest([this.results$]).pipe(
      map(([results]) => {
        return (results.length > 0);
      }),
      shareReplay(1)
    );

    this.resultCount$ = this.searchServices$.pipe(
      switchMap(searchServices => combineLatest(searchServices.map(searchService => searchService.resultCount$))),
      map(results => {
        let count = 0;
        results.forEach(result => {
          count = count + result;
        });
        return count;
      }),
      tap(count => {
        this.searchResultService.resultCount = count;
      }),
      shareReplay(1)
    );

    this.loading$ = this.searchServices$.pipe(
      switchMap(searchServices => combineLatest(searchServices.map(searchService => searchService.loading$))),
      map(results => {
        const loadingServices: boolean[] = [];
        results.forEach(loading => {
          if (loading === true) {
            loadingServices.push(true);
          }
        });
        return loadingServices.length > 0;
      }),
      shareReplay(1)
    );

  }

  onSelectKeyword(keyword: Resource) {
    this.resetSearch();
  }

  blurSearchInput() {
    setTimeout(() => {
      this.searchInputFocus = false;
    });
  }


}
