import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Apollo, QueryRef} from 'apollo-angular';
import {ApolloQueryResult, WatchQueryOptions} from 'apollo-client';
import {ApolloSearchApi, ApolloSearchApiResult} from './apollo-search-api';
import {filter, map, shareReplay, take, tap, withLatestFrom} from 'rxjs/operators';
import {ApolloQueries} from './apollo-queries';

@Injectable({
  providedIn: 'root'
})
export class ApolloSearchIndexService {

  get queryRef(): QueryRef<any> {
    return this._queryRef;
  }

  lockLoading$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  get variables(): any {
    return this._variables;
  }

  private _variables: any;
  set variables(value: any) {

    if (this._variables) {
      this._variables = {...this._variables, start: 0, end: this.pageSize};
      /*if (value && JSON.stringify(this._variables) === JSON.stringify(value)) {
        return;
      }*/
    }

    this.data = [];

    this._variables = value;
    if (!this._queryRef) {
      this.init();
      this.start();
    } else {
      this._queryRef.resetLastResults();
    }
    this._queryRef.setVariables(this._variables);
  }

  data: any[];

  data$: Observable<any[]>;

  facets$: Observable<any[]>;

  resultCount$: Observable<number>;

  loading$: Observable<boolean>;

  end$: Observable<boolean>;

  apolloSearchApiErrors$: Observable<ReadonlyArray<any>>;

  public pageStart: number;
  public pageSize: number;

  private _queryRef: QueryRef<any>;

  constructor(
    private apollo: Apollo
  ) {
    this.lockLoading$.next(false);
  }

  destroy() {
    this._queryRef.stopPolling();
  }

  init() {
    const searchQueryOptions: WatchQueryOptions = {
      query: ApolloQueries.searchInnovation,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: this._variables
    };
    this._queryRef = this.apollo.watchQuery<ApolloSearchApi>(searchQueryOptions);
  }

  start() {
    const apolloQueryResult$: Observable<ApolloQueryResult<any>> = this._queryRef.valueChanges
      .pipe(
        filter((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => !!apolloQueryResult),
      );

    this.loading$ = apolloQueryResult$.pipe(
      map((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => apolloQueryResult.loading),
    );

    const apolloSearchApi$: Observable<ApolloSearchApiResult> = apolloQueryResult$.pipe(
      filter((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => !!apolloQueryResult && !!apolloQueryResult.data),
      map((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => {
        return apolloQueryResult.data.searchAPISearch;
      }),
      filter((apolloQueryResult: ApolloSearchApiResult) => !!apolloQueryResult)
    );

    this.resultCount$ = apolloSearchApi$.pipe(
      map((apolloQueryResult: ApolloSearchApiResult) => {
        return apolloQueryResult.result_count;
      })
    );

    this.data$ = apolloSearchApi$.pipe(
      map((apolloQueryResult: ApolloSearchApiResult) => {
        this.data = [...this.data, ...apolloQueryResult.documents];
        this.lockLoading$.next(false);
        return this.data;
      })
    );

    this.facets$ = apolloSearchApi$.pipe(
      map((apolloQueryResult: ApolloSearchApiResult) => {
        return (apolloQueryResult as any).facets ? (apolloQueryResult as any).facets : [];
      })
    );

    this.apolloSearchApiErrors$ = apolloQueryResult$.pipe(
      filter((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => !!apolloQueryResult && !!apolloQueryResult.errors && apolloQueryResult.errors.length > 0),
      map((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => {
        return apolloQueryResult.errors;
      }),
      tap(() => {
        this.lockLoading$.next(false);
      })
    );

    this.end$ = this.resultCount$.pipe(
      map(count => {
        return !(this.pageStart < (count - this.pageSize));
      }),
      shareReplay(1)
    );


  }

  nextPage() {
    this.resultCount$.pipe(
      withLatestFrom(this.lockLoading$),
      filter(([count, lock]) => {
        return lock === false;
      }),
      take(1)
    ).subscribe(([count, lock]) => {

      if (this.pageStart < (count - this.pageSize)) {

        this.pageStart = this.pageStart + this.pageSize;
        this._queryRef.setVariables({
          ...this.variables,
          start: this.pageStart,
          end: this.pageSize
        });
        this.lockLoading$.next(true);

      }


    });
  }

}
