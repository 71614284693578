<ng-container *ngIf="data$|async as data">
  <ul class="list-style-type-none p-0 m-0">
    <ng-template ngFor [ngForOf]="data" let-node>

      <ng-container *ngIf="node">

        <li>
          <ng-template
            [ngTemplateOutlet]="innoListItemTemplate"
            [ngTemplateOutletContext]="{node: node}">
          </ng-template>
        </li>

      </ng-container>

    </ng-template>
  </ul>
</ng-container>

<ng-template #innoListItemTemplate let-node="node">

  <a [routerLink]="['../../../',node.type, node.id]" routerLinkActive #rla="routerLinkActive">

    <ng-template
      [ngTemplateOutlet]="templatesService.getTemplate('listSmallRenderer')"
      [ngTemplateOutletContext]="{
      data: (node|resourceToInnovationIndexItem:zone)|async,
      selected: rla.isActive}">
    </ng-template>

  </a>

</ng-template>
