<div class="row no-gutters top-group pb-4 px-sm-4 flex-shrink-0">

  <ng-container *ngIf="!(responsiveService.isXs$|async)">

    <div class="logo-group d-flex flex-column col-3 col-xl-4 ml-sm-n3 text-center px-2 justify-content-center align-items-center flex-shrink-0">

      <ng-container *ngrxLet="userFirstName$; let userFirstName">

        <div
          [innerHTML]="'APP.WELCOME'|translate:{userFirstName: userFirstName ?? ''}"
          class="message mb-3">
        </div>

      </ng-container>

      <img
        src="assets/logos/wip_logo-full.svg"
        class="align-self-center logo-full"/>

    </div>

  </ng-container>

  <div class="col-12 col-sm-9 col-xl-8 d-flex position-relative justify-content-center">

    <div class="news-group position-relative w-100">

      <div class="d-flex justify-content-between section-header px-2 px-sm-0">

        <h2 [innerHTML]="'APP.LATEST_NEWS'|translate" class="text-uppercase"></h2>

        <a
          mat-flat-button
          [routerLink]="'/news'"
          [innerHTML]="'APP.ALL_NEWS'|translate"
          color="accent">
        </a>

      </div>

      <ng-container *ngIf="(cachedPromotedNews$|async) | orderBy: '-attributes.published_at' as promotedNews">

        <app-swiper
          #swiper
          class="swiper-group d-flex position-relative mat-elevation-z2"
          [options]="{autoplay:{delay: 7000}}"
          [slideTemplate]="newsSlideTemplate"
          [data]="promotedNews"></app-swiper>

        <app-swiper-bullets [swiper]="swiper" class="d-flex my-3" [data]="promotedNews">
        </app-swiper-bullets>

      </ng-container>

    </div>

  </div>

</div>

<div class="row no-gutters px-sm-4 pt-4 innovation-list-group flex-column flex-fill">

  <div class="col-12 innovation-list-content-group d-flex flex-column flex-fill">

    <ng-template
      [ngTemplateOutlet]="('mainHeader'|templates)|async"
      [ngTemplateOutletContext]="{
      title: 'Innovations',
      linkTemplate:linkButtonTemplate,
      topLayerTemplate: (responsiveService.isXs$|async)?null:innoTypeNavTemplate,
      showSizeControls: true
      }">
    </ng-template>

    <ng-container *ngIf="(responsiveService.isXs$|async)">
      <ng-template
        [ngTemplateOutlet]="innoTypeNavTemplate">
      </ng-template>
    </ng-container>

    <ng-container *ngIf="activeLink.path === 'latest'">

      <app-apollo-list
        class="d-flex flex-fill flex-shrink-0"
        [data]="itemsByContentType$|async"
        [rendererTemplate]="listItemTemplate">
      </app-apollo-list>

    </ng-container>

    <ng-container *ngIf="activeLink.path === 'popular'">

      <app-apollo-list
        class="d-flex flex-fill flex-shrink-0"
        [data]="mostPopularInnovations$|async"
        [rendererTemplate]="listItemTemplate">
      </app-apollo-list>

    </ng-container>

    <ng-template [ngTemplateOutlet]="('appFooter'|templates)|async">
    </ng-template>

  </div>

</div>

<ng-template #newsSlideTemplate let-data="data">

  <app-news-slide-renderer
    [zone]="'welcome-'+(currentLanguage$|async)"
    [data]="data">
  </app-news-slide-renderer>

</ng-template>

<ng-template #listItemTemplate let-data="data">

  <ng-template
    [ngTemplateOutlet]="('listLink'|templates)|async"
    [ngTemplateOutletContext]="{data: data, template: ('listRenderer'|templates)|async, size:listThumbSize$|async}">
  </ng-template>

</ng-template>

<ng-template #innoTypeNavTemplate>
  <nav mat-tab-nav-bar color="accent" mat-align-tabs="center" #nav>

    <ng-template ngFor [ngForOf]="navLinks$|async" let-navLink let-i=index>

      <span mat-tab-link
         class="text-uppercase pointer-events-auto"
         (click)="onNavBarClick($event, nav, i, navLink)"
         [active]="activeLink == navLink">
        {{navLink.label|translate}}
      </span>

    </ng-template>

  </nav>
</ng-template>

<ng-template #linkButtonTemplate let-path="path" let-title="title">
  <a
    mat-flat-button
    [routerLink]="'/innovations'"
    [innerHTML]="'APP.ALL_INNOVATIONS'|translate" color="accent">
  </a>
</ng-template>

