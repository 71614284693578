import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {FieldSet, FormAutocompleteParams} from '@madeinlune/ngx-drupal-schemata';
import {MaterialWidgetRegistry} from '../../material-widgets-registry';
import {DrupalFile} from '@madeinlune/ngx-drupal-uploader';
import {DateFormats} from '@madeinlune/ngx-material-schema-form';
import {Templates} from '../../components/templates';

@Pipe({
  name: 'newsSchema'
})
@Injectable()
export class NewsSchemaPipe implements PipeTransform {

  constructor() {
  }

  transform(schema: any): any {
    const properties: any = schema.properties;

    const contentType: string = NgrxJsonApiDefinitions.news.type.split('--')[1];

    delete properties.field_media_transcoding_status;

    delete properties.innovation_voting_result;

    /*const test = {
      'operations': [{
        'op': 'add',
        'data': {'id': 'dad6106e-3b18-4d68-afe2-e851735fcb83', 'type': 'taxonomy_term--free_tagging', 'attributes': {'name': 'tissus'}},
        'ref': {'type': 'taxonomy_term--free_tagging'}
      }, {
        'op': 'add',
        'data': {
          'id': '76f22098-9dee-401d-8e77-b562ef0b5288',
          'type': 'node--news',
          'attributes': {
            'field_news_description': {'format': 'basic_html', 'value': '<p>Test Ben</p>'},
            'field_news_workflow': 'editorial_news_draft',
            'title': 'Test Ben 1',
            'field_inspired_by_win': false
          },
          'relationships': {
            'field_free_tagging': {
              'data': [{
                'type': 'taxonomy_term--free_tagging',
                'id': '0adb24cb-961a-4420-b7ff-803756bf6bc4'
              }, {
                'type': 'taxonomy_term--free_tagging',
                'id': 'bd59df0a-d30f-4d2c-8808-f23c35ef6edf'
              }, {
                'type': 'taxonomy_term--free_tagging',
                'id': 'dad6106e-3b18-4d68-afe2-e851735fcb83'
              }, {'type': 'taxonomy_term--free_tagging', 'id': 'c3847209-8489-4c32-bf37-b2848d4ec2f1'}]
            },
            'field_media_images': {'data': []},
            'field_media_videos': {'data': []},
            'field_brands': {'data': []},
            'field_new_category': {'data': {'type': 'taxonomy_term--news_category', 'id': '61ebd341-13ff-4622-b150-3edc8a46c350'}}
          }
        },
        'ref': {'type': 'node--news'}
      }]
    };*/

    if (properties.field_news_description) {
      const descriptionField: any = properties.field_news_description;
      descriptionField.properties.value.maxLength = 5000;
      descriptionField.properties.value.description = descriptionField.description;
      delete descriptionField.description;
    }

    if (properties.published_at) {
      delete properties.published_at;
    }

    if (properties.field_media_videos) {
      properties.field_media_videos.meta = {
        ...properties.field_media_videos.meta,
        fileType: 'video',
        needMedia: true,
        maxUpload: 5,
        uploadPath: '/media/video/field_media_video_file'
      };
    }

    if (properties.field_free_tagging) {
      const freeTaggingProperty = properties.field_free_tagging;
      /*freeTaggingProperty.searchQuery = JSON.parse(JSON.stringify(NgrxJsonApiQueries.taxonomyTermSearchQuery));
      freeTaggingProperty.searchQuery.type = NgrxJsonApiDefinitions.freeTagging.type;*/
      freeTaggingProperty.widget = MaterialWidgetRegistry.taxonomyTermAutoCompleteReference;
      freeTaggingProperty.meta = {
        ...freeTaggingProperty.meta,
        title: 'MY_CONTRIBUTIONS.FREE_TAG_CREATE.TITLE',
        subTitle: 'MY_CONTRIBUTIONS.FREE_TAG_CREATE.SUBTITLE',
        loading: 'MY_CONTRIBUTIONS.FREE_TAG_CREATE.LOADING',
        autoLoad: false
      };
      const formAutoCompleteParams: FormAutocompleteParams = {
        bundle: NgrxJsonApiDefinitions.ctNews,
        entity_type: 'node',
        field_name: 'field_free_tagging'
      };
      freeTaggingProperty.meta.formAutoCompleteParams = formAutoCompleteParams;
    }

    if (properties.field_human_translation_review) {
      delete properties.field_human_translation_review;
    }

    if (properties.field_news_workflow) {
      properties.field_news_workflow.widget = MaterialWidgetRegistry.select;
      properties.field_news_workflow.data = {
        source: 'schema_enum',
        translatePath: '',
        multipleKey: 'allOf'
      };
    }

    if (properties.field_supplier) {
      delete properties.field_supplier;
    }

    /*if (properties.field_news_description) {
      console.log('properties.field_news_description', properties.field_news_description);
      properties.field_news_description.properties.value.title = properties.field_news_description.title;
      properties.field_news_description.properties.value.widget = MaterialWidgetRegistry.textarea;
      properties.field_news_description.properties.format.widget = MaterialWidgetRegistry.hidden;
      delete properties.field_news_description.title;
    }*/

    if (properties.field_contact_marketing) {
      properties.field_contact_marketing.searchQuery = NgrxJsonApiQueries.userSearchQuery;
      properties.field_contact_marketing.templateInfos = {
        item: 'contactReference',
        list: 'contactReferenceList',
        relationMessage: 'Link a Contact'
      };
    }


    if (properties.metatag) {
      delete properties.metatag;
      if (schema.required.indexOf('metatag') > -1) {
        const index = schema.required.indexOf('metatag');
        schema.required.splice(index, 1);
      }
    }

    if (properties.field_contact_contributor) {
      properties.field_contact_contributor.widget = MaterialWidgetRegistry.hidden;
    }
    if (properties.field_contact_npd) {
      properties.field_contact_npd.widget = MaterialWidgetRegistry.hidden;
    }

    if (properties.field_media_images) {

      properties.field_media_images.meta = {
        ...properties.field_media_images.meta,
        fileType: DrupalFile.image,
        needMedia: true,
        maxUpload: 10,
        uploadPath: '/media/image/field_media_image'
      };

    }

    if (properties.field_awards) {
      properties.field_awards.widget = MaterialWidgetRegistry.taxonomyTermReference;
    }

    // MH Product 6fe41d44-a08d-4b1d-addd-0c8d69c80b7f
    // MH News : c2149b8b-34da-4a01-b7aa-2393ca24982a
    // Other News : 61ebd341-13ff-4622-b150-3edc8a46c350

    if (properties.field_brands) {
      properties.field_brands.widget = MaterialWidgetRegistry.taxonomyTermReference;
      properties.field_brands.visibleIf = {
        'oneOf': [
          {
            '/field_new_category/data/id': [
              '6fe41d44-a08d-4b1d-addd-0c8d69c80b7f',
              'c2149b8b-34da-4a01-b7aa-2393ca24982a'
            ]
          }
        ]
      };
    }

    if (properties.field_new_category) {
      properties.field_new_category.widget = MaterialWidgetRegistry.taxonomyTermReference;
      properties.field_new_category.required = ['data'];
    }

    if (properties.field_launch_date) {
      properties.field_launch_date.data = {format: DateFormats.dateAndTime};
      properties.field_launch_date.visibleIf = {
        'oneOf': [
          {
            '/field_new_category/data/id': [
              '6fe41d44-a08d-4b1d-addd-0c8d69c80b7f'
            ]
          }
        ]
      };
    }

    if (properties.field_inspired_by_win) {
      properties.field_inspired_by_win.visibleIf = {
        'oneOf': [
          {
            '/field_new_category/data/id': [
              '6fe41d44-a08d-4b1d-addd-0c8d69c80b7f',
              'c2149b8b-34da-4a01-b7aa-2393ca24982a'
            ]
          }
        ]
      };
    }

    delete properties.ds_switch;

    delete properties.field_node_thumbnail;

    delete properties.published_at;

    if (properties.field_news_description) {
      properties.field_news_description.properties.format.default = 'basic_html';
      delete properties.field_news_description.properties.value.description;
    }

    const fieldSets: FieldSet[] =
      [
        {
          id: 'main',
          title: '1. Infos',
          name: '',
          description: '',
          className: 'root-fieldset',
          fields: [
            'title',
            'id',
            'type',
            'field_free_tagging',
            'field_news_workflow',
            'field_news_description',
            'field_contact_marketing',
            'field_contact_contributor',
            'field_contact_npd',
            'field_launch_date',
            'field_awards',
            'field_brands',
            'field_new_category',
            'field_inspired_by_win',
            'field_media_videos'
          ],
          template: Templates.newsForm
        },
        {
          id: 'media',
          name: '',
          description: '',
          className: 'root-fieldset',
          title: '2. Media',
          fields: [
            'field_media_images'
          ],
          template: Templates.newsMediaForm
        },
        {
          id: 'preview',
          name: '',
          description: '',
          className: 'root-fieldset',
          title: 'MY_CONTRIBUTIONS.PREVIEW_AND_SUBMIT',
          fields: [],
          template: Templates.previewTemplate
        }
      ];

    schema.fieldsets = fieldSets;
    // schema.required = [...schema.required, 'field_new_category'].filter(fieldName => fieldName !== 'innovation_voting_result');

    schema.required = schema.required = [...schema.required, 'field_new_category'].filter(fieldName => fieldName !== 'innovation_voting_result' && fieldName !== 'title');
    schema.required = [...schema.required, 'field_news_description', 'title'];

    schema.widget = {id: MaterialWidgetRegistry.node};

    return schema;
  }
}
