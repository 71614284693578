import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {NgrxJsonApiService, ResourceIdentifier} from 'ngrx-json-api';
import {FormComponent} from 'ngx-schema-form';
import {EditWindowParams} from './edit-window-params';
import {FormTemplatesService} from '@madeinlune/ngx-material-schema-form';
import {DrupalFormIoService} from '@madeinlune/ngx-drupal-schemata';

@Component({
  selector: 'app-edit-window',
  templateUrl: './edit-window.component.html',
  styleUrls: ['./edit-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DrupalFormIoService]
})
export class EditWindowComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

  get isFormValid(): boolean {
    return this._isFormValid;
  }

  set isFormValid(value: boolean) {
    this._isFormValid = value;
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  data$: Subject<EditWindowParams> = new ReplaySubject<EditWindowParams>();

  id$: Observable<string>;
  type$: Observable<string>;
  title$: Observable<string>;
  iconClass$: Observable<string>;
  zone$: Observable<string>;
  resourceIdentifier$: Observable<ResourceIdentifier>;

  private _isFormValid: boolean;

  iconTypes: { [type: string]: string } = {};

  _formEdit: FormComponent;
  @ContentChild('formEdit')
  set formEdit(value: FormComponent) {
    this._formEdit = value;
  }

  @ViewChild('innovationReferenceTemplate') innovationReferenceTemplate: TemplateRef<any>;
  @ViewChild('innovationReferenceListTemplate') innovationReferenceListTemplate: TemplateRef<any>;
  @ViewChild('contactReferenceTemplate') contactReferenceTemplate: TemplateRef<any>;

  /*myValidators = {
    '/id': (value, property, form) => {
      return null;
    }
  };*/

  getFormEdit(): FormComponent {
    return this._formEdit;
  }

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    public formTemplatesService: FormTemplatesService,
    public drupalFormIoService: DrupalFormIoService
  ) {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.drupalFormIoService.destroy();
  }

  ngOnInit() {
  }

  onFormError(errorEvent: any) {
  }

  ngAfterViewInit(): void {

  }

  ngAfterContentInit(): void {
  }

  onFormChange(event) {
    this.drupalFormIoService.setValue(event.value);
  }

  save() {
    if (this.isFormValid) {
      this.zone$.pipe(take(1)).subscribe(zone => {
        this.ngrxJsonApiService.getZone(zone).apply();
      });
    }
  }

}
