import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ObjectLayoutWidget} from 'ngx-schema-form';
import {Templates} from '../../templates';
import {TemplatesService} from '../../../templates.service';
import {NgrxJsonApiDefinitions} from '../../../ngrx-json-api/ngrx-json-api-definitions';
import {AppRoutes} from '../../../routes/routes';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {NgrxJsonApiService, Query, Resource, ResourceIdentifier} from 'ngrx-json-api';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {CURRENT_LANGUAGE} from '../../../providers/app-core.providers';
import {MyContributionsService} from '../../../services/my-contributions.service';
import {NodeFormService} from '../../node-form/node-form.service';
import {FormTab, MyContributionsFormService} from '../../../my-contributions/my-contributions/my-contributions-form.service';
import {Store} from '@ngrx/store';
import {OpenAlert} from '../../../store/actions/app-core.actions';
import {TranslateService} from '@ngx-translate/core';

export interface TranslationsMeta {
  [languageKey: string]: TranslationMeta;
}

export interface TranslationMeta {
  id: string;
  name: string;
}

@Component({
  selector: 'app-form-node',
  templateUrl: './node.material.component.html',
})
export class NodeMaterialComponent extends ObjectLayoutWidget implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('newsFormTemplate') newsFormTemplate: TemplateRef<any>;
  @ViewChild('newsFormMediaTemplate') newsFormMediaTemplate: TemplateRef<any>;
  @ViewChild('innovationFormTemplate') innovationFormTemplate: TemplateRef<any>;
  @ViewChild('previewTemplate') previewTemplate: TemplateRef<any>;

  @ViewChild('languagesTabGroup') languagesTabGroup: MatTabGroup;


  previewTemplates: any = {
    [NgrxJsonApiDefinitions.news.type]: AppRoutes.detailNewsViewTemplates,
    [NgrxJsonApiDefinitions.innovation.type]: AppRoutes.detailInnovationViewTemplates
  };

  previewClassnames: any = {
    [NgrxJsonApiDefinitions.news.type]: 'news-template',
    [NgrxJsonApiDefinitions.innovation.type]: 'innovation-template'
  };

  translationFormModel: any;

  languageTabs$: Observable<TranslationMeta[]>;

  resourceIdentifier$: Observable<ResourceIdentifier>;

  translationProcessing: boolean;

  constructor(
    public templatesService: TemplatesService,
    public myContributionsService: MyContributionsService,
    private ngrxJsonApiService: NgrxJsonApiService,
    public nodeFormService: NodeFormService,
    public myContributionsFormService: MyContributionsFormService,
    public cdr: ChangeDetectorRef,
    @Inject(CURRENT_LANGUAGE) public readonly currentLanguage$: Observable<string>,
    private store: Store<any>,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.ngrxJsonApiService.getZone(this.formProperty.root.schema.meta.zone).clear();
  }

  ngAfterViewInit() {

    super.ngAfterViewInit();

    this.resourceIdentifier$ = this.formProperty.valueChanges
      .pipe(
        filter(value => !!value),
        map(value => {
          const {id, type} = value;
          return {id, type};
        }),
        shareReplay(1)
      );

    const metaTranslations$: Observable<TranslationsMeta> = this.resourceIdentifier$.pipe(
      switchMap(resourceIdentifier => {
        const zone: string = this.formProperty.root.schema.meta.zone;
        return this.ngrxJsonApiService.getZone(zone)
          .selectStoreResource(resourceIdentifier)
          .pipe(
            filter(resource => !!resource),
            distinctUntilChanged((oldValue, newValue) => {
              return JSON.stringify(oldValue) === JSON.stringify(newValue);
            }),
            map(resource => {
              return resource?.meta?.drupal?.translations;
            })
          );
      }),
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      }),
      shareReplay(1)
    );

    this.languageTabs$ = combineLatest([this.currentLanguage$, metaTranslations$])
      .pipe(
        map(([currentLanguage, metaTranslations]) => {

          const labels: any = {
            fr: 'Français',
            en: 'English'
          };

          let languagesTabs: TranslationMeta[];
          if (metaTranslations) {
            languagesTabs = metaTranslations[currentLanguage] ? [metaTranslations[currentLanguage]] : [];
            for (const [key, value] of Object.entries(metaTranslations)) {
              if (key !== currentLanguage) {
                languagesTabs.push(value);
              }
            }
          } else {
            languagesTabs = [{
              id: currentLanguage,
              name: labels[currentLanguage]
            }];
          }

          return languagesTabs;

        }),
        shareReplay(1)
      );

    this.resourceIdentifier$
      .pipe(
        withLatestFrom(this.languageTabs$),
        filter(([resourceIdentifier, languageTabs]) => {
          return !!resourceIdentifier && languageTabs?.length > 1;
        }),
        distinctUntilChanged((oldValue, newValue) => {
            return JSON.stringify(oldValue) === JSON.stringify(newValue);
          }
        ),
        take(1)
      )
      .subscribe(([resourceIdentifier, languageTabs]: [ResourceIdentifier, TranslationMeta[]]) => {
        this.loadTranslation(resourceIdentifier, languageTabs[1].id);
      });

    this.templatesService.removeTemplate(Templates.newsForm);
    this.templatesService.removeTemplate(Templates.newsMediaForm);
    this.templatesService.removeTemplate(Templates.innovationForm);
    this.templatesService.removeTemplate(Templates.previewTemplate);

    this.templatesService.addTemplate(Templates.newsForm, this.newsFormTemplate);
    this.templatesService.addTemplate(Templates.newsMediaForm, this.newsFormMediaTemplate);
    this.templatesService.addTemplate(Templates.innovationForm, this.innovationFormTemplate);
    this.templatesService.addTemplate(Templates.previewTemplate, this.previewTemplate);

  }

  onFormSelectedTabChange(event: MatTabChangeEvent) {
    let selectedTab: FormTab;
    if (event.index === 0) {
      selectedTab = 'info';
    } else if (event.index === 1) {
      selectedTab = 'media';
    } else if (event.index === 2) {
      selectedTab = 'submit';
    }
    this.myContributionsFormService.currentTabEmitter.emit(selectedTab);
  }

  loadTranslation(resourceIdentifier: ResourceIdentifier, language: string, selectTab: boolean = false) {
    const {id, type} = resourceIdentifier;
    const query: Query = {
      queryId: 'translation',
      type,
      id,
      params: {
        filtering: [
          {
            operator: 'forceLanguage',
            value: language
          }
        ]
      }
    };

    this.ngrxJsonApiService.getZone('translation').clear();

    this.ngrxJsonApiService.getZone('translation').putQuery({query: query, fromServer: true});

    this.ngrxJsonApiService.getZone('translation')
      .selectStoreResource(resourceIdentifier)
      .pipe(
        filter(translationResource => !!translationResource),
        take(1)
      )
      .subscribe(translationResource => {
        if (translationResource.type === NgrxJsonApiDefinitions.news.type) {
          this.translationFormModel = {
            title: translationResource.attributes?.title,
            body: translationResource.attributes?.field_news_description?.processed
          };
        } else if (translationResource.type === NgrxJsonApiDefinitions.innovation.type) {
          this.translationFormModel = {
            title: translationResource.attributes?.title,
            body: translationResource.attributes?.field_innovation_description?.processed,
            innovative_why: translationResource.attributes?.field_innovation_why?.processed
          };
        }
        this.translationProcessing = false;
        if (selectTab === true) {
          this.selectOtherLanguageTab();
        }
      });

  }

  selectOtherLanguageTab() {

    setTimeout(() => {
      if (this.languagesTabGroup._allTabs.length === 2) {
        this.languagesTabGroup.selectedIndex = 1;
        this.cdr.detectChanges();
      } else {
        this.selectOtherLanguageTab();
      }
    }, 10);

  }

  languageTabTrackBy(index, item: TranslationMeta) {
    return index || item?.id;
  }

  generateTranslation() {
    this.translationProcessing = true;
    this.resourceIdentifier$
      .pipe(
        filter((resourceIdentifier) => !!resourceIdentifier),
        take(1),
        tap((resourceIdentifier) => {
          this.ngrxJsonApiService.getZone(this.formProperty.root.schema.meta.zone).apply();
        }),
        delay(100),
        switchMap((resourceIdentifier) => {
          return this.ngrxJsonApiService.getZone(this.formProperty.root.schema.meta.zone).selectStoreResource(resourceIdentifier)
            .pipe(
              filter(resource => resource?.state === 'IN_SYNC'),
              take(1)
            );
        }),
        catchError(this.handleError<Resource>('generateTranslation', null)),
        withLatestFrom(this.languageTabs$),
        filter(([resource, languageTabs]) => {
          return languageTabs?.length > 0;
        }),
        take(1)
      )
      .subscribe(([resource, languageTabs]) => {
        const firstTabLanguage: TranslationMeta = languageTabs[0];
        const fromLanguage: string = firstTabLanguage.id;
        const toLanguage: string = fromLanguage === 'fr' ? 'en' : 'fr';
        this.myContributionsService.triggerTranslation(resource.id, fromLanguage, toLanguage)
          .pipe(
            take(1)
          )
          .subscribe(result => {
            this.myContributionsService.reLoadFullContributions();
            this.loadTranslation(resource, toLanguage, true);
            const {id, type} = resource;
            this.ngrxJsonApiService.getZone(this.formProperty.root.schema.meta.zone).putQuery({
              query: {
                queryId: 'reloadAfterTranslation',
                id,
                type
              },
              fromServer: true
            });
          });
      });
  }

  saveTranslation(contentType: 'news' | 'innovation') {

    this.languageTabs$
      .pipe(take(1))
      .subscribe(languageTabs => {

        const firstTabLanguage: TranslationMeta = languageTabs[0];
        const fromLanguage: string = firstTabLanguage.id;
        const toLanguage: string = fromLanguage === 'fr' ? 'en' : 'fr';

        let translation: Resource;
        const {id, type} = this.formProperty.value;

        if (contentType === 'news') {
          translation = {
            id,
            type,
            attributes: {
              title: this.translationFormModel.title,
              field_news_description: {
                value: this.translationFormModel.body,
                format: 'basic_html'
              },
              forceLanguage: toLanguage
            }
          };
        } else if (contentType === 'innovation') {
          translation = {
            id,
            type,
            attributes: {
              title: this.translationFormModel.title,
              field_innovation_description: {
                value: this.translationFormModel.body,
                format: 'basic_html'
              },
              field_innovation_why: {
                value: this.translationFormModel.innovative_why,
                format: 'basic_html'
              },
              forceLanguage: toLanguage
            }
          };
        }

        if (translation) {
          this.ngrxJsonApiService.getZone('translation').patchResource({resource: translation, toRemote: true});
          this.ngrxJsonApiService.getZone('translation').selectStoreResource(translation)
            .pipe(
              filter(translationResource => {
                return translationResource?.state === 'IN_SYNC' || translationResource.errors.length > 0;
              }),
              take(1)
            ).subscribe(translationResource => {
            if (translationResource.errors.length > 0) {
              this.store.dispatch(new OpenAlert(this.translateService.instant('MY_CONTRIBUTIONS.ERROR')));
              this.ngrxJsonApiService.getZone('translation').removeResourceErrors(translationResource, translationResource.errors);
            }
          });
        }

      });

  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
