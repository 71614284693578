import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-component-templates',
  templateUrl: './component-templates.component.html',
  styleUrls: ['./component-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentTemplatesComponent implements OnInit {

  @Input()
  render: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
