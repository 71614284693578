<app-apollo-list
  class="d-flex flex-fill"
  [data]="inspiratingContents$|async"
  [rendererTemplate]="innoListItemTemplate"
  [noDataMessage]="'MY_INSPIRATION.NO_CONTENTS_IN_INSPIRATION'">
</app-apollo-list>

<router-outlet></router-outlet>

<ng-template #innoListItemTemplate let-data="data">

  <a [routerLink]="[data.type, data.id]" routerLinkActive #rla="routerLinkActive">

    <ng-template
      [ngTemplateOutlet]="('listRenderer'|templates)|async"
      [ngTemplateOutletContext]="{
      data: (data|resourceToInnovationIndexItem:'artboards')|async,
      selected: rla.isActive}">
    </ng-template>

  </a>

</ng-template>

<ng-template #inspirationsActionsTemplate>

  <ng-container *ngIf="(inspiration$|async) as inspiration">

    <button
      (click)="editInspiration()"
      mat-fab
      color="accent"
      class="user-function-icon circle-button mr-3"
      matBadgeColor="accent">
      <mat-icon [svgIcon]="'icon-contribution'"></mat-icon>
    </button>

    <button
      (click)="addToShare(inspiration)"
      mat-fab
      color="accent"
      class="user-function-icon circle-button"
      matBadgeColor="accent">
      <mat-icon [svgIcon]="'icon-share'"></mat-icon>
    </button>

  </ng-container>

</ng-template>

<ng-template #renameInspirationContentTemplate>

  <ng-container *ngIf="(inspiration$|async) as inspiration">

    <mat-form-field class="px-3">
      <input
        #inspirationNameInput
        matInput
        [(ngModel)]="inspirationTitleValue"
        [placeholder]="'MY_INSPIRATION.FORM.NAME'|translate">
    </mat-form-field>

  </ng-container>

</ng-template>


<ng-template #renameInspirationActionsTemplate>

  <div class="mb-2">

    <button
      (click)="deleteInnovation()"
      mat-flat-button
      class="secondary-action-button mr-3"
      mat-dialog-close>
      <span [innerHTML]="'ACTIONS.DELETE'|translate"></span>
    </button>

    <button
      class="main-action-button"
      (click)="saveInnovation()"
      mat-flat-button
      mat-dialog-close>
      <span [innerHTML]="'ACTIONS.SAVE'|translate"></span>
    </button>

  </div>

</ng-template>
