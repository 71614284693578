import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../templates.service';
import {Observable} from 'rxjs';
import {ManyQueryResult, NgrxJsonApiService, StoreResource} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {filter, map} from 'rxjs/operators';
import {Templates} from '../../components/templates';
import {ResponsiveService} from '../../services/responsive.service';
import {Store} from '@ngrx/store';
import {SideMenuService} from '../../services/side-menu.service';

@Component({
  selector: 'app-search-templates',
  templateUrl: './search-templates.component.html',
  styleUrls: ['./search-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchTemplatesComponent implements OnInit, AfterViewInit {

  @ViewChild('searchSelectFilterTemplate', /* TODO: check static flag */ {static: true})
  searchSelectFilterTemplate: TemplateRef<any>;

  @ViewChild('searchFiltersTemplate', /* TODO: check static flag */ {static: true})
  searchFiltersTemplate: TemplateRef<any>;

  @ViewChild('defaultSearchFiltersTemplate', /* TODO: check static flag */ {static: true})
  defaultSearchFiltersTemplate: TemplateRef<any>;

  constructor(
    public templatesService: TemplatesService,
    private ngrxJsonApiService: NgrxJsonApiService,
    public responsiveService: ResponsiveService,
    private store: Store<any>,
    private sideMenuService: SideMenuService
  ) {
  }

  ngOnInit() {
  }


  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.searchFilters, this.searchFiltersTemplate);
    this.templatesService.addTemplate(Templates.searchSelectFilter, this.searchSelectFilterTemplate);
    this.templatesService.addTemplate(Templates.defaultSearchFilters, this.defaultSearchFiltersTemplate);

  }

  openFilters() {
    this.sideMenuService.sideFiltersMenu.toggle();
  }

  getTerms(type: string): Observable<StoreResource[]> {
    return this.ngrxJsonApiService
      .getZone(NgrxJsonApiDefinitions.zoneTaxonomies)
      .selectManyResults(type)
      .pipe(
        filter(manyQueryResult => !!manyQueryResult),
        map((manyQueryResult: ManyQueryResult) => manyQueryResult.data)
      );
  }

}
