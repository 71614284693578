<ng-template #titleTemplate>
  <span [innerHTML]="(title$|async)"></span>
</ng-template>

<ng-template #contentTemplate>

  <div class="d-flex flex-fill flex-column p-4 overflow-hidden">

    <ng-container *ngIf="(drupalFormIoService.schema$|async) as schema; else loadingTemplate">

      <ng-container *ngIf="(drupalFormIoService.model$|async) as model; else loadingTemplate">

        <sf-form
          #formEdit
          class="overflow-hidden d-flex flex-fill flex-column"
          (isValid)="isFormValid = $event"
          (error)="onFormError($event)"
          (onErrorChange)="onFormError($event)"
          (onErrorsChange)="onFormError($event)"
          (onChange)="onFormChange($event)"
          [model]="model"
          [schema]="schema">
        </sf-form>

      </ng-container>

    </ng-container>

  </div>

</ng-template>

<ng-template #actionsTemplate>

  <div class="d-flex justify-content-end align-self-stretch align-items-center flex-fill px-5 py-3">

    <button
      mat-stroked-button
      [matDialogClose]
      color="accent"
      class="mr-3">
      Cancel
    </button>

    <button
      (click)="save()"
      [disabled]="!this.isFormValid"
      mat-flat-button color="accent">
      Save
    </button>

  </div>

</ng-template>

<ng-template #innovationReferenceTemplate
             let-option="option"
             let-zone="zone"
             let-removable="removable"
             let-removeEmitter="removeEmitter">

  <app-innovation-reference-renderer
    [data]="(option|jaSelectStoreResource:zone)|async"
    [zone]="zone"
    [removable]="removable"
    [removeEmitter]="removeEmitter">
  </app-innovation-reference-renderer>

</ng-template>

<ng-template #innovationReferenceListTemplate let-data="data" let-removeEmitter="removeEmitter" let-zone="zone">

  <ul class="d-flex flex-column list-style-type-none p-0 m-0">

    <ng-template ngFor [ngForOf]="data" let-item>

      <li class="d-flex">

        <ng-template
          [ngTemplateOutlet]="innovationReferenceTemplate"
          [ngTemplateOutletContext]="{option:(item|jaSelectStoreResource:zone)|async, removable:true, removeEmitter:removeEmitter}">
        </ng-template>

      </li>

    </ng-template>

  </ul>

</ng-template>

<ng-template #contactReferenceTemplate let-option="option" let-removable="removable" let-removeEmitter="removeEmitter">

  <div class="d-flex flex-fill innovation-reference align-items-center px-3">

    <span [innerHTML]="option.attributes.name"></span>

    <span class="flex-fill ml-3" [innerHTML]="option.attributes.mail"></span>

    <ng-container *ngIf="removable && removeEmitter">
      <button mat-mini-fab aria-label="Remove Reference" (click)="removeEmitter.emit(option)">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>

  </div>

</ng-template>

<ng-template #loadingTemplate>
  <div class="position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center">
    <mat-progress-spinner [diameter]="40" [color]="'accent'" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
</ng-template>
