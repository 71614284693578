import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../../templates.service';
import {combineLatest, Observable} from 'rxjs';
import {NgrxJsonApiService, Resource} from 'ngrx-json-api';
import {filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {DrupalPathGuard} from '@ngx-mil-drupal/drupal-path-guard';
import {LanguageSwitcherService} from '../../../services/language-switcher.service';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit, AfterViewInit {

  node$: Observable<Resource>;

  resourceIdentifier$: Observable<{ id?: string, type?: string, zone?: string }>;

  templates$: Observable<TemplateRef<any>[]> = this.route.data.pipe(
    map(data => data.templates),
    withLatestFrom(this.templatesService.templates$),
    map(([templateIds, templates]: [string[], { [templateId: string]: TemplateRef<any> }]) => templateIds.map(templateId => {
      return templates[templateId];
    }).filter(template => !!template))
  );

  templatesIds$: Observable<string[]> = this.route.data.pipe(
    map(data => data.templates)
  );

  routeData$: Observable<any> = this.route.data;

  routeParams$: Observable<any> = this.route.params;

  routeUrl$: Observable<any> = this.route.url;

  @ViewChild('testTemplate', /* TODO: check static flag */ {static: true})
  testTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private ngrxJsonApiService: NgrxJsonApiService,
    public templatesService: TemplatesService,
    private languageSwitcherService: LanguageSwitcherService
  ) {
  }

  ngOnInit() {

    this.resourceIdentifier$ = combineLatest([
      this.routeData$,
      this.routeParams$,
      this.languageSwitcherService.currentLanguage$
    ])
      .pipe(map(([routeData, routeParams, currentLanguage]) => {
        let resourceIdentifier: { id?: string, type?: string, zone?: string };
        if (routeData.staticRoute) {
          if (routeParams.type || routeParams.id || routeData.zone) {
            resourceIdentifier = {};
            if (routeParams.type) {
              resourceIdentifier.type = routeParams.type;
            }
            if (routeParams.type) {
              resourceIdentifier.id = routeParams.id;
            }
            if (routeData.zone) {
              resourceIdentifier.zone = routeData.zone;
            }
          }
        } else {
          resourceIdentifier = {...routeData.resourceIdentifier, zone: DrupalPathGuard.DRUPAL_ZONE + '-' + currentLanguage};
        }
        return resourceIdentifier;
      }));

    this.node$ = this.resourceIdentifier$.pipe(
      filter(resourceIdentifier => !!resourceIdentifier),
      switchMap(resourceIdentifier => {
        return this.ngrxJsonApiService
          .getZone(resourceIdentifier.zone)
          .selectStoreResource({type: resourceIdentifier.type, id: resourceIdentifier.id}) as unknown as Observable<Resource>;
      })
    );

  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate('test', this.testTemplate);
  }

}
