import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {AppConfigService} from '../../../app/config/app-config.service';

@Pipe({
  name: 'mediaUrl'
})
@Injectable()
export class MediaUrlPipe implements PipeTransform {

  constructor(
    public appConfig: AppConfigService
  ) {

  }


  transform(value: string): string {
    return value.indexOf('assets/temp') > -1 ? value : this.appConfig.getConfig().backendUrl + value;
  }
}
