<div class="pwa-update-group d-flex flex-column flex-fill align-items-center justify-content-center mt-5 mb-3">

  <img class="app-icon" src="assets/icons/icon-192x192.png"/>


  <p [innerHTML]="'APP.CONNECT.AGAIN'|translate" class="text-center mt-4"></p>

  <a (click)="connect()" mat-flat-button color="primary" class="mt-3">
    <span [innerHTML]="'APP.CONNECT.ACTION'|translate"></span>
  </a>


</div>
