import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DenormaliseStoreResourcePipe, GetDenormalisedValuePipe, SelectStoreResourcePipe} from 'ngrx-json-api';
import {YouTubeIdPipe} from './youtube-id.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {MasonryImageHeightPipe} from './masonry-image-height';
import {ListItemFirstImagePipe} from './list-item-first-image';
import {InnovationSchemaPipe} from './schema/innovation-schema.pipe';
import {NewsSchemaPipe} from './schema/news-schema.pipe';
import {InnovationInArtboardPipe} from './innovation-in-artboard.pipe';
import {ResourceToInnovationIndexItemPipe} from './schema/resource-to-innovation-index-item';
import {AlreadyVotedPipe} from './already-voted.pipe';
import {CartSchemaPipe} from './schema/cart-schema.pipe';
import {FacetValueTidMatcher} from './facet-value-tid-matcher.pipe';
import {DynamicQueryParamsPipe} from './dynamic-query-params.pipe';
import {FacetLabelByTid} from './facet-label-by-tid.pipe';
import {ThumbUrlPipe} from './thumb-url';
import {GetAppWorkflowStatePipe} from './get-app-workflow-state.pipe';
import {NgPipesModule, ShortenPipe, StripTagsPipe} from 'ngx-pipes';
import {PdfUrlPipe} from './pdf-url';
import {ContentTypeToLabelPipe} from './content-type-to-label.pipe';
import {ListPathPipe} from './list-path.pipe';
import {SearchPlaceHolderCountPipe} from './search-place-holder-count.pipe';
import {MenuItemActivePipe} from './menu-item-active.pipe';
import {TemplatesPipe} from './templates.pipe';
import {UserNamePipe} from './username.pipe';
import {MenuItemShortLabelPipe} from './menu-item-path-translate';
import {UserIconCountPipe} from './user-icon-count.pipe';
import { ShortenDescriptionPipe } from './shorten-description.pipe';

const pipes: any = [
  AlreadyVotedPipe,
  CartSchemaPipe,
  ContentTypeToLabelPipe,
  DynamicQueryParamsPipe,
  FacetLabelByTid,
  FacetValueTidMatcher,
  GetAppWorkflowStatePipe,
  InnovationInArtboardPipe,
  InnovationSchemaPipe,
  ListItemFirstImagePipe,
  MasonryImageHeightPipe,
  MenuItemActivePipe,
  MenuItemShortLabelPipe,
  NewsSchemaPipe,
  PdfUrlPipe,
  ResourceToInnovationIndexItemPipe,
  SearchPlaceHolderCountPipe,
  TemplatesPipe,
  ThumbUrlPipe,
  UserIconCountPipe,
  UserNamePipe,
  YouTubeIdPipe,
  ListPathPipe,
  ShortenDescriptionPipe
];

@NgModule({
  declarations: [
    ...pipes
  ],
  exports: [
    ...pipes,
    NgPipesModule,
    ListPathPipe
  ],
  providers: [
    ...pipes,
    GetDenormalisedValuePipe,
    DenormaliseStoreResourcePipe,
    SelectStoreResourcePipe,
    StripTagsPipe,
    ShortenPipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgPipesModule
  ]
})
export class PipesModule {
}
