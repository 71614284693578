import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';

@Pipe({
  name: 'username'
})
export class UserNamePipe implements PipeTransform {

  transform(user: Resource): string {
    if (user && user.attributes) {
      const userAttributes: { [key: string]: any } = user.attributes;
      return userAttributes.display_name ? userAttributes.display_name : (userAttributes.name ? userAttributes.name : userAttributes.mail);
    }
    return null;
  }

}
