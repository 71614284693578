import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {LoaderService} from '../../services/loader.service';
import {MenusService} from '../../services/menus.service';
import {ResponsiveService} from '../../services/responsive.service';
import {TemplatesService} from '../../templates.service';
import {Templates} from '../templates';
import {select, Store} from '@ngrx/store';
import {getRouteContext} from '../../store/reducers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, AfterViewInit {

  isLoading$: Observable<boolean> = this.loaderService.isLoading$.pipe(debounceTime(10));

  routerContext$: Observable<string> = this.store.pipe(select(getRouteContext));

  @Output()
  openMenu: EventEmitter<any> = new EventEmitter<any>();

  isNewsContext$: Observable<boolean> = this.routerContext$.pipe(
    map(context => context === 'news')
  );

  isInnovationContext$: Observable<boolean> = this.routerContext$.pipe(
    map(context => context === 'innovation')
  );

  iconsMap: {};

  constructor(
    private loaderService: LoaderService,
    public menusService: MenusService,
    public templatesService: TemplatesService,
    public responsiveService: ResponsiveService,
    private store: Store<any>,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  get userIconsMenuTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.userFunctionMenu);
  }

}
