import {ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {ApolloQueries} from '../apollo/apollo-queries';
import {WatchQueryOptions} from 'apollo-client';
import {select, Store} from '@ngrx/store';
import {getCurrentLanguage, getItemsByContentType, getListThumbSize} from '../store/reducers';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {ApolloService} from '../apollo/apollo.service';
import {filter, map, shareReplay, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {TemplatesService} from '../templates.service';
import {Direction, ManyQueryResult, NgrxJsonApiService, NgrxJsonApiStoreResources, Query, StoreResource} from 'ngrx-json-api';
import {NgrxJsonApiQueries} from '../ngrx-json-api/ngrx-json-queries';
import {ListThumbSizes} from '../models/list-thumb-sizes.model';
import {PwaService} from '../pwa.service';
import {ResponsiveService} from '../services/responsive.service';
import {Templates} from '../components/templates';
import {ScrollDispatcher} from '@angular/cdk/overlay';
import {InnovationIndexItem} from '../apollo/innovation-index-item';
import {AddToIndex} from '../store/actions/search.actions';
import {StripTagsPipe} from 'ngx-pipes';
import {ResourceToInnovationIndexItemPipe} from '../pipes/schema/resource-to-innovation-index-item';
import {MatTabNav} from '@angular/material/tabs';
import {USER_FIRST_NAME} from '../providers/user.providers';

export interface NavLinkItem {
  path: string;
  label: string;
}

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StripTagsPipe]
})
export class WelcomeComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'd-flex flex-column position-relative flex-grow-1 flex-shrink-0';

  navLinks: NavLinkItem[] = [
    {
      path: 'latest',
      label: 'APP.LATEST_INNOVATIONS'
    },
    {
      path: 'popular',
      label: 'APP.MOST_POPULAR_INNOVATIONS'
    }
  ];

  navLinks$: ReplaySubject<NavLinkItem[]> = new ReplaySubject<NavLinkItem[]>(1);

  activeLink: NavLinkItem = this.navLinks[0];

  innovationQueryOptions$: Observable<WatchQueryOptions>;

  innovationQueryOptions: WatchQueryOptions = {
    query: ApolloQueries.innovationList,
    fetchPolicy: 'network-only',
    variables: {
      contentType: NgrxJsonApiDefinitions.ctInnovation
    }
  };

  listThumbSize$: Observable<ListThumbSizes> = this.store.pipe(
    select(getListThumbSize)
  );

  currentLanguage$: Observable<string> = this.store.pipe(
    select(getCurrentLanguage),
    shareReplay(1)
  );

  innovationsApolloService: ApolloService;

  promotedNews$: Observable<StoreResource[]> = this.currentLanguage$.pipe(
    switchMap(language => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneWelcome + '-' + language)
        .selectManyResults(NgrxJsonApiQueries.queryPromotedNews.queryId)
        .pipe(
          filter(manyQueryResult => !!manyQueryResult),
          map((manyQueryResult: ManyQueryResult) => manyQueryResult.data),
          shareReplay(1)
        );
    })
  );

  cachedPromotedNews$: Observable<StoreResource[]> = this.currentLanguage$.pipe(
    switchMap(language => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneWelcome + '-' + language)
        .selectStoreResourcesOfType(NgrxJsonApiDefinitions.news.type)
        .pipe(
          filter(ngrxJsonApiStoreResources => !!ngrxJsonApiStoreResources),
          withLatestFrom(this.promotedNews$),
          map(([ngrxJsonApiStoreResources, remoteNews]: [NgrxJsonApiStoreResources, StoreResource[]]) => {
            if (ngrxJsonApiStoreResources) {
              let remoteNewsIds: string[] = [];
              if (remoteNews) {
                remoteNewsIds = remoteNews.map(remoteNewsResource => remoteNewsResource.id);
              }
              let news: StoreResource[] = Object.keys(ngrxJsonApiStoreResources).map(key => ngrxJsonApiStoreResources[key]);
              news = news.filter(newsResource => {
                return remoteNewsIds.indexOf(newsResource.id) > -1;
              });
              return news;
            } else {
              of(null);
            }
          }),
          take(1),
          shareReplay(1)
        );
    }),
    shareReplay(1)
  );

  mostPopularInnovations$: Observable<InnovationIndexItem[]> = this.currentLanguage$.pipe(
    switchMap(language => {
      const zone: string = NgrxJsonApiDefinitions.zoneWelcome + '-' + language;
      return this.ngrxJsonApiService.getZone(zone)
        .selectManyResults(NgrxJsonApiQueries.mostPopularInnovationQueryId)
        .pipe(
          map(manyQueryResult => manyQueryResult?.data?.filter(resource => resource?.attributes?.field_inspiration_reverse_entity >= 1)),
          filter(resources => !!resources),
          withLatestFrom(this.currentLanguage$),
          switchMap(([resources, currentLanguage]) => {
            return combineLatest(resources.map(resource => {
              return this.resourceToInnovationIndexItemPipe.transform(resource, zone, currentLanguage);
            }));
          })
        );
    })
  );

  itemsByContentType$: Observable<InnovationIndexItem[]>;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private store: Store<any>,
    public templatesService: TemplatesService,
    private ngrxJsonApiService: NgrxJsonApiService,
    public pwaService: PwaService,
    public responsiveService: ResponsiveService,
    @Inject('apolloServiceFactory') private apolloServiceFactory,
    private elementRef: ElementRef,
    private stripTagsPipe: StripTagsPipe,
    private resourceToInnovationIndexItemPipe: ResourceToInnovationIndexItemPipe,
    @Inject(USER_FIRST_NAME) public readonly userFirstName$: Observable<string>
  ) {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  ngOnInit() {

    this.innovationsApolloService = this.apolloServiceFactory(this.innovationQueryOptions, 0, 20);

    this.currentLanguage$.pipe(
      filter(language => !!language),
      takeUntil(this.destroyed$)
    ).subscribe(
      currentLanguage => {

        this.innovationsApolloService.variables = {...this.innovationQueryOptions.variables, langCode: currentLanguage};

        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneWelcome + '-' + currentLanguage)
          .putQuery({query: NgrxJsonApiQueries.getMostPopularInnovationQuery(currentLanguage), fromServer: true});

        this.ngrxJsonApiService
          .getZone(NgrxJsonApiDefinitions.zoneWelcome + '-' + currentLanguage)
          .putQuery({query: NgrxJsonApiQueries.queryPromotedNews, fromServer: true});
      }
    );

    this.innovationsApolloService.data$
      .pipe(
        withLatestFrom(this.currentLanguage$),
        takeUntil(this.destroyed$)
      )
      .subscribe(([data, currentLanguage]) => {
        const newData = data.map(item => {
          const clonedItem: InnovationIndexItem = {...item};
          return clonedItem;
        });
        this.store.dispatch(new AddToIndex(currentLanguage, 'innovation', newData));
      });

    this.navLinks$.next(this.navLinks);

    this.itemsByContentType$ = this.currentLanguage$.pipe(
      switchMap(language => {
        return this.store.pipe(
          select(getItemsByContentType(language, 'innovation'))
        );
      })
    );

    /*this.promotedNews$.pipe(
      untilDestroyed(this),
      filter(news => !!news),
      take(1),
      withLatestFrom(this.currentLanguage$)
    ).subscribe(([promotedNews, currentLanguage]) => {
      console.log('currentLanguage', currentLanguage);
      this.ngrxJsonApiService
        .getZone(DrupalPathGuard.DRUPAL_ZONE + '-' + currentLanguage)
        .putQuery({query: NgrxJsonApiQueries.queryPromotedNews, fromServer: true});
    });*/
  }

  onNavBarClick(event: MouseEvent, nav: MatTabNav, index: number, navLink: NavLinkItem) {
    event.preventDefault();
    event.stopImmediatePropagation();
    nav.selectedIndex = index;
    this.activeLink = navLink;
  }

  get mainHeaderTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.mainHeader);
  }

  get pwaBannerTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.pwaBanner);
  }

  get appFooterTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.appFooter);
  }

}
