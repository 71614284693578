<ng-container *ngIf="mediaImages$|async as mediaImages">

  <ng-container *ngIf="mediaImages.length > 1">
    <app-swiper-thumbs [zone]="zone" [swiperRef]="swiper" class="d-flex my-2" [data]="mediaImages">
    </app-swiper-thumbs>
  </ng-container>

  <div class="square-ratio">

    <app-swiper
      class="d-flex content"
      #swiper
      [slideTemplate]="slideTemplate"
      [data]="mediaImages">
    </app-swiper>

  </div>

</ng-container>

<ng-template #imageSlideTemplate let-data="data">

  <app-image-slide-renderer [data]="data"></app-image-slide-renderer>

</ng-template>

<ng-template #videoSlideTemplate let-data="data" let-pause="pause">

  <app-video-player
    class="overflow-hidden"
    [externalPause]="pause"
    [videoPlayerPassport]="{zone: zone, media: data}">
  </app-video-player>

</ng-template>

<ng-template #youTubeSlideTemplate let-data="data" let-pause="pause">

  <ng-container *ngIf="data?.attributes?.field_media_oembed_video as videoUrl">
    <youtube-player
      #youTubePlayer
      [playerVars]="youTubePlayerVars"
      [videoId]="videoUrl|youTubeId"
      [width]="'100%'"
      [height]="'100%'">
    </youtube-player>
  </ng-container>

</ng-template>

<ng-template #slideTemplate let-data="data" let-isCurrent="isCurrent">

  <ng-container *ngIf="data">

    <ng-container *ngIf="data.type == 'media--image'">
      <ng-template
        [ngTemplateOutlet]="imageSlideTemplate"
        [ngTemplateOutletContext]="{data: data}">
      </ng-template>
    </ng-container>

    <ng-container *ngIf="data.type == 'media--original_video'">
      <ng-template
        [ngTemplateOutlet]="videoSlideTemplate"
        [ngTemplateOutletContext]="{data: data, pause: !isCurrent}">
      </ng-template>
    </ng-container>

    <ng-container *ngIf="data.type == 'media--video'">
      <ng-template
        [ngTemplateOutlet]="videoSlideTemplate"
        [ngTemplateOutletContext]="{data: data, pause: !isCurrent}">
      </ng-template>
    </ng-container>

    <ng-container *ngIf="data.type == 'media--remote_video'">
      <ng-template
        [ngTemplateOutlet]="youTubeSlideTemplate"
        [ngTemplateOutletContext]="{data: data, pause: !isCurrent}">
      </ng-template>
    </ng-container>

  </ng-container>

</ng-template>
