import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {VideoPlayerPassport} from './video-player-passport';
import {Observable, of, ReplaySubject} from 'rxjs';
import {NgrxJsonApiService, Resource, ResourceIdentifier} from 'ngrx-json-api';
import {filter, map, switchMap, take} from 'rxjs/operators';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @ViewChild('vPlayer') private vPlayer: ElementRef;

  videoPlayerPassport$: ReplaySubject<VideoPlayerPassport> = new ReplaySubject<VideoPlayerPassport>();

  @Input()
  set videoPlayerPassport(videoPlayerPassport: VideoPlayerPassport) {
    this.videoPlayerPassport$.next(videoPlayerPassport);
  }

  @Input()
  set externalPause(value: boolean) {
    if (this.vPlayer && value === true) {
      this.vPlayer.nativeElement.pause();
    }
  }

  videoFiles$: Observable<Resource[]>;

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService
  ) {
  }

  ngOnInit() {
    this.videoFiles$ = this.videoPlayerPassport$.pipe(
      switchMap((videoPlayerPassport: VideoPlayerPassport) => {
        if (videoPlayerPassport.media.type === 'media--original_video') {
          const videoFileRefs: ResourceIdentifier[] = videoPlayerPassport.media.relationships.field_media_transcoded.data;
          return this.ngrxJsonApiService.getZone(videoPlayerPassport.zone).selectStoreResources(videoFileRefs).pipe(
            filter(videoFiles => !!videoFiles),
            switchMap(videoFiles => {
              const encodedVideoFileRefs: ResourceIdentifier[] = videoFiles.map(videoFile => videoFile.relationships.field_media_file.data);
              return this.ngrxJsonApiService.getZone(videoPlayerPassport.zone).selectStoreResources(encodedVideoFileRefs)
                .pipe(
                  filter(encodedVideoFiles => !!encodedVideoFiles),
                  map(encodedVideoFiles => encodedVideoFiles),
                  take(1)
                );
            }),
            take(1)
          );
        } else if (videoPlayerPassport.media.type === 'media--video') {
          const videoFileRef: ResourceIdentifier = videoPlayerPassport.media.relationships.field_media_video_file.data;
          return this.ngrxJsonApiService.getZone(videoPlayerPassport.zone).selectStoreResource(videoFileRef).pipe(
            filter(videoFile => !!videoFile),
            map(videoFile => {
              return [videoFile];
            }),
            take(1)
          );
        }
        return of([]);
      }),
      take(1)
    );

  }

}
