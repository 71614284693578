import {Provider} from '@angular/core';
import {
  DRUPAL_UPLOADER_ICONS,
  DRUPAL_UPLOADER_INTERFACE_TERMS,
  DrupalUploaderIcons,
  DrupalUploaderInterfaceTerms
} from '@madeinlune/ngx-drupal-uploader';
import {MATERIAL_SCHEMA_FORM_INTERFACE_TERMS, MaterialSchemaFormInterfaceTerms} from '@madeinlune/ngx-material-schema-form';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';


export const MADEINLUNE_PROVIDERS: Provider[] = [
  {
    provide: DRUPAL_UPLOADER_INTERFACE_TERMS,
    deps: [TranslateService],
    useFactory: drupalUploaderInterfaceTermsFactory
  },
  {
    provide: DRUPAL_UPLOADER_ICONS,
    useValue: {
      remove: 'icon-close',
      draggable: 'icon-drag'
    } as DrupalUploaderIcons
  },
  {
    provide: MATERIAL_SCHEMA_FORM_INTERFACE_TERMS,
    deps: [TranslateService],
    useFactory: materialSchemaFormInterfaceTermsFactory
  }
];


export function drupalUploaderInterfaceTermsFactory(
  translateService: TranslateService
): Observable<DrupalUploaderInterfaceTerms> {
  return combineLatest([
    translateService.get('dependencies.drupal-uploader.browse-file'),
    translateService.get('dependencies.drupal-uploader.browse-files'),
    translateService.get('dependencies.drupal-uploader.cancel'),
    translateService.get('dependencies.drupal-uploader.download'),
    translateService.get('dependencies.drupal-uploader.mime'),
    translateService.get('dependencies.drupal-uploader.modification-date'),
    translateService.get('dependencies.drupal-uploader.upload'),
    translateService.get('dependencies.drupal-uploader.upload-help'),
    translateService.get('dependencies.drupal-uploader.weight')
  ]).pipe(
    map(([
           browseFile,
           browseFiles,
           cancel,
           download,
           mimeType,
           modificationDate,
           upload,
           uploaderHelp,
           weight
         ]) => {
      return {
        browseFile,
        browseFiles,
        cancel,
        download,
        mimeType,
        modificationDate,
        upload,
        uploaderHelp,
        weight
      };
    })
  );
}

export function materialSchemaFormInterfaceTermsFactory(
  translateService: TranslateService
): Observable<MaterialSchemaFormInterfaceTerms> {
  return combineLatest([
    translateService.get('dependencies.material-schema-form.add'),
    translateService.get('dependencies.material-schema-form.import-box-file'),
    translateService.get('dependencies.material-schema-form.import-box-folder'),
    translateService.get('dependencies.material-schema-form.import-you-tube'),
    translateService.get('dependencies.material-schema-form.remove'),
    translateService.get('dependencies.material-schema-form.upload-files')
  ]).pipe(
    map(([
           add,
           importBoxFiles,
           importBoxFolders,
           importYouTube,
           remove,
           uploadFiles
         ]) => {
      return {
        add,
        importBoxFiles,
        importBoxFolders,
        importYouTube,
        remove,
        uploadFiles
      };
    })
  );
}
