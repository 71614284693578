<ng-container *ngIf="tags && tags.length > 0">

  <h3 [innerHTML]="'COMMON.KEYWORDS'|translate"></h3>

  <mat-chip-list>

    <ng-template ngFor [ngForOf]="tags" let-freeTag>

      <ng-container *ngIf="freeTag as tag">
        <a class="cursor-pointer" mat-chip [routerLink]="'/search'"
           [queryParams]="{ text : 'name'|getDenormalisedValue:tag }">{{'name'|getDenormalisedValue:tag}}</a>
      </ng-container>

    </ng-template>

  </mat-chip-list>

</ng-container>

