import {Action} from '@ngrx/store';
import {PathInfos} from '../../models/path-infos.model';

export enum PathActionTypes {
  HydratePathInfosCollection = '[Path] Hydrate Path Infos Collection',
  SetPathInfos = '[Path] Set Path Infos',
  LoadAndSetPathInfos = '[Path] Load and Set Path Infos'
}


export class SetPathInfos implements Action {
  readonly type = PathActionTypes.SetPathInfos;

  constructor(public pathInfos: PathInfos) {

  }
}

export class HydratePathInfosCollection implements Action {
  readonly type = PathActionTypes.HydratePathInfosCollection;

  constructor(public pathInfosCollection: PathInfos[]) {

  }
}

export class LoadAndSetPathInfos implements Action {
  readonly type = PathActionTypes.LoadAndSetPathInfos;

  constructor(public url: string) {

  }
}


export type PathActionsUnion = SetPathInfos | LoadAndSetPathInfos | HydratePathInfosCollection;
