<div class="d-flex flex-fill px-sm-4">

  <ng-container *ngrxLet="loadingVotes$; let loadingVotes">
    <ng-container *ngIf="!loadingVotes">
      <div class="list-group flex-fill">

        <ng-container *ngIf="(pollService.waitingForVotesContents$|async) as nodesForVote">

          <ng-container *ngIf="nodesForVote.length > 0">

            <ng-template
              [ngTemplateOutlet]="listTemplate"
              [ngTemplateOutletContext]="{data: nodesForVote, title:'POLL.WAITING_YOUR_VOTE'|translate, voted: false}">
            </ng-template>

          </ng-container>

        </ng-container>

        <ng-container *ngIf="(pollService.votedContents$|async) as nodesVoted">

          <ng-container *ngIf="nodesVoted.length > 0">

            <ng-template
              [ngTemplateOutlet]="listTemplate"
              [ngTemplateOutletContext]="{data: nodesVoted, title:'POLL.WAITING_RESULT'|translate, voted: true}">
            </ng-template>

          </ng-container>

        </ng-container>

      </div>
    </ng-container>
    <ng-container *ngIf="loadingVotes">
      <mat-progress-spinner [diameter]="30" [mode]="'indeterminate'"></mat-progress-spinner>
    </ng-container>
  </ng-container>

</div>


<ng-template #innoListItemTemplate let-data="data" let-voted="voted">

  <a class="d-flex" [routerLink]="[data.type, data.id]" routerLinkActive #rla="routerLinkActive">

    <ng-template
      [ngTemplateOutlet]="templatesService.getTemplate('listSmallRenderer')"
      [ngTemplateOutletContext]="{
      data: (data|resourceToInnovationIndexItem:'drupal-'+(languageSwitcherService.currentLanguage$|async))|async,
      pollInfos: {
      title:voted?
      (('VOTE.VOTED'|translate)|uppercase):
      ((pollService.workflowScheduledTransitions$|async) && (pollService.workflowScheduledTransitions$|async)[data.id] ? ( 'VOTE.REMAINING_DAYS'|translate:{value:((pollService.workflowScheduledTransitions$|async)[data.id]|amFromUtc|amDifference: today :'days' : true)|ceil}) : '--')},
      selected: rla.isActive}">
    </ng-template>

  </a>

</ng-template>

<ng-template #listTemplate let-data="data" let-title="title" let-voted="voted">

  <div class="mb-4">
    <h3 [innerHTML]="title" class="text-uppercase mb-2 text-center"></h3>

    <ul class="list-style-type-none p-0 m-0 small-side-list d-flex flex-row flex-sm-column flex-wrap">
      <ng-template ngFor [ngForOf]="data" let-node>

        <li class="mb-2 d-flex d-sm-block poll-item">
          <ng-template
            [ngTemplateOutlet]="innoListItemTemplate"
            [ngTemplateOutletContext]="{data: node, voted: voted}">
          </ng-template>
        </li>

      </ng-template>
    </ul>
  </div>

</ng-template>
