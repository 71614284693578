import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class IdTokenInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private oauthService: OAuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url === this.oauthService.tokenEndpoint) {
      const headers = request.headers.delete('authorization');
      return next.handle(request.clone({headers})).pipe(
        map(event => {
          if (event instanceof HttpResponse && !!event.body.access_token && !event.body.id_token) {
            // console.log('intercept', event);
            event.body.id_token = event.body.access_token;
            return event;
          }
          return event;
        })
      );
    } else {
      return next.handle(request);
    }

  }
}
