import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DrupalFormIoService} from '@madeinlune/ngx-drupal-schemata';
import {FileActions} from '@madeinlune/ngx-material-schema-form';
import {NodeFormPassport} from '../../models/node-form.passport';
import {map, ReplaySubject} from 'rxjs';
import {distinctUntilKeyChanged, filter, take, takeUntil} from 'rxjs/operators';
import {NgrxJsonApiService} from 'ngrx-json-api';
import {JsonApiError} from '../../models/json-api-error';
import {NotificationsService} from '../../services/notifications.service';
import {NodeFormService} from './node-form.service';
import {MyContributionsFormService} from '../../my-contributions/my-contributions/my-contributions-form.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-node-form',
  templateUrl: './node-form.component.html',
  styleUrls: ['./node-form.component.scss'],
  providers: [DrupalFormIoService, NodeFormService]
})
export class NodeFormComponent implements OnInit, AfterViewInit, OnDestroy {

  private _isFormValid: boolean;

  get isFormValid(): boolean {
    return this._isFormValid;
  }

  set isFormValid(value: boolean) {
    this._isFormValid = value;
    this.nodeFormService.canSave = this._isFormValid === true;
    this.formValid.emit(this._isFormValid === true);
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  passport$: ReplaySubject<NodeFormPassport> = new ReplaySubject<NodeFormPassport>(1);

  _passport: NodeFormPassport;
  @Input()
  set passport(value: NodeFormPassport) {
    this._passport = value;
    this.passport$.next(value);
  }

  @Output()
  formValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  formActions: any;

  model$ = this.drupalFormIoService.model$.pipe(
    map(model => {
      if (model) {
        const clonedModel = {...model};
        if (clonedModel.field_launch_date?.indexOf('+') > 0) {
          clonedModel.field_launch_date = clonedModel.field_launch_date.split('+').shift();
        }
        return clonedModel;
      }
      return model;
    })
  );

  constructor(
    public drupalFormIoService: DrupalFormIoService,
    private ngrxJsonApiService: NgrxJsonApiService,
    private notificationService: NotificationsService,
    public nodeFormService: NodeFormService,
    public myContributionsFormService: MyContributionsFormService
  ) {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.drupalFormIoService.destroy();
  }

  ngOnInit() {

    this.formActions = {
      [FileActions.error]: (property, error) => {
        const jsonApiError: JsonApiError = error as JsonApiError;
        if (jsonApiError) {
          let messages: string[];
          if (jsonApiError.errors) {
            messages = jsonApiError.errors.map(jsonApiErrorDetail => jsonApiErrorDetail.detail);
          }
          this.notificationService.showServerError(422, messages);
        }
      }
    };

    this.myContributionsFormService.saveEmitter
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.save();
      });

  }

  ngAfterViewInit(): void {
    this.passport$
      .pipe(
        takeUntil(this.destroyed$),
        filter(passport => !!passport),
        distinctUntilKeyChanged('zone')
      )
      .subscribe(
        passport => {
          this.drupalFormIoService.zone = passport.zone;
          this.drupalFormIoService.resourceIdentifier = passport.resourceIdentifier;
        }
      );
  }

  onFormError(errorEvent: any) {
  }

  onFormChange(event) {
    const formValue = {...event.value};
    if (formValue.field_launch_date && formValue.field_launch_date.indexOf('+00:00') === -1) {
      formValue.field_launch_date = `${formValue.field_launch_date}+00:00`;
    }
    this.drupalFormIoService.setValue(formValue);
  }

  save() {
    if (this.isFormValid) {
      this.passport$.pipe(take(1)).subscribe(passport => {
        this.ngrxJsonApiService.getZone(passport.zone).apply();
      });
    }
  }

}
