import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {MaterialModule} from './material.module';
import {NgrxJsonApiModule} from 'ngrx-json-api';
import {SchemaFormModule} from 'ngx-schema-form';
import {PipesModule} from './pipes/pipes.module';
import {DrupalModule} from '@ngx-mil-drupal/drupal.module';
import {NgrxJsonApiQueries} from './ngrx-json-api/ngrx-json-queries';
import {AppRoutes} from './routes/routes';
import {ReactiveFormsModule} from '@angular/forms';
import {NgObjectPipesModule} from 'ngx-pipes';
import {ClampyModule} from '@clampy-js/ngx-clampy';

@NgModule({
  exports: [
    ClampyModule,
    DrupalModule,
    TranslateModule,
    MaterialModule,
    NgrxJsonApiModule,
    ReactiveFormsModule,
    RouterModule,
    SchemaFormModule,
    PipesModule,
    NgObjectPipesModule,
  ],
  imports: [
    CommonModule,
    ClampyModule,
    DrupalModule.forRoot({queries: NgrxJsonApiQueries.nodeQueries, routes: AppRoutes.routeMap}),
    PipesModule
  ]
})
export class SharedModule {
}
