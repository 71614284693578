import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'dynamicQueryParams'
})
@Injectable()
export class DynamicQueryParamsPipe implements PipeTransform {

  constructor(
    private activatedRoute: ActivatedRoute
  ) {

  }

  transform(key: number, filterName: string, isActive: boolean): Observable<any> {
    return of({[filterName]: key});
    /*return this.activatedRoute.queryParamMap.pipe(
      map((paramMap) => paramMap.getAll(filterName)),
      map(ids => {
        const selectedId: any = ids.filter(id => parseInt(id, 100) === key).shift();
        return selectedId ? {[filterName]: null} : {[filterName]: key};
      })
    );*/
  }

}
