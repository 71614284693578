import {Pipe, PipeTransform} from '@angular/core';
import {Resource} from 'ngrx-json-api';

@Pipe({
  name: 'facetLabelByTid'
})
export class FacetLabelByTid implements PipeTransform {

  transform(tid: string, terms: Resource[]): string {
    const selectedTerm: Resource = terms.filter(term => term.attributes.drupal_internal__tid === parseInt(tid, 10)).shift();
    if (selectedTerm) {
      return selectedTerm.attributes.name;
    } else {
      return tid;
    }
  }

}
