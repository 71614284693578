import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {DrupalFile} from '@madeinlune/ngx-drupal-uploader';
import {MaterialWidgetRegistry} from '../../material-widgets-registry';
import {FieldSet, FormAutocompleteParams, MilSchema} from '@madeinlune/ngx-drupal-schemata';
import {Templates} from '../../components/templates';

@Pipe({
  name: 'innovationSchema'
})
@Injectable()
export class InnovationSchemaPipe implements PipeTransform {

  constructor() {
  }

  transform(schema: any): any {

    const properties: any = schema.properties;
    delete properties.field_voting;
    // delete properties.field_innovation_workflow;
    delete properties.field_innovation_id;

    delete properties.field_voting_result;

    delete properties.field_media_transcoding_status;

    delete properties.innovation_voting_result;

    if (properties.field_innovation_description) {
      if (properties.field_innovation_description) {
        properties.field_innovation_description.properties.format.default = 'basic_html';
        properties.field_innovation_description.properties.value.maxLength = 5000;
        delete properties.field_innovation_description.properties.value.description;
      }
    }

    if (properties.field_innovation_why) {
      if (properties.field_innovation_why) {
        properties.field_innovation_why.properties.format.default = 'basic_html';
        properties.field_innovation_why.properties.value.maxLength = 5000;
        delete properties.field_innovation_why.properties.value.description;
      }
    }

    if (properties.published_at) {
      delete properties.published_at;
    }

    if (properties.field_inspiration_reverse_entity) {
      delete properties.field_inspiration_reverse_entity;
    }

    const contentType: string = NgrxJsonApiDefinitions.innovation.type.split('--')[1];
    if (properties.field_media_images) {
      properties.field_media_images.meta = {
        ...properties.field_media_images.meta,
        fileType: DrupalFile.image,
        needMedia: true,
        maxUpload: 10,
        uploadPath: '/media/image/field_media_image'
      };
    }

    if (properties.field_media_videos) {

      properties.field_media_videos.meta = {
        ...properties.field_media_videos.meta,
        fileType: 'video',
        needMedia: true,
        maxUpload: 5,
        uploadPath: '/media/video/field_media_video_file'
      };
    }

    if (properties.field_contact_email) {
      properties.field_contact_email.maxLength = parseInt(properties.field_contact_email.maxLength, 10);
    }

    if (properties.field_free_tagging) {
      const freeTaggingProperty: MilSchema = properties.field_free_tagging;
      // freeTaggingProperty.searchQuery = JSON.parse(JSON.stringify(NgrxJsonApiQueries.taxonomyTermSearchQuery));
      // freeTaggingProperty.searchQuery.type = NgrxJsonApiDefinitions.freeTagging.type;
      freeTaggingProperty.widget = MaterialWidgetRegistry.taxonomyTermAutoCompleteReference;
      freeTaggingProperty.meta = {
        ...freeTaggingProperty.meta,
        title: 'MY_CONTRIBUTIONS.FREE_TAG_CREATE.TITLE',
        subTitle: 'MY_CONTRIBUTIONS.FREE_TAG_CREATE.SUBTITLE',
        loading: 'MY_CONTRIBUTIONS.FREE_TAG_CREATE.LOADING',
        autoLoad: false
      };
      const formAutoCompleteParams: FormAutocompleteParams = {
        bundle: NgrxJsonApiDefinitions.ctInnovation,
        entity_type: 'node',
        field_name: 'field_free_tagging'
      };
      freeTaggingProperty.meta.formAutoCompleteParams = formAutoCompleteParams;
    }

    if (properties.field_human_translation_review) {
      delete properties.field_human_translation_review;
    }

    if (properties.field_innovation_workflow) {
      properties.field_innovation_workflow.widget = MaterialWidgetRegistry.select;
      properties.field_innovation_workflow.data = {
        source: 'schema_enum',
        translatePath: '',
        multipleKey: 'allOf'
      };
    }

    if (properties.metatag) {
      delete properties.metatag;
      if (schema.required.indexOf('metatag') > -1) {
        const index = schema.required.indexOf('metatag');
        schema.required.splice(index, 1);
      }
    }

    if (properties.field_innovation_category) {
      properties.field_innovation_category.widget = MaterialWidgetRegistry.taxonomyTermReference;
      properties.field_innovation_category.required = ['data'];
    }

    if (properties.field_innovation_status) {
      properties.field_innovation_status.widget = MaterialWidgetRegistry.taxonomyTermReference;
    }

    delete properties.ds_switch;

    delete properties.field_node_thumbnail;

    // delete properties.field_media_videos;

    const fieldSets: FieldSet[] =
      [
        {
          id: 'main',
          name: '',
          description: '',
          title: '1. Infos',
          className: 'root-fieldset',
          fields: [
            'title',
            'id',
            'type',
            'field_free_tagging',
            'field_innovation_workflow',
            'field_innovation_description',
            'field_innovation_why',
            'field_intellectual_property',
            'field_supplier',
            'field_innovation_category',
            'field_innovation_status',
            'field_media_videos'
          ],
          template: Templates.innovationForm
        },
        {
          id: 'media',
          name: '',
          description: '',
          className: 'root-fieldset',
          title: '2. Media',
          fields: [
            'field_media_images'
          ],
          template: Templates.newsMediaForm
        },
        {
          id: 'preview',
          name: '',
          description: '',
          className: 'root-fieldset',
          title: 'MY_CONTRIBUTIONS.PREVIEW_AND_SUBMIT',
          fields: [],
          template: Templates.previewTemplate
        }
      ];

    schema.fieldsets = fieldSets;
    schema.required = [...schema.required, 'field_innovation_category'].filter(fieldName => fieldName !== 'innovation_voting_result');
    schema.widget = MaterialWidgetRegistry.node;

    return schema;
  }
}
