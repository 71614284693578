import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromPath from './path.reducers';
import {DrupalPathState} from './path.reducers';
import * as fromMenu from './menu.reducers';
import {DrupalMenusState} from '@ngx-mil-drupal/store/reducers/menu.reducers';


export interface DrupalState {
  path: DrupalPathState;
  menus: DrupalMenusState;
}

export const drupalReducers: ActionReducerMap<DrupalState> = {
  path: fromPath.pathReducer,
  menus: fromMenu.menusReducer
};

export const getDrupalState = createFeatureSelector<DrupalState>('drupal');


export const getDrupalMenusState = createSelector(getDrupalState, (state: DrupalState) => state.menus);

export const getDrupaPathState = createSelector(getDrupalState, (state: DrupalState) => state.path);

export const getMenuLoading = (menuId: string, language: string) => createSelector(
  getDrupalMenusState,
  (state: DrupalMenusState) => {
    return state[language] && state[language][menuId] ? state[language][menuId].loading : null;
  }
);

export const getMenuLoaded = (menuId: string, language: string) => createSelector(
  getDrupalMenusState,
  (state: DrupalMenusState) => {
    return state[language] && state[language][menuId] ? state[language][menuId].loaded : null;
  }
);

export const getMenu = (menuId: string, language: string) => createSelector(
  getDrupalMenusState,
  (state: DrupalMenusState) => {
    return state[language] && state[language][menuId] ? state[language][menuId].menuItems : null;
  }
);

export const getPathInfosByPath = (path: string) => createSelector(
  getDrupaPathState,
  (state: DrupalPathState) => {
    return state && state.entities && state.entities[path];
  }
);
