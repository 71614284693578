import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {NgrxJsonApiService, Resource, StoreResource} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, mergeMap, shareReplay, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {Store} from '@ngrx/store';
import {TemplatesService} from '../../templates.service';
import {Templates} from '../../components/templates';
import {OpenWindow} from '../../store/actions/app-core.actions';
import {MatInput} from '@angular/material/input';
import {AddToShareBox} from '../../store/actions/user.actions';
import {CartService} from '../../services/cart.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CURRENT_LANGUAGE} from '../../providers/app-core.providers';
import {TranslateService} from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-inspiration',
  templateUrl: './inspiration.component.html',
  styleUrls: ['./inspiration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InspirationComponent implements OnInit, AfterViewInit, OnDestroy {

  inspiration$: Observable<StoreResource> = this.activatedRoute.params.pipe(
    distinctUntilKeyChanged('inspirationId'),
    map(params => {
      return params.inspirationId;
    }),
    switchMap(inspirationId => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectStoreResource({id: inspirationId, type: NgrxJsonApiDefinitions.inspiration.type})
        .pipe(
          filter(inspiration => {
            return !!inspiration;
          }),
          distinctUntilChanged((oldValue, newValue) => {
            return JSON.stringify(oldValue) === JSON.stringify(newValue);
          })
        );
    }),
    shareReplay(1)
  );

  inspiratingContents$: Observable<Resource[]> = this.inspiration$.pipe(
    switchMap(inspiration => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectStoreResources(inspiration.relationships.field_innovation.data)
        .pipe(
          map(storeResources => {
              return storeResources.filter(storeResource => {
                return !!storeResource;
              });
            }
          ),
          distinctUntilChanged((oldValue, newValue) => {
            return JSON.stringify(oldValue.map(resource => resource.id)) === JSON.stringify(newValue.map(resource => resource.id));
          })
        );
    }),
    shareReplay(1)
  );

  @ViewChild('inspirationsActionsTemplate', /* TODO: check static flag */ {static: true})
  inspirationsActionsTemplate: TemplateRef<any>;

  @ViewChild('renameInspirationActionsTemplate', /* TODO: check static flag */ {static: true})
  renameInspirationActionsTemplate: TemplateRef<any>;

  @ViewChild('renameInspirationContentTemplate', /* TODO: check static flag */ {static: true})
  renameInspirationContentTemplate: TemplateRef<any>;

  @ViewChild('inspirationNameInput', /* TODO: check static flag */ {read: MatInput})
  inspirationNameInput: MatInput;

  inspirationTitleValue: string;

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    public templatesService: TemplatesService,
    private cdr: ChangeDetectorRef,
    private cartService: CartService,
    private translateService: TranslateService,
    @Inject(CURRENT_LANGUAGE) public readonly currentLanguage$: Observable<string>
  ) {

  }

  ngOnDestroy(): void {
  }

  ngOnInit() {

    combineLatest([this.inspiration$, this.currentLanguage$]).pipe(
      untilDestroyed(this),
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      })
    ).subscribe(([inspiration, currentLanguage]) => {
      const queryId: string = 'inspiration-full-' + inspiration.id;
      this.inspirationTitleValue = inspiration.attributes.title;
      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards).putQuery({
        query: {
          ...NgrxJsonApiQueries.queryInspirationNodeInclInnovations,
          queryId: queryId,
          id: inspiration.id
        }
      });
    });

    this.cdr.detectChanges();

  }

  onInspirationNameChange(event) {
  }

  editInspiration() {
    this.store.dispatch(new OpenWindow(
      this.translateService.instant('MY_INSPIRATION.EDIT'), {
        contentTemplate: Templates.renameInspirationContent,
        actionsTemplate: Templates.renameInspirationActions
      }));
  }

  saveInnovation() {
    if (this.inspirationTitleValue) {
      this.inspiration$.pipe(take(1)).subscribe(inspiration => {
        const patchedInspiration: Resource = {
          id: inspiration.id,
          type: inspiration.type,
          attributes: {
            title: this.inspirationTitleValue
          }
        };
        this.ngrxJsonApiService
          .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
          .patchResource({resource: patchedInspiration, toRemote: true});
      });
    }
  }

  addToShare(inspiration: Resource) {
    this.store.dispatch(new AddToShareBox(inspiration?.relationships?.field_innovation?.data));
    this.cartService.currentCart$.pipe(
      untilDestroyed(this),
      take(1)
    ).subscribe(currentCart => {
      if (currentCart && !currentCart.attributes?.field_cart_subject) {
        const updatedCart = {
          id: currentCart.id,
          type: currentCart.type,
          attributes: {
            field_cart_subject: 'Share Inspiration : ' + inspiration.attributes.title
          }

        };
        this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneShareBox).patchResource({resource: updatedCart, toRemote: true});
      }
    });
  }

  deleteInnovation() {
    this.inspiration$.pipe(take(1)).subscribe(inspiration => {
      this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .deleteResource({resourceId: inspiration, toRemote: true});
    });
    this.router.navigateByUrl('/my-inspirations');
  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.inspirationsActions, this.inspirationsActionsTemplate);
    this.templatesService.addTemplate(Templates.renameInspirationActions, this.renameInspirationActionsTemplate);
    this.templatesService.addTemplate(Templates.renameInspirationContent, this.renameInspirationContentTemplate);
  }

}
