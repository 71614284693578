import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {LoadMenu, LoadMenuSuccess, MenuActionTypes} from '@ngx-mil-drupal/store/actions/menu.actions';
import {MenuService} from '@ngx-mil-drupal/menu.service';
import {DrupalMenuItem} from '@ngx-mil-drupal/models/menu.model';

@Injectable()
export class MenuEffects {


  
  loadMenu$ = createEffect(() => this.actions$.pipe(
    ofType(MenuActionTypes.LoadMenu),
    mergeMap((action: LoadMenu) => {
      return this.menuService.resolve(action.menuId, action.language).pipe(
        map(result => {
          return new LoadMenuSuccess(result as DrupalMenuItem[], action.menuId, action.language);
        })
      );
    })
  ));


  constructor(
    private actions$: Actions,
    private menuService: MenuService
  ) {
  }

}
