import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import {SearchFilterService} from '../search-select-filter/search-filter.service';
import {FacetModel} from '../models/facet.model';
import {UntypedFormControl} from '@angular/forms';
import {distinctUntilChanged, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ROUTE_QUERY_PARAMS} from '../../providers/route.providers';

@UntilDestroy()
@Component({
  selector: 'app-search-check-box-filter',
  templateUrl: './search-check-box-filter.component.html',
  styleUrls: ['./search-check-box-filter.component.scss'],
  providers: [SearchFilterService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchCheckBoxFilterComponent implements OnInit {

  @Input()
  facet: FacetModel;

  @Input()
  label: string;

  formControl: UntypedFormControl;

  queryParams$: Observable<number[]>;

  constructor(
    private searchFilterService: SearchFilterService,
    @Inject(ROUTE_QUERY_PARAMS) public readonly routeQueryParams$: Observable<any>
  ) {
  }

  ngOnInit(): void {

    this.searchFilterService.facet = this.facet;

    this.queryParams$ = this.routeQueryParams$.pipe(map(routeQueryParams => {
      if (routeQueryParams && routeQueryParams[this.facet.name]) {
        let values: string[];
        if (!Array.isArray(routeQueryParams[this.facet.name])) {
          values = [routeQueryParams[this.facet.name]];
        } else {
          values = routeQueryParams[this.facet.name];
        }
        if (values?.length > 0) {
          return values.map(id => parseInt(id, 10));
        }
      }
      return null;
    }));

    this.formControl = new UntypedFormControl();
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged((oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue)),
      withLatestFrom(this.queryParams$)
    ).subscribe(([formValue, queryParams]) => {
      if (JSON.stringify(formValue) !== JSON.stringify(queryParams)) {
        if (formValue === true) {
          this.searchFilterService.updateMonoFilter(1);
        } else {
          this.searchFilterService.updateMonoFilter(null);
        }
      }
    });

    this.queryParams$.pipe(
      untilDestroyed(this),
      distinctUntilChanged((oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue))
    ).subscribe(params => {
        this.formControl.setValue(params, {
          emitModelToViewChange: true,
          onlySelf: true
        });
      }
    );

  }


}
