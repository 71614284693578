import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, take, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {Route, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {PathInfos, PathInfosMetaTag} from '@ngx-mil-drupal/models/path-infos.model';
import {LoadAndSetPathInfos, PathActionTypes, SetPathInfos} from '@ngx-mil-drupal/store/actions/path.actions';
import {PathService} from '@ngx-mil-drupal/path.service';
import {DrupalState} from '@ngx-mil-drupal/store/reducers';
import {DrupalService} from '@ngx-mil-drupal/drupal.service';
import {RoutesMap} from '@ngx-mil-drupal/models/routes-map.model';

@Injectable()
export class PathEffects {


  
  setPathInfos$ = createEffect(() => this.actions$.pipe(
    ofType(PathActionTypes.SetPathInfos),
    map((action: SetPathInfos) => action.pathInfos),
    tap((pathInfos: PathInfos) => {
        //
        const metatags: { [tagId: string]: PathInfosMetaTag } = pathInfos.metatags;
        for (const name in metatags) {
          if (metatags[name].tag === 'meta') {
            const metaDefinition: MetaDefinition = {};
            const metaTagAttributes: any = metatags[name].attributes;
            Object.keys(metaTagAttributes).forEach(attributeName => {
              metaDefinition[attributeName] = metaTagAttributes[attributeName];
            });
            if (metatags[name].attributes.name) {
              this.meta.removeTag('name="' + metatags[name].attributes.name + '"');
            } else if (metatags[name].attributes.property) {
              this.meta.removeTag('property="' + metatags[name].attributes.property + '"');
            } else if (metatags[name].attributes.itemprop) {
              this.meta.removeTag('itemprop="' + metatags[name].attributes.itemprop + '"');
            }
            this.meta.addTag(metaDefinition);
          }
        }
      }
    )
  ), {dispatch: false});

  
  LoadAndSetPathInfos$ = createEffect(() => this.actions$.pipe(
    ofType(PathActionTypes.LoadAndSetPathInfos),
    map((action: LoadAndSetPathInfos) => action.url),
    tap((url: string) => {
      }
    )
  ), {dispatch: false});


  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
    private pathService: PathService,
    private router: Router,
    private store: Store<DrupalState>,
    private drupalService: DrupalService
  ) {
  }

  getPathInfos(url: string) {
    return this.pathService.resolve(url)
      .pipe(
        map((pathInfos: PathInfos) => {
          this.managePathInfos(url, pathInfos);
        }),
        take(1)
      );
  }

  managePathInfos(url: string, pathInfos: PathInfos) {

    const routes: RoutesMap = this.drupalService.config.routes;
    const newRoute: Route = {...routes[pathInfos.entity.bundle]};
    newRoute.path = url;
    newRoute.data = {
      ...newRoute.data,
      [pathInfos.entity.bundle + 'Id']: pathInfos.entity.id
    };
    /*if (this.drupalService.routeExists(pathInfos.entity.bundle)) {
      this.router.config.unshift(
        newRoute
      );
    }*/
    this.store.dispatch(new SetPathInfos(pathInfos));
    // this.router.navigateByUrl(url);

  }
}
