import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PathInfos} from './models/path-infos.model';
import {AppConfigService} from '@config/app-config.service';
import {Observable, of} from 'rxjs';
import {catchError, map, withLatestFrom} from 'rxjs/operators';
import {CURRENT_LANGUAGE} from '../../app/providers/app-core.providers';

@Injectable()
export class PathService {

  constructor(
    private appConfig: AppConfigService,
    private httpClient: HttpClient,
    @Inject(CURRENT_LANGUAGE) private readonly currentLanguage$: Observable<string>
  ) {
  }

  resolve(path): Observable<PathInfos> {
    const drupalPath = path === '' ? '/' : path;
    const apiURL = [(this.appConfig.getConfig().backendUrl ? this.appConfig.getConfig().backendUrl : ''), 'api/path'].join('/');

    // https://stackoverflow.com/questions/46019771/catching-errors-in-angular-httpclient
    // TODO catch errors with Interceptors https://angular.io/guide/http#intercepting-requests-and-responses

    return this.httpClient.get<PathInfos>(apiURL, {
      params: new HttpParams()
        .set('path', drupalPath),
      headers: new HttpHeaders({'Accept': 'application/json'})
    }).pipe(
      catchError((error: any) => {
        const pathInfos: PathInfos = {
          error: error
        };
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption

        // Let the app keep running by returning an empty result.
        return of(
          pathInfos
        ).pipe(
          withLatestFrom(this.currentLanguage$),
          map(([pInfos, currentLanguage]) => {
            // console.log('currentLanguage', currentLanguage);
            pInfos.language = currentLanguage;
            return pInfos;
          })
        );
      })
    );
  }

}
