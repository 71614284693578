import {Component, OnInit, TemplateRef} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {DrupalPathGuard} from '@ngx-mil-drupal/drupal-path-guard';
import {NgrxJsonApiService, Resource} from 'ngrx-json-api';
import {ActivatedRoute} from '@angular/router';
import {TemplatesService} from '../../templates.service';
import {LanguageSwitcherService} from '../../services/language-switcher.service';

@Component({
  selector: 'app-routed-template',
  templateUrl: './routed-template.component.html',
  styleUrls: ['./routed-template.component.scss']
})
export class RoutedTemplateComponent implements OnInit {

  node$: Observable<Resource>;

  template$: Observable<TemplateRef<any>> = this.route.data.pipe(
    map(data => data.template ? data.template : 'defaultNode'),
    map(templateId => this.templatesService.getTemplate(templateId))
  );

  constructor(
    private route: ActivatedRoute,
    private ngrxJsonApiService: NgrxJsonApiService,
    public templatesService: TemplatesService,
    public languageSwitcherService: LanguageSwitcherService
  ) {
  }

  ngOnInit() {

    const resourceIdentifier$: Observable<{ id: string, type: string, zone: string }> = combineLatest(
      [
        this.route.data,
        this.route.params,
        this.languageSwitcherService.currentLanguage$
      ]
    )
      .pipe(
        map(([routeData, routeParams, currentLanguage]) => {
          if (routeData.staticRoute) {
            return {type: routeParams.type, id: routeParams.id, zone: routeData.zone};
          } else {
            return {...routeData.resourceIdentifier, zone: DrupalPathGuard.DRUPAL_ZONE + '-' + currentLanguage};
          }
        }));

    this.node$ = resourceIdentifier$.pipe(
      switchMap(resourceIdentifier => {
        return this.ngrxJsonApiService
          .getZone(resourceIdentifier.zone)
          .selectStoreResource(resourceIdentifier)
          .pipe(
            filter(rIdentifier => !!rIdentifier)
          ) as unknown as Observable<Resource>;
      })
    );

  }

}
