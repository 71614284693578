<ng-template
  #searchFiltersTemplate
  let-facets="facets"
  let-fieldTypeQueries="fieldTypeQueries"
  let-fromSideMenu="fromSideMenu"
  let-layoutClasses="layoutClasses">

  <div
    class="d-flex my-2 my-sm-0 mx-3 mx-sm-0 search-filters-group justify-content-between justify-content-sm-start align-items-baseline"
    [ngClass]="layoutClasses">

    <ng-container *ngIf="(responsiveService.isXs$|async) && !fromSideMenu">

      <ng-template [ngTemplateOutlet]="('breadcrumbs'|templates)|async"></ng-template>

      <button
        (click)="openFilters()"
        mat-flat-button
        color="accent">
        {{'SEARCH.FILTER.FILTERS'|translate}}
      </button>

    </ng-container>

    <ng-container *ngIf="!(responsiveService.isXs$|async) || fromSideMenu">

      <ng-template
        [ngTemplateOutlet]="defaultSearchFiltersTemplate"
        [ngTemplateOutletContext]="{
        facets: facets,
        fieldTypeQueries:
        fieldTypeQueries,
        layoutClasses:layoutClasses
      }">
      </ng-template>

    </ng-container>

  </div>

</ng-template>

<ng-template
  #defaultSearchFiltersTemplate
  let-facets="facets"
  let-fieldTypeQueries="fieldTypeQueries">

  <ng-template ngFor [ngForOf]="facets" let-facet>

    <ng-container *ngIf="fieldTypeQueries[facet.name] && fieldTypeQueries[facet.name].type as type">

      <ng-container *ngIf="fieldTypeQueries[facet.name].template === 'select'">

        <ng-template
          [ngTemplateOutlet]="searchSelectFilterTemplate"
          [ngTemplateOutletContext]="{
          facet: facet,
          terms: getTerms(type)|async,
          label: (fieldTypeQueries[facet.name].label)|translate
          }">
        </ng-template>

      </ng-container>

      <ng-container *ngIf="fieldTypeQueries[facet.name].template === 'checkbox'">

        <app-search-check-box-filter
          [facet]="facet"
          [label]="(fieldTypeQueries[facet.name].label)|translate">
        </app-search-check-box-filter>

      </ng-container>

    </ng-container>

  </ng-template>

</ng-template>

<ng-template
  #searchSelectFilterTemplate
  let-label="label"
  let-terms="terms"
  let-facet="facet">

  <app-search-select-filter
    [class]="facet?.name"
    [terms]="terms"
    [facet]="facet"
    [label]="label">
  </app-search-select-filter>

</ng-template>

