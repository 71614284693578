import {Injectable} from '@angular/core';
import {AppConfigLoader} from './app-config-loader';
import {Observable, ReplaySubject} from 'rxjs';

export class AppConfig {
  backendUrl: string;
  googleAnalyticsAccount: string;
  jsonapiUrl: string;
  jsonApiOperationsUrl: string;
  graphQlUrl: string;
  schemataUrl: string;
  jsonrpc: string;
  oauth: AppOauthConfig;
}

export class AppOauthConfig {
  clientId: string;
  issuer: string;
  tokenUrl: string;
  userInfoUrl: string;
  authorizationUrl: string;
}

@Injectable()
export class AppConfigService {

  private appConfig;

  public appConfig$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private loader: AppConfigLoader) {
  }

  loadAppConfig() {
    return this.loader.load().then(data => {
      this.appConfig = data;
      this.appConfig$.next(this.appConfig);
    }, error => {
      console.error('load config error', error);
    });
  }

  loadAppAllConfig(): Observable<any> {
    return this.loader.loadAll();
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }
}
