import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NodeFormService {

  canSave: boolean;

  constructor() {
  }

}
