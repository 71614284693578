import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {
  ManyQueryResult,
  NgrxJsonApiService,
  NgrxJsonApiStoreResources,
  OneQueryResult,
  Query,
  Resource,
  StoreResource
} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../ngrx-json-api/ngrx-json-queries';
import {catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {LanguageSwitcherService} from './language-switcher.service';
import {DrupalPathGuard} from '@ngx-mil-drupal/drupal-path-guard';
import {diff} from 'deep-object-diff';
import {ComponentStore} from '@ngrx/component-store';

interface PollServiceState {
  pollingContentsLoaded: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PollService extends ComponentStore<PollServiceState> {

  loadingVotes$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  refreshVotes$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  languageZone$: Observable<string> = this.languageSwitcherService.currentLanguage$.pipe(
    map(language => {
      return DrupalPathGuard.DRUPAL_ZONE + '-' + language;
    }),
    shareReplay(1)
  );

  pollingContentsLoaded$ = this.select(s => s.pollingContentsLoaded);


  pollingContents$: Observable<Resource[]> = this.languageZone$.pipe(
    switchMap(zone => {
      return this.ngrxJsonApiService
        .getZone(zone)
        .selectManyResults(NgrxJsonApiQueries.queryPolling.queryId)
        .pipe(
          map((manyQueryResult: ManyQueryResult) => {
            if (!!manyQueryResult && manyQueryResult.loading === false && !!manyQueryResult.data) {
              this.patchState({pollingContentsLoaded: true});
              return manyQueryResult.data.filter(storeResource => {
                return !!storeResource;
              });
            }
            return [];
          })
        );
    }),
    distinctUntilChanged((oldValue, newValue) => {
      return Object.keys(diff(oldValue, newValue)).length === 0;
    }),
    catchError((error) => {
      console.error({error});
      return of([]);
    })
  );

  hasContentsInVotesStatus$: Observable<boolean> = this.pollingContentsLoaded$.pipe(
    filter(pollingContentsLoaded => !!pollingContentsLoaded),
    switchMap(pollingContentsLoaded => {
      return this.pollingContents$.pipe(
        map(pollingContentsList => pollingContentsList && pollingContentsList.length > 0),
        catchError((error) => {
          return of(false);
        })
      );
    })
  );

  votes$: Observable<StoreResource[]> = this.refreshVotes$.pipe(
    switchMap(() => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zonePolling)
        .selectStoreResourcesOfType(NgrxJsonApiQueries.queryVoteCollection.type)
        .pipe(
          distinctUntilChanged((oldValue, newValue) => {
            return oldValue && newValue && Object.keys(diff(oldValue.data, newValue.data)).length === 0;
          }),
          map((ngrxJsonApiStoreResources: NgrxJsonApiStoreResources) => {
            const votes: StoreResource[] = Object.keys(ngrxJsonApiStoreResources).map(key => {
              return ngrxJsonApiStoreResources[key];
            });
            return votes.filter(storeResource => {
              return !!storeResource;
            }).filter(vote => vote.relationships.entity_id.data.id !== 'missing');
          }),
          distinctUntilChanged((oldValue, newValue) => {
            return Object.keys(diff(oldValue, newValue)).length === 0;
          }),
          catchError((error) => {
            console.error({error});
            return of([]);
          })
        );
    }),
    shareReplay(1),
    catchError((error) => {
      console.error({error});
      return of([]);
    })
  );

  waitingForVotesContents$: Observable<Resource[]> = combineLatest([this.pollingContents$, this.votes$]).pipe(
    map(([pollingContents, votes]) => {
      const votedInnovationIds: string[] = votes.map(vote => vote.relationships.entity_id.data.id);
      const innovationsWaitingForVote: StoreResource[] = pollingContents.filter(pollingContent => {
        return votedInnovationIds.indexOf(pollingContent.id) === -1;
      });
      return innovationsWaitingForVote;
    }),
    distinctUntilChanged((oldValue, newValue) => {
      return Object.keys(diff(oldValue, newValue)).length === 0;
    }),
    catchError((error) => {
      console.error({error});
      return of([]);
    })
  );

  waitingForVotesCount$: Observable<number> = this.waitingForVotesContents$.pipe(
    map((votes) => {
      return votes.length;
    }),
    distinctUntilChanged()
  );

  votedContents$: Observable<Resource[]> = combineLatest([this.pollingContents$, this.votes$]).pipe(
    map(([pollingContents, votes]) => {
      const votedInnovationIds: string[] = votes.map(vote => vote.relationships.entity_id.data.id);
      const votedInnovations: StoreResource[] = pollingContents.filter(pollingContent => {
        return votedInnovationIds.indexOf(pollingContent.id) > -1;
      });
      return votedInnovations;
    }),
    catchError((error) => {
      console.error({error});
      return of([]);
    })
  );

  workflowScheduledTransitions$: Observable<{ [innovationId: string]: string }> =
    this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zonePolling)
      .selectStoreResourcesOfType(NgrxJsonApiDefinitions.workflowScheduledTransition.type)
      .pipe(
        map((ngrxJsonApiStoreResources: NgrxJsonApiStoreResources) => {
          if (ngrxJsonApiStoreResources) {
            return Object.keys(ngrxJsonApiStoreResources).map(key => {
              return ngrxJsonApiStoreResources[key];
            });
          } else {
            return [];
          }
        }),
        withLatestFrom(
          this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zonePolling)
            .selectStoreResourcesOfType(NgrxJsonApiDefinitions.workflowState.type)
            .pipe(
              map((ngrxJsonApiStoreResources: NgrxJsonApiStoreResources) => {
                if (ngrxJsonApiStoreResources) {
                  return Object.keys(ngrxJsonApiStoreResources).map(key => {
                    return ngrxJsonApiStoreResources[key];
                  }).filter(
                    (workflowState: StoreResource) => {
                      return workflowState.attributes.label === 'Voted';
                    }
                  ).shift();
                } else {
                  return null;
                }
              }),
              catchError(error => throwError(error))
            )
        ),
        map(([workflowTransitions, workflowVotedState]: [StoreResource[], StoreResource]) => {
          const workflowTransitionsMap: { [entityId: string]: string } = {};
          if (workflowTransitions?.length > 0 && !!workflowVotedState) {
            workflowTransitions.forEach((workflowTransition: StoreResource) => {
              if (workflowTransition?.relationships?.to_sid?.data?.id === workflowVotedState.id) {
                if (workflowTransition.relationships.entity_id?.data) {
                  workflowTransitionsMap[workflowTransition.relationships.entity_id.data.id] = workflowTransition.attributes.timestamp as string;
                }

              }
            });
          }
          return workflowTransitionsMap;
        }),
        distinctUntilChanged((oldValue, newValue) => {
          return JSON.stringify(oldValue) === JSON.stringify(newValue);
        })
      );

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    private languageSwitcherService: LanguageSwitcherService
  ) {
    super({pollingContentsLoaded: false});
  }

  init() {

    this.languageZone$.subscribe(zone => {
      this.ngrxJsonApiService
        .getZone(zone)
        .putQuery({query: NgrxJsonApiQueries.queryPolling, fromServer: true});
    });

    this.loadUserVotes();
    this.loadWorkflowScheduledTransitions();

  }

  loadWorkflowScheduledTransitions() {
    this.ngrxJsonApiService
      .getZone(NgrxJsonApiDefinitions.zonePolling)
      .putQuery({query: NgrxJsonApiQueries.queryWorkflowScheduledTransition, fromServer: true});
  }

  loadUserVotes() {
    this.ngrxJsonApiService
      .getZone(NgrxJsonApiDefinitions.zoneUser)
      .selectOneResults('user')
      .pipe(
        filter((oneQueryResult: OneQueryResult) => oneQueryResult && oneQueryResult.loading === false),
        map((oneQueryResult: OneQueryResult) => oneQueryResult.data),
        take(1)
      ).subscribe(userInfos => {
      const voteCollectionQuery: Query = {...NgrxJsonApiQueries.queryVoteCollection};
      voteCollectionQuery.params = {
        ...voteCollectionQuery.params,
        filtering: [
          voteCollectionQuery.params.filtering[0],
          {...voteCollectionQuery.params.filtering[1], value: userInfos.id},
          voteCollectionQuery.params.filtering[2]
        ]
      };
      this.loadingVotes$.next(true);
      this.findUserVotes(voteCollectionQuery).subscribe(() => {
        this.loadingVotes$.next(false);
      });
      /*this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zonePolling)
        .putQuery({query: voteCollectionQuery, fromServer: true});*/
    });
  }

  findUserVotes(query: Query, page: number = 0): Observable<ManyQueryResult> {
    query = {
      ...query,
      queryId: query.queryId + '-' + page,
      params: {
        ...query.params,
        page: {
          limit: 50,
          offset: 50 * page
        }
      }
    };
    return this.ngrxJsonApiService.findMany({
      zone: NgrxJsonApiDefinitions.zonePolling,
      query: query,
      fromServer: true
    }).pipe(
      filter(manyQueryResult => manyQueryResult?.loading === false),
      take(1),
      switchMap(manyQueryResult => {
        this.refreshVotes$.next(true);
        if (!!manyQueryResult.links.next) {
          return this.findUserVotes(manyQueryResult.query, page + 1);
        }
        return of(manyQueryResult);
      })
    );
  }

  isVotedContent$(innovationId: string): Observable<boolean> {
    return this.votedContents$.pipe(
      map(contents => {
        return contents.filter(content => content.id === innovationId).length > 0;
      })
    );
  }
}
