<ng-container *ngIf="render">

  <h1>Icon Big</h1>

  <ng-template
    [ngTemplateOutlet]="bigIconTemplate"
    [ngTemplateOutletContext]="{icon: 'icon-contribution'}">
  </ng-template>

</ng-container>

<ng-template #bigIconTemplate let-icon="icon">

  <mat-icon class="icon big" [svgIcon]="icon"></mat-icon>

</ng-template>
