import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared.module';
import {InspirationRendererComponent} from './inspiration-renderer/inspiration-renderer.component';
import {ComponentsModule} from '../components/components.module';
import {InspirationComponent} from './inspiration/inspiration.component';
import {InspirationsListComponent} from './inspirations-list/inspirations-list.component';
import {InspirationContentListComponent} from './inspiration-content-list/inspiration-content-list.component';

@NgModule({
  declarations: [
    InspirationRendererComponent,
    InspirationComponent,
    InspirationsListComponent,
    InspirationContentListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule
  ]
})
export class MyInspirationsModule {
}
