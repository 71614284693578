// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {getCurrentUrl, getPageTitle, getRouteQueryParams, isStaticRoute, staticRouteTitle} from '../store/reducers';
import {distinctUntilChanged, shareReplay} from 'rxjs/operators';


export const ROUTE_QUERY_PARAMS = new InjectionToken<Observable<any>>(
  'A stream with the current route params'
);

export const ROUTE_URL = new InjectionToken<Observable<string>>(
  'A stream with the current route'
);

export const PAGE_TITLE = new InjectionToken<Observable<string>>(
  'A stream with the current page title'
);

export const IS_STATIC_ROUTE = new InjectionToken<Observable<boolean>>(
  'A stream to know if the route is static or not (Drupal)'
);

export const STATIC_ROUTE_TITLE = new InjectionToken<Observable<string>>(
  'A stream to get the static route title'
);

export const ROUTE_PROVIDERS: Provider[] = [
  {
    provide: ROUTE_QUERY_PARAMS,
    deps: [Store],
    useFactory: routeParamsFactory
  },
  {
    provide: ROUTE_URL,
    deps: [Store],
    useFactory: routeUrlFactory
  },
  {
    provide: PAGE_TITLE,
    deps: [Store],
    useFactory: routePageTitleFactory
  },
  {
    provide: IS_STATIC_ROUTE,
    deps: [Store],
    useFactory: isStaticRouteFactory
  },
  {
    provide: STATIC_ROUTE_TITLE,
    deps: [Store],
    useFactory: staticRouteTitleFactory
  }
];

export function routeParamsFactory(
  store: Store
): Observable<string> {
  return store.pipe(
    select(getRouteQueryParams),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function routeUrlFactory(
  store: Store
): Observable<string> {
  return store.pipe(
    select(getCurrentUrl),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function routePageTitleFactory(
  store: Store
): Observable<string> {
  return store.pipe(
    select(getPageTitle),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function isStaticRouteFactory(
  store: Store
): Observable<boolean> {
  return store.pipe(
    select(isStaticRoute),
    distinctUntilChanged(),
    shareReplay(1)
  );
}

export function staticRouteTitleFactory(
  store: Store
): Observable<string> {
  return store.pipe(
    select(staticRouteTitle),
    distinctUntilChanged(),
    shareReplay(1)
  );
}
