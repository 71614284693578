import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {filter, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {
  AppCoreActionTypes,
  ChangeSyncData,
  ChangeSyncDataRequest,
  ClearLocalStorage,
  ClearLocalStorageRequest,
  HydrateStore,
  LoadFacets,
  LoadNewContents,
  NavigateRandomly,
  OpenAlert,
  OpenTemplateAlert,
  OpenWindow,
  SetAutoPlay,
  SetCurrentLanguage,
  SetDefaultLanguage,
  SetPageTitle,
  SetUnSeenContents,
  UpdateContentCount,
  UpdateKeywords
} from '../actions/app-core.actions';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {TemplatesService} from '../../templates.service';
import {Templates} from '../../components/templates';
import {select, Store} from '@ngrx/store';
import {ClearAllSchemata} from '@madeinlune/ngx-drupal-schemata';
import {DbService} from '../../services/db.service';
import {LoadMenu, LoadMenuSuccess} from '@ngx-mil-drupal/store/actions/menu.actions';
import {PathInfos} from '@ngx-mil-drupal/models/path-infos.model';
import {HydratePathInfosCollection, PathActionTypes, SetPathInfos} from '@ngx-mil-drupal/store/actions/path.actions';
import {NgrxJsonApiService, Query} from 'ngrx-json-api';
import {AuthActionType, AuthenticatedChangeAction} from '../actions/auth.actions';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {Apollo} from 'apollo-angular';
import {AUTO_PLAY, CURRENT_LANGUAGE} from '../../providers/app-core.providers';
import {combineLatest, Observable} from 'rxjs';
import {Condition, ConditionGroupInput} from '../../apollo/models/condition.model';
import {ApolloQueryResult, QueryOptions} from 'apollo-client';
import {ApolloQueries} from '../../apollo/apollo-queries';
import {LatinisePipe} from 'ngx-pipes';
import {ROUTER_NAVIGATED, RouterNavigatedAction} from '@ngrx/router-store';
import {DOCUMENT} from '@angular/common';
import {getAuthenticated} from '../reducers';
import {Title} from '@angular/platform-browser';
import {STATIC_ROUTE_TITLE} from '../../providers/route.providers';

export interface Keyword {

  count: number;
  label: string;
  latinised: string;

}

export const lastConnectionTimestampKey = 'lastConnectionTimestamp';

export const lastInnovationsTimestampKey = 'lastInnovationsTimestamp';
export const lastNewsTimestampKey = 'lastNewsTimestamp';

@Injectable()
export class AppCoreEffects {


  readonly initApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    tap((action) => {
    })
  ), {dispatch: false});


  readonly authenticationChange$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.AUTHENTICATED_CHANGE),
    filter((action: AuthenticatedChangeAction) => action.authenticated),
    take(1),
    tap((action: AuthenticatedChangeAction) => {

      if (action.authenticated) {

        this.store.dispatch(new HydrateStore());
      }

    })
  ), {dispatch: false});


  setPathInfos$ = createEffect(() => this.actions$.pipe(
    ofType(PathActionTypes.SetPathInfos),
    map((action: SetPathInfos) => action.pathInfos),
    tap((pathInfos: PathInfos) => {
        let title: string = pathInfos?.metatags?.title?.attributes?.content;
        if (title.indexOf(' | WIN') > -1) {
          title = title.split(' | WIN')[0];
        }
        this.store.dispatch(new SetPageTitle(title, pathInfos?.entity?.bundle));
      }
    )
  ), {dispatch: false});


  languageChange$ = createEffect(() => combineLatest([this.currentLanguage$, this.store.pipe(select(getAuthenticated))]).pipe(
    filter(([language, authenticated]) => !!language),
    tap(([language, authenticated]) => {
        if (authenticated) {
          this.store.dispatch(new LoadFacets(NgrxJsonApiDefinitions.ctNews));
          this.store.dispatch(new LoadFacets(NgrxJsonApiDefinitions.ctInnovation));

          const lastConnectionInnovationsTimestamp: number = parseInt(localStorage.getItem(lastInnovationsTimestampKey), 10);
          const lastConnectionNewsTimestamp: number = parseInt(localStorage.getItem(lastNewsTimestampKey), 10);

          if (!isNaN(lastConnectionInnovationsTimestamp) && !isNaN(lastConnectionNewsTimestamp)) {

            const appInnovationsLastConnectionTimestamp = Math.round(lastConnectionInnovationsTimestamp / 1000);
            const appNewsLastConnectionTimestamp = Math.round(lastConnectionNewsTimestamp / 1000);

            this.store.dispatch(new LoadNewContents(NgrxJsonApiDefinitions.ctNews, appNewsLastConnectionTimestamp));
            this.store.dispatch(new LoadNewContents(NgrxJsonApiDefinitions.ctInnovation, appInnovationsLastConnectionTimestamp));

          } else {

            localStorage.setItem(lastInnovationsTimestampKey, Date.now().toString());
            localStorage.setItem(lastNewsTimestampKey, Date.now().toString());

          }

          let taxonomyTermsQuery: Query = JSON.parse(JSON.stringify(NgrxJsonApiQueries.taxonomyTermCollectionQuery));
          taxonomyTermsQuery.queryId = NgrxJsonApiDefinitions.newsCategory.type;
          taxonomyTermsQuery.type = NgrxJsonApiDefinitions.newsCategory.type;
          this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneTaxonomies).putQuery({
            query: taxonomyTermsQuery,
            fromServer: true
          });

          taxonomyTermsQuery = JSON.parse(JSON.stringify(NgrxJsonApiQueries.taxonomyTermCollectionQuery));
          taxonomyTermsQuery.queryId = NgrxJsonApiDefinitions.brands.type;
          taxonomyTermsQuery.type = NgrxJsonApiDefinitions.brands.type;
          this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneTaxonomies).putQuery({
            query: taxonomyTermsQuery,
            fromServer: true
          });

          taxonomyTermsQuery = JSON.parse(JSON.stringify(NgrxJsonApiQueries.taxonomyTermCollectionQuery));
          taxonomyTermsQuery.queryId = NgrxJsonApiDefinitions.innovationCategory.type;
          taxonomyTermsQuery.type = NgrxJsonApiDefinitions.innovationCategory.type;
          this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneTaxonomies).putQuery({
            query: taxonomyTermsQuery,
            fromServer: true
          });
        }
      }
    )
  ), {dispatch: false});


  loadFacets$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.LoadFacets),
    withLatestFrom(this.currentLanguage$),
    mergeMap(([action, currentLanguage]: [LoadFacets, string]) => {
        const variables = this.buildSearchVariables(currentLanguage, action.contentType);
        const searchQueryOptions: QueryOptions = {
          query: ApolloQueries.searchInnovation,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            filterConditions: variables.filterConditions,
            facets: variables.facets,
            start: 0,
            end: 0
          }
        };
        return this.apollo.query(searchQueryOptions)
          .pipe(
            filter((apolloQueryResult: ApolloQueryResult<any>) => {
              return !!apolloQueryResult && apolloQueryResult.loading === false && apolloQueryResult?.data?.searchAPISearch;
            }),
            map((apolloQueryResult: ApolloQueryResult<any>) => {
              const searchAPISearch = apolloQueryResult?.data?.searchAPISearch;
              const documents: any[] = searchAPISearch?.documents;
              const resultCount: number = searchAPISearch?.result_count;
              const contentType: string = action.contentType;
              const facets: any[] = searchAPISearch?.facets;
              return {contentType, documents, resultCount, facets};
            }),
            take(1)
          );
      }
    ),
    switchMap((result: { contentType: string, documents: any[], resultCount: number, facets: any[] }) => {
      const keywordsFacet: any = result.facets.filter(facet => facet.name === 'free_tagging_name').shift();
      const values: any[] = keywordsFacet?.values;
      let keywords: Keyword[];
      if (values) {
        keywords = values.map(facet => {
          return {count: facet.count, label: facet.filter, latinised: this.latinisePipe.transform(facet.filter)};
        });
      }
      return [
        new UpdateContentCount(result.contentType, result.resultCount),
        new UpdateKeywords(result.contentType, keywords)
      ];
    })
  ));


  loadNewContents$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.LoadNewContents),
    withLatestFrom(this.currentLanguage$),
    mergeMap(([action, currentLanguage]: [LoadNewContents, string]) => {

        const conditionGroupInput: ConditionGroupInput = {
          conjunction: 'AND',
          groups: [
            {
              conjunction: 'AND',
              conditions: [
                {operator: '=', name: 'langcode', value: currentLanguage},
                {operator: '=', name: 'status', value: 'true'},
                {operator: '>', name: 'published_at', value: action.timestamp.toString(10)}
              ]
            }
          ]
        };

        const contentTypeConditions: Condition[] = [{operator: '=', name: 'content_type', value: action.contentType}];
        conditionGroupInput.groups.push({
          conjunction: 'OR',
          conditions: contentTypeConditions
        });

        const variables: { facets: any[], filterConditions: ConditionGroupInput, freeText?: string[] } = {
          facets: [],
          filterConditions: conditionGroupInput
        };

        const searchQueryOptions: QueryOptions = {
          query: ApolloQueries.searchInnovation,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            filterConditions: variables.filterConditions,
            facets: variables.facets,
            start: 0,
            end: 0
          }
        };

        return this.apollo.query(searchQueryOptions)
          .pipe(
            filter((apolloQueryResult: ApolloQueryResult<any>) => {
              return !!apolloQueryResult && apolloQueryResult.loading === false && apolloQueryResult?.data?.searchAPISearch;
            }),
            map((apolloQueryResult: ApolloQueryResult<any>) => {
              const searchAPISearch = apolloQueryResult?.data?.searchAPISearch;
              const documents: any[] = searchAPISearch?.documents;
              const resultCount: number = searchAPISearch?.result_count;
              const contentType: string = action.contentType;
              const facets: any[] = searchAPISearch?.facets;
              return {contentType, documents, resultCount, facets};
            }),
            map(result => {
              return new SetUnSeenContents(action.contentType, result.resultCount);
            })
          );
      }
    )
  ));


  setCurrentLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.SetCurrentLanguage),
    map((action: SetCurrentLanguage) => action.language),
    tap((language: string) => {
        this.store.dispatch(new ClearAllSchemata());
        if (this.translate.currentLang !== language) {
          this.translate.use(language);
        }
      }
    )
  ), {dispatch: false});


  hydrateStore$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.HydrateStore),
    take(1),
    tap(() => {
        // this.hydrateStore();
      }
    )
  ), {dispatch: false});


  setDefaultLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.SetDefaultLanguage),
    map((action: SetDefaultLanguage) => action.language),
    tap((language: string) =>
      this.translate.setDefaultLang(language)
    )
  ), {dispatch: false});


  setListThumbSize = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.SetDefaultLanguage),
    map((action: SetDefaultLanguage) => action.language),
    tap((language: string) =>
      this.translate.setDefaultLang(language)
    )
  ), {dispatch: false});


  openAlert = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.OpenAlert),
    map((action: OpenAlert) => action.message),
    switchMap((message: string) => {
        const alertWindowTemplate$ = this.templatesService.getTemplate$(Templates.alertWindow);
        return alertWindowTemplate$.pipe(
          filter(alertWindowTemplate => !!alertWindowTemplate),
          take(1),
          tap((alertWindowTemplate) => {
            if (alertWindowTemplate) {
              this.matDialog.open(alertWindowTemplate,
                {
                  maxWidth: '100vw',
                  width: '280px',
                  panelClass: 'alert',
                  data: {
                    contentData: message,
                    isAlert: true
                  }
                });
            }
          })
        );
      }
    )
  ), {dispatch: false});


  openAlertTemplate = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.OpenTemplateAlert),
    tap((action: OpenTemplateAlert) => {

      let windowData: any = {
        contentTemplate: action.windowData.contentTemplate,
        actionsTemplate: action.windowData.actionsTemplate,
        ...action.windowData.data,
        isAlert: true
      };

      if (action?.config?.data) {
        windowData = {...windowData, ...action.config.data};
      }

      this.matDialog.open(this.templatesService.getTemplate(Templates.window),
        {
          maxWidth: '100vw',
          width: '400px',
          panelClass: 'alert',
          ...action.config,
          data: windowData
        });

    })
  ), {dispatch: false});


  openWindow = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.OpenWindow),
    tap((action: OpenWindow) =>
      this.matDialog.open(this.templatesService.getTemplate(Templates.window),
        {
          ...action.config,
          data: {
            title: action.title,
            contentTemplate: action.windowData.contentTemplate,
            actionsTemplate: action.windowData.actionsTemplate,
            classes: action.windowData.classes
          }
        })
    )
  ), {dispatch: false});


  clearLocalStorageRequest$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.ClearLocalStorageRequest),
    tap((action: ClearLocalStorageRequest) => {
        this.store.dispatch(new OpenTemplateAlert({
          contentTemplate: Templates.clearLocalStorage,
          actionsTemplate: Templates.clearLocalStorageActions
        }, {
          width: '500px',
          height: '280px',
          data: {}
        }));
      }
    )
  ), {dispatch: false});


  clearLocalStorage$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.ClearLocalStorage),
    tap((action: ClearLocalStorage) => {
        this.dbService.clearAll(action.allData);
      }
    )
  ), {dispatch: false});


  changeSyncDataRequest$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.ChangeSyncDataRequest),
    tap((action: ChangeSyncDataRequest) => {
        this.store.dispatch(new OpenTemplateAlert({
          contentTemplate: Templates.syncData,
          actionsTemplate: Templates.syncDataActions,
          data: {
            contentData: {
              syncData: action.syncData
            }
          }
        }, {
          width: '500px',
          height: '280px',
          data: {}
        }));
      }
    )
  ), {dispatch: false});


  changeSyncData$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.ChangeSyncData),
    tap((action: ChangeSyncData) => {
        if (action.syncData === false) {
          this.store.dispatch(new ClearLocalStorage());
        }
      }
    )
  ), {dispatch: false});


  routerNavigated$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    withLatestFrom(this.autoPlay$, this.staticRouteTitle$),
    tap(([action, autoPlay, staticRouteTitle]: [RouterNavigatedAction, boolean, string]) => {
        if (staticRouteTitle) {
          this.store.dispatch(new SetPageTitle(this.translate.instant(staticRouteTitle)));
        }
      }
    )
  ), {dispatch: false});


  setAutoPlay$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.SetAutoPlay),
    switchMap((action: SetAutoPlay) => {
        return [new NavigateRandomly()];
      }
    )
  ));


  navigateRandomly$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.NavigateRandomly),
    tap((action: NavigateRandomly) => {
        this.navigateRandomly();
      }
    )
  ), {dispatch: false});


  setPageTitle$ = createEffect(() => this.actions$.pipe(
    ofType(AppCoreActionTypes.SetPageTitle),
    tap((action: SetPageTitle) => {
        let title: string = action.title;
        const hasCTInfos: boolean = action.contentType === NgrxJsonApiDefinitions.ctInnovation ||
          action.contentType === NgrxJsonApiDefinitions.ctNews;
        if (!!action.contentType && hasCTInfos) {
          title = title + ' | ' + this.translate.instant('APP.TITLE_TYPES.' + action.contentType);
        }
        this.titleService.setTitle(title + ' | WIN');
      }
    )
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private templatesService: TemplatesService,
    private store: Store<any>,
    private dbService: DbService,
    private ngrxJsonApiService: NgrxJsonApiService,
    private apollo: Apollo,
    private latinisePipe: LatinisePipe,
    private titleService: Title,
    @Inject(CURRENT_LANGUAGE) private currentLanguage$: Observable<string>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(AUTO_PLAY) private autoPlay$: Observable<boolean>,
    @Inject(STATIC_ROUTE_TITLE) private staticRouteTitle$: Observable<string>
  ) {
  }

  navigateRandomly() {
    setTimeout(() => {
      const matches = this.document.querySelectorAll('a[ng-reflect-drupal-router-link]');
      if (matches?.length > 0) {
        const randomIndex: number = Math.floor(Math.random() * matches?.length);
        const selectedTag: HTMLLinkElement = matches[randomIndex] as HTMLLinkElement;
        if (selectedTag) {
          selectedTag.click();
        }
        this.navigateRandomly();
      } else {
        this.navigateRandomly();
      }
    }, 2000);
  }

  buildSearchVariables(language: string, contentType: string): {
    facets: any[],
    filterConditions: ConditionGroupInput,
    freeText?: string[]
  } {
    const conditionGroupInput: ConditionGroupInput = {
      conjunction: 'AND',
      groups: [
        {
          conjunction: 'AND',
          conditions: [
            {operator: '=', name: 'langcode', value: language},
            {operator: '=', name: 'status', value: 'true'}
          ]
        }
      ]
    };

    const contentTypeConditions: Condition[] = [{operator: '=', name: 'content_type', value: contentType}];
    conditionGroupInput.groups.push({
      conjunction: 'OR',
      conditions: contentTypeConditions
    });

    const facets = [
      {
        field: 'free_tagging_name',
        limit: 0,
        min_count: 1,
        operator: '=',
        missing: false
      }
    ];

    const variables: { facets: any[], filterConditions: ConditionGroupInput, freeText?: string[] } = {
      facets: facets,
      filterConditions: conditionGroupInput
    };

    return variables;
  }

  hydrateStore() {

    const defaultZones: string[] = [
      'NgrxJsonApiZone-artboards',
      'NgrxJsonApiZone-drupal',
      'NgrxJsonApiZone-drupal-fr',
      'NgrxJsonApiZone-drupal-en',
      'NgrxJsonApiZone-shareBox',
      'NgrxJsonApiZone-polling',
      'NgrxJsonApiZone-taxonomies',
      'NgrxJsonApiZone-user',
      'NgrxJsonApiZone-welcome',
      'NgrxJsonApiZone-welcome-fr',
      'NgrxJsonApiZone-welcome-en',
      'NgrxJsonApiZone-contributionsList-fr',
      'NgrxJsonApiZone-contributionsList-en'
    ];

    this.dbService.getKey('syncData').subscribe(syncData => {
      if (syncData !== undefined && syncData !== null) {
        this.store.dispatch(new ChangeSyncData(syncData));
      } else {
        this.store.dispatch(new ChangeSyncData(true));
      }
    });

    this.dbService.hydrateZones$(defaultZones).subscribe(
      zone => {
        this.ngrxJsonApiService.getZone(zone.zoneId).hydrateZone(zone.data);
      }, error => {
        // console.log('error', error);
      }, () => {
        // console.log('done');
        // this.bheNgrxJsonApiService.clearRuntimeVars();
      });

    /*this.dbService.hydrateSearch$().subscribe(
      searchState => {
        if (searchState) {
          const languages: string[] = Object.keys(searchState);
          languages.forEach(language => {
            const searchI18nState: SearchI18nState = searchState[language];
            const contentTypes: string[] = ['innovation', 'news'];
            contentTypes.forEach(contentType => {
              let searchContentTypeState: SearchContentTypeState;
              if (searchI18nState[contentType]) {
                searchContentTypeState = searchI18nState[contentType];
                this.store.dispatch(new AddToIndex(language, contentType, searchContentTypeState.items));
              }
            });

          });
        }
      }, error => {
      }, () => {
      });*/

    this.dbService.hydrateDrupal$().subscribe(
      drupalState => {
        if (drupalState && drupalState.menus) {
          const languages: string[] = Object.keys(drupalState.menus);
          languages.forEach(language => {
            const menuIds: string[] = Object.keys(drupalState.menus[language]);
            menuIds.forEach(menuId => {
              this.store.dispatch(new LoadMenu(menuId, language));
              this.store.dispatch(new LoadMenuSuccess(drupalState.menus[language][menuId].menuItems, menuId, language));
            });
          });
        }
      }, error => {
      }, () => {
      });

    this.dbService.hydrateDrupalPathInfos$().subscribe(
      drupalPathState => {
        if (drupalPathState && drupalPathState.entities) {
          const pathCollection: PathInfos[] = Object.keys(drupalPathState.entities).map(key => drupalPathState.entities[key]);
          this.store.dispatch(new HydratePathInfosCollection(pathCollection));
        }
      }, error => {
      }, () => {
      });
  }

}
