import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef
} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  title: string;

  @Input()
  data: any;

  @Input()
  titleTemplate: TemplateRef<any>;

  @Input()
  contentTemplate: TemplateRef<any>;

  @Input()
  actionsTemplate: TemplateRef<any>;

  @HostBinding('class') class = '';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {

    if (this.injectedData) {
      if (this.injectedData.contentTemplate && !this.contentTemplate) {
        this.contentTemplate = this.injectedData.contentTemplate;
      }
      if (this.injectedData.title) {
        this.title = this.injectedData.title;
      }
      if (this.injectedData.classes) {
        this.class = this.injectedData.classes;
      }
    } else if (this.data) {
      if (this.data.contentTemplate && !this.contentTemplate) {
        this.contentTemplate = this.data.contentTemplate;
      }
      if (this.data.title) {
        this.title = this.data.title;
      }
      if (this.data.classes) {
        this.class = this.data.classes;
      }
    }

  }


  ngAfterViewInit(): void {

    this.cdr.detectChanges();

  }

}
