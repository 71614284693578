/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Direction, NgrxJsonApiService, Query} from 'ngrx-json-api';


@Injectable()
export class DrupalListResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
    private ngrxJsonApiService: NgrxJsonApiService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const routeData: any = route.data;
    const type: string = routeData.queryType;
    const queryId: string = routeData.queryId;

    const query: Query = {
      queryId: queryId,
      type: type,
      params: {
        page: {
          offset: 0,
          limit: 20
        },
        sorting: [
          {api: 'changed', direction: Direction.DESC}
        ]
      }
    };

    return query;

    // this.ngrxJsonApiService.getZone(NGRX_JSON_API_DEFAULT_ZONE).putQuery({query: drupalContentQuery, fromServer: true});

  }

}


