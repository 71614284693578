/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {DrupalPathState} from '@ngx-mil-drupal/store/reducers/path.reducers';
import {LoadAndSetPathInfos, SetPathInfos} from '@ngx-mil-drupal/store/actions/path.actions';
import {getPathInfosByPath} from '@ngx-mil-drupal/store/reducers';


@Injectable()
export class DrupalResolver implements Resolve<any> {

  constructor(
    private store: Store<DrupalPathState>
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.pipe(
      select(getPathInfosByPath(state.url))
    ).subscribe(pathInfos => {
      if (pathInfos) {
        this.store.dispatch(new SetPathInfos(pathInfos));
      } else {
        this.store.dispatch(new LoadAndSetPathInfos(state.url));
      }
    });

  }

}


