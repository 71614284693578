import {Injectable} from '@angular/core';
import {StoreResource} from 'ngrx-json-api';

@Injectable({
  providedIn: 'root'
})
export class MyContributionsDeletionService {

  deletingResource: StoreResource;

  constructor() { }
}
