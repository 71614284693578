<ng-container *ngIf="inspiration$|async as inspiration">

  <div class="d-flex flex-fill image-group overflow-hidden flex-wrap position-relative">

    <ng-template
      [ngTemplateOutlet]="thumbTemplate"
      [ngTemplateOutletContext]="{images: inspirationImages$|async}">
    </ng-template>

  </div>

  <div class="d-flex title-group align-items-center px-2">
    <span
      class="text-uppercase flex-fill mat-h4"
      [innerHTML]="inspiration.attributes.title"
    >
    </span>

    <span
      [matBadge]="innovationCount$|async"
      class="mr-3"
      matBadgeColor="accent"></span>

  </div>

</ng-container>

<ng-template #thumbTemplate let-images="images">

  <ng-template ngFor [ngForOf]="[0, 1, 2, 3]" let-index>

    <ng-container *ngIf="images && images.length >= index + 1">

      <app-image-renderer class="item-thumb" [imageMedia]="images[index]"
                          [derivative]="'original_256'"></app-image-renderer>

    </ng-container>

  </ng-template>

  <mat-divider [vertical]="true" class="divider-v"></mat-divider>
  <mat-divider class="divider-h"></mat-divider>

</ng-template>
