import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared.module';
import {NotFoundComponent} from './not-found/not-found.component';
import {SimplePageComponent} from './simple-page/simple-page.component';
import {PipesModule} from '../pipes/pipes.module';
import {AppSettingsComponent} from './app-settings/app-settings.component';
import {DialogComponent} from './dialog/dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './header/header.component';
import {CustomSwiperComponent} from './swipper/swiper.component';
import {NewsSlideRendererComponent} from './renderers/news-slide-renderer/news-slide-renderer.component';
import {SearchFreeTextComponent} from './search/search-free-text/search-free-text.component';
import {InnovationRendererComponent} from './renderers/innovation-renderer/innovation-renderer.component';
import {NodeBodyComponent} from './node-body/node-body.component';
import {ImageSlideRendererComponent} from './renderers/image-slide-renderer/image-slide-renderer.component';
import {SwiperThumbsComponent} from './swipper/swiper-thumbs/swiper-thumbs.component';
import {SwiperBulletsComponent} from './swipper/swiper-bullets/swiper-bullets.component';
import {MiniListComponent} from './mini-list/mini-list.component';
import {VoteHeaderComponent} from './vote-header/vote-header.component';
import {ApolloListComponent} from './apollo-list/apollo-list.component';
import {NgxMasonryModule} from '../ngx-masonry/ngx-masonry.module';
import {EditWindowComponent} from './edit-window/edit-window.component';
import {AddToBoardComponent} from './add-to-board/add-to-board.component';
import {ImageRendererComponent} from './renderers/image-renderer/image-renderer.component';
import {InnovationReferenceRendererComponent} from './renderers/innovation-reference-renderer/innovation-reference-renderer.component';
import {FreeTagsComponent} from './free-tags/free-tags.component';
import {ImagesSliderComponent} from './images-slider/images-slider.component';
import {UserDateComponent} from './user-date/user-date.component';
import {NewsInnovationHeaderComponent} from './news-innovation-header/news-innovation-header.component';
import {NodeMaterialComponent} from './forms/node/node.material.component';
import {RoutedTemplateComponent} from './routed-template/routed-template.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {LanguageSwitcherComponent} from './language-switcher/language-switcher.component';
import {CartMaterialComponent} from './forms/cart/cart.material.component';
import {RendererTemplatesComponent} from './renderers/renderer-templates.component';
import {ComponentTemplatesComponent} from './component-templates.component';
import {ToggleButtonBarTemplatesComponent} from './toggle-button-bar-templates/toggle-button-bar-templates.component';
import {VoteTemplatesComponent} from './templates/vote-templates/vote-templates.component';
import {ContentHeaderComponent} from './templates/content-header/content-header.component';
import {PwaTemplatesComponent} from './templates/pwa-templates/pwa-templates.component';
import {UserFunctionIconsComponent} from './templates/user-function-icons/user-function-icons.component';
import {ButtonTemplatesComponent} from './templates/button-templates/button-templates.component';
import {MainHeaderTemplatesComponent} from './templates/main-header-templates/main-header-templates.component';
import {WindowTemplatesComponent} from './templates/window-templates/window-templates.component';
import {IconTemplatesComponent} from './templates/icon-templates/icon-templates.component';
import {NodeFormComponent} from './node-form/node-form.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {SnackbarTemplatesComponent} from './templates/snackbar-templates/snackbar-templates.component';
import {UserAccountComponent} from './user-account/user-account.component';
import {PreferencesComponent} from './preferences/preferences.component';
import {LogoutComponent} from './logout/logout.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {QuillTextComponent} from './quill-text/quill-text.component';
import {QuillModule} from 'ngx-quill';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {TemplatesModule} from '../templates/templates.module';
import {LayoutModule} from '../layout/layout.module';
import {TemplateSchemaModule} from 'ngx-schema-form';
import {ResizeObserverModule} from '@ng-web-apis/resize-observer';

const components: any = [
  AddToBoardComponent,
  ApolloListComponent,
  AppSettingsComponent,
  ButtonTemplatesComponent,
  CartMaterialComponent,
  ComponentTemplatesComponent,
  NodeMaterialComponent,
  ContentHeaderComponent,
  CustomSwiperComponent,
  DialogComponent,
  EditWindowComponent,
  FreeTagsComponent,
  HeaderComponent,
  IconTemplatesComponent,
  ImageRendererComponent,
  ImagesSliderComponent,
  ImageSlideRendererComponent,
  InnovationReferenceRendererComponent,
  InnovationRendererComponent,
  LanguageSwitcherComponent,
  LogoutComponent,
  MainHeaderTemplatesComponent,
  MainMenuComponent,
  MiniListComponent,
  NewsInnovationHeaderComponent,
  NewsSlideRendererComponent,
  NodeBodyComponent,
  NodeFormComponent,
  NotFoundComponent,
  PreferencesComponent,
  PwaTemplatesComponent,
  RendererTemplatesComponent,
  RoutedTemplateComponent,
  SearchFreeTextComponent,
  SimplePageComponent,
  SnackbarTemplatesComponent,
  SwiperBulletsComponent,
  SwiperThumbsComponent,
  ToggleButtonBarTemplatesComponent,
  UserAccountComponent,
  UserDateComponent,
  UserFunctionIconsComponent,
  VideoPlayerComponent,
  VoteTemplatesComponent,
  VoteHeaderComponent,
  WindowTemplatesComponent,
  QuillTextComponent
];


@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        LazyLoadImageModule,
        NgxMasonryModule,
        PipesModule,
        ReactiveFormsModule,
        SharedModule,
        QuillModule,
        TemplatesModule,
        LayoutModule,
        TemplateSchemaModule,
        QuillModule,
        YouTubePlayerModule,
        ResizeObserverModule
    ]
})
export class ComponentsModule {
}
