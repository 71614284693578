import * as MenuActions from '../actions/menu.actions';
import {DrupalMenuItem} from '@ngx-mil-drupal/models/menu.model';


/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface DrupalMenusState {
  [language: string]: MenuGroupState;
}

export interface MenuGroupState {
  [menuId: string]: DrupalMenuState;
}

export interface DrupalMenuState {
  loading: boolean;
  loaded: boolean;
  menuItems?: DrupalMenuItem[];
  menuItemsByPath?: { [path: string]: DrupalMenuItem };
}

const initialState: DrupalMenusState = {};

const initialMenuGroupState: MenuGroupState = {};

const initialMenuState: DrupalMenuState = {
  loading: false,
  loaded: false
};

export function menusReducer(
  state: DrupalMenusState = initialState,
  action: MenuActions.MenuActionsUnion
): DrupalMenusState {
  switch (action.type) {

    case MenuActions.MenuActionTypes.LoadMenu:
      return {
        ...state,
        [action.language]: menuReducer(state[action.language], action)
      };

    case MenuActions.MenuActionTypes.LoadMenuSuccess:
      return {
        ...state,
        [action.language]: menuReducer(state[action.language], action)
      };

    default:
      return state;
  }
}

export function menuReducer(state: MenuGroupState = initialMenuGroupState, action: MenuActions.MenuActionsUnion): MenuGroupState {

  switch (action.type) {

    case MenuActions.MenuActionTypes.LoadMenu:
      return {
        ...state,
        [action.menuId]: {
          loading: true,
          loaded: false
        }
      };

    case MenuActions.MenuActionTypes.LoadMenuSuccess:

      const menuItemsByPath = {};
      action.menu.forEach(menuItem => {
        menuItemsByPath[menuItem.relative] = menuItem;
      });

      return {
        ...state,
        [action.menuId]: {
          menuItems: action.menu,
          menuItemsByPath: menuItemsByPath,
          loading: false,
          loaded: true
        }
      };

  }

  return state;

}

