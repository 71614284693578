<div class="row infos-window-block">

  <div class="col-12 d-flex">

    <div class="d-flex align-items-center">

      <img src="assets/icons/icon-72x72.png"/>

      <div class="d-flex flex-column mx-3">
        <h2
          [matBadge]="1"
          matBadgeOverlap="false"
          matBadgeColor="accent"
          [matBadgeHidden]="(pwaService.updateAvailable$|async) !== true"
          class="align-self-start font-weight-bold badged">
          WIN
        </h2>
        <small>Version: {{version}} - n</small>
      </div>

    </div>

  </div>

  <ng-container *ngIf="pwaService.updateAvailable$|async">

    <div class="col-12 d-flex mt-4">

      <div class="d-flex flex-fill align-items-center justify-content-between">

        <p class="mr-2" [innerHTML]="'PWA.UPDATE_MESSAGE'|translate"></p>

        <button
          (click)="pwaService.reload()"
          class="accent-action-button"
          color="accent"
          mat-flat-button
          mat-dialog-close>
          <span [innerHTML]="'ACTIONS.RELOAD'|translate"></span>
        </button>


      </div>

    </div>

  </ng-container>

</div>


<div class="row infos-window-block">

  <div class="col-12 d-flex flex-column">

    <h3 [innerHTML]="'SETTINGS.CACHE.TITLE'|translate"></h3>

    <div class="d-flex mt-2">
      <div (click)="onSyncDataChange(!syncStateSlideToggle.checked)" class="align-self-start cursor-pointer">
        <mat-slide-toggle
            #syncStateSlideToggle
            class="pointer-events-none"
            [checked]="appCoreService.syncData$|async">
        </mat-slide-toggle>
      </div>
      <span class="ml-2" [innerHTML]="'SETTINGS.CACHE.DESCRIPTION'|translate"></span>
    </div>

  </div>

</div>

<div class="row infos-window-block">

  <div class="col-12 d-flex flex-column">

    <h3 [innerHTML]="'SETTINGS.ADVANCED.TITLE'|translate"></h3>

    <div class="d-flex my-2 flex-column justify-content-start">
      <button mat-flat-button class="align-self-start" (click)="clearLocalStorage()">
        <span [innerHTML]="'SETTINGS.ADVANCED.CLEAR.TITLE'|translate"></span>
      </button>
      <p class="mt-2" [innerHTML]="'SETTINGS.ADVANCED.CLEAR.DESCRIPTION'|translate"></p>
    </div>

    <div class="d-flex my-2 flex-column justify-content-start">
      <button mat-flat-button class="align-self-start">
        <span [innerHTML]="'SETTINGS.ADVANCED.UNINSTALL.TITLE'|translate"></span>
      </button>
      <p class="mt-2" [innerHTML]="'SETTINGS.ADVANCED.UNINSTALL.DESCRIPTION'|translate"></p>
    </div>

  </div>

</div>
