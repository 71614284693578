import {Action} from '@ngrx/store';
import {AuthActionType, AuthenticatedChangeAction, OAuthEventAction} from '../actions/auth.actions';
import {AuthClaims} from '../effects/auth.effects';

export interface AuthState {
  loaded?: boolean;
  loading?: boolean;
  authenticated?: boolean;
  indeterminateAuthenticationStatus?: boolean;
  claims: AuthClaims;
}


const initialState: AuthState = {
  loading: false,
  loaded: false,
  claims: null
};

export function reducer(state: AuthState = initialState, action: Action): AuthState {
  switch (action.type) {
    case AuthActionType.AUTHENTICATED_CHANGE:
      return {
        ...state,
        loading: false,
        loaded: true,
        authenticated: (action as AuthenticatedChangeAction).authenticated,
        indeterminateAuthenticationStatus: (action as AuthenticatedChangeAction).indeterminate,
        claims: (action as AuthenticatedChangeAction).claims
      };
    case AuthActionType.OAUTH_EVENT:
      const event = (action as OAuthEventAction).event;
      switch (event.type) {
        case 'token_expires':
          return {
            ...state,
            loading: true,
          };
      }
  }
  return state;
}

export const getClaims = (state: AuthState) => state.claims;
