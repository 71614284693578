import {Component, Input, OnInit} from '@angular/core';
import {Resource, ResourceIdentifier} from 'ngrx-json-api';

@Component({
  selector: 'app-free-tags',
  templateUrl: './free-tags.component.html',
  styleUrls: ['./free-tags.component.scss']
})
export class FreeTagsComponent implements OnInit {

  @Input()
  tags: Resource[];

  constructor() {
  }

  ngOnInit() {
  }

}
