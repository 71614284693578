import {Injectable} from '@angular/core';
import {TemplatesService} from '../templates.service';
import {MatDialog} from '@angular/material/dialog';
import {LogoutComponent} from '../components/logout/logout.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private templatesService: TemplatesService,
    private matDialog: MatDialog
  ) {
  }

  openNeedAuthentication() {
    // console.log('openNeedAuthentication()');
    // console.log('this.matDialog.getDialogById(\'logout\')', this.matDialog.getDialogById('logout'));
    if (!this.matDialog.getDialogById('logout')) {
      this.matDialog.open(
        LogoutComponent,
        {
          disableClose: true,
          id: 'logout',
          width: '100%',
          maxWidth: '300px',
          backdropClass: ['win', 'logout']
        });
    }
  }

}
