<div class="d-flex flex-fill px-4 align-items-start align-items-sm-center flex-column flex-sm-row py-4 py-sm-0">

  <ng-template [ngTemplateOutlet]="{no: unVotedTemplate, yes: votedTemplate}[isVotedContent$|async]">
  </ng-template>

</div>

<ng-template #unVotedTemplate>

  <ng-template [ngTemplateOutlet]="deadlineTemplate">
  </ng-template>

  <div class="d-flex flex-fill justify-content-center">

    <span class="mat-headline mb-3 mb-sm-0" [innerHTML]="'VOTE.IS_IT_INNOVATIVE'|translate"></span>

  </div>

  <div class="d-flex align-items-center">

    <span class="mat-headline mr-3" [innerHTML]="'VOTE.SELECT'|translate"></span>

    <mat-button-toggle
      [checked]="voteYes === false"
      (change)="voteYes = $event.value"
      [value]="false"
      color="accent"
      class="gelule-button-toggle mat-contrast mat-accent">
      <mat-icon svgIcon="icon-vote-no">
      </mat-icon>
      <span [innerHTML]="'ACTIONS.NO'|translate"></span>
    </mat-button-toggle>

    <span class="mat-headline mx-3" [innerHTML]="'VOTE.OR'|translate"></span>

    <mat-button-toggle
      [checked]="voteYes === true"
      (change)="voteYes = $event.value"
      [value]="true"
      color="accent"
      class="gelule-button-toggle mat-contrast mat-accent">
      <mat-icon svgIcon="icon-vote-yes">
      </mat-icon>
      <span [innerHTML]="'ACTIONS.YES'|translate"></span>
    </mat-button-toggle>

  </div>

</ng-template>

<ng-template #votedTemplate>

  <ng-template [ngTemplateOutlet]="deadlineTemplate">
  </ng-template>

  <div class="d-flex mx-5 flex-column">

    <small [innerHTML]="'VOTE.STATE'|translate">
    </small>

    <ng-container *ngIf="((node$|async)|jaSelectStoreResource:zone)|async as node">

      <div class="d-flex">
        <span class="mr-4"
              [innerHTML]="'VOTE.VOTING_YES'|translate:{value:((node.attributes.innovation_voting_result)*100)|round}"></span>
        <span class="mr-4"
              [innerHTML]="'VOTE.VOTING_NO'|translate:{value:((1-node.attributes.innovation_voting_result)*100)|round}"></span>
      </div>

    </ng-container>


  </div>

  <div class="d-flex flex-fill justify-content-center">

    <span class="mat-headline" [innerHTML]="'VOTE.THANK_YOU'|translate"></span>

  </div>

</ng-template>

<ng-template #deadlineTemplate>

  <div class="d-flex flex-sm-column">
    <small class="deadline-label space-after" [innerHTML]="'VOTE.DEADLINE'|translate">
    </small>
    <ng-container *ngIf="(node$|async) as node">
      <strong>{{(pollService.workflowScheduledTransitions$|async) && (pollService.workflowScheduledTransitions$|async)[node.id] ? ((pollService.workflowScheduledTransitions$|async)[node.id]|date:'mediumDate':'+0000') : '--'}}</strong>
    </ng-container>
  </div>

</ng-template>

<ng-template #voteConfirmationContentTemplate>

  <div class="content-group my-3 px-3 d-flex flex-column align-items-center">


    <ng-container *ngIf="voteYes">
      <div class="d-flex align-items-center">
        <h1 [innerHTML]="'VOTE.VOTED_YES'|translate"></h1>
        <mat-icon svgIcon="icon-vote-yes" class="ml-3"></mat-icon>
      </div>
    </ng-container>


    <ng-container *ngIf="!voteYes">

      <div class="d-flex align-items-center mb-4">
        <h1 [innerHTML]="'VOTE.VOTED_NO'|translate"></h1>
        <mat-icon svgIcon="icon-vote-no" class="ml-3">
        </mat-icon>
      </div>

      <h3 class="align-self-start mb-2">{{'VOTE.LEAVE_COMMENT'|translate}}</h3>
      <textarea matInput [rows]="7" class="flex-fill w-100 vote-comment" [formControl]="commentControl"></textarea>

    </ng-container>

    <!--
    <p [innerHTML]="'VOTE.DO_YOU_CONFIRM'|translate"></p>
    -->

  </div>

</ng-template>

<ng-template #voteConfirmationActionsTemplate>

  <div class="d-flex justify-content-end mx-4 my-2">

    <button
      (click)="cancelVote()"
      class="mx-3 secondary-action-button"
      mat-flat-button
      [mat-dialog-close]>
      {{'ACTIONS.CANCEL'|translate}}
    </button>

    <button
      (click)="confirmVote()"
      mat-flat-button
      color="accent"
      class="mx-3 main-action-button"
      [mat-dialog-close]="true">
      {{'ACTIONS.CONFIRM'|translate}}
    </button>

  </div>

</ng-template>
