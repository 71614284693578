<ng-container *ngIf="apolloSearchIndexService">

  <app-apollo-list
    [loading]="apolloSearchIndexService.lockLoading$|async"
    [end]="apolloSearchIndexService.end$|async"
    [data]="(itemsByContentType$|async)|unique:'nid'"
    [rendererTemplate]="listLinkItemTemplate"
    class="pt-0 mt-0 mt-sm-3">
  </app-apollo-list>

  <ng-template [ngTemplateOutlet]="appFooterTemplate">
  </ng-template>

</ng-container>

<ng-template
  #listLinkItemTemplate
  let-data="data">

  <ng-template [ngTemplateOutlet]="templatesService.getTemplate('listLink')"
               [ngTemplateOutletContext]="{
               data: data,
               template: templatesService.getTemplate('listRenderer'),
               size:listThumbSize$|async
               }">
  </ng-template>

</ng-template>


<ng-template #searchFiltersGroupTemplate
             let-fromSideMenu="fromSideMenu"
let-layoutClasses="layoutClasses">

  <ng-container *ngIf="apolloSearchIndexService">


      <ng-template
        [ngTemplateOutlet]="('searchFilters'|templates)|async"
        [ngTemplateOutletContext]="{
          fieldTypeQueries: fieldTypeQueries,
          facets: (apolloSearchIndexService.facets$|async),
          fromSideMenu: fromSideMenu,
          layoutClasses: layoutClasses
        }">
      </ng-template>


  </ng-container>

</ng-template>

<ng-template #searchResultCountTemplate>

  <ng-container
    *ngIf="(apolloSearchIndexService.resultCount$|async) >= 0 && !(apolloSearchIndexService.loading$|async)">

    <ng-container *ngIf="(apolloSearchIndexService.resultCount$|async) as resultCount">

      <ng-template
        [ngTemplateOutlet]="templatesService.getTemplate('searchResultCount')"
        [ngTemplateOutletContext]="{data:(('SEARCH.' + (searchService.contentTypes$|async)[0] + '.FOUND.' + ((resultCount>1)?'SEVERAL':'SINGLE')|translate:{value:resultCount}))}">
      </ng-template>

    </ng-container>

  </ng-container>

</ng-template>


<app-search-templates></app-search-templates>

