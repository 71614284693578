import {Injectable} from '@angular/core';
import {ApolloQueryResult, WatchQueryOptions} from 'apollo-client';
import {Observable, ReplaySubject} from 'rxjs';
import {ApolloSearchApi, ApolloSearchApiResult} from './apollo-search-api';
import {Apollo, QueryRef} from 'apollo-angular';
import {filter, map, shareReplay, take, withLatestFrom} from 'rxjs/operators';
import {InnovationIndexItem} from './innovation-index-item';

@Injectable()
export class ApolloService {

  get queryRef(): QueryRef<any> {
    return this._queryRef;
  }

  get variables(): any {
    return this._variables;
  }

  enabled$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  _started: boolean;

  private _variables: any;
  set variables(value: any) {
    this.data = [];
    this._variables = value;
    this.watchQueryOptions.variables = {...this.watchQueryOptions.variables, ...this._variables};
    this._queryRef.setVariables(this._variables);
    /*if (!this._started) {
      this.start();
    }*/
  }

  data: any[] = [];

  data$: Observable<InnovationIndexItem[]>;

  facets$: Observable<any[]>;

  resultCount$: Observable<number>;

  loading$: Observable<boolean>;

  end$: Observable<boolean>;

  private _queryRef: QueryRef<any>;

  _queryRef$: Observable<QueryRef<any>>;

  lockLoading$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private apollo: Apollo,
    private watchQueryOptions: WatchQueryOptions,
    private pageStart: number = 0,
    public pageSize: number = 15
  ) {
    this._variables = {...watchQueryOptions.variables, start: this.pageStart, end: this.pageSize};
    this.watchQueryOptions.variables = this._variables;
    this.init();
    this.start();
  }

  init() {

    this._queryRef = this.apollo.watchQuery<ApolloSearchApi>(this.watchQueryOptions);

  }

  start() {

    this._started = true;

    const apolloQueryResult$: Observable<ApolloQueryResult<any>> = this._queryRef.valueChanges;

    this.loading$ = apolloQueryResult$.pipe(
      map((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => {
        return apolloQueryResult.loading;
      }),
      shareReplay(1)
    );

    const apolloSearchApi$: Observable<ApolloSearchApiResult> = apolloQueryResult$.pipe(
      filter((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => !!apolloQueryResult && !!apolloQueryResult.data),
      map((apolloQueryResult: ApolloQueryResult<ApolloSearchApi>) => {
        return apolloQueryResult.data.searchAPISearch;
      })
    );

    this.resultCount$ = apolloSearchApi$.pipe(
      map((apolloQueryResult: ApolloSearchApiResult) => {
        return apolloQueryResult.result_count;
      })
    );

    this.data$ = apolloSearchApi$.pipe(
      map((apolloQueryResult: ApolloSearchApiResult) => {
        this.data = [...this.data, ...apolloQueryResult.documents];
        this.lockLoading$.next(false);
        return this.data;
      })
    );

    this.facets$ = apolloSearchApi$.pipe(
      map((apolloQueryResult: ApolloSearchApiResult) => {
        return (apolloQueryResult as any).facets ? (apolloQueryResult as any).facets : [];
      })
    );

    this.end$ = this.resultCount$.pipe(
      map(count => {
        return !(this.pageStart < (count - this.pageSize));
      })
    );

  }

  nextPage() {
    this.resultCount$.pipe(
      withLatestFrom(this.lockLoading$),
      filter(([count, lock]) => {
        return lock === false;
      }),
      take(1)
    ).subscribe(([count, lock]) => {

      if (this.pageStart < (count - this.pageSize)) {

        this.pageStart = this.pageStart + this.pageSize;
        this._queryRef.setVariables({
          ...this.watchQueryOptions.variables,
          start: this.pageStart,
          end: this.pageSize
        });

        this.lockLoading$.next(true);

      }

    });
  }


}
