import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getSyncData} from '../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class AppCoreService {

  syncData$: Observable<boolean> = this.store.pipe(select(getSyncData));

  constructor(
    private store: Store<any>
  ) {
  }

}
