import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NgrxJsonApiService, NgrxJsonApiStoreResources, Query, Resource, ResourceIdentifier, StoreResource} from 'ngrx-json-api';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import {getRouteParams} from '../../store/reducers';
import {AddContentToBoard, AddToShareBox, CreateBoard, RemoveContentFromBoard, RemoveFromShareBox} from '../../store/actions/user.actions';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {filter, map, shareReplay, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {TemplatesService} from '../../templates.service';
import {OpenTemplateAlert} from '../../store/actions/app-core.actions';
import {Templates} from '../templates';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {CartService} from '../../services/cart.service';

@Component({
  selector: 'app-news-innovation-header',
  templateUrl: './news-innovation-header.component.html',
  styleUrls: ['./news-innovation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsInnovationHeaderComponent implements OnInit, AfterViewInit {

  get node(): Resource {
    return this._node;
  }

  node$: ReplaySubject<Resource> = new ReplaySubject<Resource>(1);

  private _node: Resource;
  @Input()
  set node(value: Resource) {
    this._node = value;
    this.node$.next(value);
  }

  @Input()
  zone: string;

  @Output()
  addToShareBoxEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('addToBoardTemplate')
  addToBoardTemplate: TemplateRef<any>;

  @ViewChild('removeFromBoardConfirmationContentTemplate', {static: true})
  removeFromBoardConfirmationContentTemplate: TemplateRef<any>;

  @ViewChild('removeFromBoardActionsTemplate', {static: true})
  removeFromBoardActionsTemplate: TemplateRef<any>;

  myInspirationsQuery: Query = {...NgrxJsonApiQueries.queryInspirationNodeCollectionInclInnovations};
  myInspirationsQueryId: string = this.myInspirationsQuery.queryId;

  inspirations$: Observable<StoreResource[]> = this.userService.userUid$.pipe(
    filter(userId => {
      return !!userId;
    }),
    take(1),
    switchMap(() => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectStoreResourcesOfType(NgrxJsonApiDefinitions.inspiration.type)
        .pipe(
          map((ngrxJsonApiStoreResources: NgrxJsonApiStoreResources) => {
            if (ngrxJsonApiStoreResources) {
              return Object.keys(ngrxJsonApiStoreResources).map(key => ngrxJsonApiStoreResources[key]);
            } else {
              return [];
            }
          })
        );
    }),
    shareReplay(1)
  );

  isInInspiration$: Observable<boolean> = combineLatest([this.inspirations$, this.node$])
    .pipe(
      map(([inspirations, node]) => {
        const innovationsRefs: any[] = inspirations.map(inspiration => inspiration.relationships.field_innovation.data)
          .filter(innovationsRef => innovationsRef.length > 0);
        const flatInnovationsRefs: ResourceIdentifier[] = [].concat(...innovationsRefs);
        // console.log('flatInnovationsRefs', flatInnovationsRefs);
        // console.log('node.id', node.id);
        return flatInnovationsRefs.filter(inspirationsRef => inspirationsRef.id === node.id).length > 0;
      }),
      shareReplay(1)
    );

  isInCart$: Observable<boolean> = combineLatest([this.cartService.carts$, this.node$])
    .pipe(
      map(([carts, node]) => {
        if (carts && carts.length > 0) {
          const firstCart: Resource = carts[0];
          const cartParts: ResourceIdentifier[] = firstCart.relationships.field_cart_part.data;
          return cartParts.filter(cartPartRef => cartPartRef.id === node.id).length > 0;
        } else {
          return false;
        }
      })
    );

  routeParams$: Observable<any> = this.store.pipe(select(getRouteParams));

  constructor(
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private ngrxJsonApiService: NgrxJsonApiService,
    public templatesService: TemplatesService,
    private router: Router,
    private userService: UserService,
    private cartService: CartService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.removeFromBoardConfirmationContent, this.removeFromBoardConfirmationContentTemplate);
    this.templatesService.addTemplate(Templates.removeFromBoardActions, this.removeFromBoardActionsTemplate);
  }

  confirmRemoveFromBoard(inspiration: Resource) {
    if (inspiration) {
      this.store.dispatch(new RemoveContentFromBoard(inspiration, this.node));
      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectStoreResource(inspiration).pipe(
        map(inspirationResource => inspirationResource.relationships.field_innovation.data.map(data => data.id)),
        filter(innovationIds => {
          return innovationIds.indexOf(this.node.id) === -1;
        }),
        take(1)
      ).subscribe(ready => {
        this.router.navigateByUrl('/my-inspirations/' + inspiration.id);
      });
    }
  }

  cancelRemoveFromBoard() {

  }

  addRemoveInnovationFromBoard() {
    this.isInInspiration$
      .pipe(
        withLatestFrom(this.routeParams$),
        take(1)
      )
      .subscribe(([isInInspiration, routeParams]) => {

        const isInspirationRoute: boolean = routeParams.inspirationId !== null && routeParams.inspirationId !== undefined;

        if (isInInspiration && isInspirationRoute) {
          this.ngrxJsonApiService
            .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
            .selectStoreResource({id: routeParams.inspirationId, type: NgrxJsonApiDefinitions.inspiration.type})
            .pipe(take(1))
            .subscribe(inspirationResource => {
              const alertMessage: string = this.translate
                .instant('MY_INSPIRATION.ALERTS.REMOVE_FROM_BOARD', {value: inspirationResource.attributes.title});
              this.store.dispatch(new OpenTemplateAlert({
                contentTemplate: Templates.removeFromBoardConfirmationContent,
                actionsTemplate: Templates.removeFromBoardActions,
                data: {
                  contentData: {
                    message: alertMessage,
                    inspiration: inspirationResource
                  }
                }
              }));
            });
          return;
        }

        const dialogData: any = {
          contentTemplate: this.addToBoardTemplate,
          classes: 'transparent-header'
        };

        dialogData.title = this.translate.instant('ADD_TO_BOARD.WINDOW_TITLE');
        dialogData.contentData = {
          mode: 'create',
          hideCloseButton: true
        };
        // TODO an openWindow Action rather than that
        this.dialog.open(DialogComponent, {
          panelClass: ['transparent-header', 'add-to-board'],
          width: '400px',
          maxWidth: '100vw',
          maxHeight: '80vh',
          data: dialogData,
          disableClose: true
        });

      });

  }

  createBoard(name: string) {
    this.store.dispatch(new CreateBoard(name, this.node));
  }

  addContentToBoard(board: Resource) {
    this.store.dispatch(new AddContentToBoard(board, this.node));
  }

  removeContentFromBoard(board: Resource) {

    if (board) {
      this.store.dispatch(new RemoveContentFromBoard(board, this.node));
    }

  }

  addToShareBox() {
    this.isInCart$.pipe(
      take(1)
    ).subscribe(isInCart => {
      if (!isInCart) {
        this.store.dispatch(new AddToShareBox([this.node]));
      } else {
        this.store.dispatch(new RemoveFromShareBox(this.node));
      }
    });
  }

}
