import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {NgrxJsonApiService, Resource} from 'ngrx-json-api';
import {filter, map, mergeMap, switchMap, take} from 'rxjs/operators';

@Component({
  selector: 'app-innovation-reference-renderer',
  templateUrl: './innovation-reference-renderer.component.html',
  styleUrls: ['./innovation-reference-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InnovationReferenceRendererComponent implements OnInit {

  @Input()
  zone: string;

  data$: ReplaySubject<Resource> = new ReplaySubject<Resource>(1);

  thumbnail$: Observable<Resource>;

  _data: any;
  @Input()
  set data(value: any) {
    this._data = value;
    this.data$.next(this._data);
  }

  @Input()
  removable: boolean;

  @Input()
  removeEmitter: EventEmitter<any>;

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService
  ) {
  }

  ngOnInit() {
    this.thumbnail$ = this.data$.pipe(
      map(data => {
        return data.relationships.field_node_thumbnail.data;
      }),
      filter(thumbnailRef => !!thumbnailRef),
      switchMap(thumbnailRef => {
        return this.ngrxJsonApiService.getZone(this.zone).selectStoreResource(thumbnailRef);
      }),
      filter(thumbnail => !!thumbnail),
      take(1)
    );
  }

}
