import {ResourceDefinition} from 'ngrx-json-api';


export class NgrxJsonApiDefinitions {

  /** Example
   * static readonly page: ResourceDefinition = {
    type: 'node--page',
    collectionPath: 'node/page',
    attributes: {
      title: {}
    },
    relationships: {
      field_paragraphs: {
        type: NgrxJsonApiDefinitions.paragraphLayout.type,
        relationType: 'hasMany'
      }
    }
  };


   static readonly resourceDefinitions: Array<ResourceDefinition> = [
   NgrxJsonApiDefinitions.page
   ];
   */

  static readonly zoneWelcome: string = 'welcome';
  static readonly zoneArtBoards: string = 'artboards';
  static readonly zonePolling: string = 'polling';
  static readonly zoneShareBox: string = 'shareBox';
  static readonly zoneTaxonomies: string = 'taxonomies';
  static readonly zoneUser: string = 'user';
  static readonly zoneContributionsList: string = 'contributionsList';

  static readonly taxonomyTerm: string = 'taxonomy_term';
  static readonly node = 'node';
  static readonly userKey = 'user';
  static readonly media = 'media';
  static readonly fileKey = 'file';
  static readonly paragraph = 'paragraph';

  static readonly ctInnovation = 'innovation';
  static readonly ctInspiration = 'inspiration';
  static readonly ctNews = 'news';
  static readonly ctCart = 'cart';
  static readonly ctPage = 'page';

  static readonly ctFakeVote = 'vote';

  static readonly searchIndex: ResourceDefinition = {
    type: 'index--innovation',
    collectionPath: 'index/innovation'
  };

  static readonly mhProfile: ResourceDefinition = {
    type: 'node--mh_ad_profile',
    collectionPath: 'node/mh_ad_profile',
    attributes: {}
  };

  static readonly user: ResourceDefinition = {
    type: 'user--user',
    collectionPath: 'user/user',
    attributes: {}
  };

  static readonly file: ResourceDefinition = {
    type: 'file--file',
    collectionPath: 'file/file',
    attributes: {
      uri: {}
    }
  };

  static readonly mediaRemoteVideo: ResourceDefinition = {
    type: 'media--remote_video',
    collectionPath: 'media/remote_video'
  };

  static readonly mediaImage: ResourceDefinition = {
    type: 'media--image',
    collectionPath: 'media/image'
  };

  static readonly mediaOriginalVideo: ResourceDefinition = {
    type: 'media--original_video',
    collectionPath: 'media/original_video'
  };

  static readonly mediaVideo: ResourceDefinition = {
    type: 'media--video',
    collectionPath: 'media/video'
  };

  static readonly freeTagging: ResourceDefinition = {
    type: 'taxonomy_term--free_tagging',
    collectionPath: 'taxonomy_term/free_tagging',
    attributes: {
      name: {}
    }
  };

  static readonly innovation: ResourceDefinition = {
    type: 'node--' + NgrxJsonApiDefinitions.ctInnovation,
    collectionPath: 'node/' + NgrxJsonApiDefinitions.ctInnovation,
    attributes: {
      title: {},
      field_innovation_description: {},
      field_innovation_id: {}
    },
    relationships: {
      field_free_tagging: {
        type: NgrxJsonApiDefinitions.freeTagging.type,
        relationType: 'hasMany'
      },
      field_images: {
        type: NgrxJsonApiDefinitions.file.type,
        relationType: 'hasMany'
      }
    }
  };

  static readonly inspiration: ResourceDefinition = {
    type: 'node--' + NgrxJsonApiDefinitions.ctInspiration,
    collectionPath: 'node/' + NgrxJsonApiDefinitions.ctInspiration,
    attributes: {
      title: {}
    },
    relationships: {
      field_innovation: {
        type: NgrxJsonApiDefinitions.innovation.type,
        relationType: 'hasMany'
      }
    }
  };

  static readonly news: ResourceDefinition = {
    type: 'node--news',
    collectionPath: 'node/news',
    attributes: {
      title: {},
      field_news_description: {}
    },
    relationships: {
      field_free_tagging: {
        type: NgrxJsonApiDefinitions.freeTagging.type,
        relationType: 'hasMany'
      },
      field_images: {
        type: NgrxJsonApiDefinitions.file.type,
        relationType: 'hasMany'
      }
    }
  };

  static readonly page: ResourceDefinition = {
    type: 'node--page',
    collectionPath: 'node/page',
    attributes: {
      title: {},
      body: {}
    }
  };

  static readonly cart: ResourceDefinition = {
    type: 'node--' + NgrxJsonApiDefinitions.ctCart,
    collectionPath: 'node/' + NgrxJsonApiDefinitions.ctCart,
    attributes: {
      field_cart_subject: {},
      field_cart_message: {},
      field_cart_workflow: {}
    },
    relationships: {
      field_cart_recipient: {
        type: NgrxJsonApiDefinitions.user.type,
        relationType: 'hasMany'
      },
      field_cart_part: {
        type: NgrxJsonApiDefinitions.innovation.type,
        relationType: 'hasMany'
      }
    }
  };

  static readonly innovationCategory: ResourceDefinition = {
    type: 'taxonomy_term--innovation_category',
    collectionPath: 'taxonomy_term/innovation_category',
    attributes: {
      name: {}
    }
  };

  static readonly brands: ResourceDefinition = {
    type: 'taxonomy_term--brands',
    collectionPath: 'taxonomy_term/brands',
    attributes: {
      name: {}
    }
  };

  static readonly newsCategory: ResourceDefinition = {
    type: 'taxonomy_term--news_category',
    collectionPath: 'taxonomy_term/news_category',
    attributes: {
      name: {}
    }
  };

  static readonly awards: ResourceDefinition = {
    type: 'taxonomy_term--awards',
    collectionPath: 'taxonomy_term/awards',
    attributes: {
      name: {}
    }
  };

  static readonly advancement: ResourceDefinition = {
    type: 'taxonomy_term--advancement',
    collectionPath: 'taxonomy_term/advancement',
    attributes: {
      name: {}
    }
  };

  static readonly voteType: ResourceDefinition = {
    type: 'vote_type--vote_type',
    collectionPath: 'vote_type/vote_type'
  };

  static readonly vote: ResourceDefinition = {
    type: 'vote--vote',
    collectionPath: 'vote/vote',
    attributes: {}
  };

  static readonly workflowScheduledTransition: ResourceDefinition = {
    type: 'workflow_scheduled_transition--editorial_innovation',
    collectionPath: 'workflow_scheduled_transition/editorial_innovation',
    attributes: {}
  };

  static readonly workflowCartTransition: ResourceDefinition = {
    type: 'workflow_transition--editorial_cart',
    collectionPath: 'workflow_transition/editorial_cart',
    attributes: {}
  };

  static readonly workflowState: ResourceDefinition = {
    type: 'workflow_state--workflow_state',
    collectionPath: 'workflow_state/workflow_state',
    attributes: {}
  };

  static readonly resourceDefinitions: Array<ResourceDefinition> = [
    NgrxJsonApiDefinitions.innovation,
    NgrxJsonApiDefinitions.freeTagging,
    NgrxJsonApiDefinitions.file,
    NgrxJsonApiDefinitions.innovationCategory,
    NgrxJsonApiDefinitions.brands,
    NgrxJsonApiDefinitions.newsCategory,
    NgrxJsonApiDefinitions.awards,
    NgrxJsonApiDefinitions.advancement,
    NgrxJsonApiDefinitions.user,
    NgrxJsonApiDefinitions.news,
    NgrxJsonApiDefinitions.inspiration,
    NgrxJsonApiDefinitions.vote,
    NgrxJsonApiDefinitions.cart,
    NgrxJsonApiDefinitions.page,
    NgrxJsonApiDefinitions.workflowScheduledTransition,
    NgrxJsonApiDefinitions.workflowState,
    NgrxJsonApiDefinitions.workflowCartTransition,
    NgrxJsonApiDefinitions.mhProfile,
    NgrxJsonApiDefinitions.mediaRemoteVideo,
    NgrxJsonApiDefinitions.mediaImage,
    NgrxJsonApiDefinitions.mediaOriginalVideo,
    NgrxJsonApiDefinitions.mediaVideo,
    NgrxJsonApiDefinitions.searchIndex
  ];

}
