<ng-container *ngIf="render">

  <h1 class="mb-3">User Function Icons</h1>

  <ng-template [ngTemplateOutlet]="userFunctionMenuTemplate"
               [ngTemplateOutletContext]="{itemLayoutClasses: ['mr-3']}">
  </ng-template>

  <h1 class="mb-3">User Function Icons with Labels</h1>

  <ng-template
    [ngTemplateOutlet]="userFunctionMenuTemplate"
    [ngTemplateOutletContext]="{showLabel: true, layoutClasses: ['flex-column']}">
  </ng-template>

</ng-container>

<ng-template #userFunctionMenuTemplate let-showLabel="showLabel" let-layoutClasses="layoutClasses" let-itemLayoutClasses="itemLayoutClasses">

  <ng-container *ngIf="menusService.getUserMenu()|async as userMenu; else loadingTemplate">

    <ul class="user-functions-list list-style-type-none d-flex p-0 m-0" [ngClass]="layoutClasses">

      <ng-template ngFor [ngForOf]="userMenu" let-menuItem>

        <li [ngClass]="itemLayoutClasses">

          <ng-template
            [ngTemplateOutlet]="userFunctionLinkTemplate"
            [ngTemplateOutletContext]="{menuItem: menuItem, showLabel:showLabel}">
          </ng-template>

        </li>

      </ng-template>

    </ul>

  </ng-container>

</ng-template>

<ng-template #userFunctionLinkTemplate let-menuItem="menuItem" let-showLabel="showLabel">

  <a
    [routerLink]="menuItem.relative"
    #rla="routerLinkActive"
    [routerLinkActive]="'active'"
    class="user-function-icon-link d-flex flex-column align-items-center justify-content-center pt-2">

    <button
      mat-fab
      color="accent"
      class="user-function-icon circle-button"
      [class.active]="rla.isActive"
      [matTooltip]="menuItem.title"
      [matBadgeHidden]="((menuItem.relative|userIconCount)|async)==0 || menuItem.relative === '/my-contributions'"
      [matBadge]="(menuItem.relative|userIconCount)|async" matBadgeColor="accent">
      <ng-template
        [ngTemplateOutlet]="userFunctionIconTemplate"
        [ngTemplateOutletContext]="{menuItem: menuItem, showLabel:showLabel}">
      </ng-template>
    </button>
    <ng-container *ngIf="showLabel">
      <span class="mt-1 text-center mat-body-1 main-font" [innerHTML]="(menuItem.relative|menuItemShortLabel)|translate"></span>
    </ng-container>

  </a>



</ng-template>

<ng-template #userFunctionIconTemplate let-menuItem="menuItem">
  <mat-icon [svgIcon]="userService.getIcon(menuItem.relative)"></mat-icon>
</ng-template>

<ng-template #loadingTemplate>
  <mat-spinner [diameter]="15" color="primary"></mat-spinner>
</ng-template>
