import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {NgrxJsonApiService, NgrxJsonApiStoreResources, Query, StoreResource} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiQueries} from '../../ngrx-json-api/ngrx-json-queries';
import {filter, map, mergeMap, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-inspirations-list',
  templateUrl: './inspirations-list.component.html',
  styleUrls: ['./inspirations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InspirationsListComponent implements OnInit, AfterViewInit {

  query: Query = {...NgrxJsonApiQueries.queryInspirationNodeCollectionInclInnovations};
  queryId: string = this.query.queryId;

  inspirations$: Observable<StoreResource[]> = this.userService.userUid$.pipe(
    filter(userId => {
      return !!userId;
    }),
    take(1),
    tap((userId) => {
        this.ngrxJsonApiService
          .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
          .putQuery({query: this.query, fromServer: true});
    }),
    switchMap(() => {
      return this.ngrxJsonApiService
        .getZone(NgrxJsonApiDefinitions.zoneArtBoards)
        .selectStoreResourcesOfType(NgrxJsonApiDefinitions.inspiration.type)
        .pipe(
          filter(ngrxJsonApiStoreResources => !!ngrxJsonApiStoreResources),
          map((ngrxJsonApiStoreResources: NgrxJsonApiStoreResources) => {
            if (ngrxJsonApiStoreResources) {
              return Object.keys(ngrxJsonApiStoreResources).map(key => ngrxJsonApiStoreResources[key]);
            } else {
              return [];
            }
          })
        );
    }),
    shareReplay(1)
  );

  constructor(
    private ngrxJsonApiService: NgrxJsonApiService,
    private store: Store<any>,
    private userService: UserService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

  }

}
