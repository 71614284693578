import {Action} from '@ngrx/store';
import {DrupalMenuItem} from '@ngx-mil-drupal/models/menu.model';

export enum MenuActionTypes {
  LoadMenu = '[Menu] LoadMenu',
  LoadMenuSuccess = 'Menu] LoadMenu > Success',
  LoadMenuFail = 'Menu] LoadMenu > Fail'
}


export class LoadMenu implements Action {
  readonly type = MenuActionTypes.LoadMenu;

  constructor(public menuId: string, public language: string) {

  }
}

export class LoadMenuSuccess implements Action {
  readonly type = MenuActionTypes.LoadMenuSuccess;

  constructor(public menu: DrupalMenuItem[], public menuId: string, public language: string) {

  }
}

export class LoadMenuFail implements Action {
  readonly type = MenuActionTypes.LoadMenuFail;

  constructor(public menuId: string, public language: string) {

  }
}


export type MenuActionsUnion =
  LoadMenu |
  LoadMenuSuccess |
  LoadMenuFail;
