export enum Templates {
  contributionFilters = 'contributionFilters',
  myContributionsHeader = 'myContributionsHeader',
  toggleBarStateFilters = 'toggleBarStateFilters',
  searchFiltersGroup = 'searchFiltersGroup',
  newsForm = 'newsForm',
  newsMediaForm = 'newsMediaForm',
  innovationForm = 'innovationForm',
  voteHeader = 'voteHeader',
  listRenderer = 'listRenderer',
  listSmallRenderer = 'listSmallRenderer',
  innovationId = 'innovationId',
  listLink = 'listLink',
  contentHeader = 'contentHeader',
  pwaBanner = 'pwaBanner',
  searchFullTextDetail = 'searchFullTextDetail',
  userFunctionMenu = 'userFunctionMenu',
  userFunctionLink = 'userFunctionLink',
  userFunctionIcon = 'userFunctionIcon',
  backDetailButton = 'backDetailButton',
  searchFilters = 'searchFilters',
  searchSelectFilter = 'searchSelectFilter',
  defaultSearchFilters = 'defaultSearchFilters',
  mainHeader = 'mainHeader',
  searchIndexResultCount = 'searchIndexResultCount',
  mainHeaderSearch = 'mainHeaderSearch',
  mainHeaderInspirations = 'mainHeaderInspirations',
  inspirationsActions = 'inspirationsActions',
  alertWindow = 'alertWindow',
  window = 'window',
  renameInspirationContent = 'renameInspirationContent',
  renameInspirationActions = 'renameInspirationActions',
  testWindowContent = 'alertWindowContent',
  testWindowActions = 'alertWindowActions',
  createInspirationActions = 'createInspirationActions',
  createInspirationContent = 'createInspirationContent',
  voteConfirmationContent = 'voteConfirmationContent',
  voteConfirmationActions = 'voteConfirmationActions',
  myContributionsActions = 'myContributionsActions',
  mainHeaderContributions = 'mainHeaderContributions',
  bigIcon = 'bigIcon',
  formEditContent = 'formEditContent',
  formEditActions = 'formEditActions',
  innovationWhy = 'innovationWhy',
  freeTags = 'freeTags',
  innovationInfos = 'innovationInfos',
  innovationDescription = 'innovationDescription',
  appFooter = 'appFooter',
  imagesSlider = 'imagesSlider',
  removeFromBoardConfirmationContent = 'removeFromBoardConfirmationContent',
  removeFromBoardActions = 'removeFromBoardActions',
  pwaUpdate = 'pwaUpdate',
  pwaUpdateActions = 'pwaUpdateActions',
  sharedRecipientSearchItem = 'sharedRecipientSearchItem',
  userChip = 'userChip',
  serverErrorSnackBar = 'serverErrorSnackBar',
  submitConfirm = 'submitConfirm',
  submitConfirmActions = 'submitConfirmActions',
  userAccount = 'userAccount',
  settings = 'settings',
  clearLocalStorage = 'clearLocalStorage',
  clearLocalStorageActions = 'clearLocalStorageActions',
  syncData = 'syncData',
  syncDataActions = 'syncDataActions',
  breadcrumbs = 'breadcrumbs',
  simplePage = 'simplePage',
  brandTags = 'brandTags',
  awardTags = 'awardTags',
  newsCategoryLaunchDate = 'newsCategoryLaunchDate',
  myShare = 'myShare',
  searchFullText = 'searchFullText',
  newsDescription = 'newsDescription',
  previewTemplate = 'previewTemplate',
  deleteConfirm = 'deleteConfirm',
  deleteConfirmationActions = 'deleteConfirmationActions',
  deleteError = 'deleteError',
  deleteErrorActions = 'deleteErrorActions',
  saveError = 'saveError',
  saveErrorActions = 'saveErrorActions'

}
