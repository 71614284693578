import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NgrxJsonApiService, Resource, uuid} from 'ngrx-json-api';
import {Store} from '@ngrx/store';
import {OpenTemplateAlert} from '../../store/actions/app-core.actions';
import {TemplatesService} from '../../templates.service';
import {Templates} from '../templates';
import {NgrxJsonApiDefinitions} from '../../ngrx-json-api/ngrx-json-api-definitions';
import {PollService} from '../../services/poll.service';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {UserService} from '../../services/user.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-vote-header',
  templateUrl: './vote-header.component.html',
  styleUrls: ['./vote-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoteHeaderComponent implements OnInit, AfterViewInit {

  node$: ReplaySubject<Resource> = new ReplaySubject<Resource>(1);

  _node: Resource;
  @Input()
  set node(value: Resource) {
    this._node = value;
    this.node$.next(this._node);
    this.voted = 'no';
    this.voteYes = null;
    this.cdr.detectChanges();
  }

  @Input()
  zone: string;

  @ViewChild('voteConfirmationContentTemplate', {static: true})
  voteConfirmationContentTemplate: TemplateRef<any>;

  @ViewChild('voteConfirmationActionsTemplate', {static: true})
  voteConfirmationActionsTemplate: TemplateRef<any>;

  get voteYes(): boolean {
    return this._voteYes;
  }

  set voteYes(value: boolean) {
    this._voteYes = value;
    if (this._voteYes != null) {
      this.invalidateVote();
    }
  }

  isVotedContent$: Observable<string>;

  private _voteYes: boolean;

  public voted = 'no';

  commentControl: UntypedFormControl = new UntypedFormControl('');

  constructor(
    public dialog: MatDialog,
    private ngrxJsonApiService: NgrxJsonApiService,
    private store: Store<any>,
    private templatesService: TemplatesService,
    public pollService: PollService,
    public userService: UserService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

    this.templatesService.addTemplate(Templates.voteConfirmationContent, this.voteConfirmationContentTemplate);
    this.templatesService.addTemplate(Templates.voteConfirmationActions, this.voteConfirmationActionsTemplate);

    /*this.isVotedContent$ = this.pollService.isVotedContent$(this.node.id).pipe(
      map(isVoted => isVoted === true ? 'yes' : 'no')
    );*/

    this.isVotedContent$ = this.node$.pipe(
      filter(node => !!node),
      switchMap(node => {
        return this.pollService.isVotedContent$(node.id).pipe(
          map(isVoted => isVoted === true ? 'yes' : 'no')
        );
      })
    );

    this.cdr.detectChanges();

  }

  invalidateVote() {

    this.commentControl.reset();

    this.store.dispatch(new OpenTemplateAlert({
        contentTemplate: Templates.voteConfirmationContent,
        actionsTemplate: Templates.voteConfirmationActions
      },
      {
        width: this._voteYes ? '360px' : '500px',
        disableClose: true
      }));

  }

  confirmVote() {

    this.userService.userUid$.pipe(take(1)).subscribe(userUuid => {

      this.voted = 'yes';

      let comment: string;
      if (!this._voteYes) {
        comment = this.commentControl.value;
      }

      const vote: Resource = {
        type: NgrxJsonApiDefinitions.vote.type,
        id: uuid(),
        attributes: {
          field_name: 'field_voting',
          value: this._voteYes === true ? 1 : 0,
          entity_type: 'node'
        },
        relationships: {
          entity_id: {data: {id: this._node.id, type: this._node.type}},
          user_id: {data: {id: userUuid, type: NgrxJsonApiDefinitions.user.type}}
        }
      };

      if (!!comment) {
        vote.attributes.field_vote_comment = comment;
      }

      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zonePolling).postResource({resource: vote, toRemote: true});

      this.ngrxJsonApiService.getZone(NgrxJsonApiDefinitions.zonePolling).selectStoreResource(vote)
        .pipe(
          filter(voteResource => voteResource.state === 'IN_SYNC'),
          take(1)
        ).subscribe(voteResource => {

        this.pollService.loadUserVotes();

        this.ngrxJsonApiService.getZone(this.zone).putQuery({
          query: {
            queryId: this._node.id,
            type: this._node.type,
            id: this._node.id
          },
          fromServer: true
        });
      });

    });

  }

  cancelVote() {
    this.voted = 'no';
    this.voteYes = null;
    this.cdr.detectChanges();
  }

}
