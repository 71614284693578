import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';
import {RouterReducerState} from '@ngrx/router-store';

import * as fromAppCore from './app-core.reducers';
import * as fromAuth from './auth.reducers';
import {AuthState} from './auth.reducers';
import * as fromSearch from './search.reducers';
import {SearchContentTypeState, SearchI18nState} from './search.reducers';

import {environment} from '../../../environments/environment';
import {NgrxJsonApiState} from 'ngrx-json-api';
import {InnovationIndexItem} from '../../apollo/innovation-index-item';
import {Observable} from 'rxjs';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */


/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: fromRouter.RouterReducerState;
  appCore: fromAppCore.State;
  auth: fromAuth.AuthState;
  search: fromSearch.SearchState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  appCore: fromAppCore.reducer,
  auth: fromAuth.reducer,
  search: fromSearch.searchReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): any => {
    const result = reducer(state, action);

    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];

export const getAppCoreState = createFeatureSelector< fromAppCore.State>(
  'appCore'
);

export const getDefaultLanguage = createSelector(
  getAppCoreState,
  fromAppCore.getDefaultLanguage
);

export const getCurrentLanguage = createSelector(
  getAppCoreState,
  fromAppCore.getCurrentLanguage
);

export const getUnSeenNewsCount = createSelector(
  getAppCoreState,
  fromAppCore.getUnSeenNewsCount
);

export const getUnSeenInnovationsCount = createSelector(
  getAppCoreState,
  fromAppCore.getUnSeenInnovationsCount
);


export const getAutoPlay = createSelector(
  getAppCoreState,
  fromAppCore.getAutoPlay
);

export const getAvailableLanguages = createSelector(
  getAppCoreState,
  fromAppCore.getAvailableLanguages
);


export const getBreakpoint = createSelector(
  getAppCoreState,
  fromAppCore.getBreakpoint
);

export const getMarketingConsent = createSelector(
  getAppCoreState,
  fromAppCore.getMarketingConsent
);

export const getPreferencesConsent = createSelector(
  getAppCoreState,
  fromAppCore.getPreferencesConsent
);

export const getStatisticsConsent = createSelector(
  getAppCoreState,
  fromAppCore.getStatisticsConsent
);

export const getListThumbSize = createSelector(
  getAppCoreState,
  fromAppCore.getListThumbSize
);

export const getBreadcrumbs = createSelector(
  getAppCoreState,
  fromAppCore.getBreadcrumbs
);

export const getRedirectUrl = createSelector(
  getAppCoreState,
  fromAppCore.getRedirectUrl
);

export const getSyncData = createSelector(
  getAppCoreState,
  fromAppCore.getSyncData
);

export const getInnovationKeywords = createSelector(
  getAppCoreState,
  fromAppCore.getInnovationKeywords
);

export const getNewsKeywords = createSelector(
  getAppCoreState,
  fromAppCore.getNewsKeywords
);

export const getPageTitle = createSelector(
  getAppCoreState,
  fromAppCore.getPageTitle
);

export const getRouterState = createFeatureSelector< fromRouter.RouterReducerState>(
  'router'
);

export const getCurrentUrl = createSelector(
  getRouterState,
  (state: RouterReducerState) => state?.state?.url
);

export const getRouteParams = createSelector(
  getRouterState,
  (state: RouterReducerState) => state && state.state['params']
);

export const getRouteQueryParams = createSelector(
  getRouterState,
  (state: RouterReducerState) => state && state.state['queryParams']
);


export const getRouteLanguage = createSelector(
  getRouterState,
  (state: RouterReducerState) => state && state.state['params'].lang
);

export const getRouteData = createSelector(
  getRouterState,
  (state: RouterReducerState) => state?.state['data']
);

export const isStaticRoute = createSelector(
  getRouteData,
  (data: any) => data?.staticRoute === true
);

export const staticRouteTitle = createSelector(
  getRouteData,
  (data: any) => data?.title
);

export const getRouteContentTypes = createSelector(
  getRouteData,
  (routeData: any) => routeData['contentTypes']
);

export const getRouteContext = createSelector(
  getRouteData,
  (data: any) => data && data.context
);

export const getRouteIsHome = createSelector(
  getRouteData,
  (data: any) => data && data.home === true
);

export const getRouteAppClassName = createSelector(
  getRouterState,
  (state: RouterReducerState) => state && state.state['data'] && state.state['data']['appClass']
);

export const getRouteMainGroupClassName = createSelector(
  getRouterState,
  (state: RouterReducerState) => state && state.state['data'] && state.state['data']['mainGroupClass']
);

export const getRouteMainContentGroupClassName = createSelector(
  getRouterState,
  (state: RouterReducerState) => {
    return state
    && state.state['data']
    && state.state['data']['mainContentGroupClass'] ? state.state['data']['mainContentGroupClass'] : null;
  }
);

export const getRouteTopGroupClassName = createSelector(
  getRouterState,
  (state: RouterReducerState) => state
  && state.state['data']
  && state.state['data']['topGroupClass'] ? state.state['data']['topGroupClass'] : null
);

export const getNgrxJsonApiState = createFeatureSelector<NgrxJsonApiState>(
  'NgrxJsonApi'
);

export const getNgrxJsonApiZone = (zone: string) => createSelector(
  getNgrxJsonApiState,
  (state: NgrxJsonApiState) => state && state.zones && state.zones[zone]
);

export const getAuthState = createFeatureSelector< fromAuth.AuthState>(
  'auth'
);

export const getAuthenticated = createSelector(
  getAuthState,
  (state: AuthState) => state && state.authenticated === true
);

export const getAuthenticatedLoaded = createSelector(
  getAuthState,
  (state: AuthState) => state && state.loaded === true
);

export const getAuthClaims = createSelector(
  getAuthState,
  (state: AuthState) => state?.claims
);

export const getSearchi18nState = (language: string) => createSelector(
  getSearchState,
  (state: SearchI18nState) => state && state[language]
);

export const getSearchState = createFeatureSelector< fromSearch.SearchState>(
  'search'
);


export const getSearchContentTypeState = (language: string, contentType: string) => createSelector(
  getSearchi18nState(language),
  (state: SearchI18nState) => state && state[contentType]
);

export const getItemsByContentType = (language: string, contentType: string) => createSelector(
  getSearchState,
  (state: fromSearch.SearchState) => state?.[language]?.[contentType]?.items
);
