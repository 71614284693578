import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyContributionsComponent } from './my-contributions/my-contributions.component';
import {ComponentsModule} from '../components/components.module';
import {SharedModule} from '../shared.module';

@NgModule({
  declarations: [MyContributionsComponent],
  exports: [
    MyContributionsComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SharedModule
  ]
})
export class MyContributionsModule { }
