import {Inject, Injectable} from '@angular/core';
import {AuthConfig, OAuthErrorEvent, OAuthModuleConfig, OAuthService} from 'angular-oauth2-oidc';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {
  AuthActionType,
  AuthenticatedChangeAction,
  InitAuthAction,
  InitImplicitFlowAction,
  OAuthEventAction,
  SetUpAuthAction
} from '../actions/auth.actions';
import {catchError, filter, map, mergeMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {getAuthState} from '../reducers';
import {EMPTY, from, of} from 'rxjs';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {setUser} from '@sentry/browser';
import {AppCoreActionTypes, ClearLocalStorage, SetRedirectUrl} from '../actions/app-core.actions';
import {AppConfig, AppConfigService} from '@config/app-config.service';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {NGRX_JSON_API_CONFIG, NgrxJsonApiConfig} from 'ngrx-json-api';
import {HttpLink} from 'apollo-angular-link-http';
import {Apollo} from 'apollo-angular';
import {MatDialog} from '@angular/material/dialog';
import {PwaService} from '../../pwa.service';
import {DbService} from '../../services/db.service';
import {AlertService} from '../../services/alert.service';

export interface AuthClaims {

  sub: string;
  email: string;
  preferred_username: string;

}

@Injectable()
export class AuthEffects {

  bootUrl: string;

  constructor(
    protected actions: Actions,
    protected store: Store<fromRoot.State>,
    protected oauthService: OAuthService,
    protected userService: UserService,
    protected router: Router,
    private httpLink: HttpLink,
    private appConfigService: AppConfigService,
    private apollo: Apollo,
    private oauthModuleConfig: OAuthModuleConfig,
    private dialog: MatDialog,
    private pwaService: PwaService,
    @Inject(NGRX_JSON_API_CONFIG) private ngrxJsonAPIConfig: NgrxJsonApiConfig,
    private dbService: DbService,
    private alertService: AlertService,
  ) {
  }

  
  readonly initApplication$ = createEffect(() => this.actions.pipe(
    ofType(ROOT_EFFECTS_INIT),
    tap(() => {
      this.appConfigService.appConfig$.subscribe(appConfig => {
        if (location.href.indexOf('?bypassauth=true') === -1) {
          if (localStorage.getItem('directUrl')) {
            this.bootUrl = localStorage.getItem('directUrl');
            localStorage.removeItem('directUrl');
          } else {
            this.bootUrl = location.href.replace(location.origin, '');
          }
          this.store.dispatch(new SetUpAuthAction());
        } else {
          this.oauthService.logOut();
          this.pwaService.openAppSettings();
        }
      });

      this.oauthService.events.subscribe(e => (e instanceof OAuthErrorEvent) ? console.error(e) : console.warn(e));

      this.oauthService.events.subscribe(event => {
        this.store.dispatch(new OAuthEventAction(event));
      });

    })
  ), {dispatch: false});

  
  readonly setUpAuth$ = createEffect(() => this.actions.pipe(
    ofType(AuthActionType.AUTH_SET_UP),
    map((action) => {
      const appConfig: AppConfig = this.appConfigService.getConfig();
      const oauthTokenUrl = appConfig.oauth.tokenUrl;
      const oauthIssuer = appConfig.oauth.issuer;
      const oauthUserInfoUrl = appConfig.oauth.userInfoUrl;
      const oauthClientId = appConfig.oauth.clientId;
      const oauthAuthorizationUrl = appConfig.oauth.authorizationUrl;


      const link = this.httpLink.create({uri: appConfig.graphQlUrl});
      const cache = new InMemoryCache();

      this.apollo.create({
        link,
        cache
        // other options like cache
      });

      this.ngrxJsonAPIConfig.apiUrl = appConfig.jsonapiUrl;
      this.ngrxJsonAPIConfig.applyEnabled = false;
      this.ngrxJsonAPIConfig.operationsApplyEnabled = true;
      this.ngrxJsonAPIConfig.operationsUrl = appConfig.jsonApiOperationsUrl;
      this.ngrxJsonAPIConfig.diffUpdates = true;


      this.oauthModuleConfig.resourceServer.sendAccessToken = true;
      this.oauthModuleConfig.resourceServer.allowedUrls = [
        appConfig.backendUrl,
        appConfig.jsonrpc
      ];
      const authConfig: AuthConfig = {
        requireHttps: true,
        loginUrl: oauthAuthorizationUrl,
        redirectUri: window.location.origin,
        tokenEndpoint: oauthTokenUrl,
        userinfoEndpoint: oauthUserInfoUrl,
        issuer: oauthIssuer,
        clientId: oauthClientId,
        scope: 'openid',
        oidc: true,
        responseType: 'code',
        skipIssuerCheck: true,
        disableAtHashCheck: true,
        skipSubjectCheck: true,
        clearHashAfterLogin: true,
        showDebugInformation: true,
      };

      this.oauthService.configure(authConfig);
      this.oauthService.tokenValidationHandler = null;
      const originalFn = this.oauthService.processIdToken;
      this.oauthService.processIdToken = (idToken, accessToken, skipNonceCheck) => {
        return originalFn.apply(this.oauthService, [idToken, accessToken, true]);
      };
      this.oauthService.setupAutomaticSilentRefresh();
      return new InitAuthAction();
    })
  ));

  
  readonly initImplicitFlow$ = createEffect(() => this.actions.pipe(
    ofType(AuthActionType.INIT_IMPLICIT_FLOW),
    tap((action: InitImplicitFlowAction) => {
      this.store.dispatch(new SetRedirectUrl(action.redirectUrl));
      this.oauthService.initCodeFlow();
    })
  ), {dispatch: false});

  
  readonly setDirectAccess$ = createEffect(() => this.actions.pipe(
    ofType(AppCoreActionTypes.SetRedirectUrl),
    tap((action: SetRedirectUrl) => {
      // console.log('action.url', action.url);
      if (action.url) {
        this.dbService.setKey('redirectUrl', action.url).subscribe();
      } else {
        this.dbService.delete('redirectUrl').subscribe();
      }
    })
  ), {dispatch: false});

  
  readonly oauthEvent$ = createEffect(() => this.actions.pipe(
    ofType(AuthActionType.OAUTH_EVENT),
    mergeMap((action: OAuthEventAction) => {
        // console.log('action.event.type', action.event.type);
        switch (action.event.type) {
          case 'token_refreshed':
            return of(new AuthenticatedChangeAction(true, this.oauthService.getIdentityClaims()));
          case 'token_error':
            return of(new AuthenticatedChangeAction(false));
          case 'silent_refresh_error':
          case 'silent_refresh_timeout':
            if (!this.oauthService.hasValidAccessToken()) {
              return of(new AuthenticatedChangeAction(false));
            }
            break;
          case 'logout':
            this.store.dispatch(new ClearLocalStorage(true));
            this.dialog.closeAll();
            this.alertService.openNeedAuthentication();
            return of(new AuthenticatedChangeAction(false));
            break;
          case 'user_profile_load_error':
            return of(new AuthenticatedChangeAction(false));
            break;
          case 'user_profile_loaded':
            /*
            quand on a le token, visiblement c'est le seul évènement qui nous dit qu'on est authentifié,
            du coup j'ai ajouté l'action qui permet de setter authenticated true dans le store
             */
            const userUuid: string = this.oauthService.getIdentityClaims()['sub'];
            this.userService.loadCurrentUser(userUuid);
            return of(new AuthenticatedChangeAction(true, this.oauthService.getIdentityClaims()));
            break;
        }
        return EMPTY;
      }
    )
  ));

  
  readonly init$ = createEffect(() => this.actions.pipe(
    ofType(AuthActionType.AUTH_INIT),
    mergeMap((action: OAuthEventAction) => {
      return from(this.oauthService.tryLogin()).pipe(
        map(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            // set not authenticated
            return new AuthenticatedChangeAction(false);
          } else {
            if ((this.oauthService.getIdentityClaims() as any)?.sub) {
              // "sub" claim available, set authenticated and try to load user
              const userUuid: string = (this.oauthService.getIdentityClaims() as any)?.sub;
              this.userService.loadCurrentUser(userUuid);
              return new AuthenticatedChangeAction(true, this.oauthService.getIdentityClaims());
            } else {
              // no "sub" claim available to load user
              return new AuthenticatedChangeAction(false);
            }
          }
        }),
        catchError(() => {
          return of(new AuthenticatedChangeAction(false));
        })
      );
    }),
  ));

  
  initialAuthentication$ = createEffect(() => this.actions.pipe(
    ofType(AuthActionType.AUTHENTICATED_CHANGE),
    take(1),
    withLatestFrom(this.store.select(getAuthState)),
    filter(([action, authState]) => {
      return !authState.authenticated && !authState.indeterminateAuthenticationStatus;
    }),
    map(([action, authState]) => {
      const currentPath = location.href.replace(location.origin, '');
      return new InitImplicitFlowAction(currentPath.startsWith('/logout') ? '/' : currentPath);
    })
  ));

  
  setSentryUser$ = createEffect(() => this.actions.pipe(
    ofType(AuthActionType.AUTHENTICATED_CHANGE),
    withLatestFrom(this.store.select(getAuthState)),
    tap(([action, authState]) => {
      if (!authState.indeterminateAuthenticationStatus) {
        if (authState.authenticated) {
          const claims = this.oauthService.getIdentityClaims();
          if (claims) {
            setUser({
              id: claims['sub'],
              username: claims['preferred_username'],
              email: claims['email'],
            });
          }
        } else {
          setUser(null);
        }
      }
    })
  ), {dispatch: false});

}


