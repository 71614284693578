import {Action} from '@ngrx/store';
import {CookieBotConsent} from '../reducers/app-core.reducers';
import {ListThumbSizes} from '../../models/list-thumb-sizes.model';
import {Breadcrumb} from '../../models/breadcrumb';
import {WindowDataModel} from '../../models/window-data.model';
import {MatDialogConfig} from '@angular/material/dialog';
import {Keyword} from '../effects/app-core.effects';

export enum AppCoreActionTypes {
  SetPageTitle = '[AppCore] Set Page Title',
  SetCurrentLanguage = '[AppCore] Set Current Language',
  SetDefaultLanguage = '[AppCore] Set Default Language',
  SetAvailableLanguages = '[AppCore] Set Available Language',
  SetBreakpoint = '[AppCore] Set Breakpoint',
  SetCookieBotConsent = '[AppCore] Set CookieBotConsent',
  SetListThumbSize = '[AppCore] Set List Thumb Size',
  UpdateBreadcrumbs = '[AppCore] Update Breadcrumbs',
  UpdateBreadcrumb = '[AppCore] Update Breadcrumb',
  OpenAlert = '[AppCore] Open Alert',
  OpenTemplateAlert = '[AppCore] Open Template Alert',
  OpenWindow = '[AppCore] Open Window',
  SetRedirectUrl = '[AppCore] Set Redirect Url',
  ClearLocalStorage = '[AppCore] Clear Local Storage',
  ClearLocalStorageRequest = '[AppCore] Clear Local Storage Request',
  ChangeSyncDataRequest = '[AppCore] Change Sync Data Request',
  ChangeSyncData = '[AppCore] Change Sync Data',
  HydrateStore = '[AppCore] Hydrate Store',
  LoadFacets = '[AppCore] Load Facets (News or Innovation)',
  UpdateContentCount = '[AppCore] Update Content Count (News or Innovation)',
  UpdateKeywords = '[AppCore] Update Keywords (News or Innovation)',
  SetAutoPlay = '[AppCore] Set Auto Play',
  NavigateRandomly = '[AppCore] Navigate Randomly',
  LoadNewContents = '[AppCore] New Contents (News or Innovation)',
  SetUnSeenContents = '[AppCore] Set UnSeen Contents (News or Innovation)',
}

export class SetRedirectUrl implements Action {
  readonly type = AppCoreActionTypes.SetRedirectUrl;

  constructor(public url: string) {

  }
}

export class SetPageTitle implements Action {
  readonly type = AppCoreActionTypes.SetPageTitle;

  constructor(public title: string, public contentType: string = null) {

  }
}

export class SetAutoPlay implements Action {
  readonly type = AppCoreActionTypes.SetAutoPlay;

  constructor(public autoPlay: boolean) {

  }
}

export class NavigateRandomly implements Action {
  readonly type = AppCoreActionTypes.NavigateRandomly;

  constructor() {

  }
}

export class SetDefaultLanguage implements Action {
  readonly type = AppCoreActionTypes.SetDefaultLanguage;

  constructor(public language: string) {

  }
}

export class SetUnSeenContents implements Action {

  readonly type = AppCoreActionTypes.SetUnSeenContents;

  constructor(public contentType: string, public count: number) {

  }
}

export class LoadNewContents implements Action {

  readonly type = AppCoreActionTypes.LoadNewContents;

  constructor(public contentType: string, public timestamp: number) {

  }
}

export class SetCurrentLanguage implements Action {
  readonly type = AppCoreActionTypes.SetCurrentLanguage;

  constructor(public language: string) {

  }
}

export class SetAvailableLanguages implements Action {
  readonly type = AppCoreActionTypes.SetAvailableLanguages;

  constructor(public languages: string[]) {

  }
}

export class SetBreakpoint implements Action {
  readonly type = AppCoreActionTypes.SetBreakpoint;

  constructor(public breakpoint: string) {

  }
}

export class SetCookieBotConsent implements Action {
  readonly type = AppCoreActionTypes.SetCookieBotConsent;

  constructor(public cookieBotConsent: CookieBotConsent) {

  }
}

export class SetListThumbSize implements Action {
  readonly type = AppCoreActionTypes.SetListThumbSize;

  constructor(public size: ListThumbSizes) {

  }
}

export class UpdateBreadcrumbs implements Action {
  readonly type = AppCoreActionTypes.UpdateBreadcrumbs;

  constructor(public breadcrumbs: Breadcrumb[]) {

  }
}

export class UpdateBreadcrumb implements Action {
  readonly type = AppCoreActionTypes.UpdateBreadcrumb;

  constructor(public breadcrumb: Breadcrumb) {

  }
}

export class OpenAlert implements Action {
  readonly type = AppCoreActionTypes.OpenAlert;

  constructor(public message: string) {

  }
}

export class OpenTemplateAlert implements Action {
  readonly type = AppCoreActionTypes.OpenTemplateAlert;

  constructor(public windowData: WindowDataModel, public config?: MatDialogConfig) {
  }
}

export class OpenWindow implements Action {

  readonly type = AppCoreActionTypes.OpenWindow;

  constructor(
    public title: string,
    public windowData: WindowDataModel,
    public config?: MatDialogConfig
  ) {
  }

}

export class ClearLocalStorageRequest implements Action {

  readonly type = AppCoreActionTypes.ClearLocalStorageRequest;

  constructor() {
  }

}

export class ClearLocalStorage implements Action {

  readonly type = AppCoreActionTypes.ClearLocalStorage;

  constructor(public allData: boolean = false) {
  }

}

export class ChangeSyncDataRequest implements Action {

  readonly type = AppCoreActionTypes.ChangeSyncDataRequest;

  constructor(
    public syncData: boolean
  ) {
  }

}

export class ChangeSyncData implements Action {

  readonly type = AppCoreActionTypes.ChangeSyncData;

  constructor(
    public syncData: boolean
  ) {
  }

}

export class HydrateStore implements Action {

  readonly type = AppCoreActionTypes.HydrateStore;

  constructor() {
  }

}

export class LoadFacets implements Action {

  readonly type = AppCoreActionTypes.LoadFacets;

  constructor(
    public contentType: string
  ) {
  }

}

export class UpdateContentCount implements Action {

  readonly type = AppCoreActionTypes.UpdateContentCount;

  constructor(
    public contentType: string,
    public count: number
  ) {
  }

}

export class UpdateKeywords implements Action {

  readonly type = AppCoreActionTypes.UpdateKeywords;

  constructor(
    public contentType: string,
    public keywords: Keyword[]
  ) {
  }

}

export type AppCoreActionsUnion =
  SetCurrentLanguage |
  SetDefaultLanguage |
  SetBreakpoint |
  SetCookieBotConsent |
  SetAvailableLanguages |
  SetListThumbSize |
  UpdateBreadcrumbs |
  UpdateBreadcrumb |
  OpenWindow |
  OpenAlert |
  OpenTemplateAlert |
  SetRedirectUrl |
  ClearLocalStorage |
  ClearLocalStorageRequest |
  ChangeSyncDataRequest |
  ChangeSyncData |
  HydrateStore |
  LoadFacets |
  UpdateContentCount |
  UpdateKeywords |
  SetAutoPlay |
  NavigateRandomly |
  SetPageTitle |
  LoadNewContents |
  SetUnSeenContents;
