import {Component, HostBinding, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Roles} from '../../models/roles';
import {AppConfigService} from '@config/app-config.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {SetAutoPlay} from '../../store/actions/app-core.actions';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  @HostBinding('class') class = 'overflow-y-auto overflow-x-hidden';

  scopeMap: { [roleId: string]: string } = {
    [Roles.contributor]: 'front',
    [Roles.jury]: 'front',
    [Roles.administrator]: 'back',
    [Roles.editor]: 'back',
    [Roles.newsletter_manager]: 'back',
    [Roles.user_manager]: 'back'
  };

  roles$: Observable<{ id: string, label: string, scope: string }[]> = this.userService.roles$.pipe(
    map(roles => {

      return roles.map(role => {
        return {
          id: role.attributes.drupal_internal__id,
          label: role.attributes.label,
          scope: this.scopeMap[role.attributes.drupal_internal__id]
        };
      });
    }),
    map(roles => {
      roles.unshift({
        id: 'user',
        label: 'User',
        scope: 'front'
      });
      return roles;
    })
  );

  constructor(
    public userService: UserService,
    public appConfig: AppConfigService,
    private oauthService: OAuthService,
    private httpClient: HttpClient,
    private router: Router,
    private matDialog: MatDialog,
    private store: Store<any>
  ) {
  }

  ngOnInit() {
  }

  onLogOutClick() {
    this.oauthService.logOut();
  }

  onAutoPlayClick() {
    this.matDialog.closeAll();
    this.store.dispatch(new SetAutoPlay(true));
  }

}
