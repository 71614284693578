import {SchemaValidatorFactory} from 'ngx-schema-form';
import * as ZSchema from 'z-schema';
import {Injectable} from '@angular/core';

@Injectable()
export class MhiSchemaValidatorFactory extends SchemaValidatorFactory {

  protected zschema;

  constructor() {
    super();
    this.createSchemaValidator();
  }

  private createSchemaValidator() {
    ZSchema.registerFormat('uuid', function (str) {
      return true;
    });
    ZSchema.registerFormat('date', function (date: string) {
      const dateRegExp = '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]' +
        '|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$';
      const dateMatch = date.match(dateRegExp);
      return dateMatch && dateMatch.length > 0;
    });
    this.zschema = new ZSchema({
      breakOnFirstError: false
    });
  }

  reset() {
    this.createSchemaValidator();
  }

  createValidatorFn(schema: any) {
    return (value): { [key: string]: boolean } => {


      if (schema.type === 'number' || schema.type === 'integer') {
        value = +value;
      }

      this.zschema.validate(value, schema);
      const err = this.zschema.getLastErrors();

      this.denormalizeRequiredPropertyPaths(err);

      return err || null;

    };
  }

  getSchema(schema: any, ref: string) {
    // check definitions are valid
    const isValid = this.zschema.compileSchema(schema);
    if (isValid) {
      return this.getDefinition(schema, ref);
    } else {
      throw this.zschema.getLastError();
    }
  }

  private denormalizeRequiredPropertyPaths(err: any[]) {
    if (err && err.length) {
      err = err.map(error => {
        if (error.path === '#/' && error.code === 'OBJECT_MISSING_REQUIRED_PROPERTY') {
          error.path = `${error.path}${error.params[0]}`;
        }
        return error;
      });
    }
  }

  private getDefinition(schema: any, ref: string) {
    let foundSchema = schema;
    ref.split('/').slice(1).forEach(ptr => {
      if (ptr) {
        foundSchema = foundSchema[ptr];
      }
    });
    return foundSchema;
  }
}
