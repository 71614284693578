<ng-container *ngIf="(loading$|async) === false || (sentState$|async); else loadingCartTemplate">

  <ng-container *ngIf="(schema$|async) as schema">

    <ng-container *ngIf="!(sentState$|async); else sendTemplate">

      <ng-container *ngIf="(model$|async) !== null; else noCartTemplate">

        <div class="container-fluid content-group">

          <div class="row">

            <sf-form
                #formEdit
                class="col-12"
                (isValid)="isFormValid = $event"
                (error)="onFormError($event)"
                (onErrorChange)="onFormError($event)"
                (onErrorsChange)="onFormError($event)"
                (onChange)="onFormChange($event)"
                [model]="model$|async"
                [schema]="schema">
            </sf-form>

          </div>

          <div class="row">

            <div class="col-12 offset-sm-6 col-sm-6 d-flex justify-content-center">

              <button
                [disabled]="!isFormValid"
                (click)="send()"
                color="accent"
                mat-flat-button>
                {{'ACTIONS.SEND'|translate}}
              </button>

            </div>

          </div>

        </div>

      </ng-container>

    </ng-container>

    <ng-template [ngTemplateOutlet]="appFooterTemplate">
    </ng-template>

  </ng-container>

</ng-container>

<ng-template #innovationReferenceTemplate let-option="option" let-removable="removable"
             let-removeEmitter="removeEmitter">

  <app-innovation-reference-renderer
    [zone]="zone"
    [data]="option"
    [removable]="removable"
    [removeEmitter]="removeEmitter">
  </app-innovation-reference-renderer>

</ng-template>

<ng-template #innovationReferenceListTemplate let-data="data" let-removeEmitter="removeEmitter">

  <ul class="d-flex flex-column list-style-type-none p-0 m-0">

    <ng-template ngFor [ngForOf]="data" let-item>

      <li class="d-flex">

        <ng-template
          [ngTemplateOutlet]="innovationReferenceTemplate"
          [ngTemplateOutletContext]="{option:(item|jaSelectStoreResource:zone)|async, removable:true, removeEmitter:removeEmitter}">
        </ng-template>

      </li>

    </ng-template>

  </ul>

</ng-template>

<ng-template #loadingCartTemplate>

  <div class="d-flex flex-fill align-items-center justify-content-center">
    <mat-spinner [diameter]="30" color="primary"></mat-spinner>
  </div>

</ng-template>

<ng-template #noCartTemplate>

  <div class="d-flex flex-fill align-items-center justify-content-center">
    <p class="text-center" [innerHTML]="'MY_SHARE.NO_CONTENT'|translate"></p>
  </div>

</ng-template>

<ng-template #sendTemplate>

  <div class="sent-states-group d-flex flex-fill flex-column justify-content-center align-items-center">

    <ng-container *ngIf="(sentState$|async) === 'pending'">

      <mat-spinner color="accent" diameter="30"></mat-spinner>

      <p class="state-message" [innerHTML]="'MY_SHARE.PENDING'|translate"></p>

    </ng-container>

    <ng-container *ngIf="(sentState$|async) === 'complete'">

      <mat-icon [svgIcon]="'icon-message-sent'"></mat-icon>

      <p class="state-message" [innerHTML]="'MY_SHARE.COMPLETE'|translate"></p>

    </ng-container>

  </div>

</ng-template>
