import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resource, ResourceIdentifier, uuid} from 'ngrx-json-api';
import {MatListOption, MatSelectionListChange} from '@angular/material/list';

@Component({
  selector: 'app-add-to-board',
  templateUrl: './add-to-board.component.html',
  styleUrls: ['./add-to-board.component.scss']
})
export class AddToBoardComponent implements OnInit {

  @Input()
  dataToAdd: Resource;

  @Output()
  createBoard: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  addToBoard: EventEmitter<ResourceIdentifier> = new EventEmitter<ResourceIdentifier>();

  @Output()
  removeFromBoard: EventEmitter<ResourceIdentifier> = new EventEmitter<ResourceIdentifier>();

  @Input()
  inspirations: Resource[];

  uuid: string;

  constructor() {
  }

  ngOnInit() {
    this.uuid = uuid();
  }

  onBoardSelectionChange(event: MatSelectionListChange) {
    console.log({event});
    if (event.options.length > 0) {
      const option: MatListOption = event.options[0];
      if (option.selected) {
        this.addToBoard.emit(option.value);
      } else {
        this.removeFromBoard.emit(option.value);
      }
    }
  }

}
