import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WelcomeComponent} from './welcome.component';
import {SharedModule} from '../shared.module';
import {PipesModule} from '../pipes/pipes.module';
import {ComponentsModule} from '../components/components.module';
import {ApolloModule} from 'apollo-angular';
import {HttpLinkModule} from 'apollo-angular-link-http';
import {ReactiveComponentModule} from '@ngrx/component';


@NgModule({
  declarations: [WelcomeComponent],
    imports: [
        CommonModule,
        ApolloModule,
        HttpLinkModule,
        ComponentsModule,
        PipesModule,
        SharedModule,
        ReactiveComponentModule
    ]
})

export class WelcomeModule {
}
