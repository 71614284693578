import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {MenusService} from '../../../services/menus.service';
import {TemplatesService} from '../../../templates.service';
import {Templates} from '../../templates';

@Component({
  selector: 'app-user-function-icons',
  templateUrl: './user-function-icons.component.html',
  styleUrls: ['./user-function-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFunctionIconsComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('userFunctionMenuTemplate', /* TODO: check static flag */ {static: true})
  userFunctionMenuTemplate: TemplateRef<any>;

  @ViewChild('userFunctionLinkTemplate', /* TODO: check static flag */ {static: true})
  userFunctionLinkTemplate: TemplateRef<any>;

  @ViewChild('userFunctionIconTemplate', /* TODO: check static flag */ {static: true})
  userFunctionIconTemplate: TemplateRef<any>;

  constructor(
    public userService: UserService,
    public menusService: MenusService,
    public templatesService: TemplatesService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.userFunctionMenu, this.userFunctionMenuTemplate);
    this.templatesService.addTemplate(Templates.userFunctionLink, this.userFunctionLinkTemplate);
    this.templatesService.addTemplate(Templates.userFunctionIcon, this.userFunctionIconTemplate);
  }

}
