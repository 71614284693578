import {AfterViewInit, Component, HostListener, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../../templates.service';
import {Templates} from '../../templates';
import {PwaService} from '../../../pwa.service';
import {ResponsiveService} from '../../../services/responsive.service';

@Component({
  selector: 'app-pwa-templates',
  templateUrl: './pwa-templates.component.html',
  styleUrls: ['./pwa-templates.component.scss']
})
export class PwaTemplatesComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('pwaBannerTemplate', /* TODO: check static flag */ {static: true})
  pwaBannerTemplate: TemplateRef<any>;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.pwaService.beforeInstallPrompt(e);
  }

  constructor(
    public templatesService: TemplatesService,
    public pwaService: PwaService,
    public responsiveService: ResponsiveService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.pwaBanner, this.pwaBannerTemplate);
  }

}
