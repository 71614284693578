import {AfterViewInit, Component, HostBinding, OnInit} from '@angular/core';
import {ControlWidget} from 'ngx-schema-form';


import * as Quill from 'quill';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {StripTagsPipe} from 'ngx-pipes';
import {MilSchema} from '@madeinlune/ngx-drupal-schemata/lib/model/mil-schema';

const Link = Quill.import('formats/link');
Link.sanitize = function (url: string) {
  if (url.indexOf('http') === -1) {
    url = 'http://' + url;
  }
  if (url.indexOf('win.moethennessy.com') > -1) {
    url = '';
  }
  if (validURL(url)) {
    return url;
  } else {
    return null;
  }
};

class MyLink extends Link {

  static create(value) {

    value = this['sanitize'](value);

    let node;

    if (value) {
      node = super.create(value);
      node.setAttribute('href', value);
      /*if (value.startsWith('https://quilljs.com')) {
        node.removeAttribute('target');
      }*/
    }

    return node;
  }

}

Quill.register(MyLink);

function validURL(str) {
  const pattern = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$');
  return !!pattern.test(str);
}

@Component({
  selector: 'app-quill-text',
  templateUrl: './quill-text.component.html',
  styleUrls: ['./quill-text.component.scss'],
  providers: [StripTagsPipe]
})
export class QuillTextComponent extends ControlWidget implements OnInit, AfterViewInit {


  @HostBinding('class') class = 'd-flex flex-column';

  modules: any = {
    toolbar: [
      ['bold', 'italic'],        // toggled buttons       // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],       // remove formatting button
      ['link']
    ]
  };

  initialValue: any;

  textLength$: Observable<number>;

  textTooLong$: Observable<boolean>;

  textTooLongError$: Observable<boolean>;

  constructor(
    private stripTagsPipe: StripTagsPipe
  ) {
    super();
  }

  ngOnInit() {

    this.textLength$ = this.control.valueChanges.pipe(
      filter(value => !!value),
      map(value => {
        return this.stripTagsPipe.transform(value).length;
      })
    );

    this.textTooLong$ = this.textLength$.pipe(
      filter(textLength => !!this.milSchemaProperty['maxLength']),
      map(textLength => textLength > this.milSchemaProperty['maxLength'])
    );

    this.textTooLongError$ = this.formProperty.errorsChanges.pipe(
      map(errors => {
        return errors?.filter(error => error.path === '#' + this.formProperty.path);
      }),
      map(errors => {
        if (errors) {
          return errors.filter(error => error.code === 'MAX_LENGTH').length > 0;
        } else {
          return false;
        }
      })
    );

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.initialValue = this.formProperty.value;
    if (!this.schema.isRequired) {
      this.formProperty._hasValue = this._hasValue.bind(this);
    }
  }

  onContentChanged(event: any) {
  }

  onEditorChanged(event: any) {
  }

  _hasValue() {
    return (this.initialValue !== this.formProperty.value);
  }

  get milSchemaProperty(): MilSchema {
    return this.formProperty.schema as MilSchema;
  }

}
