<app-renderer-templates [render]="render">
</app-renderer-templates>

<mat-divider class="my-5" *ngIf="render"></mat-divider>

<app-toggle-button-bar-templates [render]="render">
</app-toggle-button-bar-templates>

<mat-divider class="my-5" *ngIf="render"></mat-divider>

<app-vote-templates [render]="render">
</app-vote-templates>

<mat-divider class="my-5" *ngIf="render"></mat-divider>

<app-content-header [render]="render">
</app-content-header>

<mat-divider class="my-5" *ngIf="render"></mat-divider>

<app-pwa-templates [render]="render">
</app-pwa-templates>

<app-user-function-icons [render]="render">
</app-user-function-icons>

<app-button-templates [render]="render">
</app-button-templates>

<app-main-header-templates [render]="render">
</app-main-header-templates>


<app-window-templates [render]="render">
</app-window-templates>


<app-icon-templates [render]="render">
</app-icon-templates>

<app-snackbar-templates [render]="render">
</app-snackbar-templates>
