import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, InjectionToken, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRoute, RouteReuseStrategy} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ComponentsModule} from './components/components.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './shared.module';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {Store, StoreModule} from '@ngrx/store';
import {NgrxJsonApiModule} from 'ngrx-json-api';
import {EffectsModule} from '@ngrx/effects';
import {RouterEffects} from './store/effects/router.effects';
import {AppConfigModule} from './config/app-config.module';
import {MilRouterStateSerializer} from './mil-router-state-serializer';
import {SchemaFormModule, SchemaValidatorFactory, WidgetRegistry} from 'ngx-schema-form';
import {metaReducers, reducers} from './store/reducers';
import {AppCoreEffects} from './store/effects/app-core.effects';
import {AppRoutingModule} from './app-routing.module';
import {AppReuseStrategy} from './app-reuse-strategy';
import {WindowRef} from './utils/window-ref';
import {NgrxJsonApiDefinitions} from './ngrx-json-api/ngrx-json-api-definitions';
import {PipesModule} from './pipes/pipes.module';
import {LoaderService} from './services/loader.service';
import {LoaderInterceptor} from './loader.interceptor';
import {OAuthModule, OAuthStorage, UrlHelperService} from 'angular-oauth2-oidc';
import {MhiSchemaValidatorFactory} from './MhiSchemaValidatorFactory';
import {MhInnoSchemaLoaderService} from './mh-inno-schema-loader.service';
import {JsonApiInterceptor} from './jsonapi.interceptor';
import {ApolloService} from './apollo/apollo.service';
import {Apollo} from 'apollo-angular';
import {WatchQueryOptions} from 'apollo-client';
import {MyContributionsModule} from './my-contributions/my-contributions.module';
import {PollModule} from './poll/poll.module';
import {TemplatesModule} from './templates/templates.module';
import {MyShareModule} from './my-share/my-share.module';
import {NgxDrupalSchemataModule, NgxDrupalSchemataService} from '@madeinlune/ngx-drupal-schemata';
import {MaterialWidgetRegistry} from './material-widgets-registry';
import {NgxMaterialSchemaFormModule, NgxMaterialSchemaFormService} from '@madeinlune/ngx-material-schema-form';
import {DRUPAL_UPLOADER_ICONS, NgxDrupalUploaderService} from '@madeinlune/ngx-drupal-uploader';
import {MhInnoUploaderService} from './mh-inno-uploader.service';
import {UserEffects} from './store/effects/user.effects';
import {MhInnoMaterialSchemaFormService} from './mh-inno-material-schema-form.service';
import {CommonModule} from '@angular/common';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {CustomUrlHelperService} from './custom-url-helper-service';
import {AuthEffects} from './store/effects/auth.effects';
import {Angulartics2Module} from 'angulartics2';
import {ServerErrorInterceptor} from './server-error.interceptor';
import {SearchService} from './search/search.service';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {SwBypassInterceptor} from './sw-bypass-interceptor';
import {QuillModule} from 'ngx-quill';
import {LatinisePipe, UniquePipe} from 'ngx-pipes';
import {APP_CORE_PROVIDERS} from './providers/app-core.providers';
import {ROUTE_PROVIDERS} from './providers/route.providers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {IdTokenInterceptor} from './id-token.interceptor';
import {createErrorHandler} from '@sentry/angular';

import {USER_PROVIDERS} from './providers/user.providers';
import {MADEINLUNE_PROVIDERS} from './providers/madeinlune.providers';

// AoT requires an exported function for factories

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const SEARCH_SERVICE = new InjectionToken<SearchService>(
  'Win Search Service'
);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    AppConfigModule,
    OAuthModule.forRoot({resourceServer: {sendAccessToken: true, allowedUrls: []}}),
    BrowserModule.withServerTransition({appId: 'ngx-mhinnovactions'}),
    Angulartics2Module.forRoot({
      developerMode: !environment.production
    }),
    BrowserAnimationsModule,
    ComponentsModule,
    HttpClientModule,
    LazyLoadImageModule,
    NgxMaterialSchemaFormModule,
    PipesModule,
    StoreModule.forRoot(reducers, {metaReducers, runtimeChecks: {strictStateImmutability: true, strictActionImmutability: true}}),
    SchemaFormModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    NgrxJsonApiModule.configure({
      apiUrl: 'will be set later',
      resourceDefinitions: NgrxJsonApiDefinitions.resourceDefinitions,
    }),
    AppRoutingModule,
    SharedModule,
    NgxDrupalSchemataModule,
    TemplatesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    EffectsModule.forRoot([
      AuthEffects,
      RouterEffects,
      AppCoreEffects,
      UserEffects
    ]),
    MyContributionsModule,
    PollModule,
    MyShareModule,
    QuillModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ...(!environment.production ? [
      StoreDevtoolsModule.instrument({
        name: 'NgRx MH InnovActions Store',
        logOnly: environment.production,
      })] : []),
  ],
  providers: [
    APP_CORE_PROVIDERS,
    (environment.production ? {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    } : []),
    UniquePipe,
    {
      provide: UrlHelperService, useClass: CustomUrlHelperService
    },
    {
      provide: OAuthStorage,
      useValue: localStorage
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppReuseStrategy
    },
    {
      provide: RouterStateSerializer,
      useClass: MilRouterStateSerializer
    },
    {
      provide: NgxDrupalSchemataService,
      useClass: MhInnoSchemaLoaderService
    },
    {
      provide: NgxDrupalUploaderService,
      useClass: MhInnoUploaderService
    },
    {
      provide: NgxMaterialSchemaFormService,
      useClass: MhInnoMaterialSchemaFormService
    },
    LoaderService,
    /*{provide: ErrorHandler, useClass: GlobalErrorHandler},*/
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SwBypassInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: IdTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JsonApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true},
    WindowRef,
    {
      provide: SchemaValidatorFactory,
      useClass: MhiSchemaValidatorFactory
    },
    {
      provide: 'apolloServiceFactory',
      useFactory: (apollo: Apollo) =>
        (watchQueryOptions: WatchQueryOptions, pageStart, pageSize) => new ApolloService(apollo, watchQueryOptions, pageStart, pageSize),
      deps: [Apollo]
    },
    {
      provide: SEARCH_SERVICE,
      useFactory: () =>
        (store: Store<any>, activatedRoute: ActivatedRoute, apollo: Apollo) => new SearchService(store, activatedRoute, apollo),
      deps: []
    },
    {
      provide: WidgetRegistry,
      useClass: MaterialWidgetRegistry
    },
    ROUTE_PROVIDERS,
    USER_PROVIDERS,
    LatinisePipe,
    MADEINLUNE_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
