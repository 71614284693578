<ng-container *ngIf="terms && facet">


  <ng-container *ngIf="mode == 'default'">

    <ng-template
      [ngTemplateOutlet]="defaultSelectTemplate"
      [ngTemplateOutletContext]="{terms: terms, multiple: true}">
    </ng-template>

  </ng-container>

  <ng-container *ngIf="mode == 'split'">

    <ng-template
      [ngTemplateOutlet]="defaultSelectTemplate"
      [ngTemplateOutletContext]="{terms: terms, multiple: true}">
    </ng-template>

  </ng-container>

</ng-container>

<ng-template #singleFilterTemplate let-term="term" let-isRoot="isRoot">

  <div
    [class.font-weight-bold]="isRoot"
    class="d-flex flex-fill justify-content-between">

    <span>{{term.attributes.name}}</span><span>{{term|facetValueTidMatcher:facet.values}}</span>

  </div>

</ng-template>

<ng-template #defaultSelectTemplate let-selectTerms="terms" let-isRoot="isRoot" let-multiple="multiple">

  <mat-form-field class="mr-3">

    <mat-select
      [panelClass]="facet.name"
      [placeholder]="label"
      [formControl]="formControl"
      [multiple]="multiple">

      <mat-select-trigger>
        {{formControl.value ? (formControl.value[0]|facetLabelByTid:selectTerms) : ''}}
        <span *ngIf="formControl.value?.length > 1" class="example-additional-selection">
        (+{{formControl.value.length - 1}} {{formControl.value?.length === 2 ? 'other' : 'others'}})
      </span>
      </mat-select-trigger>

      <ng-container *ngIf="(selectTerms | filterBy: ['relationships.parent.data.0.id']: 'virtual') as rootTerms">

        <ng-template ngFor [ngForOf]="rootTerms|orderBy:'attributes.weight'" let-term>

          <ng-container *ngIf="(selectTerms | filterBy: ['relationships.parent.data.0.id']: term.id).length == 0">

            <mat-option
              [disabled]="(term|facetValueTidMatcher:facet.values) === 0"
              [class.ml-3]="term.relationships.parent.data[0].id != 'virtual'"
              [value]="term.attributes.drupal_internal__tid">
              <ng-template
                [ngTemplateOutlet]="singleFilterTemplate"
                [ngTemplateOutletContext]="{term: term}">
              </ng-template>
            </mat-option>

          </ng-container>

          <ng-container *ngIf="(selectTerms | filterBy: ['relationships.parent.data.0.id']: term.id).length > 0">

            <mat-option
              [disabled]="(term|facetValueTidMatcher:facet.values) === 0"
              [class.ml-3]="term.relationships.parent.data[0].id != 'virtual'"
              [value]="term.attributes.drupal_internal__tid">
              <ng-template
                [ngTemplateOutlet]="singleFilterTemplate"
                [ngTemplateOutletContext]="{term: term, isRoot:term.relationships.parent.data[0].id == 'virtual'}">
              </ng-template>
            </mat-option>

            <ng-container *ngIf="selectTerms | filterBy: ['relationships.parent.data.0.id']: term.id as subTerms">

              <ng-template ngFor [ngForOf]="subTerms|orderBy:'attributes.weight'" let-subTerm>

                <mat-option
                  [disabled]="(subTerm|facetValueTidMatcher:facet.values) === 0"
                  [class.ml-3]="subTerm.relationships.parent.data[0].id != 'virtual'"
                  [value]="subTerm.attributes.drupal_internal__tid">
                  <ng-template
                    [ngTemplateOutlet]="singleFilterTemplate"
                    [ngTemplateOutletContext]="{term: subTerm, isRoot:subTerm.relationships.parent.data[0].id == 'virtual'}">
                  </ng-template>
                </mat-option>

              </ng-template>

            </ng-container>

          </ng-container>

        </ng-template>

      </ng-container>

    </mat-select>

  </mat-form-field>

</ng-template>

<ng-template #splitSelectTemplate>

  <ng-container *ngIf="terms|filterBy:['relationships.parent.data.0.id']:'virtual' as rootTerms">

    <ng-template
      [ngTemplateOutlet]="defaultSelectTemplate"
      [ngTemplateOutletContext]="{terms: rootTerms, isRoot: true, multiple: true}">
    </ng-template>

  </ng-container>

  <ng-container *ngIf="selectedRootTermId">

    <ng-container *ngIf="terms|filterBy:['relationships.parent.data.0.id']:selectedRootTermId as childTerms">

      <ng-template
        [ngTemplateOutlet]="defaultSelectTemplate"
        [ngTemplateOutletContext]="{terms: childTerms, multiple: true}">
      </ng-template>

    </ng-container>

  </ng-container>

</ng-template>
