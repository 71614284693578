<ng-container *ngIf="render">

  <h1>Main Header</h1>

  <ng-template [ngTemplateOutlet]="mainHeaderTemplate">
  </ng-template>

</ng-container>

<ng-template
  #mainHeaderTemplate
  let-title="title"
  let-actionsTemplate="actionsTemplate"
  let-linkTemplate="linkTemplate"
  let-topLayerTemplate="topLayerTemplate"
  let-showSizeControls="showSizeControls">

  <div
    class="section-header overflow-hidden d-flex position-relative px-2 px-sm-0 flex-shrink-0">

    <div
      class="icon-title-actions-group d-flex flex-fill justify-content-between align-items-sm-center flex-column flex-sm-row">

      <div class="d-flex align-items-center flex-wrap">

        <ng-container *ngIf="headerIcon$|async as headerIcon">

          <div class="mr-1 mr-sm-3">
            <ng-template
              [ngTemplateOutlet]="bigIcon"
              [ngTemplateOutletContext]="{icon: headerIcon}">
            </ng-template>
          </div>

        </ng-container>

        <ng-container *ngIf="!title">

          <ng-template [ngTemplateOutlet]="breadcrumbsTemplate">
          </ng-template>

        </ng-container>

        <ng-container *ngIf="title">

          <ng-template
            [ngTemplateOutlet]="headerTitleH1Template"
            [ngTemplateOutletContext]="{title: title}">
          </ng-template>

        </ng-container>

      </div>

      <div class="d-flex flex-fill ml-2">

        <div class="d-flex flex-fill">

          <ng-container *ngIf="actionsTemplate">
            <ng-template
              [ngTemplateOutlet]="actionsTemplate">
            </ng-template>
          </ng-container>

        </div>

        <div class="d-flex">

          <ng-container *ngIf="(showSizeControls$|async) || showSizeControls">
            <ng-template
              [ngTemplateOutlet]="listModeSwitcherTemplate">
            </ng-template>
          </ng-container>

          <ng-container *ngIf="linkTemplate">
            <ng-template
              [ngTemplateOutlet]="linkTemplate">
            </ng-template>
          </ng-container>

        </div>

      </div>

    </div>

    <ng-container *ngIf="topLayerTemplate">
      <div
        class="d-flex position-absolute top-0 left-0 w-100 h-100 align-items-center justify-content-center pointer-events-none">

        <ng-template
          [ngTemplateOutlet]="topLayerTemplate">
        </ng-template>

      </div>
    </ng-container>

  </div>

</ng-template>

<ng-template #headerTitleH1Template let-title="title" let-endSlash="endSlash">

  <h2
    class="mat-display-1 text-uppercase"
    [ngClass]="{'slash-after': endSlash}"
    [innerHTML]="title">
  </h2>

</ng-template>

<ng-template #listModeSwitcherTemplate>

  <div class="d-flex align-items-center" *ngIf="!(responsiveService.isXs$|async)">
    <button
      (click)="changeListThumbSize('normal')"
      class="list-thumb-size-button square-button mr-2"
      [class.selected]="(listThumbSize$|async) == 'normal'"
      mat-fab
      color="accent">
      <mat-icon svgIcon="icon-grid-big"></mat-icon>
    </button>

    <button
      (click)="changeListThumbSize('small')"
      class="list-thumb-size-button square-button mr-2"
      [class.selected]="(listThumbSize$|async) == 'small'"
      mat-fab
      color="accent">
      <mat-icon svgIcon="icon-grid-small"></mat-icon>
    </button>
  </div>

</ng-template>

<ng-template #breadcrumbsTemplate>
  <ng-container *ngIf="breadcrumbService.breadcrumbs$|async as breadcrumbs">

    <ng-template ngFor [ngForOf]="breadcrumbs" let-breadcrumb let-isLast="last" let-i="index">

      <ng-container *ngIf="i < 2">

        <a [routerLink]="breadcrumb.url" [class.d-none]="i == 0 && breadcrumbs.length > 1 && (responsiveService.isXs$|async)">

          <ng-template
            [ngTemplateOutlet]="headerTitleH1Template"
            [ngTemplateOutletContext]="{
                title:(breadcrumb.title|translate),
                endSlash: (breadcrumbs.length > 1 && i == 0 && breadcrumbs[1].title!=undefined)
                }">
          </ng-template>

        </a>

      </ng-container>

    </ng-template>

  </ng-container>
</ng-template>

<ng-template
  #mainHeaderSearchTemplate
  let-title="title"
  let-actionsTemplate="actionsTemplate"
  let-linkTemplate="linkTemplate"
  let-topLayerTemplate="topLayerTemplate">

  <ng-template
    [ngTemplateOutlet]="mainHeaderTemplate"
    [ngTemplateOutletContext]="{
    actionsTemplate: actionsTemplate,
    linkTemplate: linkTemplate,
    topLayerTemplate: searchIndexResultCountTemplate
    }">

  </ng-template>

</ng-template>

<ng-template
  #mainHeaderInspirationsTemplate
  let-title="title"
  let-actionsTemplate="actionsTemplate"
  let-linkTemplate="linkTemplate"
  let-topLayerTemplate="topLayerTemplate">

  <ng-template
    [ngTemplateOutlet]="mainHeaderTemplate"
    [ngTemplateOutletContext]="{
    actionsTemplate: (isInspirations$|async)?inspirationActionsTemplate:createBoardButtonTemplate,
    linkTemplate: linkTemplate,
    topLayerTemplate: null
    }">

  </ng-template>

</ng-template>

<ng-template
  #mainHeaderContributionsTemplate
  let-title="title"
  let-actionsTemplate="actionsTemplate"
  let-linkTemplate="linkTemplate"
  let-topLayerTemplate="topLayerTemplate">

  <ng-template
    [ngTemplateOutlet]="mainHeaderTemplate"
    [ngTemplateOutletContext]="{
    actionsTemplate: contributionsActionsTemplate,
    linkTemplate: linkTemplate,
    topLayerTemplate: null
    }">

  </ng-template>

</ng-template>

<ng-template #createBoardButtonTemplate>

  <div class="d-flex justify-content-end flex-fill">
    <button
      mat-flat-button
      (click)="openNewInspirationWindow()"
      [innerHTML]="'MY_INSPIRATION.CREATE_BOARD'|translate" color="accent">
    </button>
  </div>

</ng-template>


<ng-template #createInspirationContentTemplate>

  <mat-form-field class="px-3">
    <input
      #inspirationNameInput
      matInput
      [name]="uuid"
      [autocomplete]="uuid"
      [placeholder]="'MY_INSPIRATION.FORM.NAME'|translate">
  </mat-form-field>

</ng-template>


<ng-template #createInspirationActionsTemplate>

  <div class="mb-2">

    <!--
    <button mat-flat-button class="mr-3" mat-dialog-close>
      <span [innerHTML]="'ACTIONS.DELETE'|translate"></span>
    </button>
    -->
    <button
      class="accent-action-button"
      (click)="createInspiration(inspirationNameInput.value)"
      [disabled]="inspirationNameInput ? inspirationNameInput.value === '':true"
      mat-flat-button
      mat-dialog-close>
      <span [innerHTML]="'ACTIONS.SAVE'|translate"></span>
    </button>

  </div>

</ng-template>
