import {Injectable} from '@angular/core';
import {AppConfigService} from '@config/app-config.service';
import {take} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import {Config, NgxMaterialSchemaFormService} from '@madeinlune/ngx-material-schema-form';
import {DialogComponent} from './components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MhInnoMaterialSchemaFormService extends NgxMaterialSchemaFormService {

  public config: Config;

  constructor(
    public appConfig: AppConfigService,
    private oauthService: OAuthService
  ) {
    super();

    this.appConfig.appConfig$.pipe(take(1)).subscribe(appConfigResult => {

      const config: Config = {
        dialogComponent: DialogComponent,
        jsonApiUrl: appConfig.getConfig().jsonapiUrl,
        drupalUrl: appConfig.getConfig().backendUrl,
        jsonRpc: appConfig.getConfig().jsonrpc,
        accessToken: this.oauthService.getAccessToken()
      };

      this.config = config;
    });

  }
}
