<ng-template [ngTemplateOutlet]="createBoardTemplate">
</ng-template>

<ng-template [ngTemplateOutlet]="addBoardTemplate">
</ng-template>

<button
  mat-flat-button
  class="ok-button mt-3 align-self-center"
  color="primary"
  mat-dialog-close>
      <span [innerHTML]="'OK'">
      </span>
</button>

<ng-template #createBoardTemplate>

  <div class="d-flex align-items-center">

    <mat-form-field class="flex-fill mr-4">
      <input
        matInput
        [name]="uuid"
        [autocomplete]="uuid"
        [placeholder]="'ADD_TO_BOARD.CREATE'|translate"
        #boardName>
    </mat-form-field>

    <button
      class="main-action-button"
      mat-flat-button
      [disabled]="boardName ? boardName.value === '':true"
      (click)="createBoard.emit(boardName.value);boardName.value=null">
      <span [innerHTML]="'ACTIONS.CREATE'|translate">
      </span>
    </button>

  </div>

</ng-template>

<ng-template #addBoardTemplate>

  <div class="d-flex flex-column position-relative flex-fill overflow-hidden">

    <ng-container *ngIf="inspirations && inspirations.length > 0; else noBoardTemplate">

      <h3 [innerHTML]="('ADD_TO_BOARD.SELECT')|translate" class="subheading-2 text-uppercase mt-2"></h3>

      <mat-selection-list #artboardsList (selectionChange)="onBoardSelectionChange($event)"
                          class="flex-fill overflow-y-auto">

        <ng-template ngFor [ngForOf]="inspirations" let-artboard>
          <mat-list-option
            [value]="artboard"
            [selected]="artboard|innovationInArtboard:dataToAdd">
            <span
              class="align-self-start text-uppercase">
              {{artboard.attributes.title}}
            </span>
          </mat-list-option>
          <mat-divider></mat-divider>
        </ng-template>

      </mat-selection-list>

    </ng-container>

  </div>

</ng-template>

<ng-template #noBoardTemplate>
  <div class="d-flex position-absolute w-100 h-100 top-0 left-0 justify-content-center align-items-center">
    <span class="text-center" [innerHTML]="'ADD_TO_BOARD.NO_BOARD'|translate"></span>
  </div>
</ng-template>
