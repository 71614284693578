import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {NGRX_JSON_API_CONFIG} from 'ngrx-json-api';
import {AppConfigLoader, BrowserAppConfigLoader} from './app-config-loader';

const appInitializerFn = (appConfig: AppConfigService, ngrxJsonApiConfig) => {
  return () => {
    return appConfig.loadAppConfig().then(function (config) {
      ngrxJsonApiConfig.apiUrl = appConfig.getConfig().jsonapiUrl;
      return config;
    });
  };
};

@NgModule({
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService, NGRX_JSON_API_CONFIG]
    },
    {
      provide: AppConfigLoader,
      useClass: BrowserAppConfigLoader
    }
  ]
})
export class AppConfigModule {
}
