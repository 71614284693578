import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';
import {combineLatest, merge, Observable, ReplaySubject} from 'rxjs';
import {NgrxJsonApiService, StoreResource} from 'ngrx-json-api';
import {ActivatedRoute, Router} from '@angular/router';
import {distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {DrupalPathGuard} from '@ngx-mil-drupal/drupal-path-guard';
import {TemplatesService} from '../../templates.service';
import {LanguageSwitcherService} from '../../services/language-switcher.service';

@Component({
  selector: 'app-double-columns',
  templateUrl: './double-columns.component.html',
  styleUrls: ['./double-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoubleColumnsComponent implements OnInit, OnDestroy, AfterViewInit {

  staticData$: ReplaySubject<any> = new ReplaySubject<any>(1);

  staticOrRouteData$: Observable<any> = merge(this.route.data, this.staticData$);

  staticOrRouteParams$: Observable<any> = merge(this.route.params, this.staticData$).pipe(
    map(data => {
      return {id: data.id, type: data.type};
    })
  );

  @Input()
  set staticData(value: any) {
    this.staticData$.next(value);
  }

  node$: Observable<StoreResource>;

  templates$: Observable<any> = this.staticOrRouteData$.pipe(
    map(data => data.templates),
    filter(templates => !!templates)
  );


  topTemplates$: Observable<TemplateRef<any>[]> = this.templates$.pipe(
    map(templates => templates.top)
  );

  leftTemplates$: Observable<TemplateRef<any>[]> = this.templates$.pipe(
    map(templates => templates.left)
  );

  rightTemplates$: Observable<TemplateRef<any>[]> = this.templates$.pipe(
    map(templates => templates.right)
  );

  footerTemplates$: Observable<TemplateRef<any>[]> = this.templates$.pipe(
    map(templates => templates.footer)
  );

  zone$: Observable<string> = this.staticOrRouteData$.pipe(
    withLatestFrom(this.languageSwitcherService.currentLanguage$),
    map(([data, currentLanguage]) => {
      let zone: string;
      if (data.zone) {
        if (data.zone.indexOf('%language') > -1) {
          zone = data.zone.replace('%language', currentLanguage);
        } else {
          zone = data.zone;
        }
      } else {
        zone = DrupalPathGuard.DRUPAL_ZONE + '-' + currentLanguage;
      }
      return zone;
    })
  );

  className$: Observable<string> = this.staticOrRouteData$.pipe(
    filter(data => !!data && !!data.className),
    map(data => data.className),
    take(1)
  );

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @HostBinding('class') class;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngrxJsonApiService: NgrxJsonApiService,
    public templatesService: TemplatesService,
    private cdr: ChangeDetectorRef,
    private languageSwitcherService: LanguageSwitcherService
  ) {
  }

  ngOnInit() {

    this.className$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(className => {
      this.class = className;
    });

    // this.rightTemplates$.subscribe(rightTemplates => console.log('rightTemplates', rightTemplates));

  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  ngAfterViewInit(): void {

    const resourceIdentifier$: Observable<{ id: string, type: string, zone: string }> = combineLatest(
      [
        this.staticOrRouteData$,
        this.staticOrRouteParams$,
        this.languageSwitcherService.currentLanguage$
      ]
    )
      .pipe(
        map(([routeData, routeParams, currentLanguage]) => {
          let resourceIdentifierWithZone: any;
          if (routeData.staticRoute) {
            let zone: string;
            if (routeData.zone.indexOf('%language') > -1) {
              zone = routeData.zone.replace('%language', currentLanguage);
            } else {
              zone = routeData.zone;
            }
            resourceIdentifierWithZone = {type: routeParams.type || routeData.bundle, id: routeParams.id, zone: zone};
          } else {
            resourceIdentifierWithZone = {
              ...routeData.resourceIdentifier,
              zone: routeData.zone ? routeData.zone : DrupalPathGuard.DRUPAL_ZONE + '-' + currentLanguage
            };
          }
          return resourceIdentifierWithZone;
        }),
        shareReplay(1)
      );

    this.node$ = resourceIdentifier$.pipe(
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      }),
      switchMap(resourceIdentifier => {
        return this.ngrxJsonApiService
          .getZone(resourceIdentifier.zone)
          .selectStoreResource(resourceIdentifier)
          .pipe(
            map(node => {
              return node;
            }),
            distinctUntilChanged((oldValue, newValue) => {
              return JSON.stringify(oldValue) === JSON.stringify(newValue);
            })
          );
      }),
      map(node => {
        return node;
      }),
      shareReplay(1)
    );

    this.cdr.detectChanges();

  }

}
