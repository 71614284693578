<ng-container *ngIf="myContributionsService.filteredContributionsList$|async as contributionsList">

  <app-apollo-list
    class="list-group d-flex flex-fill"
    [data]="contributionsList|orderBy:'-attributes.created'"
    [end]="true"
    [noDataMessage]="'MY_CONTRIBUTIONS.NO_CONTRIBUTIONS'"
    [rendererTemplate]="listItemTemplate">
  </app-apollo-list>

  <ng-template [ngTemplateOutlet]="('appFooter'|templates)|async">
  </ng-template>

</ng-container>

<ng-template #contributionFiltersTemplate>

  <ng-container *ngIf="(myContributionsService.facets$|async) as facets">
    <ng-container *ngIf="facets.length > 0">
      <ng-template
        [ngTemplateOutlet]="('toggleBarStateFilters'|templates)|async"
        [ngTemplateOutletContext]="{
        count:myContributionsService.contributionsCount$|async,
        facets: facets
        }">
      </ng-template>
    </ng-container>
  </ng-container>

</ng-template>

<ng-template #listItemTemplate let-data="data">

  <ng-template
    [ngTemplateOutlet]="('listRenderer'|templates)|async"
    [ngTemplateOutletContext]="{
    itemClass: 'my-contributions',
    data:(data|resourceToInnovationIndexItem:(myContributionsService.contributionsZone$|async))|async,
    topLayerTemplate:editTemplate
    }">
  </ng-template>

</ng-template>

<ng-template #editTemplate let-data="data">

  <ng-container
    *ngIf="(data.field_news_workflow || data.field_innovation_workflow)|getAppWorkflowState as workflowInfos">
    <span class="state-chip" [ngClass]="workflowInfos.state"></span>

    <ng-container *ngIf="workflowInfos.state == 'draft'">

      <button
        mat-fab
        color="accent"
        class="edit-button circle-button"
        (click)="openEditWindow(data)">
        <mat-icon [svgIcon]="'icon-contribution'"></mat-icon>
      </button>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template #myContributionsActionsTemplate>

  <div class="d-flex my-2 mr-2 flex-fill justify-content-sm-end">

    <button
      class="mr-2"
      color="accent"
      (click)="openCreateWindow('news')"
      mat-flat-button>
      {{'MY_CONTRIBUTIONS.NEW_NEWS'|translate}}
    </button>

    <ng-container *ngIf="userService.isContributor$|async">

      <button
        color="accent"
        (click)="openCreateWindow('innovation')"
        mat-flat-button>
        {{'MY_CONTRIBUTIONS.NEW_INNOVATION'|translate}}
      </button>

    </ng-container>

  </div>

</ng-template>

<ng-template #saveErrorActionsTemplate>

  <div class="d-flex my-2 flex-fill justify-content-sm-center">

    <button
      color="accent"
      mat-dialog-close
      mat-flat-button>
      {{'OK'|translate}}
    </button>

  </div>

</ng-template>

<ng-template
  #saveErrorTemplate let-data>

  <div class="d-flex flex-column flex-fill align-items-center justify-content-center mt-5 mb-3">

    <p class="mat-subheading-2 normalcase text-center mt-3" [innerHTML]="'MY_CONTRIBUTIONS.SAVE_ERROR'|translate">
    </p>

  </div>

</ng-template>

<ng-template #formEditContentTemplate>

  <div class="d-flex flex-fill flex-column overflow-hidden">

    <app-node-form
        #contentForm
        [passport]="(currentPassport$|async)"
        (formValid)="setFormValid($event)">
    </app-node-form>

  </div>

  <ng-container *ngIf="(isSaving$|async) || (isSubmitting$|async) || (isDeleting$|async)">
    <div
      class="position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center form-loading-group">
      <mat-progress-spinner [diameter]="40" [color]="'accent'" [mode]="'indeterminate'">
      </mat-progress-spinner>
    </div>
  </ng-container>


</ng-template>

<ng-template #formEditActionsTemplate>

  <div
    [class.justify-content-between]="(myContributionsFormService.currentTab$|async) !== 'submit'"
    [class.justify-content-center]="(myContributionsFormService.currentTab$|async) === 'submit'"
    class="edit-actions-group d-flex align-self-stretch align-items-center flex-fill">


    <ng-container *ngIf="(currentEditingContent$|async) as currentEditingContent">
      <!--
      <pre [style]="{'color': 'white'}">{{currentEditingContent.state}}</pre>
      <pre [style]="{'color': 'white'}">canModify$ {{(canModify$|async)|json}}</pre>
      <pre
        [style]="{'color': 'white'}">myContributionsFormService.currentTab$ {{(myContributionsFormService.currentTab$|async)}}</pre>
      <pre [style]="{'color': 'white'}">contentFormComponent {{(contentFormComponent)}}</pre>
      -->
      <ng-container
        *ngIf="(canModify$|async)
        && (currentEditingContent.state !== 'CREATED'
        && currentEditingContent.state !== 'NEW')
        && (myContributionsFormService.currentTab$|async) !== 'submit'">

        <ng-container
          *ngIf="(currentEditingContent.attributes.field_news_workflow || currentEditingContent.attributes.field_innovation_workflow)|getAppWorkflowState as workflowInfos">

          <ng-container *ngIf="workflowInfos.state === 'draft'">
            <button
              (click)="onDelete()"
              color="warn"
              mat-flat-button>
              {{'ACTIONS.DELETE'|translate}}
            </button>
          </ng-container>

        </ng-container>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="(canModify$|async) && (myContributionsFormService.currentTab$|async) !== 'submit'">
      <button
        [disabled]="formValid === false || ((isApplying$|async)===true || (isSubmitting$|async)===true)"
        (click)="onSave()"
        mat-flat-button>
        {{'ACTIONS.SAVE'|translate}}
      </button>
    </ng-container>

    <ng-container
      *ngIf="(canModify$|async)
      && ((currentEditingContent$|async)?.state !== 'CREATED'
      && (currentEditingContent$|async)?.state !== 'NEW')
      && (myContributionsFormService.currentTab$|async) === 'submit'">

      <button
        class="submit-button primary-function"
        [disabled]="!(canSubmit$|async) || ((isApplying$|async)===true || (isSubmitting$|async)===true)"
        (click)="onSubmitForReview()"
        mat-flat-button>
        {{'ACTIONS.SUBMIT_FOR_REVIEW'|translate}}
      </button>
    </ng-container>

  </div>

</ng-template>

<ng-template
    #submitConfirmTemplate let-data>

  <div class="pwa-update-group d-flex flex-column flex-fill align-items-center justify-content-center mt-5 mb-3">

    <p class="mat-subheading-2 normalcase text-center mt-3" [innerHTML]="'MY_CONTRIBUTIONS.CONFIRM_SUBMIT'|translate">
    </p>

  </div>

</ng-template>

<ng-template #submitConfirmActionsTemplate>

  <div class="pwa-update-actions-group d-flex flex-fill button-group two-buttons justify-content-around my-2">

    <button color="primary" class="stroke-button" mat-flat-button mat-dialog-close>
      <span [innerHTML]="'ACTIONS.NO'|translate"></span>
    </button>

    <button
      (click)="onConfirmSubmitForReview()"
      class="reload-button"
      color="accent"
      mat-flat-button
      mat-dialog-close>
      <span [innerHTML]="'ACTIONS.YES'|translate"></span>
    </button>

  </div>

</ng-template>

<ng-template #deleteConfirmationContentTemplate>

  <div class="content-group my-3 px-3 d-flex flex-column align-items-center">

    <p [innerHTML]="'MY_CONTRIBUTIONS.DELETE.DO_YOU_CONFIRM'|translate" class="text-center"></p>

  </div>

</ng-template>

<ng-template #deleteConfirmationActionsTemplate>

  <div class="d-flex justify-content-end mx-4 my-2">

    <button
      (click)="confirmDeletion()"
      mat-flat-button
      color="accent"
      class="mr-3"
      [mat-dialog-close]="true">
      {{'ACTIONS.CONFIRM'|translate}}
    </button>

    <button
      mat-flat-button
      [mat-dialog-close]>
      {{'ACTIONS.CANCEL'|translate}}
    </button>

  </div>

</ng-template>

<ng-template #deleteErrorContentTemplate>

  <div class="content-group my-3 d-flex flex-column align-items-center">

    <p [innerHTML]="'MY_CONTRIBUTIONS.DELETE.ERROR'|translate" class="text-center"></p>

  </div>

</ng-template>

<ng-template #deleteErrorActionsTemplate>

  <div class="d-flex justify-content-end mx-4 my-2">

    <button
      mat-flat-button
      color="accent"
      [mat-dialog-close]="true">
      {{'ACTIONS.OK'|translate}}
    </button>

  </div>

</ng-template>
