import {Action} from '@ngrx/store';
import {InnovationIndexItem} from '../../apollo/innovation-index-item';

export enum SearchActionTypes {
  AddToIndex = '[User] Add To Index',
  ClearAll = '[User] Clear All'
}


export class AddToIndex implements Action {

  readonly type = SearchActionTypes.AddToIndex;

  constructor(public language: string, public contentType: string, public items: InnovationIndexItem[]) {

  }
}

export class ClearAll implements Action {

  readonly type = SearchActionTypes.ClearAll;

  constructor() {

  }
}


export type SearchActionsUnion =
  AddToIndex
  | ClearAll;
