<ng-container *ngIf="milSchemaProperty.readOnly || (formProperty.parent && formProperty.parent.schema.readOnly); else editTemplate">

  <div class="read-only-form-group">
    <small [innerHTML]="schema.title"></small>
    <ng-container *ngIf="formProperty.value; else noValueTemplate">
      <p [innerHTML]="formProperty.value"></p>
    </ng-container>
  </div>

</ng-container>

<ng-template #editTemplate>

  <div class="mt-3 mb-2">

    <p [innerHTML]="schema.title + (schema.isRequired?' *':'')" class="my-0"></p>

    <ng-container *ngIf="schema.description as description">
      <p [innerHTML]="schema.description" class="my-0"></p>
    </ng-container>

  </div>

  <ng-container *ngIf="formProperty">

    <quill-editor
      [formats]="['bold', 'list', 'link', 'italic']"
      [styles]="{height: '320px'}"
      [modules]="modules"
      theme="snow"
      [placeholder]="'MY_CONTRIBUTIONS.INSERT_TEXT_HERE'|translate"
      [sanitize]="false"
      [formControl]="control">
    </quill-editor>

    <ng-container *ngIf="textTooLongError$|async">
      <small class="text-right mt-1">{{('MY_CONTRIBUTIONS.TEXT_TOO_LONG'|translate: {value: milSchemaProperty['maxLength']})}}</small>
    </ng-container>

  </ng-container>

</ng-template>

<ng-template #noValueTemplate>
  <p [innerHTML]="'--'"></p>
</ng-template>
