import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {PathService} from './path.service';
import {MediaUrlPipe} from './pipes/media-url-pipe';
import {PathAliasPipe} from './pipes/path-alias.pipe';
import {StoreModule} from '@ngrx/store';
import {AppConfigModule} from '../../app/config/app-config.module';
import {DrupalConfig} from './drupal-config';
import {AnalyticsService} from './analytics.service';
import {DrupalRouterLinkDirective} from '@ngx-mil-drupal/drupal-router-link';
import {EffectsModule} from '@ngrx/effects';
import {PathEffects} from '@ngx-mil-drupal/store/effects/path.effects';
import {drupalReducers} from '@ngx-mil-drupal/store/reducers';
import {MenuEffects} from '@ngx-mil-drupal/store/effects/menu.effects';

@NgModule({
  declarations: [
    DrupalRouterLinkDirective,
    MediaUrlPipe,
    PathAliasPipe
  ],
  exports: [
    DrupalRouterLinkDirective,
    MediaUrlPipe,
    PathAliasPipe
  ],
  imports: [
    CommonModule,
    AppConfigModule,
    HttpClientModule,
    StoreModule.forFeature('drupal', drupalReducers),
    EffectsModule.forFeature([MenuEffects, PathEffects])
  ],
  providers: [
    PathService,
    MediaUrlPipe,
    PathAliasPipe,
    AnalyticsService
  ]
})

export class DrupalModule {

  constructor(@Optional() @SkipSelf() parentModule: DrupalModule) {
    if (parentModule) {
      throw new Error(
        'DrupalModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: DrupalConfig): ModuleWithProviders<DrupalModule> {
    return {
      ngModule: DrupalModule,
      providers: [
        {provide: DrupalConfig, useValue: config}
      ]
    };
  }

}
