import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getCurrentLanguage} from '../store/reducers';
import {distinctUntilChanged, map, mergeMap, shareReplay, switchMap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Condition, ConditionGroup, ConditionGroupInput} from '../apollo/models/condition.model';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {FacetModel, FacetValueModel} from './models/facet.model';
import {ApolloQueryResult, QueryOptions} from 'apollo-client';
import {ApolloQueries} from '../apollo/apollo-queries';
import {Apollo} from 'apollo-angular';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  currentLanguage$: Observable<string> = this.store.pipe(
    select(getCurrentLanguage),
    distinctUntilChanged(),
    shareReplay(1)
  );

  contentTypes$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  queryParams$: Observable<ParamMap> = this.activatedRoute.queryParamMap.pipe(
    map(paramsMap => {
      return paramsMap;
    }),
    shareReplay(1)
  );

  variables$: Observable<{ facets: any[], filterConditions: ConditionGroupInput, freeText?: string[] }>;

  keywords$: ReplaySubject<FacetValueModel[]> = new ReplaySubject<FacetValueModel[]>();

  resultCount$: ReplaySubject<number> = new ReplaySubject<number>(1);

  results$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  hasMoreResults$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  freeText$: ReplaySubject<string> = new ReplaySubject<string>(1);

  set contentTypes(value: string[]) {
    this.contentTypes$.next(value);
  }

  set freeText(value: string) {
    this.freeText$.next(value);
  }

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private apollo: Apollo
  ) {

    this.queryParams$ = this.activatedRoute.queryParamMap.pipe(
      map(paramsMap => {
        return paramsMap;
      }),
      shareReplay(1)
    );

    this.init();

  }

  reset() {
    this.keywords$.next(null);
    this.results$.next(null);
    this.loading$.next(false);
    this.freeText$.next('');
    this.results$.next([]);
    this.resultCount$.next(0);
    this.hasMoreResults$.next(null);
  }

  clearResult() {
    this.results$.next([]);
    this.resultCount$.next(0);
  }

  destroy() {
    this.reset();
  }

  init() {

    this.reset();

    this.variables$ = combineLatest([
      this.queryParams$,
      this.currentLanguage$,
      this.contentTypes$
    ]).pipe(
      map(([
             queryParams,
             language,
             contentTypes
           ]: [ParamMap, string, string[]]) => {

        const conditionGroupInput: ConditionGroupInput = {
          conjunction: 'AND',
          groups: [
            {
              conjunction: 'AND',
              conditions: [
                {operator: '=', name: 'langcode', value: language},
                {operator: '=', name: 'status', value: 'true'}
              ]
            }
          ]
        };

        const contentTypeConditions: Condition[] = contentTypes.map(ct => {
          return {operator: '=', name: 'content_type', value: ct};
        });

        conditionGroupInput.groups.push({
          conjunction: 'OR',
          conditions: contentTypeConditions
        });

        let facets: any[] = [];

        const contentType: string = contentTypes[0];
        if (contentType === NgrxJsonApiDefinitions.ctInnovation) {
          facets = [
            {
              field: 'field_innovation_category',
              limit: 0,
              min_count: 0,
              operator: '=',
              missing: false
            }
          ];
        } else if (contentType === NgrxJsonApiDefinitions.ctNews) {
          facets = [
            {
              field: 'field_new_category',
              limit: 0,
              min_count: 0,
              operator: '=',
              missing: false
            },
            {
              field: 'field_brands',
              limit: 0,
              min_count: 0,
              operator: '=',
              missing: true
            },
            {
              field: 'awards_count',
              limit: 0,
              min_count: 0,
              operator: '=',
              missing: true
            }
          ];
        }

        const facetNames: string[] = ['field_new_category', 'field_brands', 'free_tagging_name', 'field_innovation_category', 'awards_count'];

        facetNames.forEach(facetName => {
          if (queryParams.get(facetName)) {

            const facetQueryParam: any[] = queryParams.getAll(facetName);
            const conditionGroup: ConditionGroup = {
              conjunction: 'OR',
              conditions: []
            };
            facetQueryParam.forEach(queryParam => {
              conditionGroup.conditions.push({operator: '=', name: facetName, value: queryParam});
            });
            conditionGroupInput.groups.push(conditionGroup);
          }
        });

        const variables: { facets: any[], filterConditions: ConditionGroupInput, freeText?: string[] } = {
          facets: facets,
          filterConditions: conditionGroupInput
        };

        if (queryParams.get('freeText')) {
          const freeTextSplit: string[] = queryParams.get('freeText').split(/[ ]+/);
          variables.freeText = freeTextSplit;
        }

        return variables;

      }),
      shareReplay(1)
    );

    this.freeText$.pipe(
      untilDestroyed(this, 'destroy'),
      withLatestFrom(
        this.variables$
      ),
      switchMap((
        [
          freeText,
          variables
        ]: [
          string,
          { facets: any[], filterConditions: ConditionGroupInput }
        ]) => {
        if (freeText && freeText.length > 0) {

          const freeTextSplit: string[] = freeText.split(/[ ]+/);
          const searchQueryOptions: QueryOptions = {
            query: ApolloQueries.searchInnovation,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            variables: {
              filterConditions: variables.filterConditions,
              facets: variables.facets,
              start: 0,
              end: ApolloQueries.searchResultSize,
              freeText: freeTextSplit,
            }
          };
          // this.searchInputFocus = false;
          this.results$.next([]);
          this.loading$.next(true);
          return this.apollo.query(searchQueryOptions);
        } else {
          return of([]);
        }
      }),
      shareReplay(1)
    ).subscribe((apolloQueryResult: ApolloQueryResult<any>) => {
      if (apolloQueryResult && apolloQueryResult.data && apolloQueryResult.data['searchAPISearch']) {
        const result: any[] = apolloQueryResult.data['searchAPISearch'].documents;
        const resultCount: number = apolloQueryResult.data['searchAPISearch'].result_count;
        const facets: FacetModel[] = apolloQueryResult.data['searchAPISearch'].facets;
        const hasMoreResults: boolean = result?.length > 1;
        this.results$.next(result);
        this.resultCount$.next(resultCount);
        this.hasMoreResults$.next(hasMoreResults);
        const freeTaggingFacet: FacetModel = facets.filter(facet => facet.name === 'free_tagging_name').shift();
        if (freeTaggingFacet) {
          const pertinenceFilteredValues: FacetValueModel[] = freeTaggingFacet.values.sort(this.compare);
          this.keywords$.next(pertinenceFilteredValues);
        } else {
          this.keywords$.next(null);
        }
      } else {
        this.results$.next(null);
        this.resultCount$.next(null);
        this.keywords$.next(null);
        this.hasMoreResults$.next(null);
      }
      this.loading$.next(false);
    });

  }

  compare(a, b) {
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    return 0;
  }

}
