import {RouteReuseStrategy} from '@angular/router/';
import {ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import {Injectable} from '@angular/core';


export class DefaultRouteReuseStrategy implements RouteReuseStrategy {

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

}

@Injectable()
export class AppReuseStrategy extends DefaultRouteReuseStrategy {

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const reuse = (future.data && future.data.hasOwnProperty('reuse')) ? future.data.reuse : null;
    const currContext = (curr.data && curr.data.hasOwnProperty('context')) ? curr.data.context : null;
    const futureContext = (future.data && future.data.hasOwnProperty('context')) ? future.data.context : null;
    /*console.log('future', future);
    console.log('curr', curr);
    console.log('curr.outlet', curr.outlet);
    console.log('reuse', reuse);
    console.log('currContext', currContext);
    console.log('futureContext', futureContext);*/
    /*if (currContext && futureContext) {
      return currContext.indexOf(futureContext) > -1 || reuse;
    }*/
    if (reuse !== null) {
      return reuse;
    }

    return future.routeConfig === curr.routeConfig;

  }
}
