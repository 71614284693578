<mat-toolbar class="position-relative py-1 py-sm-0 overflow-hidden">

  <div class="logo-group d-flex flex-fill align-self-stretch align-items-center">

    <a [routerLink]="'/welcome'" class="d-flex align-self-stretch flex-shrink-0">

      <img class="logo-img" src="assets/logos/wip_logo.svg"/>

    </a>

    <div class="ml-3">

      <ng-container *ngIf="(isLoading$|async)">

        <mat-progress-spinner
          [diameter]="30"
          [color]="'accent'"
          [mode]="'indeterminate'">
        </mat-progress-spinner>

      </ng-container>

    </div>

  </div>

  <ng-container *ngIf="!(responsiveService.isXs$|async)">

    <nav mat-tab-nav-bar color="accent" class="position-absolute pointer-events-none" mat-align-tabs="center">

      <ng-template ngFor [ngForOf]="menusService.getMainMenu()|async" let-menuItem let-index="index">

        <ng-container *ngIf="index != 0">

          <a mat-tab-link
             class="text-uppercase header mat-headline pointer-events-auto main-menu-item"
             [routerLink]="menuItem.relative"
             routerLinkActive #rla="routerLinkActive"
             [active]="rla.isActive || (!rla.isActive && (menuItem.relative === '/news' ? (isNewsContext$|async) : (isInnovationContext$|async)))">
            <ng-template
              [ngTemplateOutlet]="textMenuItem"
              [ngTemplateOutletContext]="{menuItem: menuItem, count:(menuItem?.count$|async)}">
            </ng-template>
          </a>

        </ng-container>

      </ng-template>

    </nav>

    <ng-template
      [ngTemplateOutlet]="('userFunctionMenu'|templates)|async"
      [ngTemplateOutletContext]="{itemLayoutClasses: ['mr-3']}">
    </ng-template>

  </ng-container>

  <button
    class="open-main-menu-button"
    (click)="openMenu.emit()"
    mat-icon-button
    color="accent">
    <mat-icon svgIcon="icon-menu"></mat-icon>
  </button>

</mat-toolbar>

<ng-template #textMenuItem let-menuItem="menuItem" let-count="count">
  <span
    [matBadge]="count"
    [matBadgeHidden]="!count"
    [innerHTML]="menuItem.title"></span>
</ng-template>

