import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'masonryImageHeight'
})
@Injectable()
export class MasonryImageHeightPipe implements PipeTransform {

  constructor() {
  }

  transform(imageObject: any, containerWidth: number, imageRatio: number = null): number {
    let ratio: number;
    if (!imageRatio) {
      if (!!imageObject.size.height && imageObject.size.width) {
        ratio = imageObject.size.height / imageObject.size.width;
      } else {
        ratio = 9 / 16;
      }
    } else {
      ratio = imageRatio;
    }
    const height: number = containerWidth * ratio;
    return height;
  }

}
