import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ImageFile} from '@ngx-mil-drupal/models/image-file';

@Component({
  selector: 'app-image-slide-renderer',
  templateUrl: './image-slide-renderer.component.html',
  styleUrls: ['./image-slide-renderer.component.scss']
})
export class ImageSlideRendererComponent implements OnInit {

  get data(): ImageFile {
    return this._data;
  }

  @HostBinding('class') class = 'd-flex overflow-hidden flex-fill';

  private _data: ImageFile;

  @Input()
  set data(value: ImageFile) {
    this._data = value;
  }

  constructor() {
  }

  ngOnInit() {

  }

}
