import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {PathInfos} from './models/path-infos.model';

import {AppConfigService} from '@config/app-config.service';
import {getPathInfos, DrupalPathState} from '@ngx-mil-drupal/store/reducers/path.reducers';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  currentPathInfos$: Observable<PathInfos> = this.store.pipe(select(getPathInfos));

  currentUrl$: Observable<string>;


  currentUrlSubscription: Subscription;

  constructor(
    private appConfig: AppConfigService,
    private store: Store<DrupalPathState>,
    @Inject(PLATFORM_ID) private platform: Object
  ) {

  }

  enable() {
    const enableGA: boolean = isPlatformBrowser(this.platform) && window['ga'] && !window['navigator'].userAgent.match(/HeadlessChrome/);
    if (enableGA) {
      const gaApi: any = window['ga'];
      gaApi('create', this.appConfig.getConfig().googleAnalyticsAccount, 'auto');
      this.currentUrl$ = this.currentPathInfos$.pipe(
        filter((pathInfos: PathInfos) => !!pathInfos),
        map((pathInfos: PathInfos) => {
          return pathInfos.entity.canonical;
        }));
      this.currentUrlSubscription = this.currentUrl$.pipe(take(1)).subscribe((url: string) => {
        this.sendAnalytics(url);
      });
    } else {
      setTimeout(() => this.enable(), 25);
    }
  }

  disable() {
    if (this.currentUrlSubscription) {
      this.currentUrlSubscription.unsubscribe();
    }
  }

  sendAnalytics(url: string) {
    const enableGA: boolean = isPlatformBrowser(this.platform) && window['ga'] && !window['navigator'].userAgent.match(/HeadlessChrome/);
    if (enableGA) {
      const gaApi: any = window['ga'];
      gaApi('set', 'page', url);
      gaApi('send', 'pageview');
    }
  }

}
