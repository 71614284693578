import {Component, Input, OnInit} from '@angular/core';
import {ImageFile} from '@ngx-mil-drupal/models/image-file';
import {MediaImage} from '@ngx-mil-drupal/models/media-image';

@Component({
  selector: 'app-image-renderer',
  templateUrl: './image-renderer.component.html',
  styleUrls: ['./image-renderer.component.scss']
})
export class ImageRendererComponent implements OnInit {

  get data(): ImageFile {
    return this._data;
  }

  private _data: ImageFile;

  @Input()
  set data(value: ImageFile) {
    this._data = value;
  }

  @Input()
  imageMedia: MediaImage;

  @Input()
  relationshipName = 'field_media_image';

  @Input()
  derivative: string;

  constructor() {
  }

  ngOnInit() {
    /*console.log('***');
    console.log('this.imageMedia', this.imageMedia);
    console.log('this.imageMedia.relationships', this.imageMedia.relationships);
    console.log('this.imageMedia.relationships.field_media_image', this.imageMedia.relationships.field_media_image);
    console.log('this.imageMedia.relationships.field_media_image.data', this.imageMedia.relationships.field_media_image.data);
    console.log('this.imageMedia.relationships.field_media_image.data.meta', this.imageMedia.relationships.field_media_image.data.meta);
    console.log('this.imageMedia.relationships.field_media_image.data.meta.imageDerivatives', this.imageMedia.relationships.field_media_image.data.meta.imageDerivatives);
    console.log('***');*/
  }

}
