import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {AppConfigService} from '../../config/app-config.service';
import {WindowRef} from '../../utils/window-ref';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ClearLocalStorage} from '../../store/actions/app-core.actions';

@Component({
  selector: 'app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;

  subs: Subscription[] = [];

  environnements$: Observable<any[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private appConfig: AppConfigService,
    private windowRef: WindowRef,
    private store: Store<any>
  ) {


    let environmentKey = 'prod';
    if (localStorage && localStorage.getItem('app_config_key')) {
      environmentKey = localStorage.getItem('app_config_key');
    }
    this.form = this.fb.group({
        environment: new UntypedFormControl(environmentKey)
      }
    );

    this.environnements$ = this.appConfig.loadAppAllConfig().pipe(
      map((configs) => {
          return Object.keys(configs).map(key => {
            return {key: key, label: key, backendUrl: configs[key].backendUrl};
          });
        }
      )
    );

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  reload() {
    if (localStorage) {
      localStorage.clear();
      localStorage.setItem('app_config_key', this.form.value.environment);
    }
    this.store.dispatch(new ClearLocalStorage(true));
    if (this.windowRef && this.windowRef.nativeWindow) {
      (this.windowRef.nativeWindow as Window).location.reload();
    }
  }

}
