<ng-container *ngIf="node$|async as node; else loadingTemplate">

  <ng-container *ngIf="node.state==='NEW'">
    <ng-template
      [ngTemplateOutlet]="loadingTemplate">
    </ng-template>
  </ng-container>

  <header class="row">

    <div class="col-12">

      <ng-template
        [ngTemplateOutlet]="templatesCheckerTemplate"
        [ngTemplateOutletContext]="{templates: (topTemplates$|async)}">
      </ng-template>

    </div>

  </header>

  <main class="row flex-fill">

    <div class="col-12 col-sm-6" [class]="class === 'news-template'?'':'order-1 order-sm-0'">

        <ng-template
          [ngTemplateOutlet]="templatesCheckerTemplate"
          [ngTemplateOutletContext]="{templates: (leftTemplates$|async)}">
        </ng-template>

    </div>

    <div class="col-12 col-sm-6" [class]="class === 'news-template'?'':'order-0 order-sm-1'">

      <ng-template
        [ngTemplateOutlet]="templatesCheckerTemplate"
        [ngTemplateOutletContext]="{templates: (rightTemplates$|async)}">
      </ng-template>

    </div>

  </main>

  <ng-container *ngIf="(footerTemplates$|async)">

    <footer class="row footer">

      <div class="col-12">

        <ng-template
          [ngTemplateOutlet]="templatesCheckerTemplate"
          [ngTemplateOutletContext]="{templates: (footerTemplates$|async)}">
        </ng-template>

      </div>

    </footer>

  </ng-container>

</ng-container>

<ng-template #templatesCheckerTemplate let-templates="templates">

  <ng-container *ngIf="templates">

    <ng-template ngFor [ngForOf]="templates" let-templateId>

      <ng-template
        [ngTemplateOutlet]="(templatesService.getTemplate$(templateId)|async)"
        [ngTemplateOutletContext]="{node: (node$|async), zone: (zone$|async)}">
      </ng-template>

    </ng-template>

  </ng-container>

</ng-template>

<ng-template #loadingTemplate>
  <div class="position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center">
    <mat-progress-spinner [diameter]="40" [color]="'accent'" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
</ng-template>
