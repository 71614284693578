import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'menuItemActive'
})
export class MenuItemActivePipe implements PipeTransform {

  constructor() {

  }

  transform(link: string, url: string): boolean {
    let isActive: boolean;
    if (url) {
      if (url.indexOf('/innovation') > -1 && link === '/innovations') {
        isActive = true;
      } else if ((url.indexOf('/news') > -1 || url.indexOf('/actualites') > -1) && link === '/news') {
        isActive = true;
      } else {
        isActive = (link === url);
      }
    }
    return isActive;
  }

}
