import {Component, Input, OnInit} from '@angular/core';
import {Resource} from 'ngrx-json-api';

@Component({
  selector: 'app-user-date',
  templateUrl: './user-date.component.html',
  styleUrls: ['./user-date.component.scss']
})
export class UserDateComponent implements OnInit {

  @Input()
  user: Resource;

  @Input()
  date: string;

  constructor() {
  }

  ngOnInit() {
  }

}
