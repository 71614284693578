/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {PathService} from './path.service';
import {DrupalState} from '@ngx-mil-drupal/store/reducers';


@Injectable()
export class PathInfosResolver implements Resolve<any> {

  constructor(
    private store: Store<DrupalState>,
    private router: Router,
    private pathService: PathService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const url: string = state.url.substring(1);

    return true;
  }

}


