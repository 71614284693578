import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SwiperInterface} from '../../../models/swiper-interface';

@Component({
  selector: 'app-swiper-bullets',
  templateUrl: './swiper-bullets.component.html',
  styleUrls: ['./swiper-bullets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwiperBulletsComponent implements OnInit {

  @Input()
  data: any[];

  @Input()
  swiper: SwiperInterface;

  constructor() {
  }

  ngOnInit() {
  }

  goToIndex(index: number) {
    this.swiper.goToIndex(index);
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

}
