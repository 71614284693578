<a
  [drupalRouterLink]="'/' + data.attributes.langcode + data.attributes.path.alias"
  [drupalTitle]="data.attributes.title"
  [drupalLanguage]="data.attributes.langcode"
  [drupalIdentifier]="{id:data.id, type: data.type}"
  class="row no-gutters news-slide d-flex overflow-hidden flex-fill">

  <div
    class="col-12 col-sm-8 overflow-hidden max-height-100">

    <ng-container *ngIf="(data.relationships.field_node_thumbnail) as thumbnail">

      <ng-container *ngIf="thumbnail.data">

        <app-image-renderer
          [derivative]="'crop_16_9_1024'"
          [imageMedia]="(thumbnail.data|jaSelectStoreResource:zone)|async">
        </app-image-renderer>

      </ng-container>

    </ng-container>

  </div>

  <div class="col-12 col-sm-4 d-flex flex-column overflow-hidden max-height-100">

    <div class="d-flex flex-column overflow-hidden max-height-100 p-4">

      <ng-container *ngIf="!(responsiveService.isXs$|async)">
        <h2 class="text-uppercase mb-2">{{data.attributes.title}}</h2>
      </ng-container>

      <ng-container *ngIf="(responsiveService.isXs$|async)">
        <h2 class="text-uppercase" clampy="2">{{data.attributes.title}}</h2>
      </ng-container>

      <small>{{data.attributes.published_at|date}}</small>

      <ng-container *ngIf="!(responsiveService.isXs$|async)">
        <ng-container *ngIf="data.attributes.field_news_description && data.attributes.field_news_description.value">
          <ng-container *ngIf="data.attributes.field_news_description.value|stripTags as description">
            <div
              clampy
              class="flex-fill overflow-hidden"
              [innerHTML]="description">
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <!--
      <ng-template ngFor [ngForOf]="data.free_tagging_name" let-tagName>

        <span class="mr-2">#{{tagName}}</span>

      </ng-template>
      -->

    </div>

  </div>

</a>
