import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TemplatesService} from '../../../templates.service';
import {Templates} from '../../templates';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent implements OnInit, AfterViewInit {

  @Input()
  render: boolean;

  @ViewChild('contentHeaderTemplate', /* TODO: check static flag */ {static: true})
  contentHeaderTemplate: TemplateRef<any>;

  constructor(
    public templatesService: TemplatesService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.templatesService.addTemplate(Templates.contentHeader, this.contentHeaderTemplate);
  }

}
