import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared.module';
import {ComponentsModule} from '../components/components.module';
import {PollListComponent} from './poll-list/poll-list.component';
import {ReactiveComponentModule} from '@ngrx/component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MomentModule} from 'ngx-moment';

@NgModule({
  declarations: [PollListComponent],
  exports: [],
  imports: [
    CommonModule,
    ComponentsModule,
    SharedModule,
    ReactiveComponentModule,
    MatProgressSpinnerModule,
    MomentModule
  ]
})
export class PollModule {
}
