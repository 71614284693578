/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {NgrxJsonApiService, Query} from 'ngrx-json-api';


@Injectable()
export class DrupalContentResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
    private ngrxJsonApiService: NgrxJsonApiService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const routeData: any = route.data;
    const typeBundle: string = routeData.type + '--' + routeData.bundle;
    const uuid: string = routeData.uuid;

    const drupalContentQuery: Query = {
      queryId: 'drupalContent',
      type: typeBundle,
      id: uuid
    };

    // this.ngrxJsonApiService.getZone(NGRX_JSON_API_DEFAULT_ZONE).putQuery({query: drupalContentQuery, fromServer: true});

  }

}


