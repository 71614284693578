import {RoutesMap} from './models/routes-map.model';
import {QueriesMap} from './models/queries-map';

export class DrupalConfig {

  queries: QueriesMap;

  routes: RoutesMap;

}
