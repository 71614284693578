import {Pipe, PipeTransform, TemplateRef} from '@angular/core';
import {TemplatesService} from '../templates.service';
import {filter, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
  name: 'templates'
})
export class TemplatesPipe implements PipeTransform {

  constructor(
    private templatesService: TemplatesService
  ) {

  }

  transform(templateId: string): Observable<TemplateRef<any>> {
    return this.templatesService.templates$.pipe(
      map(templates => {
        return templates[templateId];
      }),
      filter(template => !!template),
      take(1),
      map(template => {
        return template;
      }),
    );
  }

}
