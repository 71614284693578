import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store/reducers';
import {getBreakpoint} from '../store/reducers';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  breakpoint$ = this.store.pipe(select(getBreakpoint));

  isXs$: Observable<boolean> = this.breakpoint$.pipe(
    map(breakPoint => breakPoint === 'xs'),
    catchError(error => {
      console.error({error});
      return of(false);
    })
  );

  constructor(
    private store: Store<fromRoot.State>
  ) {


  }


}
