import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import {ComponentsModule} from '../components/components.module';
import {SharedModule} from '../shared.module';
import { SearchSelectFilterComponent } from './search-select-filter/search-select-filter.component';
import { SearchTemplatesComponent } from './templates/search-templates.component';
import { SearchCheckBoxFilterComponent } from './search-check-box-filter/search-check-box-filter.component';

@NgModule({
  declarations: [SearchComponent, SearchSelectFilterComponent, SearchTemplatesComponent, SearchCheckBoxFilterComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    SharedModule
  ]
})
export class SearchModule { }
