import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'youTubeId'
})
export class YouTubeIdPipe implements PipeTransform {

  transform(url: string): string {
    if (url) {
      return url.split('?v=')[1];
    }
    return null;
  }

}
