import {Component, Input, OnInit} from '@angular/core';
import {FieldBody} from '@ngx-mil-drupal/models/field-body.model';

@Component({
  selector: 'app-node-body',
  templateUrl: './node-body.component.html',
  styleUrls: ['./node-body.component.scss']
})
export class NodeBodyComponent implements OnInit {

  @Input()
  body: FieldBody;

  constructor() {
  }

  ngOnInit() {
  }

}
