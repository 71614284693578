import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {select, Store} from '@ngrx/store';
import {combineLatest, interval, Observable, of, ReplaySubject} from 'rxjs';
import {ManyQueryResult, NgrxJsonApiService, NgrxJsonApiZoneService, Resource, StoreResource, uuid} from 'ngrx-json-api';
import {debounceTime, distinctUntilChanged, filter, map, pairwise, skip, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {NgrxJsonApiQueries} from '../ngrx-json-api/ngrx-json-queries';
import {FormTemplatesService} from '@material-form-widgets/form-templates.service';
import {FlatDataToResourcePipe, getFormShema, LoadSchema, MilSchema, ResourceToFlatDataPipe} from '@madeinlune/ngx-drupal-schemata';
import {Templates} from '../components/templates';
import {TemplatesService} from '../templates.service';
import {CartWorkflow} from '../models/cart.model';
import {LanguageSwitcherService} from '../services/language-switcher.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {diff} from 'deep-object-diff';

export type SENT_STATES = 'pending' | 'complete';

@UntilDestroy()
@Component({
  selector: 'app-my-share',
  templateUrl: './my-share.component.html',
  styleUrls: ['./my-share.component.scss']
})
export class MyShareComponent implements OnInit, OnDestroy, AfterViewInit {

  formValue$: ReplaySubject<any> = new ReplaySubject<any>(1);

  sentState$: ReplaySubject<SENT_STATES> = new ReplaySubject<SENT_STATES>(1);

  private _isFormValid: boolean;

  get isFormValid(): boolean {
    return this._isFormValid;
  }

  set isFormValid(value: boolean) {
    this._isFormValid = value;
  }

  zone: string = NgrxJsonApiDefinitions.zoneShareBox;
  resource$: Observable<StoreResource>;
  model$: Observable<any>;

  loading$: Observable<boolean>;

  schema$: ReplaySubject<MilSchema> = new ReplaySubject<MilSchema>(1);

  // draftState$: Observable<StoreResource>;
  // readyState$: Observable<StoreResource>;

  @ViewChild('innovationReferenceTemplate') innovationReferenceTemplate: TemplateRef<any>;
  @ViewChild('innovationReferenceListTemplate') innovationReferenceListTemplate: TemplateRef<any>;

  saving$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private store: Store<any>,
    private ngrxJsonApiService: NgrxJsonApiService,
    private resourceToFlatData: ResourceToFlatDataPipe,
    private flatDataToResource: FlatDataToResourcePipe,
    public formTemplatesService: FormTemplatesService,
    private templatesService: TemplatesService,
    private languageSwitcherService: LanguageSwitcherService
  ) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {

    this.saving$.next(false);

    const shareBoxService: NgrxJsonApiZoneService = this.ngrxJsonApiService.getZone(this.zone);

    this.store.dispatch(new LoadSchema(NgrxJsonApiDefinitions.cart.type, NgrxJsonApiDefinitions.cart.collectionPath));

    this.languageSwitcherService.currentLanguage$.pipe(
      untilDestroyed(this),
      pairwise()
    ).subscribe(currentLanguage => {
      shareBoxService.removeQuery(NgrxJsonApiQueries.queryCart.queryId);
      shareBoxService.putQuery({
        query: NgrxJsonApiQueries.queryCart,
        fromServer: true
      });
      this.schema$.next(null);
      this.store.dispatch(new LoadSchema(NgrxJsonApiDefinitions.cart.type, NgrxJsonApiDefinitions.cart.collectionPath));
    });

    // this.store.dispatch(new LoadSchema(NgrxJsonApiDefinitions.workflowCartTransition.type, NgrxJsonApiDefinitions.workflowCartTransition.collectionPath));

    /*this.readyState$ = this.ngrxJsonApiService.findMany({
      query: {
        queryId: 'workflowStates',
        type: NgrxJsonApiDefinitions.workflowState.type,
        params: {
          filtering: [
            {
              path: 'label',
              value: 'Cart Ready'
            }
          ]
        }
      }
    }).pipe(
      filter(manyQueryResult => {
        return manyQueryResult.loading === false && !!manyQueryResult.data && manyQueryResult.data.length > 0;
      }),
      map(manyQueryResult => {
        console.log('manyQueryResult', manyQueryResult);
        return manyQueryResult.data[0];
      })
    );

    this.draftState$ = this.ngrxJsonApiService.findMany({
      query: {
        queryId: 'workflowStates',
        type: NgrxJsonApiDefinitions.workflowState.type,
        params: {
          filtering: [
            {
              path: 'label',
              value: 'Cart Draft'
            }
          ]
        }
      }
    }).pipe(
      filter(manyQueryResult => {
        return manyQueryResult.loading === false && !!manyQueryResult.data && manyQueryResult.data.length > 0;
      }),
      map(manyQueryResult => {
        console.log('manyQueryResult', manyQueryResult);
        return manyQueryResult.data[0];
      })
    );*/

    // entity_id
    // from_sid
    // to_sid
  }

  ngAfterViewInit(): void {

    const shareBoxService: NgrxJsonApiZoneService = this.ngrxJsonApiService.getZone(this.zone);

    this.formTemplatesService.addTemplate('innovationReference', this.innovationReferenceTemplate);
    this.formTemplatesService.addTemplate('innovationReferenceList', this.innovationReferenceListTemplate);


    // this.sentState$.next('pending');

    this.store.pipe(
      select(getFormShema(NgrxJsonApiDefinitions.cart.type)),
      filter(schema => !!schema),
      map(schema => {
        const clonedSchema: any = JSON.parse(JSON.stringify(schema));
        clonedSchema.zone = this.zone;
        clonedSchema.meta = {
          ...clonedSchema.meta,
          zone: this.zone
        };
        return clonedSchema;
      }),
    ).subscribe(schema => {
      this.schema$.next(schema);
    });

    /*shareBoxService.hasQuery(NgrxJsonApiQueries.queryCart.queryId).pipe(
      take(1)
    ).subscribe(hasQuery => {
      if (hasQuery) {
        shareBoxService.refreshQuery(NgrxJsonApiQueries.queryCart.queryId);
      }
    });*/

    this.loading$ = shareBoxService.selectManyResults(NgrxJsonApiQueries.queryCart.queryId)
      .pipe(
        map((manyQueryResult: ManyQueryResult) => !!manyQueryResult && manyQueryResult.loading),
      );

    this.resource$ = shareBoxService.hasQuery(NgrxJsonApiQueries.queryCart.queryId).pipe(
      filter(hasQuery => hasQuery === true),
      switchMap(hasQuery => {
        if (hasQuery) {
          return shareBoxService.selectManyResults(NgrxJsonApiQueries.queryCart.queryId).pipe(
            filter((manyQueryResult: ManyQueryResult) => !!manyQueryResult && manyQueryResult.loading === false),
            map((manyQueryResult: ManyQueryResult) => manyQueryResult.data),
            distinctUntilChanged((oldValue, newValue) => {
              return diff(oldValue, newValue) ? Object.keys(diff(oldValue, newValue)).length === 0 : false;
            }),
            map((carts: Resource[]) => {
              if (carts && carts.length > 0) {
                return carts[0];
              } else {
                return null;
              }
            })
          );
        } else {
          return of(null);
        }
      })
    );

    this.model$ = combineLatest([
      this.schema$.pipe(
        filter(schema => !!schema),
        distinctUntilChanged((oldValue, newValue) => {
          return diff(oldValue, newValue) ? Object.keys(diff(oldValue, newValue)).length === 0 : false;
        })
      ),
      this.resource$.pipe(
        distinctUntilChanged((oldValue, newValue) => {
          return diff(oldValue, newValue) ? Object.keys(diff(oldValue, newValue)).length === 0 : false;
        })
      )
    ]).pipe(
      take(1),
      map(([schema, resource]: [any, StoreResource]) => {
        if (!!schema && !!resource) {
          return this.resourceToFlatData.transform(resource, schema);
        } else {
          return null;
        }
      })
    );

    this.formValue$.pipe(
      untilDestroyed(this),
      debounceTime(1000),
      filter(value => Object.keys(value).length > 0),
      distinctUntilChanged((oldValue, newValue) => {
        return diff(oldValue, newValue) ? Object.keys(diff(oldValue, newValue)).length === 0 : false;
      }),
      skip(1),
      withLatestFrom(this.schema$, this.saving$),
      filter(([formValue, schema, saving]: [any, any, boolean]) => {
        return saving === false;
      }),
    ).subscribe(([formValue, schema, saving]: [any, any, boolean]) => {
      const updatedResource: StoreResource = this.flatDataToResource
        .transform(formValue, schema);

      // updatedResource.attributes.langcode = {value: 'fr'};

      if (updatedResource.state === 'NEW') {
        this.ngrxJsonApiService.getZone(this.zone).postResource({resource: updatedResource, toRemote: true});
      } else {
        this.ngrxJsonApiService.getZone(this.zone).patchResource({resource: updatedResource, toRemote: true});
      }

      this.saving$.next(true);

      setTimeout(() => {

        this.ngrxJsonApiService.getZone(this.zone).selectStoreResource(updatedResource)
          .pipe(
            filter(resource => resource.state === 'IN_SYNC'),
            take(1)
          ).subscribe(storeResourceSaved => {
          this.saving$.next(false);
        });

      });

    });
  }

  send() {
    this.resource$.pipe(
      take(1)
    ).subscribe((resource) => {
      const clonedResource: Resource = JSON.parse(JSON.stringify(resource));
      clonedResource.attributes.field_cart_workflow = CartWorkflow.Ready;
      this.ngrxJsonApiService.getZone(this.zone).patchResource({resource: clonedResource, toRemote: true});
      this.sentState$.next('pending');
      setTimeout(() => {
        this.ngrxJsonApiService.getZone(this.zone).selectStoreResource(clonedResource)
          .pipe(
            untilDestroyed(this),
            filter(storeResource => {
              const cartWorkflow: string = storeResource?.attributes?.field_cart_workflow;
              return storeResource.state === 'IN_SYNC' &&
                !!cartWorkflow && cartWorkflow !== CartWorkflow.Draft;
            }),
            take(1)
          ).subscribe(storeResourceSaved => {
          this.sentState$.next('complete');
          this.ngrxJsonApiService.getZone(this.zone).removeQuery(NgrxJsonApiQueries.queryCart.queryId);
          this.ngrxJsonApiService.getZone(this.zone).putQuery({query: NgrxJsonApiQueries.queryCart, fromServer: true});

          interval(4000).pipe(
            untilDestroyed(this),
            take(1)
          ).subscribe(() => {
            this.sentState$.next(null);
          });
        });
      });

      /*const workflowState: Resource = {
        type: NgrxJsonApiDefinitions.workflowCartTransition.type,
        id: uuid(),
        attributes: {
          field_name: 'field_cart_workflow'
        },
        relationships: {
          entity_id: {
            data: {
              type: resource.type,
              id: resource.id
            }
          },
          from_sid: {
            data: {
              type: draftState.type,
              id: draftState.id
            }
          },
          to_sid: {
            data: {
              type: sendedState.type,
              id: sendedState.id
            }
          }
        }
      };

      this.ngrxJsonApiService.getZone(this.zone).postResource({resource: workflowState, toRemote: true});*/

      //entity_id
      //from_sid
      //to_sid

    });
  }

  getNewCart(): Resource {
    return {
      type: NgrxJsonApiDefinitions.cart.type,
      id: uuid(),
      attributes: {
        field_cart_workflow: CartWorkflow.Draft
      }
    };
  }

  onFormError(errorEvent: any) {
  }

  onFormChange(event) {
    this.formValue$.next(event.value);

  }

  get appFooterTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.appFooter);
  }

}
