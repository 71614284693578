import {Component, OnInit} from '@angular/core';
import {InitImplicitFlowAction} from '../../store/actions/auth.actions';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private store: Store<any>,
    private router: Router,
    private oauthService: OAuthService
  ) {
  }

  ngOnInit() {
  }

  connect() {
    this.store.dispatch(new InitImplicitFlowAction(this.router.url));
  }


}
