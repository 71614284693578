import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {getItemsByContentType, getListThumbSize, getRouteContentTypes} from '../store/reducers';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {TemplatesService} from '../templates.service';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {NgrxJsonApiService} from 'ngrx-json-api';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {FacetModel} from './models/facet.model';
import {ApolloSearchIndexService} from '../apollo/apollo-search-index.service';
import {Templates} from '../components/templates';
import {ListThumbSizes} from '../models/list-thumb-sizes.model';
import {NotificationsService} from '../services/notifications.service';
import {ScrollDispatcher} from '@angular/cdk/overlay';
import {ApolloQueries} from '../apollo/apollo-queries';
import {SearchService} from './search.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AddToIndex, ClearAll} from '../store/actions/search.actions';
import {InnovationIndexItem} from '../apollo/innovation-index-item';
import {StripTagsPipe} from 'ngx-pipes';
import {SearchResultService} from './search-result.service';
import {SEARCH_SERVICE} from '../app.module';
import {Apollo} from 'apollo-angular';

@UntilDestroy()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StripTagsPipe]
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostBinding('class') class = 'd-flex flex-fill flex-column';

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  listThumbSize$: Observable<ListThumbSizes> = this.store.pipe(
    select(getListThumbSize)
  );

  routeContentTypes$: Observable<string[]> = this.store.pipe(select(getRouteContentTypes));

  queryParams$: Observable<ParamMap>;

  itemsByContentType$: Observable<InnovationIndexItem[]>;

  fieldTypeQueries: any = {
    field_new_category: {
      label: 'SEARCH.FILTER.NEWS_CATEGORY',
      template: 'select',
      type: NgrxJsonApiDefinitions.newsCategory.type
    },
    field_brands: {
      label: 'SEARCH.FILTER.NEWS_BRAND',
      template: 'select',
      type: NgrxJsonApiDefinitions.brands.type
    },
    field_innovation_category: {
      label: 'SEARCH.FILTER.INNOVATION_CATEGORY',
      template: 'select',
      type: NgrxJsonApiDefinitions.innovationCategory.type
    },
    awards_count: {
      label: 'SEARCH.FILTER.AWARDED',
      template: 'checkbox',
      type: 'boolean'
    }
  };

  facets$: Observable<FacetModel[]>;

  @ViewChild('searchResultCountTemplate', /* TODO: check static flag */ {static: true})
  searchResultCountTemplate: TemplateRef<any>;

  @ViewChild('searchFiltersGroupTemplate', /* TODO: check static flag */ {static: true})
  searchFiltersGroupTemplate: TemplateRef<any>;

  searchService: SearchService;

  liveData$: ReplaySubject<InnovationIndexItem[]> = new ReplaySubject<InnovationIndexItem[]>();

  constructor(
    private store: Store<any>,
    public templatesService: TemplatesService,
    private ngrxJsonApiService: NgrxJsonApiService,
    private activatedRoute: ActivatedRoute,
    public apolloSearchIndexService: ApolloSearchIndexService,
    private notificationsService: NotificationsService,
    private scrollDispatcher: ScrollDispatcher,
    private elementRef: ElementRef,
    private stripTagsPipe: StripTagsPipe,
    private cdr: ChangeDetectorRef,
    private searchResultService: SearchResultService,
    private apollo: Apollo,
    @Inject(SEARCH_SERVICE) private searchServiceFactory
  ) {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }

    // this.apolloSearchIndexService.destroy();

    // this.liveData$.next([]);

    // this.store.dispatch(new ClearAll());

  }

  ngOnInit() {

    this.activatedRoute.data
      .pipe(
        map(data => data?.contentTypes),
        filter(contentTypes => !!contentTypes),
        take(1)
      )
      .subscribe(contentTypes => {
        this.searchService = this.searchServiceFactory(this.store, this.activatedRoute, this.apollo);
        this.searchService.contentTypes = contentTypes;

        this.searchService.variables$
          .pipe(
            untilDestroyed(this)
          )
          .subscribe(variables => {

            this.store.dispatch(new ClearAll());

            this.apolloSearchIndexService.pageStart = 0;
            this.apolloSearchIndexService.pageSize = ApolloQueries.listPageSize;

            if (!!this.apolloSearchIndexService.queryRef) {
              this.apolloSearchIndexService.queryRef.resetLastResults();
            }

            const newVariables = {
              ...variables,
              start: 0,
              end: this.apolloSearchIndexService.pageSize
            };

            if (JSON.stringify(this.apolloSearchIndexService.variables) === JSON.stringify(newVariables)) {
              this.apolloSearchIndexService.queryRef.refetch(variables);
            } else {
              this.apolloSearchIndexService.variables = newVariables;
            }


          });

      });

    this.facets$ = this.apolloSearchIndexService.facets$;

    this.apolloSearchIndexService.apolloSearchApiErrors$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(errors => {
        this.notificationsService.showServerError(-1, errors.map(error => error.message));
      });

    this.apolloSearchIndexService.data$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.searchService.contentTypes$, this.activatedRoute.queryParamMap, this.searchService.currentLanguage$)
      )
      .subscribe(([data, contentTypes, queryParams, language]) => {
        const newData = data.map(item => {
          const clonedItem: InnovationIndexItem = {...item};
          return clonedItem;
        });

        let hasQueryParams: boolean;
        queryParams.keys.forEach(key => {
          const param: string[] = queryParams.getAll(key);
          if (param.length > 0) {
            hasQueryParams = true;
          }
        });

        this.store.dispatch(new AddToIndex(language, contentTypes[0], newData));
      });

    this.itemsByContentType$ = combineLatest(
      [this.searchService.contentTypes$, this.searchService.currentLanguage$]
    )
      .pipe(
        untilDestroyed(this),
        switchMap(([contentTypes, language]) => {
          return this.store.pipe(select(getItemsByContentType(language, contentTypes[0])));
        }),
        distinctUntilChanged((oldValue, newValue) => {
          return JSON.stringify(oldValue) === JSON.stringify(newValue);
        }),
        shareReplay(1)
      );

    this.apolloSearchIndexService.resultCount$.pipe(
      untilDestroyed(this)
    ).subscribe(resultCount => {
      this.searchResultService.resultCount = resultCount;
    });

  }

  ngAfterViewInit(): void {

    this.templatesService.addTemplate(Templates.searchIndexResultCount, this.searchResultCountTemplate);
    this.templatesService.addTemplate(Templates.searchFiltersGroup, this.searchFiltersGroupTemplate);

    this.scrollDispatcher.scrolled(100).pipe(
      takeUntil(this.destroyed$),
      withLatestFrom(this.apolloSearchIndexService.lockLoading$)
    ).subscribe(([scrolled, lockLoading]) => {
      if (scrolled && !lockLoading) {
        if (this.scrollDispatcher.getAncestorScrollContainers(this.elementRef).indexOf(scrolled) > -1) {
          if (scrolled.measureScrollOffset('bottom') < scrolled.getElementRef().nativeElement.offsetHeight) {
            this.apolloSearchIndexService.nextPage();
          }
        }
      } else if (!scrolled && !lockLoading) {
        if (window) {
          if (window.pageYOffset < window.innerHeight) {
            this.apolloSearchIndexService.nextPage();
          }
        }
      }
    });

  }

  get searchFilters(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.searchFilters);
  }

  get appFooterTemplate(): TemplateRef<any> {
    return this.templatesService.getTemplate(Templates.appFooter);
  }

}
