<ng-container>

  <ng-container *ngIf="(currentLanguage$|async) && (userService.userInfos$|async)">

    <header>
      <app-header (openMenu)="sideMainMenu.toggle()">
      </app-header>
    </header>

    <mat-sidenav-container class="background-none">

      <mat-sidenav
          #sideFiltersMenu
          class="search-filters"
          [mode]="'over'">

        <ng-container *ngIf="(responsiveService.isXs$|async)">
          <div class="p-5 flex-column">

            <h3 [innerHTML]="'SEARCH.FILTER.FILTERS'|translate"></h3>

            <ng-template
              [ngTemplateOutlet]="('searchFiltersGroup'|templates)|async"
              [ngTemplateOutletContext]="{fromSideMenu: true, layoutClasses: ['flex-column']}">
            </ng-template>

          </div>
        </ng-container>

      </mat-sidenav>

      <mat-sidenav
          #sideMainMenu
          class="sidenav-main-menu overflow-x-hidden"
          [mode]="'over'"
          position="end">

        <app-main-menu [userName]="userService.userName$|async" class="overflow-x-hidden">
        </app-main-menu>

      </mat-sidenav>


      <div class="flex-column flex-fill d-flex main-content-group" [ngClass]="mainContentGroupClassName$|async">

        <div *ngIf="pwaService.showInstallComponent && routeIsHome$|async">

          <ng-template
            [ngTemplateOutlet]="('pwaBanner'|templates)|async">
          </ng-template>


        </div>

        <div class="top-group px-sm-4" [class.pt-4]="topContainerOutletActive" [ngClass]="(topGroupClassName$|async)">
          <router-outlet
            name="topContainer"
            (activate)="topContainerOutletActive = true"
            (deactivate)="topContainerOutletActive = false">
          </router-outlet>
        </div>

        <mat-sidenav-container class="background-none">

          <mat-sidenav
              #contentLeftListSideNav
              class="content-list background-none border-none d-none d-sm-flex"
              [mode]="'side'"
              [opened]="false"
              position="start">

            <ng-container *ngIf="!(responsiveService.isXs$|async)">
              <router-outlet
                (activate)="contentLeftListSideNav.open()"
                (deactivate)="contentLeftListSideNav.close()"
                name="sideLeftList">
              </router-outlet>
            </ng-container>

          </mat-sidenav>

          <mat-sidenav
              #contentListRightSideNav
              class="content-list background-none border-none d-none d-sm-flex"
              [mode]="'side'"
              [opened]="false"
              position="end">

            <ng-container *ngIf="!(responsiveService.isXs$|async)">
              <router-outlet
                (activate)="contentListRightSideNav.open()"
                (deactivate)="contentListRightSideNav.close()"
                name="sideRightList">
              </router-outlet>
            </ng-container>

          </mat-sidenav>

          <main
            class="flex-column d-flex flex-column flex-fill"
            [ngClass]="mainGroupClassName$|async">

            <router-outlet></router-outlet>

          </main>

        </mat-sidenav-container>

      </div>

    </mat-sidenav-container>

    <app-component-templates>
    </app-component-templates>

  </ng-container>

</ng-container>

<ng-template #anonymousTemplate>

  <h1>#anonymousTemplate</h1>

</ng-template>

<ng-template #imageSlider let-node="node" let-zone="zone">

  <ng-container *ngIf="node && node.relationships">

    <ng-container *ngIf="node?.relationships?.field_media_videos?.data">

      <ng-container
        *ngIf="node.relationships.field_media_videos.data|union:[node.relationships.field_media_images.data] as mediaRefs">

        <ng-container *ngIf="mediaRefs">

          <ng-container
            *ngIf="(mediaRefs |jaSelectStoreResources:(zone?zone:'drupal'))|async as media">

            <app-images-slider
              class="mx-n4 mx-sm-0"
              [zone]="zone?zone:'drupal'"
              [mediaImages]="media">
            </app-images-slider>

          </ng-container>

        </ng-container>

      </ng-container>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template #newsDescriptionTemplate let-node="node">
  <ng-container *ngIf="node.relationships && node.attributes.field_news_description as description">

    <ng-template
      [ngTemplateOutlet]="descriptionTemplate"
      [ngTemplateOutletContext]="{description: description}">
    </ng-template>

  </ng-container>
</ng-template>

<ng-template #innovationDescriptionTemplate let-node="node">
  <ng-container *ngIf="node.attributes && node.attributes.field_innovation_description as description">

    <ng-template
      [ngTemplateOutlet]="descriptionTemplate"
      [ngTemplateOutletContext]="{description: description, title: 'INNOVATION.BRIEF_DESCRIPTION'}">
    </ng-template>

  </ng-container>
</ng-template>

<ng-template #innovationWhyTemplate let-node="node">
  <ng-container *ngIf="node.attributes && node.attributes.field_innovation_why as description">

    <ng-template
      [ngTemplateOutlet]="descriptionTemplate"
      [ngTemplateOutletContext]="{description: description, title: 'INNOVATION.WHY_IS_INNOVATIVE'}">
    </ng-template>

  </ng-container>
</ng-template>

<ng-template #freeTagsTemplate let-node="node" let-zone="zone">

  <ng-container
    *ngIf="node.relationships && node.relationships.field_free_tagging && node.relationships.field_free_tagging.data as tags">

    <ng-container *ngIf="tags.length > 0">

      <mat-divider class="my-3"></mat-divider>

      <p class="font-weight-bold mb-1" [innerHTML]="'COMMON.KEYWORDS'|translate"></p>

      <mat-chip-list>

        <ng-template ngFor [ngForOf]="tags" let-tagRef>

          <ng-container *ngIf="(tagRef|jaSelectStoreResource:zone|async) as tag">

            <a class="cursor-pointer"
               [routerLink]="[(node.type|listPath)]"
               [queryParams]="{free_tagging_name: tag?.attributes?.name}"
               mat-chip>

              <ng-container *ngIf="(tagRef|jaSelectStoreResource:zone|async) as tag">

                <span>{{tag?.attributes?.name}}</span>

              </ng-container>

            </a>

          </ng-container>

        </ng-template>

      </mat-chip-list>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template #newsCategoryLaunchDateTemplate let-node="node" let-zone="zone">

  <ng-container
    *ngIf="node?.relationships?.field_new_category?.data as tagRef">

    <ng-container
      *ngIf="((tagRef|jaSelectStoreResource:zone|async) || (tagRef|jaSelectStoreResource:'drupal-schema-loader'|async)) as tag">

      <mat-divider class="my-3"></mat-divider>

      <span class="mb-1 text-uppercase" [innerHTML]="tag?.attributes?.name"></span>

      <ng-container *ngIf="node?.attributes?.field_launch_date as launchDate">
        <span class="hyphen-before mb-1">{{('COMMON.LAUNCH_DATE'|translate:{date: (launchDate|date)})}}</span>
      </ng-container>

    </ng-container>


  </ng-container>

</ng-template>

<ng-template #brandTagsTemplate let-node="node" let-zone="zone">

  <ng-container
    *ngIf="node.relationships && node.relationships.field_brands && node.relationships.field_brands.data as tags">

    <ng-container *ngIf="tags.length > 0">

      <mat-divider class="my-3"></mat-divider>

      <p class="font-weight-bold mb-1" [innerHTML]="'COMMON.BRANDS'|translate"></p>

      <mat-chip-list>

        <ng-template ngFor [ngForOf]="tags" let-tagRef>

          <ng-container *ngIf="((tagRef|jaSelectStoreResource:zone|async) || (tagRef|jaSelectStoreResource:'drupal-schema-loader'|async)) as tag">

            <ng-container *ngIf="tag">

              <a class="cursor-pointer"
                 mat-chip
                 [routerLink]="[(node.type|listPath)]"
                 [queryParams]="{field_brands: tag?.attributes?.drupal_internal__tid}">

                <span>{{tag?.attributes?.name}}</span>

              </a>

            </ng-container>

          </ng-container>

        </ng-template>

      </mat-chip-list>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template #awardTagsTemplate let-node="node" let-zone="zone">

  <ng-container
    *ngIf="node.relationships && node.relationships.field_awards && node.relationships.field_awards.data as tags">

    <ng-container *ngIf="tags.length > 0">

      <mat-divider class="my-3"></mat-divider>

      <p class="font-weight-bold mb-1" [innerHTML]="'COMMON.AWARDS'|translate"></p>

      <mat-chip-list>

        <a class="cursor-pointer"
           mat-chip
           *ngFor="let tagRef of tags">

          <ng-container
            *ngIf="((tagRef|jaSelectStoreResource:zone|async) || (tagRef|jaSelectStoreResource:'drupal-schema-loader'|async)) as tag">

            <span>{{tag.attributes.name}}</span>

          </ng-container>

        </a>

      </mat-chip-list>

    </ng-container>

  </ng-container>

</ng-template>

<ng-template #innovationInfosTemplate let-node="node" let-zone="zone">

  <ng-container *ngIf="node && node.attributes && node.relationships">

    <ng-container
      *ngIf="node.relationships.field_innovation_category && node.relationships.field_innovation_category.data as innovationCategoryRef">

      <ng-container
        *ngIf="(((innovationCategoryRef|jaSelectStoreResource:zone)|async) || (innovationCategoryRef|jaSelectStoreResource:'drupal-schema-loader'|async)) as innovationCategory">

        <mat-divider class="my-3"></mat-divider>

        <div class="mb-1">
          <p class="mat-subheading-1 font-weight-bold" [innerHTML]="'INNOVATION.CATEGORY'|translate"></p>
          <div class="text-uppercase" [innerHTML]="innovationCategory.attributes.name"></div>

        </div>

      </ng-container>

    </ng-container>

    <ng-container
      *ngIf="node.relationships.field_innovation_status && node.relationships.field_innovation_status.data as innovationStatusRef">

      <ng-container
        *ngIf="(((innovationStatusRef|jaSelectStoreResource:zone)|async) || (innovationStatusRef|jaSelectStoreResource:'drupal-schema-loader'|async)) as innovationStatus">

        <mat-divider class="my-3"></mat-divider>

        <div class="mb-1">
          <p class="mat-subheading-1 font-weight-bold" [innerHTML]="'INNOVATION.STATUS'|translate"></p>
          <div [innerHTML]="innovationStatus.attributes.name"></div>

        </div>

      </ng-container>

    </ng-container>


    <ng-container *ngIf="node.attributes.field_supplier as supplier">

      <ng-container *ngIf="(userService.isJury$|async) || (userService.isContributor$|async)">

        <mat-divider class="my-3"></mat-divider>

        <div class="mb-1">

          <p class="mat-subheading-1 font-weight-bold" [innerHTML]="'INNOVATION.SUPPLIER'|translate"></p>
          <div [innerHTML]="supplier"></div>

        </div>

      </ng-container>

    </ng-container>

  </ng-container>

</ng-template>


<ng-template #descriptionTemplate let-description="description" let-title="title">

  <div class="mb-3 mt-3 mt-sm-0 description-group mat-body-1">

    <!--
    <ng-container *ngIf="title">
      <p class="body-2" [innerHTML]="title|translate"></p>
    </ng-container>
    -->

    <div [innerHTML]="description.value">

    </div>

  </div>

</ng-template>

<ng-template #simplePageTemplate let-node="node">

  <ng-template
    [ngTemplateOutlet]="defaultNodeTemplate"
    [ngTemplateOutletContext]="{node: node}">
  </ng-template>

</ng-template>

<ng-template #bodyTemplate let-body="body">
  <ng-container *ngIf="body">
    <div [innerHTML]="body.processed ? body.processed : body.value">
    </div>
  </ng-container>
</ng-template>

<ng-template #defaultNodeTemplate let-node="node">

  <ng-container *ngIf="node.attributes as nodeAttributes">

    <div class="container mt-5 page-group">

      <div class="row">

        <div class="col-12">

          <h1 class="mat-title text-uppercase mb-2" [innerHTML]="nodeAttributes.title">
          </h1>
          <ng-template
            [ngTemplateOutlet]="('bodyTemplate'|templates)|async"
            [ngTemplateOutletContext]="{body: nodeAttributes.body}">
          </ng-template>

        </div>

      </div>

    </div>

  </ng-container>

</ng-template>

<ng-template #listLinkTemplate let-data="data" let-template="template" let-classes="classes" let-size="size">

  <a
    (click)="onLinkClicked(data.title, data.content_type)"
    [drupalRouterLink]="data.path"
    [drupalTitle]="data.title"
    [drupalNid]="data.nid"
    [drupalLanguage]="data.langcode"
    [drupalIdentifier]="{id:data.uuid, type: 'node--' + data.content_type}"
    [ngClass]="classes"
    class="d-flex flex-column list-item">

    <ng-template
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{data: data, selected: (currentUrl$|async)==data.path, size:size}">
    </ng-template>

  </a>

</ng-template>

<ng-template #linkButtonTemplate let-path="path" let-title="title">
  <a
    mat-flat-button
    [routerLink]="path"
    [innerHTML]="title" color="accent">
  </a>
</ng-template>

<!--
<ng-template
  #searchHeaderTemplate
  let-routeData="routeData"
  let-routeParams="routeParams"
  let-title="title"
  let-centeredTemplateInfos="centeredTemplateInfos"
  let-actionsTemplate="actionsTemplate"
  let-linkTemplateInfos="linkTemplateInfos">
  <ng-template
    [ngTemplateOutlet]="listHeaderTemplate"
    [ngTemplateOutletContext]="{
      routeData: routeData,
      routeParams: routeParams,
      centeredTemplateInfos: {
        template:templateService.getTemplate('searchIndexResultCount')
      }
  }">
  </ng-template>
</ng-template>
-->

<ng-template #searchResultCountTemplate let-count="data">
  <small [innerHTML]="count"></small>
</ng-template>

<ng-template #myContributionsTemplate>
  <app-my-contributions></app-my-contributions>
</ng-template>

<!--
<ng-template #myContributionsHeaderTemplate>
  <ng-template
    [ngTemplateOutlet]="listHeaderTemplate"
    [ngTemplateOutletContext]="{
    title: 'MY_CONTRIBUTIONS.TITLE'|translate,
    actionsTemplate: templateService.getTemplate('myContributionsActions')
    }">
  </ng-template>
</ng-template>
-->

<ng-template #myShareTemplate>
  <app-my-share></app-my-share>
</ng-template>

<ng-template
    #searchFullTextTemplate
    let-routeData="routeData"
    let-routeParams="routeParams">
  <app-search-free-text [contentTypes]="routeData.contentTypes"
                        [placeholderBase]="routeData.placeholderBase"></app-search-free-text>
</ng-template>

<ng-template
    #searchFullTextDetailTemplate
    let-routeData="routeData"
    let-routeParams="routeParams">

  <ng-container *ngIf="!(responsiveService.isXs$|async)">
    <ng-template
      [ngTemplateOutlet]="searchFullTextTemplate"
      [ngTemplateOutletContext]="{routeData:routeData, routeParams:routeParams}">
    </ng-template>
  </ng-container>

</ng-template>

<ng-template #searchFiltersGroupRefTemplate>

  <ng-template [ngTemplateOutlet]="templateService.getTemplate('searchFiltersGroup')">
  </ng-template>

</ng-template>

<ng-template #componentTemplates>
  <div class="d-flex flex-column flex-fill overflow-y-auto p-5">
    <app-component-templates [render]="true">
    </app-component-templates>
  </div>
</ng-template>

<ng-template #templatesDialog>
  <app-dialog
    [title]="'Templates'"
    [contentTemplate]="componentTemplates">
  </app-dialog>
</ng-template>

<ng-template #appFooterTemplate>
  <mat-divider class="mt-4">
  </mat-divider>
  <p
    class="py-3 text-center color-primary-700 mat-caption"
    [innerHTML]="'APP.FOOTER'|translate">
  </p>
</ng-template>
