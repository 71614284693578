<app-dialog
  [title]="'Réglages de l’application'"
  [contentTemplate]="contentTemplate">

</app-dialog>

<ng-template #contentTemplate>

  <div class="content-group overflow-y-auto">

    <mat-card>
      <mat-card-title>Environnements</mat-card-title>
      <mat-card-subtitle class="d-none d-sm-block" >Cette Application charge ses contenus depuis Drupal. Drupal permet à la fois de consolider
        toutes
        les données et de mettre à disposition de nombreuses fonctionnalités orientées métier.
      </mat-card-subtitle>
      <mat-card-content>
        <p class="d-none d-sm-block" >Dans la chaîne de développement de l’Application, il existe plusieurs environnements afin de pouvoir mener
          les tests. En choisissant un des environnements ci-dessous, vous allez pouvoir choisir sur quel environnement
          Drupal l'Application va redémarrer.</p>
        <form [formGroup]="form">
          <label>Sélectionner un environnement</label>
          <mat-radio-group formControlName="environment" aria-label="Sélectionner un environnement" class="d-flex flex-column">
            <ng-template ngFor [ngForOf]="environnements$|async" let-environment>
              <mat-radio-button class="my-1" [value]="environment.key" color="primary">
                <span class="mr-3">{{environment.label}}</span>
                <a mat-raised-button [href]="environment.backendUrl" target="_blank">Drupal Access</a>
                <a class="d-none d-sm-inline-block" mat-raised-button [href]="environment.backendUrl+'/graphql/explorer'" target="_blank">GraphQL Explorer</a>
              </mat-radio-button>
            </ng-template>
          </mat-radio-group>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="reload()" color="primary">Recharger</button>
      </mat-card-actions>
    </mat-card>

  </div>

</ng-template>
