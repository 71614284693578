import {Pipe, PipeTransform} from '@angular/core';
import {ShortenPipe, StripTagsPipe} from 'ngx-pipes';

@Pipe({
  name: 'shortenDescription'
})
export class ShortenDescriptionPipe implements PipeTransform {

  constructor(
    private stripTagsPipe: StripTagsPipe,
    private shortenPipe: ShortenPipe
  ) {
  }

  transform(text: string): string {
    if (text) {
      return this.shortenPipe.transform(this.stripTagsPipe
        .transform(text), 200, ' (...)', false);
    } else {
      return '';
    }
  }

}
