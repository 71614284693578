<div class="d-flex flex-fill">

  <div
    class="input-group d-flex flex-fill align-items-center"
    matAutocompleteOrigin #origin="matAutocompleteOrigin">

    <ng-container *ngIf="!(newsKeywords$|async) || !(innovationKeywords$|async)">
      <mat-spinner diameter="15"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="(newsKeywords$|async) && (innovationKeywords$|async)">
      <mat-icon svgIcon="icon-search" class="magnify-search mr-2"></mat-icon>
    </ng-container>

    <ng-container *ngIf="routeParamsKeywords$|async as selectedKeywords">

      <mat-chip-list class="mr-2">

        <ng-template ngFor [ngForOf]="selectedKeywords" let-keyword>

          <mat-chip [selectable]="false">
            {{keyword}}
            <mat-icon
              [routerLink]="[]"
              [queryParams]="{free_tagging_name: null}"
              queryParamsHandling="merge"
              matChipRemove>cancel
            </mat-icon>
          </mat-chip>

        </ng-template>

      </mat-chip-list>

    </ng-container>

    <input
      #searchInput
      class="flex-fill"
      [class.disabled]="!(newsKeywords$|async) || !(innovationKeywords$|async)"
      (focus)="searchInputFocus = true"
      (blur)="blurSearchInput()"
      (keyup.enter)="setFreeText(searchInput.value)"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
      [formControl]="searchControl"
      [placeholder]="(((resultCount$|async)?(resultCount$|async):(searchResultService.resultCount$|async))|searchPlaceHolderCount:(routeContentTypes$|async):placeholderBase)|translate:{count:(resultCount$|async)?(resultCount$|async):(searchResultService.resultCount$|async)}"
      autocomplete="off"/>

    <mat-icon
      [class.show]="searchControl.value != ''"
      (click)="resetSearch()"
      svgIcon="icon-remove"
      class="ml-2 empty-search cursor-pointer">
    </mat-icon>

  </div>

</div>

<mat-autocomplete #auto="matAutocomplete" class="search-autocomplete">

  <ng-container *ngIf="searchInputFocus == true">

    <ng-container
      *ngIf="(!(loading$|async) && (results$|async)?.length === 0)">

      <ng-container *ngIf="((freeText$|async) && (results$|async)?.length === 0)">
        <mat-option class="no-result justify-content-center">

          <!--
          <ng-container *ngIf="(!(results$|async) || !(keywords$|async))">
            {{'SEARCH.AUTOCOMPLETE.HELP'|translate}}
          </ng-container>
          -->

          <span [innerHTML]="'SEARCH.AUTOCOMPLETE.NO_RESULT'|translate:{value: (freeText$|async)}"
                class="flex-grow-0"></span>

        </mat-option>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="(loading$|async)">
      <mat-option>
        <span [ngStyle]="{'paddingTop':'9px'}">
          <mat-progress-spinner
            [diameter]="30"
            [color]="'accent'"
            [mode]="'indeterminate'">
        </mat-progress-spinner>
        </span>
      </mat-option>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="showAutoComplete$|async">

    <div class="container-fluid my-3">

      <div class="row">

        <ng-container *ngIf="searchServices$|async as searchServices">

          <ng-template ngFor [ngForOf]="searchServices" let-searchService let-i="index">

            <div [class]="'col-md-'+(12/searchServices.length)" class="col-12">

              <div class="row">

                <div class="order-1 order-sm-0 col-12 mb-2">

                  <ng-container *ngIf="(searchService.contentTypes$|async)[0] as contentType">

                    <ng-template
                      [ngTemplateOutlet]="keywordsTemplate"
                      [ngTemplateOutletContext]="{contentType: contentType, keywords:(keywords$|async)[contentType]}">
                    </ng-template>

                  </ng-container>

                </div>

                <div class="order-0 order-sm-1 col-12 mb-5 mb-sm-0 mt-3">

                  <ng-container
                    *ngIf="((searchService.results$|async)|unique:'uuid') as contentTypeResults">

                    <mat-option class="fake-option"></mat-option>

                    <ng-template
                      [ngTemplateOutlet]="contentTypeResultsTemplate"
                      [ngTemplateOutletContext]="{
                      contentType: (searchService.contentTypes$|async)[0],
                      result: contentTypeResults,
                      totalCount:searchService.resultCount$|async,
                      hasMoreResults: (searchService.hasMoreResults$|async)
                      }">
                    </ng-template>

                  </ng-container>

                </div>

              </div>

            </div>

          </ng-template>

        </ng-container>

      </div>

    </div>

  </ng-container>

</mat-autocomplete>

<ng-template #freeTextResultItemTemplate let-data="data">

  <ng-template [ngTemplateOutlet]="templateService.getTemplate('listLink')"
               [ngTemplateOutletContext]="{data: data, template: listItemTemplate, classes: 'overflow-hidden'}">
  </ng-template>

</ng-template>

<ng-template #listItemTemplate let-data="data">

  <div class="d-flex flex-fill overflow-hidden align-items-center">

    <ng-container *ngIf="(data|thumbUrl:'crop') as image">

      <img class="result-thumb" [src]="image.url"/>

    </ng-container>

    <div class="d-flex flex-fill flex-column justify-content-center ml-3">

      <h3 class="m-0 mb-n1" clampy="1">{{data.title}}</h3>
      <small class="text-uppercase mt-1">{{data.innovation_category_name || data.news_category_name}}</small>

    </div>

  </div>

</ng-template>

<ng-template #keywordsTemplate let-contentType="contentType" let-keywords="keywords">

  <ng-container *ngIf="keywords?.length > 0">
    <h3 class="result-sentence mb-3" [innerHTML]="('SEARCH.SUGGESTED_KEYWORDS.'+contentType)|translate"></h3>
  </ng-container>

  <ng-container *ngIf="keywords?.length === 0">
    <h3 class="result-sentence mb-3" [innerHTML]="('SEARCH.NOT_FOUND.KEYWORDS.'+contentType)|translate"></h3>
  </ng-container>

  <ng-container *ngIf="!keywords || keywords.length === 0">
    <mat-chip class="invisible">
      <a>No result</a>
    </mat-chip>
  </ng-container>

  <ng-container *ngIf="keywords">

    <mat-chip-list>

      <ng-template ngFor [ngForOf]="keywords|unique:'latinised'" let-keyword>

        <mat-chip>
          <a [routerLink]="[contentType|listPath]"
             [queryParams]="{free_tagging_name: keyword.label}">
            {{keyword.label}}
            <small class="count">{{keyword.count}}</small>
          </a>
        </mat-chip>

      </ng-template>

    </mat-chip-list>

  </ng-container>

</ng-template>

<ng-template #contentTypeResultsTemplate
             let-contentType="contentType"
             let-result="result"
             let-totalCount="totalCount"
             let-hasMoreResults="hasMoreResults">

  <ng-container *ngIf="result.length > 0">


    <a
      class="mb-2 align-self-center see-all-result-button"
      [routerLink]="[contentType|listPath]" [queryParams]="{freeText: searchInput.value}"
      queryParamsHandling="merge"
      mat-flat-button color="accent">
      {{(('SEARCH.SEE_ALL_RESULTS.' + contentType)|translate:{value: totalCount})}}
    </a>

  </ng-container>

  <ng-container *ngIf="result.length === 0">
    <h3 class="mb-3 result-sentence"
        [innerHTML]="('SEARCH.NOT_FOUND.'+contentType)|translate"></h3>
  </ng-container>

  <ng-template ngFor [ngForOf]="result" let-item>

    <mat-option class="d-flex flex-column px-0 search-autocomplete-option">
      <ng-template
        [ngTemplateOutlet]="freeTextResultItemTemplate"
        [ngTemplateOutletContext]="{data: item, template: listItemTemplate}">
      </ng-template>
    </mat-option>

  </ng-template>

</ng-template>
