import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'menuItemShortLabel'
})
@Injectable()
export class MenuItemShortLabelPipe implements PipeTransform {

  constructor() {
  }

  transform(path: string): string {
    const paths: any = {
      '/my-contributions': 'CONTRIBUTIONS',
      '/my-inspirations': 'INSPIRATIONS',
      '/my-share': 'BASKET',
      '/poll': 'POLL'
    };
    return 'USER_MENU.' + paths[path];
  }

}
