import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store/reducers';
import {getBreadcrumbs} from '../store/reducers';
import {Observable} from 'rxjs';
import {Breadcrumb} from '../models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  breadcrumbs$: Observable<Breadcrumb[]> = this.store.pipe(select(getBreadcrumbs));

  constructor(
    private store: Store<fromRoot.State>,
  ) {
  }

}
