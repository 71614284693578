import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {InnovationIndexItem} from '../apollo/innovation-index-item';

@Pipe({
  name: 'listItemFirstImage'
})
@Injectable()
export class ListItemFirstImagePipe implements PipeTransform {

  constructor() {

  }


  transform(value: InnovationIndexItem): any {

    let imageInfos: any;

    if (value.thumbnail_url && value.thumbnail_url.length > 0) {
      imageInfos = {
        url: value.thumbnail_url[0],
        size: {
          width: value.thumbnail_width[0],
          height: value.thumbnail_height[0]
        }
      };
    }

    return imageInfos;
  }
}
