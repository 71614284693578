import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserLayoutComponent} from './layouts/user-layout/user-layout.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared.module';

@NgModule({
  declarations: [UserLayoutComponent],
  exports: [
    UserLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ]
})
export class TemplatesModule {
}
