import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {getCurrentLanguage} from '../store/reducers';
import {distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {DrupalMenuItem} from '@ngx-mil-drupal/models/menu.model';
import {getMenu, getMenuLoaded, getMenuLoading} from '@ngx-mil-drupal/store/reducers';
import {LoadMenu} from '@ngx-mil-drupal/store/actions/menu.actions';
import {UserService} from './user.service';
import {UNSEEN_INNOVATIONS, UNSEEN_NEWS} from '../providers/app-core.providers';

export class Menu {

  menuItems: MenuItem[];

}

export class MenuItem {

  label?: string;
  translateLabel?: string;
  path: { [lang: string]: string };
  icon?: string;
  count?: number;

}

@Injectable({
  providedIn: 'root'
})
export class MenusService {

  readonly mainMenuId = 'main';
  readonly secondaryMenuId = 'secondary';
  readonly userMenuId = 'user';
  readonly legalMenuId = 'legal';

  menus: { [menuId: string]: Menu } = {};

  homeLink: MenuItem;

  languages$: Observable<string> = this.store.pipe(select(getCurrentLanguage), distinctUntilChanged());

  $legalMenu: Observable<DrupalMenuItem[]> = this.createObservableMenu('legal-innovation');

  $mainMenu: Observable<DrupalMenuItem[]> = this.createObservableMenu('main-innovation').pipe(
    filter(menu => !!menu),
    map(menu => {
      // console.log('menu', menu);
      let newsMenuItem: DrupalMenuItem = menu.filter(menuItem => menuItem.title === 'news').shift();
      let innovationsMenuItem: DrupalMenuItem = menu.filter(menuItem => menuItem.title === 'innovations').shift();
      newsMenuItem = {
        ...newsMenuItem,
        count$: this.unseenNews$
      };

      innovationsMenuItem = {
        ...innovationsMenuItem,
        count$: this.unseenInnovations$
      };

      return [menu[0], newsMenuItem, innovationsMenuItem];
    })
  );

  $corporateMenu: Observable<DrupalMenuItem[]> = this.createObservableMenu('secondary-innovation');

  $userMenu: Observable<DrupalMenuItem[]> = this.createObservableMenu('user-innovation')
    .pipe(
      withLatestFrom(this.userService.isJury$),
      filter(([userMenu, isJury]) => !!userMenu),
      map(([userMenu, isJury]) => {
        if (!isJury) {
          userMenu = userMenu.filter(menuItem => menuItem.relative !== '/poll');
        }
        return userMenu;
      })
    );

  constructor(
    @Inject(UNSEEN_NEWS) private readonly unseenNews$: Observable<number>,
    @Inject(UNSEEN_INNOVATIONS) private readonly unseenInnovations$: Observable<number>,
    private store: Store<any>,
    private userService: UserService
  ) {
  }

  createObservableMenu(menuId: string): Observable<DrupalMenuItem[]> {
    return this.languages$.pipe(
      map(language => {
        return language;
      }),
      switchMap(language => this.store
        .pipe(
          select(getMenu(menuId, language)),
          withLatestFrom(
            this.languages$,
            this.store.pipe(select(getMenuLoading(menuId, language))),
            this.store.pipe(select(getMenuLoaded(menuId, language)))
          ),
          tap(([menu, lang, loading, loaded]) => {
            if (loading === null && loaded !== true) {
              this.store.dispatch(new LoadMenu(menuId, lang));
            }
          }),
          take(1),
          switchMap(([menu, lang, loading, loaded]) => {
            return this.store.pipe(select(getMenu(menuId, language)));
          }))
      )
    );
  }

  getMainMenu(): Observable<DrupalMenuItem[]> {
    return this.$mainMenu;
  }

  getUserMenu(): Observable<DrupalMenuItem[]> {
    return this.$userMenu;
  }

  getSecondaryMenu(): Observable<DrupalMenuItem[]> {
    return this.$corporateMenu;
  }

  getLegalMenu(): Observable<DrupalMenuItem[]> {
    return this.$legalMenu;
  }

  getHomeLink(): MenuItem {
    return this.homeLink;
  }


}
