import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {InnovationIndexItem} from '../../../apollo/innovation-index-item';

@Component({
  selector: 'app-innovation-renderer',
  templateUrl: './innovation-renderer.component.html',
  styleUrls: ['./innovation-renderer.component.scss']
})
export class InnovationRendererComponent implements OnInit {

  @Input()
  data: InnovationIndexItem;

  isClamped = false;

  timeOut: any;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
  }

  onClampedChanged(event) {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      this.isClamped = event;
      this.renderer.addClass(this.elementRef.nativeElement, 'ready');
    }, 100);

  }

}
