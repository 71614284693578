import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {PollService} from '../services/poll.service';
import {NgrxJsonApiDefinitions} from '../ngrx-json-api/ngrx-json-api-definitions';
import {StoreResource} from 'ngrx-json-api';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {OpenAlert} from '../store/actions/app-core.actions';
import {TranslateService} from '@ngx-translate/core';
import {ResponsiveService} from '../services/responsive.service';

@Injectable({
  providedIn: 'root'
})
export class PollGuard implements CanActivate {

  constructor(
    private pollService: PollService,
    private router: Router,
    private store: Store<any>,
    private translate: TranslateService,
    private responsiveService: ResponsiveService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.pollService.hasContentsInVotesStatus$.pipe(
      switchMap(hasContentsInVotesStatus => {
        if (hasContentsInVotesStatus) {
          if (!next?.firstChild?.params?.id) {
            return combineLatest([
              this.pollService.waitingForVotesContents$,
              this.pollService.votedContents$,
              this.responsiveService.isXs$
            ]).pipe(
              map(([waitingForVoteContents, votedContents, isXs]: [StoreResource[], StoreResource[], boolean]) => {
                if (!isXs) {
                  let firstInnovation: StoreResource;
                  if (waitingForVoteContents && waitingForVoteContents.length > 0) {
                    firstInnovation = waitingForVoteContents.shift();
                    return this.router.createUrlTree(['/poll', NgrxJsonApiDefinitions.innovation.type, firstInnovation.id]);
                  } else if (votedContents && votedContents.length > 0) {
                    firstInnovation = votedContents.shift();
                    return this.router.createUrlTree(['/poll', NgrxJsonApiDefinitions.innovation.type, firstInnovation.id]);
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }

              }),
              take(1),
              catchError(error => {
                console.error({error});
                this.store.dispatch(new OpenAlert(this.translate.instant('ALERTS.NO_POLL.MESSAGE')));
                return of(false);
              })
            );
          } else {
            return of(true);
          }
        } else {
          this.store.dispatch(new OpenAlert(this.translate.instant('ALERTS.NO_POLL.MESSAGE')));
          return of(false);
        }
      }),
      catchError(error => {
        console.error({error});
        this.store.dispatch(new OpenAlert(this.translate.instant('ALERTS.NO_POLL.MESSAGE')));
        return of(false);
      })
    );

  }

}
