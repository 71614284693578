import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {UserPaths} from '../models/user-path.model';
import {UserService} from '../services/user.service';
import {MyContributionsService} from '../services/my-contributions.service';
import {PollService} from '../services/poll.service';

@Pipe({
  name: 'userIconCount'
})
@Injectable()
export class UserIconCountPipe implements PipeTransform {

  constructor(
    private userService: UserService,
    private myContributionsService: MyContributionsService,
    private pollService: PollService
  ) {

  }

  transform(path: string): Observable<number> {
    path = path.substring(1);
    switch (path) {
      case UserPaths.Contributions:
        return this.myContributionsService.contributionsCount$;
        break;
      case UserPaths.Inspirations:
        return this.userService.inspirationsCount$;
        break;
      case UserPaths.Share:
        return this.userService.cartCount$;
        break;
      case UserPaths.Poll:
        return this.pollService.waitingForVotesCount$;
        break;
    }
  }

}
