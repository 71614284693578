import {Component, Input, OnInit} from '@angular/core';
import {SwiperInterface} from '../../../models/swiper-interface';
import {ImageFile} from '@ngx-mil-drupal/models/image-file';
import {LanguageSwitcherService} from '../../../services/language-switcher.service';

@Component({
  selector: 'app-swiper-thumbs',
  templateUrl: './swiper-thumbs.component.html',
  styleUrls: ['./swiper-thumbs.component.scss']
})
export class SwiperThumbsComponent implements OnInit {
  get data(): ImageFile[] {
    return this._data;
  }

  get swiper(): SwiperInterface {
    return this._swiper;
  }

  private _data: ImageFile[];
  @Input()
  set data(value: ImageFile[]) {
    this._data = value;
  }

  @Input()
  zone: string;

  _swiper: SwiperInterface;

  @Input()
  set swiperRef(value: SwiperInterface) {
    this._swiper = value;
  }

  constructor(
    public languageSwitcherService: LanguageSwitcherService
  ) {
  }

  ngOnInit() {
  }

}
