import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Integrations } from '@sentry/tracing';
import { init as initSentry } from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { VERSION_INFO } from './environments/version';

if (environment.production) {
  enableProdMode();
  initSentry({
    dsn: 'https://6f24506e00a448a89b798e9c84ba2b24@o231483.ingest.sentry.io/1848341',
    release: VERSION_INFO.sentryRelease,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
