import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MediaImage} from '@ngx-mil-drupal/models/media-image';
import {ImageFile} from '@ngx-mil-drupal/models/image-file';
import {CustomSwiperComponent} from '../swipper/swiper.component';
import {ReplaySubject} from 'rxjs';
import {TrurthifyPipe} from 'ngx-pipes';
import {YouTubePlayer} from '@angular/youtube-player';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-images-slider',
  templateUrl: './images-slider.component.html',
  styleUrls: ['./images-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrurthifyPipe]
})
export class ImagesSliderComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  images: ImageFile[];

  mediaImages$: ReplaySubject<MediaImage[]> = new ReplaySubject<MediaImage[]>(1);

  _mediaImages: MediaImage[];
  @Input()
  set mediaImages(value: MediaImage[]) {
    if (this._mediaImages) {
      if (JSON.stringify(this._mediaImages) !== JSON.stringify(value)) {
        this._mediaImages = this.trurthifyPipe.transform(value);
        this.mediaImages$.next(this._mediaImages);
        if (this.swiperComponent) {
          this.swiperComponent.goToIndex(0);
        }
      }
    } else {
      this._mediaImages = this.trurthifyPipe.transform(value);
      this.mediaImages$.next(this._mediaImages);
      if (this.swiperComponent) {
        this.swiperComponent.goToIndex(0);
      }
    }

  }

  @Input()
  zone: string;

  @ViewChild('swiper', {read: CustomSwiperComponent})
  swiperComponent: CustomSwiperComponent;

  @ViewChildren('youTubePlayer', {read: YouTubePlayer})
  youTubePlayers: QueryList<YouTubePlayer>;

  youTubePlayerVars: YT.PlayerVars = {
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
    playsinline: 1
  };

  constructor(
    private trurthifyPipe: TrurthifyPipe
  ) {
  }

  ngOnDestroy() {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.swiperComponent.slideIndex$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(slideIndex => {
        this.youTubePlayers.forEach(youTubePlayer => {
          if (youTubePlayer && youTubePlayer.getPlayerState() === YT.PlayerState.PLAYING) {
            youTubePlayer.pauseVideo();
          }
        });
      });
  }

}
