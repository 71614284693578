<ng-container *ngIf="data">

</ng-container>

<app-ngx-masonry
  #masonryLayout
  class="w-100 overflow-hidden">

  <ng-template ngFor [ngForOf]="data" let-dataItem [ngForTrackBy]="trackByFn">

    <div appNgxMasonryItem
         [class.small-size]="(listThumbSize$|async)=='small'"
         [class.sticky]="dataItem.sticky === true"
         class="masonry-item">

      <div class="masonry-item-content-group">

        <ng-template
          [ngTemplateOutlet]="rendererTemplate"
          [ngTemplateOutletContext]="{data:dataItem}">
        </ng-template>

      </div>

    </div>

  </ng-template>

  <!--
  <ng-container *ngIf="data && data.length > 0 && !loading && !end">

    <div appNgxMasonryItem
         [class.small-size]="(listThumbSize$|async)=='small'"
         class="masonry-item">

      <div class="masonry-item-content-group load-more-group">

        <div class="content d-flex align-items-center justify-content-center">
          <span>Load More</span>
        </div>

      </div>

    </div>

  </ng-container>
  -->

  <ng-container *ngIf="loading">

    <div appNgxMasonryItem
         [class.small-size]="(listThumbSize$|async)=='small'"
         class="masonry-item">

      <div class="masonry-item-content-group load-more-group">

        <div class="content d-flex align-items-center justify-content-center">
          <mat-spinner diameter="30"></mat-spinner>
        </div>

      </div>

    </div>

  </ng-container>

  <!--
  <ng-container *ngIf="data && !loading && end">

    <div appNgxMasonryItem
         [class.small-size]="(listThumbSize$|async)=='small'"
         class="masonry-item">

      <div class="masonry-item-content-group load-more-group">

        <div class="content d-flex align-items-center justify-content-center">
          <span>End of list</span>
        </div>

      </div>

    </div>

  </ng-container>
  -->

</app-ngx-masonry>

<ng-container *ngIf="data && data.length === 0 && noDataMessage">

  <div class="d-flex w-100 h-100 position-absolute top-0 left-0 justify-content-center align-items-center">

    <span class="text-center px-3" [innerHTML]="noDataMessage|translate"></span>

  </div>

</ng-container>

<!--
<ng-template #loadingTemplate>
  <mat-spinner [diameter]="30" color="primary"></mat-spinner>
</ng-template>
-->
