import {Injectable} from '@angular/core';
import {AppConfigService} from '@config/app-config.service';
import {take} from 'rxjs/operators';
import {Config, NgxDrupalUploaderService} from '@madeinlune/ngx-drupal-uploader';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class MhInnoUploaderService extends NgxDrupalUploaderService {

  public config: Config;

  constructor(
    public appConfig: AppConfigService,
    private oauthService: OAuthService
  ) {
    super();

    this.appConfig.appConfig$.pipe(take(1)).subscribe(appConfigResult => {

      const config: Config = {
        jsonApiUrl: appConfig.getConfig().jsonapiUrl,
        drupalUrl: appConfig.getConfig().backendUrl,
        accessToken: this.oauthService.getAccessToken(),
        relationDescriptors: {
          image: {relation: 'image', relationType: 'media--image', relationName: 'field_media_image'},
          audio: {relation: 'audio_file', relationType: 'media--audio_file', relationName: 'field_media_audio_file'},
          application: {relation: 'file', relationType: 'media--file', relationName: 'field_media_file'},
          video: {relation: 'video', relationType: 'media--video', relationName: 'field_media_video_file'}
        }
      };

      this.config = config;
    });

  }
}
