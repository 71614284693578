import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {PathService} from './path.service';
import {NgrxJsonApiService, Query, StoreResource} from 'ngrx-json-api';
import {AppConfigService} from '@config/app-config.service';
import {PathInfos} from './models/path-infos.model';
import {QueriesMap} from '@ngx-mil-drupal/models/queries-map';
import {DrupalService} from '@ngx-mil-drupal/drupal.service';
import {RoutesMap} from '@ngx-mil-drupal/models/routes-map.model';
import {Observable} from 'rxjs';
import {SetCurrentLanguage} from '../../app/store/actions/app-core.actions';
import {DrupalPathState} from '@ngx-mil-drupal/store/reducers/path.reducers';
import {SetPathInfos} from '@ngx-mil-drupal/store/actions/path.actions';
import {getPathInfosByPath} from '@ngx-mil-drupal/store/reducers';

@Injectable()
export class DrupalPathGuard implements CanActivate {

  public static DRUPAL_ZONE = 'drupal';

  constructor(
    private store: Store<DrupalPathState>,
    private router: Router,
    private pathService: PathService,
    private ngrxJsonApiService: NgrxJsonApiService,
    private appConfig: AppConfigService,
    private drupalService: DrupalService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const url: string = state.url.substring(1);

    return this.store.pipe(
      select(getPathInfosByPath(url)),
      take(1),
      switchMap(localPathInfos => {
        if (localPathInfos) {
          this.pathService.resolve(url).subscribe();
          return this.managePathInfos(url, JSON.parse(JSON.stringify(localPathInfos)));
        } else {
          return this.pathService.resolve(url).pipe(
            switchMap(remotePathInfos => {
              if (!remotePathInfos.error) {
                return this.managePathInfos(url, remotePathInfos);
              } else {
                return null;
              }
            })
          );
        }
      })
    );

  }

  managePathInfos(url: string, pathInfos: PathInfos): Observable<UrlTree> {

    const language: string = pathInfos.language;

    this.store.dispatch(new SetCurrentLanguage(language));

    const routeData: any = pathInfos.entity;
    const typeBundle: string = routeData.type + '--' + routeData.bundle;
    const uuid: string = routeData.uuid;

    let drupalContentQuery: Query;

    const queries: QueriesMap = this.drupalService.config.queries;
    const routes: RoutesMap = this.drupalService.config.routes;

    if (!queries[typeBundle]) {
      drupalContentQuery = {
        queryId: 'drupalNode-' + uuid,
        type: typeBundle,
        id: uuid,
        params: {}
      };
    } else {
      drupalContentQuery = JSON.parse(JSON.stringify(queries[typeBundle]));
      drupalContentQuery.id = uuid;
    }

    this.ngrxJsonApiService.getZone(DrupalPathGuard.DRUPAL_ZONE + '-' + language).putQuery({query: drupalContentQuery, fromServer: true});

    return this.ngrxJsonApiService.getZone(DrupalPathGuard.DRUPAL_ZONE + '-' + language).selectStoreResource({
      type: typeBundle,
      id: uuid
    }).pipe(
      filter(resource => !!resource),
      map((resource: StoreResource) => {
        let newRoute: Route;
        let context: string;
        if (pathInfos.isHomePath) {
          context = 'home';
        } else if (resource.attributes.field_properties && resource.attributes.field_properties.context) {
          context = resource.attributes.field_properties.context;
        } else {
          context = pathInfos.entity.bundle;
        }

        if (!routes[context]) {
          context = 'default';
        }
        newRoute = {...routes[context]};
        newRoute.data = {
          ...newRoute.data,
          resourceIdentifier: {
            type: pathInfos.entity.type + '--' + pathInfos.entity.bundle,
            id: pathInfos.entity.uuid
          },
          context: context,
          nid: pathInfos.entity.id
        };
        let canonicalUrl: string;
        if (pathInfos.metatags.canonical_url.attributes.href.indexOf(this.appConfig.getConfig().backendUrl) > -1) {
          canonicalUrl = pathInfos.metatags.canonical_url.attributes.href.replace(
            this.appConfig.getConfig().backendUrl, ''
          ).substring(1);
        } else {
          canonicalUrl = pathInfos.metatags.canonical_url.attributes.href;
        }
        //
        pathInfos.metatags.canonical_url.attributes.href = canonicalUrl;
        //
        newRoute.path = canonicalUrl;
        newRoute.data = {
          ...newRoute.data,
          id: pathInfos.entity.id,
          uuid: pathInfos.entity.uuid,
          bundle: pathInfos.entity.bundle,
          type: pathInfos.entity.type
        };
        this.router.config.unshift(
          newRoute
        );

        this.drupalService.addActivatedRoute(context, newRoute);
        this.store.dispatch(new SetPathInfos(pathInfos));
        // this.router.navigateByUrl(newRoute.path);
        return this.router.parseUrl(newRoute.path);
      })
    );


  }
}
