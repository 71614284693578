<ng-container *ngIf="render">

  <h3>Window</h3>

  <button
    (click)="openAlertWindow()"
    mat-flat-button
    class="mr-3"
    color="accent">
    <span>Open Alert Window</span>
  </button>

  <button
    (click)="openWindow()"
    mat-flat-button
    class="mr-3"
    color="accent">
    <span>Open Window</span>
  </button>

</ng-container>

<ng-template #windowBaseTemplate
             let-title="title"
             let-contentTemplate="contentTemplate"
             let-actionsTemplate="actionsTemplate"
             let-data="data">

  <app-dialog
    [title]="title"
    [contentTemplate]="contentTemplate"
    [actionsTemplate]="actionsTemplate"
    [data]="data">
  </app-dialog>

</ng-template>

<ng-template #alertWindowContentTemplate let-data="data">

  <div class="d-block py-3 px-3 text-center subheading-2 text-uppercase">
    <p [innerHTML]="data"></p>
  </div>

</ng-template>

<ng-template #alertWindowActionsTemplate>

  <div class="mb-2">

    <button mat-flat-button mat-dialog-close color="accent">
      <span [innerHTML]="'OK'"></span>
    </button>

  </div>

</ng-template>

<ng-template
    #alertWindowTemplate let-data>

  <ng-template
    [ngTemplateOutlet]="windowBaseTemplate"
    [ngTemplateOutletContext]="{
    contentTemplate: alertWindowContentTemplate,
    actionsTemplate: alertWindowActionsTemplate,
    data: data}">
  </ng-template>

</ng-template>

<ng-template
    #windowTemplate let-data>

  <ng-template
    [ngTemplateOutlet]="windowBaseTemplate"
    [ngTemplateOutletContext]="{
    title: data.title,
    contentTemplate:data.contentTemplate?templatesService.getTemplate(data.contentTemplate):null,
    actionsTemplate:data.actionsTemplate?templatesService.getTemplate(data.actionsTemplate):null,
    data: data}">
  </ng-template>

</ng-template>

<ng-template
    #pwaUpdateTemplate let-data>

  <div class="pwa-update-group d-flex flex-column flex-fill align-items-center justify-content-center mt-5 mb-3">

    <img class="app-icon" src="assets/icons/icon-192x192.png"/>

    <p class="mat-subheading-2 normalcase text-center mt-3" [innerHTML]="'PWA.UPDATE_MESSAGE'|translate">
    </p>
  </div>

</ng-template>

<ng-template #pwaUpdateActionsTemplate>

  <div class="pwa-update-actions-group d-flex flex-fill button-group two-buttons justify-content-around my-2">

    <button color="primary" class="stroke-button" mat-flat-button mat-dialog-close>
      <span [innerHTML]="'ACTIONS.LATER'|translate"></span>
    </button>

    <button
      (click)="pwaService.reload()"
      class="reload-button"
      color="accent"
      mat-flat-button
      mat-dialog-close>
      <span [innerHTML]="'ACTIONS.RELOAD'|translate"></span>
    </button>

  </div>

</ng-template>

<ng-template
    #clearLocalStorageTemplate let-data>

  <div class="d-flex flex-column flex-fill align-items-center justify-content-center mt-5 mb-3">

    <p class="mat-subheading-2 normalcase text-center mt-3" [innerHTML]="'PWA.ALERTS.CLEAR_STORAGE.MESSAGE'|translate">
    </p>
  </div>

</ng-template>

<ng-template #clearLocalStorageActionsTemplate>

  <div class="pwa-update-actions-group d-flex flex-fill button-group two-buttons justify-content-around my-2">

    <button color="primary" class="stroke-button" mat-flat-button mat-dialog-close>
      <span [innerHTML]="'ACTIONS.CANCEL'|translate"></span>
    </button>

    <button
      (click)="confirmClearLocalStorage()"
      class="reload-button"
      color="accent"
      mat-flat-button
      mat-dialog-close>
      <span [innerHTML]="'SETTINGS.ADVANCED.CLEAR.TITLE'|translate"></span>
    </button>

  </div>

</ng-template>

<ng-template
    #syncDataTemplate let-data="data">

  <div class="d-flex flex-column flex-fill align-items-center justify-content-center mt-5 mb-3">

    <p class="mat-subheading-2 normalcase text-center mt-3" [innerHTML]="((data.syncData === true)?'PWA.ALERTS.ENABLE_SYNC_DATA.MESSAGE':'PWA.ALERTS.DISABLE_SYNC_DATA.MESSAGE')|translate">
    </p>
  </div>

</ng-template>

<ng-template #syncDataActionsTemplate let-data="data">

  <div class="pwa-update-actions-group d-flex flex-fill button-group two-buttons justify-content-around my-2">

    <button color="primary" class="stroke-button" mat-flat-button mat-dialog-close>
      <span [innerHTML]="'ACTIONS.CANCEL'|translate"></span>
    </button>

    <button
      (click)="confirmSyncData(data.syncData)"
      class="reload-button"
      color="accent"
      mat-flat-button
      mat-dialog-close>
      <span [innerHTML]="'ACTIONS.YES'|translate"></span>
    </button>

  </div>

</ng-template>

<ng-template #userAccountTemplate>

  <app-user-account>
  </app-user-account>

</ng-template>

<ng-template #settingsTemplate>

  <app-preferences>
  </app-preferences>

</ng-template>
