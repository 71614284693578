import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';

export type FormTab = 'info' | 'media' | 'submit';

@Injectable({
  providedIn: 'root'
})
export class MyContributionsFormService {

  currentTabEmitter: EventEmitter<FormTab> = new EventEmitter<FormTab>();

  currentTab$: Observable<FormTab> = this.currentTabEmitter.asObservable().pipe(startWith('info' as const));

  saveEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }


}
